<template>
    <b-field class="search-input" :expanded="expanded">
        <template slot="label">
            <div class="columns is-gapless">
                <div class="column">{{ label }}</div>
                <div class="column" v-if="$slots.default != null">
                    <slot></slot>
                </div>
            </div>
        </template>
        <component :is="cmpType" v-bind="cmpProps" :size="size" v-model="computedValue" :data="autocompleteFilter" />
    </b-field>
</template>

<script>
    import { fieldSearchTypes } from '@/helpers/staticValues';

    export default {
        name: 'FieldSearchInput',

        components: {
            CheckboxButtonsControl: () => import('@/components/formControls/base/CheckboxButtonsControl'),
            DropdownControl: () => import('@/components/formControls/dropdown/DropdownControl'),
            RadioBtnsInputControl: () => import('@/components/formControls/input/RadioBtnsInputControl')
        },

        props: {
            value: {
                required: true
            },
            label: String,
            allowMultiple: Boolean,
            autocomplete: Boolean,
            // used to populate the autocomplete options
            options: {
                validator(value) {
                    return Array.isArray(value);
                }
            },
            type: {
                required: true,
                validator: (value) => Object.values(fieldSearchTypes).indexOf(value) !== -1
            },
            size: String,
            expanded: {
                type: Boolean,
                default: true
            },
            // other options we come up with which might have some value
            misc: Object,
        },

        data() {
            return {
                localValue: this.value,
                cmpType: null,
                cmpProps: null
            };
        },

        computed: {
            computedValue: {
                get() {
                    return this.localValue;
                },
                set(value) {
                    this.localValue = value;
                    this.$emit('input', value);
                }
            },

            // for use when you're using autocomplete with string values
            autocompleteFilter() {
                if (this.type === fieldSearchTypes.string && this.computedValue != null) {
                    return this.options.filter(option => {
                        return option.toString().toUpperCase().indexOf(this.computedValue.toUpperCase()) >= 0;
                    });
                }
                else {
                    return this.options;
                }
            }
        },

        methods: {
            setupNumberInput() {
                let props = {};
                props = Object.assign({}, this.misc);

                props.value = this.value;

                if (this.allowMultiple) {
                    this.cmpType = 'b-taginput';
                    props.autocomplete = this.autocomplete;
                    props.openOnFocus;
                }
                else if (this.autocomplete) {
                    this.cmpType = 'b-autocomplete';
                }
                else {
                    this.cmpType = 'input';
                    props.type = 'number';
                }

                if (!this.allowMultiple) props.maxtags = 1;
                props.openOnFocus = true;
                props.allowNew = true;
                props.data = this.options;

                this.cmpProps = props;
            },

            setupTextInput() {
                let props = {};
                props = Object.assign({}, this.misc);

                props.value = this.value;

                if (this.allowMultiple) {
                    this.cmpType = 'b-taginput';
                    props.autocomplete = this.autocomplete;
                }
                else if (this.autocomplete) {
                    this.cmpType = 'b-autocomplete';
                }
                else {
                    this.cmpType = 'input';
                    props.type = 'text';
                }

                if (!this.allowMultiple) props.maxtags = 1;
                props.openOnFocus = true;
                props.allowNew = true;

                this.cmpProps = props;
            },

            setupBooleanInput() {
                let props = {};
                props = Object.assign({}, this.misc);

                props.value = this.value;

                this.cmpType = 'radio-btns-input-control';

                props.options = this.options;

                this.cmpProps = props;
            },

            setupEnumInput() {
                let props = {};
                props = Object.assign({}, this.misc);

                props.value = this.value;

                if (this.allowMultiple) {
                    if (props.buttons) {
                        this.cmpType = 'checkbox-buttons-control';
                        props.options = this.options;
                    }
                    else {
                        this.cmpType = 'enum-taginput';
                        props.data = this.options;
                    }

                    props.autocomplete = true;
                }
                else {
                    this.cmpType = 'dropdown-control';
                    props.options = this.options;
                }

                this.cmpProps = props;
            }
        },

        watch: {
            value(newValue) {
                this.localValue = newValue;
            },

            type: {
                handler(newType) {
                    switch (newType) {
                    case fieldSearchTypes.number:
                        this.setupNumberInput();
                        break;
                    case fieldSearchTypes.string:
                        this.setupTextInput();
                        break;
                    case fieldSearchTypes.boolean:
                        this.setupBooleanInput();
                        break;
                    case fieldSearchTypes.enum:
                        this.setupEnumInput();
                        break;
                    }
                },
                immediate: true
            },

            options: {
                handler(newValue) {
                    if (this.cmpProps != null) {
                        if ('options' in this.cmpProps) {
                            this.cmpProps.options = newValue;
                        }
                        else if ('data' in this.cmpProps) {
                            this.cmpProps.data = newValue;
                        }
                    }
                },
                immediate: true
            }
        },
    };
</script>

<style scoped>
div.field.search-input {
    margin-bottom: 0.5rem;
}
</style>