import axios from './index';
const _routePrefix = '/api/employers/';
const _searchPrefix = '/api/opportunities/';

export const getOpportunitySearchResults = () => {
    return axios.get(_searchPrefix);
};

export const getOpportunitiesByEmployer = (employerId) => {
    return axios.get(`${_routePrefix}${employerId}/opportunities`);
};

export const getOpportunity = (employerId, opportunityId) => {
    return axios.get(`${_routePrefix}${employerId}/opportunities/${opportunityId}`);
};

export const addOpportunity = (employerId, opportunity) => {
    return axios.post(`${_routePrefix}${employerId}/opportunities`, opportunity);
};

export const updateOpportunity = (employerId, opportunity) => {
    return axios.put(`${_routePrefix}${employerId}/opportunities/${opportunity.id}`, opportunity);
};

export const deleteOpportunity = (employerId, opportunityId) => {
    return axios.delete(`${_routePrefix}${employerId}/opportunities/${opportunityId}`);
};

export default 'OpportunityApi';