import VuexPersistence from 'vuex-persist';

/* STORE: Enums */
import * as actions from './actions';
import * as getters from './getters';

const vuexLocal = new VuexPersistence({
    key: 'enumStore',
    storage: window.sessionStorage
});

const state = {
    enums: [],
    count: 0
};

const mutations = {
    LOAD_ENUMS(state, result) {
        state.enums = result.data;
        state.count = result.count;
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
    plugins: [vuexLocal.plugin]
};
