<template>
    <Card title="Participation Plan">
        <form-validator v-for="plan in participationPlans" :key="plan.fairDate">
            <field-validator :name="`attendance on ${$options.filters.date(plan.fairDate, 'MM/DD/YY')}`">
                <AffirmOrDenyQuestionControl
                    center
                    outputStyle="inline"
                    :value="plan.isAttending ? 'Yes' : 'No'"
                    :question="`Will you attend the Fair on (${$options.filters.date(plan.fairDate, 'MM/DD/YY')})?`"
                    :readonly="readonly"
                    @input="updatePlan($event === 'Yes', plan)" />
            </field-validator>
            <RequestControl
                v-if="plan.isAttending"
                :value="plan"
                :disabled="readonly"
                @input="updatePlan(plan.isAttending, $event)" />
            <hr />
        </form-validator>
        <template v-slot:footer>
            <slot name="footer"></slot>
        </template>
    </Card>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import merge from 'lodash/merge';
    import sortBy from 'lodash/sortBy';

    import * as Notifier from '@/helpers/globalNotifications';
    import { ParticipantStatusTypes } from '@/helpers/staticValues';
    import AffirmOrDenyQuestionControl from '@/components/formControls/AffirmOrDenyQuestionControl';
    import RequestControl from './RequestControl';
    import FieldValidator from '@/components/formControls/base/FieldValidator';
    import FormValidator from '@/components/formControls/base/FormValidator';

    export default {
        name: 'EmployerParticipationPlanEdit',

        components: {
            AffirmOrDenyQuestionControl,
            RequestControl,
            FieldValidator,
            FormValidator
        },

        data() {
            return {
                participationPlans: [],
                isDirty: false
            };
        },

        props: {
            readonly: Boolean
        },

        mounted() {
            if (this.activeEmployerFair.participationPlans === null
                || this.activeEmployerFair.participationPlans === undefined) {
                this.loadEmployerFairParticipationPlan().then(() => {
                    this.initializeParticipationPlans();
                });
            } else {
                this.initializeParticipationPlans();
            }
        },

        computed: {
            ...mapGetters(['activeEmployerFair', 'isEmployerRegistering', 'opportunities']),

            isNewParticipationPlan() {
                return this.isEmployerRegistering
                    && !this.activeEmployerFair.participationPlans.some(pp => pp.isAttending);
            }
        },

        methods: {
            ...mapActions([
                'loadEmployerFairParticipationPlan',
                'updateEmployerFairParticipation',
                'createParticipationPlan',
                'updateActiveEmployerFairParticipationStatus'
            ]),

            initializeParticipationPlans() {
                this.participationPlans = sortBy(this.activeEmployerFair.participationPlans
                    .map(pp => merge({}, pp)), ['fairDate']);
            },

            updatePlan(attending, updatedPlan) {
                this.isDirty = true;
                updatedPlan.isAttending = attending;

                if (!attending) {
                    updatedPlan.requestedInterviewSessionIds = [];
                    updatedPlan.requestedTableTalkSessionIds = [];
                    updatedPlan.requestingInterviewTable = false;
                    updatedPlan.requestingTableTalk = false;
                }

                this.participationPlans = this.participationPlans
                    .map(pp => pp.fairDate === updatedPlan.fairDate ? updatedPlan : pp);

                this.$emit('input', this.participationPlans);
            },

            async save() {
                if (this.isDirty) {
                    const isAttendingFair = this.participationPlans.some(p => p.isAttending);
                    const isRequestingInterviews = this.participationPlans.some(p => p.requestedInterviewSessionIds.length > 0);
                    const hasExistingOpportunities = this.activeEmployerFair.opportunityTypes.length > 0;
                    const isRegistering = this.isEmployerRegistering;
                    const isEmployerFairApproved = this.activeEmployerFair.participationStatus === ParticipantStatusTypes.approved;

                    if (!isRegistering && isEmployerFairApproved && !isAttendingFair) {
                        Notifier.ErrorAlert('To withdraw from the fair please contact a fair administrator.',
                                            () => { this.cancelSave(); });
                        return;
                    }

                    if ((!isAttendingFair || !isRequestingInterviews) && hasExistingOpportunities && isRegistering) {
                        Notifier.Confirm(
                            'Existing Opportunities',
                            'By confirming this request, you will delete existing opportunities created for this application.',
                            'Delete',
                            async () => {
                                this.saveParticipation(this.participationPlans);
                            },
                            () => {
                                this.cancelSave();
                            }
                        );
                    } else if (!isRegistering && isEmployerFairApproved && isAttendingFair) {
                        Notifier.Confirm(
                            'Attention',
                            'By updating your Participation Plan after having been approved, your status for this Fair will revert to Unapproved, and must be approved again by the Fair Administrators. Do you want to continue?',
                            'Update',
                            async () => {
                                this.saveParticipation(this.participationPlans);
                                this.updateActiveEmployerFairParticipationStatus(ParticipantStatusTypes.unapproved);
                            },
                            () => {
                                this.cancelSave();
                            }
                        );
                    } else {
                        this.saveParticipation(this.participationPlans);
                    }
                } else {
                    this.$emit('noChange');
                }

                this.isDirty = false;
            },

            async updateParticipation(plans) {
                await this.updateEmployerFairParticipation(plans);
                await this.initializeParticipationPlans();
            },

            async saveParticipation(plans) {
                let newPlans = [];

                // TODO: Refactor this. Rework the component to reflect this data structure.
                plans.forEach(plan => newPlans.push({
                    attending: plan.isAttending,
                    day: plan.fairDate,
                    interviewSessions: plan.isAttending ? plan.requestedInterviewSessionIds : [],
                    tableTalkSessions: plan.isAttending ? plan.requestedTableTalkSessionIds : []
                }));

                if (this.isNewParticipationPlan) { 
                    await this.createParticipationPlan(newPlans);
                } else {
                    await this.updateParticipation(newPlans);
                }

                this.$emit('saved', true);
                this.initializeParticipationPlans();
                this.$emit('input', this.participationPlans);
            },

            cancelSave() {
                this.$emit('saved', false);
                this.initializeParticipationPlans();
                this.$emit('input', this.participationPlans);
            }
        }
    };
</script>