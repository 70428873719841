var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-button", {
    attrs: { "icon-left": "times", type: "is-danger" },
    on: { click: _vm.handleClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }