<template>
    <section>
        <div class="content" v-if="activeEmployerFairLoaded">
            <Card title="Organization Details">
                <EmployerProfileView />
            </Card>
            <br />
            <EmployerContacts viewonly splitview />
            <br />
            <Card title="Participation Plan">
                <ParticipationPlanSummary />
            </Card>
            <br />
            <Card title="Post-Graduate Opportunity">
                <OpportunityDetailView :opportunity="postGradOpportuinty" />
            </Card>
            <br />
            <Card title="Internship Opportunity">
                <OpportunityDetailView :opportunity="internshipOpportuinty" />
            </Card>
            <b-loading :active.sync="isEmployerStoreLoading"></b-loading>
        </div>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex';
    import EmployerProfileView from '@/views/employer/profile/EmployerProfileView';
    import ParticipationPlanSummary from '@/components/employer/participationPlan/ParticipationPlanSummary';
    import EmployerContacts from '@/views/employer/EmployerContacts';
    import OpportunityDetailView from '@/components/employer/opportunity/OpportunityDetailView';

    export default {
        name: 'EmployerRegistrationReview',

        components: {
            ParticipationPlanSummary,
            EmployerContacts,
            EmployerProfileView,
            OpportunityDetailView
        },

        computed: {
            ...mapGetters([
                'isEmployerStoreLoading',
                'opportunities',
                'opportunityTypePostGrad',
                'opportunityTypeInternship',
                'activeEmployerFair'
            ]),

            postGradOpportuinty() {
                if (this.opportunities === null
                    || this.opportunities === undefined
                    || this.opportunities.length === 0) return null;
                
                let result = this.opportunities.find(o => o.type === this.opportunityTypePostGrad.id);
                
                if (!result) return null;

                return result;
            },

            internshipOpportuinty() {
                if (this.opportunities === null
                    || this.opportunities === undefined
                    || this.opportunities.length === 0) return null;

                let result = this.opportunities.find(o => o.type === this.opportunityTypeInternship.id);
                
                if (!result) return null;

                return result;
            },

            activeEmployerFairLoaded() {
                return this.activeEmployerFair !== null && this.activeEmployerFair !== undefined;
            }
        }
    };
</script>