var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value === null
    ? _c("div", [
        _c("span", { staticClass: "content is-italic" }, [
          _vm._v("No Contact Details Provided"),
        ]),
      ])
    : !_vm.stack || _vm.singleLine
    ? _c("div", [
        _c("div", { staticClass: "columns is-desktop is-multiline" }, [
          _vm._m(0),
          _c("div", { staticClass: "column has-text-left" }, [
            _c("span", { staticClass: "content" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.value.lastName) +
                  ", " +
                  _vm._s(_vm.value.firstName) +
                  " "
              ),
            ]),
          ]),
          _vm._m(1),
          _c("div", { staticClass: "column has-text-left" }, [
            _c("span", { staticClass: "content" }, [
              _vm._v(" " + _vm._s(_vm.value.phone) + " "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "columns is-desktop is-multiline" }, [
          _vm._m(2),
          _c("div", { staticClass: "column has-text-left" }, [
            _c("span", { staticClass: "content" }, [
              _vm._v(" " + _vm._s(_vm.value.email) + " "),
            ]),
          ]),
        ]),
      ])
    : _c("div", [
        _c("div", { staticClass: "columns is-desktop is-multiline" }, [
          _c("div", { staticClass: "column is-half is-narrow" }, [
            _c(
              "span",
              { staticClass: "has-text-weight-bold has-margin-right-sm" },
              [_vm._v("Name:")]
            ),
            _c("span", { staticClass: "content" }, [
              _vm._v(
                _vm._s(_vm.value.lastName) + ", " + _vm._s(_vm.value.firstName)
              ),
            ]),
          ]),
          _c("div", { staticClass: "column is-narrow" }, [
            _c(
              "span",
              { staticClass: "has-text-weight-bold has-margin-right-sm" },
              [_vm._v("Phone:")]
            ),
            _c("span", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.value.phone)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "columns is-desktop is-multiline" }, [
          _c("div", { staticClass: "column is-narrow" }, [
            _c(
              "span",
              { staticClass: "has-text-weight-bold has-margin-right-sm" },
              [_vm._v("Email:")]
            ),
            _c("span", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.value.email)),
            ]),
          ]),
        ]),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Name:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("label", { staticClass: "label" }, [_vm._v(" Phone: ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Email:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }