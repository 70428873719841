import axios from './index';

export const getApplicationsByAttendee = attendeeId => {
    return axios.get(`/api/attendees/${attendeeId}/applications`);
};

export const getApplication = (attendeeId, applicationId) => {
    return axios.get(`/api/attendees/${attendeeId}/applications/${applicationId}`);
};

export const withdrawApplication = (attendeeId, applicationId) => {
    return axios.delete(`/api/attendees/${attendeeId}/applications/${applicationId}`);
};

/**
 * Creates an application and posts it to the Application controller. The opportunity id is sent in to the url and 
 * documents are sent in a FormData object. You can't create the association between document type and the actual file 
 * easily, so here we rewrite file names to be the document type of each file.
 * @param {*} opportunityId The opportunity this application pertains to.
 * @param {*} docs A list of key value pairs where the key is the Type property and the value is the Document (File) property.
 */
export const createApplication = (attendeeId, opportunityId, docs) => {
    const formData = new FormData();

    docs.forEach(doc => {
        formData.append('files', doc.document, `${doc.type}.pdf`);
    });

    return axios.post(`/api/attendees/${attendeeId}/applications/opportunities/${opportunityId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};

export const getDocumentsByApplication = (attendeeId, applicationId) => {
    return axios.get(`/api/attendees/${attendeeId}/applications/${applicationId}/documents`);
};

export const getDocument = (attendeeId, documentId) => {
    return axios.get(`/api/attendees/${attendeeId}/applications/documents/${documentId}`, {
        responseType: 'blob'
    });
};

export default 'ApplicationApi';
