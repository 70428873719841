var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.fairDays, function (day) {
      return _c(
        "div",
        { key: day },
        [
          _vm._v(" " + _vm._s(_vm._f("date")(day, "ddd, MMM D")) + " "),
          _c(
            "b-table",
            {
              attrs: {
                data: _vm.summariesForDay(day),
                striped: true,
                selected: _vm.selected,
              },
              on: {
                "update:selected": function ($event) {
                  _vm.selected = $event
                },
              },
            },
            [
              [
                _c(
                  "b-table-column",
                  {
                    attrs: { field: "timeOfDay", label: "Time", centered: "" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getEnumDescription(
                            "SessionType",
                            _vm.props.row.timeOfDay
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "roomActivity",
                      label: "Activity",
                      centered: "",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getEnumDescription(
                            "RoomType",
                            _vm.props.row.roomActivity
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "seats",
                      label: "Avail/Total",
                      centered: "",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.props.row.uncommittedSeats) +
                        " / " +
                        _vm._s(
                          _vm.props.row.committedSeats +
                            _vm.props.row.uncommittedSeats
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
            ],
            2
          ),
          _c("br"),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }