var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.activeEmployerFairLoaded
      ? _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "Card",
              { attrs: { title: "Organization Details" } },
              [_c("EmployerProfileView")],
              1
            ),
            _c("br"),
            _c("EmployerContacts", { attrs: { viewonly: "", splitview: "" } }),
            _c("br"),
            _c(
              "Card",
              { attrs: { title: "Participation Plan" } },
              [_c("ParticipationPlanSummary")],
              1
            ),
            _c("br"),
            _c(
              "Card",
              { attrs: { title: "Post-Graduate Opportunity" } },
              [
                _c("OpportunityDetailView", {
                  attrs: { opportunity: _vm.postGradOpportuinty },
                }),
              ],
              1
            ),
            _c("br"),
            _c(
              "Card",
              { attrs: { title: "Internship Opportunity" } },
              [
                _c("OpportunityDetailView", {
                  attrs: { opportunity: _vm.internshipOpportuinty },
                }),
              ],
              1
            ),
            _c("b-loading", {
              attrs: { active: _vm.isEmployerStoreLoading },
              on: {
                "update:active": function ($event) {
                  _vm.isEmployerStoreLoading = $event
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }