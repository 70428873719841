<template>
    <FormControl
        :horizontal="horizontal"
        :controlLabel="controlLabel"
        :toolTip="toolTip"
        :centerText="centerText"
        :wrapHeader="wrapHeader"
        :leftColumnCss="leftColumnCss"
        :rightColumnCss="rightColumnCss">
        <b-field>
            <b-taginput
                autocomplete
                v-bind="$attrs"
                v-model="inputValue"
                field="description"
                :data="filteredTags"
                :placeholder="placeholder"
                :disabled="disabled"
                @typing="getFilteredTags"
                @input="updateValues" >
                <template slot-scope="props">
                    {{ props.option[valueColumn] }}
                </template>
                <template slot="empty">
                    There are no items
                </template>
            </b-taginput>
        </b-field>
        <b-field>
            <b-select
                v-if="!disabled"
                multiple
                native-size="8"
                v-model="selectedOptions"
                @input="updateSelect" expanded>
                <option v-for="option in unselectedData"
                        :value="option[idColumn]"
                        :key="option[idColumn]">
                    {{ option[valueColumn] }}
                </option>
            </b-select>
        </b-field>
    </FormControl>
</template>

<script>
    import merge from 'lodash/merge';
    import AlignmentControlPropsMixin from '@/mixins/AlignmentControlPropsMixin';
    import FormControlPropsMixin from '@/mixins/FormControlPropsMixin';
    import FromInputMixin from '@/mixins/FormInputMixin';
    import FormControl from '@/components/formControls/base/FormControl';
    
    export default {
        name: 'TaggedMultiSelectControl',

        mixins: [ AlignmentControlPropsMixin, FormControlPropsMixin, FromInputMixin ],

        components: {
            FormControl
        },

        props: {
            value: Array,
            options: Array,
            placeholder: String,
            idColumn: {
                type: String,
                default: 'id'
            },
            valueColumn: {
                type: String,
                default: 'value'
            },
            disabled: Boolean
        },

        data() {
            return {
                openOnFocus: false,
                selected: null,
                selectedOptions: [],
                filteredTags: []
            };
        },

        computed: {
            keyedValues() {
                return this.options.map(option => {
                    return {
                        id: option[this.idColumn],
                        description: option[this.valueColumn]
                    };
                });
            },

            unselectedData() {
                return this.keyedValues;
            }
        },

        methods: {
            getFilteredTags(text) {
                this.filteredTags = this.keyedValues.filter((option) => {
                    return option[this.valueColumn]
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0;
                });
            },

            updateSelect(values) {
                values.forEach(value => {
                    let selectedValue = this.keyedValues.find(option => option.id === value);

                    this.inputValue.push(selectedValue);
                });

                this.$emit('input', this.inputValue);
            },

            updateValues() {
                this.$emit('input', this.inputValue);
            }
        },

        watch: {
            value: {
                handler(newValue) {
                    this.inputValue = newValue.map(v => merge({}, v));
                },
                immediate: true
            }
        }
    };
</script>