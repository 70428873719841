<template>
    <FormControl
        :horizontal="horizontal"
        :centerText="centerText"
        :leftColumnCss="leftColumnCss"
        :rightColumnCss="rightColumnCss"
        :wrapHeader="wrapHeader"
        :controlLabel="controlLabel"
        :toolTip="toolTip">
        <b-field grouped>
            <b-select 
                :value="inputValue"
                :placeholder="placeholder"      
                @input="updateValue"
                expanded
                v-bind="$attrs">
                <option v-for="option in options"
                        :value="option.id"
                        :key="option.id">
                    {{ option.description }}
                </option>
            </b-select>
        </b-field>
    </FormControl>
</template>

<script>
    import AlignmentControlPropsMixin from '@/mixins/AlignmentControlPropsMixin';
    import FormControlPropsMixin from '@/mixins/FormControlPropsMixin';
    import FormControl from '@/components/formControls/base/FormControl';

    export default {
        name: 'DropdownControl',

        mixins: [ AlignmentControlPropsMixin, FormControlPropsMixin ],

        components: {
            FormControl
        },

        props: {
            value: [Object, Number, String],
            options: Array,
            placeholder: {
                type: String,
                default: 'Please Select One'
            }
        },

        data() {
            return {
                inputValue: null
            };
        },

        methods: {
            updateValue(value) {
                this.$emit('input', this.options.find(o => o.id == value));
            }
        },

        watch: {
            value: {
                handler(newVal) {
                    if (newVal == null) return;

                    this.inputValue = newVal.id;
                },
                immediate: true
            }
        }
    };
</script>