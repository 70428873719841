/* STORE: FairDates */
import * as actions from './actions';
import * as getters from './getters';

const state = {
    fair: {},
    fairDates: []
};

const mutations = {
    LOAD_FAIR_DATES(state, result) {
        state.fairDates = result.data;
    },

    SET_USER_DETAILS(state, details) {
        if (details.fairData === null || details.fairData === undefined) return;

        state.fair = details.fairData.fair;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};