<template>
    <div>
        <div class="has-margin-bottom-md">
            <span class="title is-6">Application</span>
        </div>
        <form-validator ref="form">
            <b-field v-for="(entry, index) in reqDocMap" :key="index">
                <field-validator 
                    :name="getEnumDescription('DocumentType', entry.type)" 
                    :rules="requestByType(entry.type).isRequired ? 'required' : ''">
                    <b-upload v-model="entry.document" :required="requestByType(entry.type).isRequired">
                        <a class="button is-primary">
                            <b-icon icon="upload" size="is-small"></b-icon>
                            <span>
                                {{ getEnumDescription('DocumentType', entry.type) }}
                            </span>
                        </a>
                    </b-upload>
                </field-validator>
                <span v-if="entry.document != null" class="file-name"  @click="openDoc(entry.document)">
                    {{ entry.document.name }}
                </span>
            </b-field>
            <b-field></b-field>
        </form-validator>
        <b-button @click="apply">Apply</b-button>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import FieldValidator from '@/components/formControls/base/FieldValidator';
    import FormValidator from '@/components/formControls/base/FormValidator';

    export default {
        name: 'OpportunityApplication',

        props: {
            id: Number,
            documentRequests: Array
        },

        components: {
            FieldValidator,
            FormValidator
        },

        data() {
            return {
                application: null,
                // this is really a map<DocType,Document> where Document can be null
                reqDocMap: [],
                // Are we still loading data?
                loading: true,
            };
        },

        mounted() {
            this.setDocMap(this.documentRequests);
        },

        methods: {
            ...mapActions(['setModalDoc', 'createApplication']),

            requestByType(docType) {
                return this.documentRequests.find(req => req.documentType == docType);
            },

            openDoc(doc) {
                this.setModalDoc(doc);
            },

            setDocMap(docRequests) {
                if (docRequests.length == 0) return [];
                    
                this.reqDocMap = docRequests.map(req => {
                    return {
                        type: req.documentType,
                        document: null
                    };
                });
            },

            async apply() {

                const valid = await this.$refs.form.validate();

                if (valid) {
                    // If this application is valid, only send up files that have been uploaded
                    const docs = this.reqDocMap.filter(docMap => docMap.document != null);

                    const opportunityId = this.id;

                    this.createApplication({ attendeeId: this.activeAttendeeId, opportunityId, docs });
                }
            }
        },

        computed: {
            ...mapGetters(['activeAttendeeId', 'getEnumDescription']),

            // Is the attendee already an applicant
            applied() {
                return this.application != null;
            },
        },

        watch: {
            docRequests: {
                handler(newVal) {
                    this.setDocMap(newVal);
                },
            }
        }
    };
</script>