<template>
    <UserList detailKey="guid"
        :defaultFilterValue="defaultFilterStatus"
        :value="employerRegistrations"
        :isLoading="loadingUserRegistrations"
        @row-toggled="clearSelectedEmployer">
        <template v-slot:default="props">
            <b-table-column field="fair_contact" label="Fair Contact">
                {{ props.row.lastName }}, {{ props.row.firstName }}
            </b-table-column>
            <b-table-column field="organization_name" label="Organization Name">
                {{ props.row.employerName }}
            </b-table-column>
        </template>
        <template v-slot:detail="props">
            <UserRegistrationDetails :user="props.row">
                <template v-slot:firstRow>
                    <div class="column is-narrow">
                        <label class="label">Employer Name:</label>
                    </div>
                    <div class="column has-text-left">
                        <span class="content">{{ props.row.employerName }}</span>
                    </div>
                </template>
                <template v-slot:lastRow v-if="canApproveContact(props.row.status)">
                    <div class="column">
                        <form-validator ref="employerForm">
                            <EmployersAutoCompleteInputControl v-model="selectedEmployer" />
                        </form-validator>
                    </div>
                    <div class="column">
                        <div class="buttons is-right">
                            <b-button type="is-primary" :disabled="disableActionButtons" @click="approveUser(props.row)">Approve</b-button>
                            <b-button type="is-danger" :disabled="disableActionButtons" @click="rejectUser(props.row)">Reject</b-button>
                        </div>
                    </div>
                </template>
            </UserRegistrationDetails>
        </template>
    </UserList>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { troyesRegistrationStatus } from '@/helpers/staticValues';

    import EmployersAutoCompleteInputControl from '@/components/formControls/input/EmployersAutoCompleteInputControl';
    import FormValidator from '@/components/formControls/base/FormValidator';
    import UserList from '@/components/account/UserList';
    import UserRegistrationDetails from '@/components/account/UserRegistrationDetails';

    export default {
        name: 'FairContactList',

        components: {
            EmployersAutoCompleteInputControl,
            FormValidator,
            UserList,
            UserRegistrationDetails
        },

        data() {
            return {
                selectedEmployer: null,
                disableActionButtons: false
            };
        },

        mounted() {
            this.loadUserRegistrations();
        },

        computed: {
            ...mapGetters(['employerRegistrations', 'loadingUserRegistrations']),

            defaultFilterStatus() {
                return troyesRegistrationStatus.PendingReview;
            }
        },

        methods: {
            ...mapActions([
                'loadUserRegistrations',
                'approveFairContactRegistration',
                'rejectFairContactRegistration'
            ]),

            clearSelectedEmployer() {
                this.selectedEmployer = null;
            },

            canApproveContact(status) {
                return status == troyesRegistrationStatus.PendingReview;
            },

            async approveUser(user) {
                const validEmployer = await this.$refs.employerForm.validate();

                if (validEmployer) {
                    this.disableActionButtons = true;

                    await this.approveFairContactRegistration({ 
                        userGuid: user.guid,
                        employerId: this.selectedEmployer.employerId == -1
                            ? null
                            : this.selectedEmployer.employerId
                    });

                    this.clearSelectedEmployer();
                    this.disableActionButtons = false;
                    this.loadUserRegistrations();
                }
            },

            async rejectUser(user) {
                this.disableActionButtons = true;
                await this.rejectFairContactRegistration(user.guid);
                this.clearSelectedEmployer();
                this.disableActionButtons = false;
                this.loadUserRegistrations();
            }
        }
    };
</script>