<template>
    <b-collapse class="card">
        <div slot="trigger" slot-scope="props" class="card-header background-white-ter">
            <p class="card-header-title">{{ title }}</p>
            <a class="card-header-icon">
                <b-icon :icon="props.open ? 'angle-down' : 'angle-up'"></b-icon>
            </a>
        </div>
        <div class="card-content">
            <slot></slot>
        </div>
        <footer class="card-footer">
            <slot name="footer"></slot>
        </footer>
    </b-collapse>
</template>

<script>
    export default {
        name: 'CollapsibleCard',

        props: {
            title: {
                type: String,
            },
            collapsable: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
