<template>
    <section class="section">
        <div class="container is-fluid">
            <h3 class="is-size-3">Welcome to PILC</h3>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'DefaultHomepage'
    };
</script>
