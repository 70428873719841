var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("h3", { staticClass: "title" }, [_vm._v("My Availability")]),
      _c("p", [
        _vm._v(
          "Enter your availability for each Fair Date listed. Don't worry about excluding breaks (eg for full day availability, use 9:00AM - 5:00pm)"
        ),
      ]),
      _c("br"),
      _vm._l(_vm.userAvailability.dailyAvailability, function (day) {
        return _c(
          "div",
          { key: day.date },
          [
            _c("h4", { staticClass: "subtitle is-4" }, [
              _vm._v(_vm._s(_vm._f("date")(day.date))),
            ]),
            _vm._l(
              day.userAvailabilitySessions,
              function (userAvailabilitySession) {
                return _c("user-availability-session", {
                  key: userAvailabilitySession.userAvailabilitySessionId,
                  attrs: { userAvailabilitySession: userAvailabilitySession },
                  on: { "refresh-user-availability": _vm.loadUserAvailability },
                })
              }
            ),
            _c("user-availability-session", { attrs: { date: day.date } }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }