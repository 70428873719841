import Standard from '@/views/shared/layouts/Standard';

import AccessDenied from '@/views/auth/AccessDenied';
import OidcCallback from '@/views/auth/Callback';
import OidcCallbackError from '@/views/auth/CallbackError';
import PendingUserApproval from '@/views/auth/PendingApproval';

export default {
    path: '/auth',
    component: Standard,
    meta: {
        isPublic: true
    },
    children: [
        {
            path: 'callback',
            name: 'oidcCallback',
            meta: {
                isPublic: true
            },
            component: OidcCallback
        },
        {
            path: 'callback-error',
            name: 'oidcCallbackError',
            meta: {
                isPublic: true
            },
            component: OidcCallbackError
        },
        {
            path: 'access-denied',
            name: 'AccessDenied',
            meta: {
                isPublic: true
            },
            component: AccessDenied
        },
        {
            path: 'pending',
            name: 'pending-user-approval',
            meta: {
                isPublic: true
            },
            component: PendingUserApproval
        }
    ]
};
