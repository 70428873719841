<template>
    <DropdownControl
        :horizontal="horizontal"
        controlLabel="Organization Type"
        placeholder="Select Organization Type"
        :options="getOrganizationTypes"
        :value="orgTypeValue"
        :toolTip="toolTip"
        :centerText="centerText"
        :leftColumnCss="leftColumnCss"
        :rightColumnCss="rightColumnCss"
        @input="updateValue"
        v-bind="$attrs" />
</template>

<script>
    import { mapGetters } from 'vuex';
    import merge from 'lodash/merge';

    import AlignmentControlPropsMixin from '@/mixins/AlignmentControlPropsMixin';
    import FormControlPropsMixin from '@/mixins/FormControlPropsMixin';
    import DropdownControl from '@/components/formControls/dropdown/DropdownControl';

    export default {
        name: 'OrganizationTypeDropdown',

        mixins: [ AlignmentControlPropsMixin, FormControlPropsMixin ],

        props: {
            horizontal: {
                type: Boolean,
                default: false
            },
            value: Object
        },

        components: {
            DropdownControl
        },

        data() {
            return {
                orgTypeValue: this.value
            };
        },

        computed: {
            ...mapGetters(['getOrganizationTypes'])
        },

        methods: {
            updateValue(value) {
                this.$emit('input', this.getOrganizationTypes.find(ot => ot.id === value.id));
            }
        },

        watch: {
            value: {
                handler(newVal) {
                    this.orgTypeValue = merge({}, newVal);
                },
                immediate: true
            }
        }
    };
</script>