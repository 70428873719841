import * as Notify from '@/helpers/globalNotifications';
import * as Api from '@/api/opportunity';

export const loadOpportunitiesForEmployerFair = async ({ commit, getters }) => {
    try {
        commit('SET_LOADING_OPPORTUNITY', true);

        const response = await Api.getOpportunitiesByEmployer(
            getters.activeEmployerId
        );

        commit('LOAD_OPPORTUNITIES', response);
        commit('SET_LOADING_OPPORTUNITY', false);
    } catch (error) {
        Notify.Error('We had some trouble loading your opportunities.');
    }
};

export const loadSearchResults = async ({ commit }) => {
    const response = await Api.getOpportunitySearchResults();

    commit('LOAD_SEARCH_RESULTS', response);
};

export const addOpportunity = async ({ commit, getters }, { opportunity }) => {
    try {
        commit('SET_LOADING_OPPORTUNITY', true);

        const response = await Api.addOpportunity(
            getters.activeEmployerId,
            opportunity
        );

        commit('ADD_OPPORTUNITY', response);
        commit('SET_LOADING_OPPORTUNITY', false);
    } catch (error) {
        Notify.Error(error.response.data);
        throw error;
    }
};

export const editOpportunity = ({ commit }, id) => {
    commit('EDIT_OPPORTUNITY', id);
};

export const clearOpportunity = ({ commit }) => {
    commit('CLEAR_OPPORTUNITY');
};

export const deleteOpportunity = async ({ commit, getters }, opportunityId) => {
    try {
        commit('SET_LOADING_OPPORTUNITY', true);
        await Api.deleteOpportunity(getters.activeEmployerId, opportunityId);

        commit('DELETE_OPPORTUNITY', opportunityId);
        commit('SET_LOADING_OPPORTUNITY', false);
    } catch (error) {
        Notify.Error('We encountered some trouble while trying to delete this opportunity.');
        throw error;
    }
};

export const updateOpportunity = async ({ commit, getters }, { opportunity }) => {
    try {
        commit('SET_LOADING_OPPORTUNITY', true);
        const response = await Api.updateOpportunity(getters.activeEmployerId, opportunity);

        commit('UPDATE_OPPORTUNITY', response);
        commit('SET_LOADING_OPPORTUNITY', false);
    } catch (error) {
        Notify.Error(error.response.data);
        throw error;
    }
};
