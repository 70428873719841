<template>
    <form-validator ref="form" hide-errors>
        <div slot-scope="{ valid, dirty }">
            <EmployerContacts ref="contactForm" splitview />
            <br>
            <div class="buttons is-marginless">
                <b-button 
                    type="is-success" 
                    icon-right="save" 
                    @click="submit"
                    :disabled="!valid && dirty">
                    Save Contacts
                </b-button>
            </div>
            <b-loading :isFullPage="false" :active.sync="isEmployerStoreLoading"></b-loading>
        </div>
    </form-validator>
</template>

<script>
    import { mapGetters } from 'vuex';

    import EmployerContacts from '@/views/employer/EmployerContacts';
    import FormValidator from '@/components/formControls/base/FormValidator';
    import ConfirmLeaveMixin from '@/mixins/ConfirmLeaveMixin';

    export default {
        name: 'ContactForm',
    
        components: {
            EmployerContacts,
            FormValidator,
        },

        mixins: [ConfirmLeaveMixin],

        methods: {
            submit() {
                this.validateAndSave(this.$refs.contactForm.save); 
            }
        },

        computed: {
            ...mapGetters(['employerFairsLoaded', 'isEmployerStoreLoading', 'activeEmployerFair']),
        },
    };
</script>