import merge from 'lodash/merge';

const unapprovedParticipationStatusId = 0;
const approvedParticipationStatusId = 1;
const rejectedParticipationStatusId = 2;

export const employers = state => state.employers;

export const employerFairs = state => state.employerFairs;

export const activeEmployer = (state, getters) => {
    let employer = merge({}, state.employers.find(e => e.employerId === state.activeEmployerId));
    const practiceTypes = getters.getPracticeTypes;
    const subjectAreas = getters.getSubjectAreas;
    const organizationTypes = getters.getOrganizationTypes;

    employer.practiceTypes = employer.practiceTypes != null ? employer.practiceTypes.map(pt => practiceTypes.find(p => p.id === pt)) : [];
    employer.subjectAreas = employer.subjectAreas != null ? employer.subjectAreas.map(sa => subjectAreas.find(a => a.id === sa)) : [];
    employer.organizationType = employer.organizationType != null ? organizationTypes.find(ot => ot.id === employer.organizationType) : null;

    return employer;
};

export const activeEmployerFair = state =>
    state.employerFairs.find(e => e.employerFairId === state.activeEmployerFairId);

export const isEmployerRegistering = state => state.isRegisteringForFair;

export const hasEmployerAttendedPreviousFairs = state => state.hasEmployerAttendedPreviousFairs;

export const isEmployerStoreLoading = state => state.employerLoading;

export const employersLoaded = state => {
    return (
        state.employers !== undefined &&
        state.employers !== null &&
        state.employers.length > 0
    );
};

export const employerFairsLoaded = state => {
    return (
        state.employerFairs !== undefined &&
        state.employerFairs !== null &&
        state.employerFairs.length > 0
    );
};

export const unapprovedEmployers = (state) => {
    return state.employerFairs
        .filter(e => e.participationStatus === unapprovedParticipationStatusId);
};

export const approvedEmployers = (state) => {
    return state.employerFairs
        .filter(e => e.participationStatus === approvedParticipationStatusId);
};

export const rejectedEmployers = (state) => {
    return state.employerFairs
        .filter(e => e.participationStatus === rejectedParticipationStatusId);
};

export const completedEmployerFairRegistrations = (state) => {
    return state.employerFairs
        .filter(ef => ef.hasSignedNDA === true);
};

export const incompleteEmployerFairRegistrations = (state) => {
    return state.employerFairs
        .filter(ef => ef.hasSignedNDA === false);
};

export const editingEmployer = state => state.editingEmployer;

export const activeEmployerId = state => state.activeEmployerId;

export const activeEmployerFairId = state => state.activeEmployerFairId;
