var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section" }, [
    _c("div", { staticClass: "container is-fluid" }, [
      _c("div", { staticClass: "columns" }, [
        _c(
          "div",
          {
            staticClass: "column",
            class: { "is-three-quarters": _vm.showSidebar },
          },
          [_c("router-view")],
          1
        ),
        _vm.showSidebar
          ? _c(
              "div",
              { staticClass: "column" },
              [_c("router-view", { attrs: { name: "sidebar" } })],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }