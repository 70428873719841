<template>
  <MainRightSidebar v-if="!isEmployerRegistering">
      <b-message title="Applications" :closable="false">
          You may begin reviewing student applications on {{ reviewStart }}  You must complete your rankings by {{ rankingDeadline }}<br /><br />

          Note: It is very important that you complete your selections by the deadline. The interview scheduler cannot run until all employers complete their selections.
          If you do not complete your selections by the deadline, you will delay both students and other employers from being able to access their interview schedules.
      </b-message>
      <b-message title="Interviews" :closable="false">
          Initial interview schedules are expected to be published on {{ firstSchedulePublication }}.
          Please be advised that this date is subject to change and is dependent upon all participating employers completing their interview selections by {{ rankingDeadline }} <br /><br />

          Your interview schedule will be final at 3:00 p.m. the day before your interviews take place
          (i.e., your schedule for Thursday will be final at 3:00 p.m. on Wednesday; your schedule for Friday will be final at 3:00 p.m. on Thursday).
      </b-message>
  </MainRightSidebar>
</template>
<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'EmployerSidebar',

        data() {
            return {
                reviewStart: 'January 17 at 9:00 a.m.', //TODO: fetch FairDate.ApplicationReviewStart
                rankingDeadline: 'January 29, 2020, 5:00 p.m.', //TODO: fetch FairDate.ApplicationRankingDeadline
                firstSchedulePublication: 'January 31, 2020'
            };
        },

        computed: {
            ...mapGetters(['isEmployerRegistering'])
        }
    };
</script>
