import { library } from '@fortawesome/fontawesome-svg-core';

// Importing only font-awesome icons that will be used
import {
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArrowUp,
    faAsterisk,
    faCaretDown,
    faCaretUp,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faEdit,
    faExclamationCircle,
    faEye,
    faInfoCircle,
    faFilePdf,
    faPlus,
    faSave,
    faTag,
    faThumbsUp,
    faTimes,
    faTrash,
    faUpload,
    faUserCircle,
    faUserGraduate,
    faSearch,
    faGavel,
    faUserSlash
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArrowUp,
    faAsterisk,
    faCaretDown,
    faCaretUp,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faEdit,
    faExclamationCircle,
    faEye,
    faInfoCircle,
    faFilePdf,
    faPlus,
    faSave,
    faTag,
    faThumbsUp,
    faTimes,
    faTrash,
    faUpload,
    faUserCircle,
    faUserGraduate,
    faSearch,
    faGavel,
    faUserSlash
);
