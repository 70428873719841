var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-field",
        { attrs: { grouped: "" } },
        [
          _c(
            "b-field",
            { attrs: { label: "Start", horizontal: "" } },
            [
              _c("b-timepicker", {
                attrs: {
                  placeholder: "Click to select...",
                  "hour-format": "12",
                  size: "is-small",
                  inline: true,
                  "increment-minutes": 5,
                },
                model: {
                  value: _vm.session.sessionStart,
                  callback: function ($$v) {
                    _vm.$set(_vm.session, "sessionStart", $$v)
                  },
                  expression: "session.sessionStart",
                },
              }),
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { label: "End", horizontal: "" } },
            [
              _c("b-timepicker", {
                attrs: {
                  placeholder: "Click to select...",
                  "hour-format": "12",
                  size: "is-small",
                  inline: true,
                  "increment-minutes": 5,
                },
                model: {
                  value: _vm.session.sessionEnd,
                  callback: function ($$v) {
                    _vm.$set(_vm.session, "sessionEnd", $$v)
                  },
                  expression: "session.sessionEnd",
                },
              }),
            ],
            1
          ),
          _c(
            "p",
            { staticClass: "control", staticStyle: { padding: "0.5em" } },
            [
              _c(
                "button",
                {
                  staticClass: "button is-primary",
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.buttonText))]
              ),
              _vm.exists()
                ? _c(
                    "button",
                    {
                      staticClass: "button is-warning",
                      staticStyle: { "margin-left": "0.5em" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteSession(
                            _vm.userAvailabilitySession
                              .userAvailabilitySessionId
                          )
                        },
                      },
                    },
                    [_c("b-icon", { attrs: { icon: "trash" } })],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }