<template>
    <div v-if="!isResume" >
        <div class="field">
            <field-validator name="add document request" required="">
                <b-checkbox v-model="showDocRequest">
                    Would you like to request a {{ docType.description }}?
                </b-checkbox>
            </field-validator>
        </div>
        <document-request v-if="showDocRequest" :request="request" />
    </div>
</template>

<script>
    import DocumentTypes from '@/enums/DocumentTypes';
    import DocumentRequest from '@/components/document/DocumentRequest';
    import FieldValidator from '@/components/formControls/base/FieldValidator';

    export default {
        name: 'DocumentRequestOption',

        components: {
            DocumentRequest,
            FieldValidator
        },

        props: {
            docType: {
                type: Object,
                required: true
            },

            value: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                inputValue: [...this.value]
            };
        },

        computed: {
            isResume() {
                return this.docType.id == DocumentTypes.Resume;
            },

            request() {
                return this.value.find(r => r.documentType == this.docType.id);
            },

            showDocRequest: {
                get() {
                    return this.request != null;
                },
                set(flag) {
                    if (flag) {
                        this.inputValue.push({
                            id: 0,
                            unique: Date.now(),
                            documentType: this.docType.id,
                            description: null,
                            isRequired: null
                        });
                    }
                    else {
                        this.inputValue = this.value.filter(r => r.documentType !== this.docType.id);
                    }
                    this.$emit('input', this.inputValue);
                }
            }
        },

        watch: {
            request: {
                handler(newVal) {
                    if (newVal != null && newVal.documentType == DocumentTypes.Resume) {
                        newVal.isRequired = true;
                    }
                },
                immediate: true
            },

            value(newVal) {
                this.inputValue = [...newVal];
            }
        }
    };
</script>

<style scoped>
    div.card >>> header.card-header {
        background-color: white;
    }
</style>