var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    { attrs: { title: "Students" } },
    [
      _vm._v(" Student data for School Admins goes here. "),
      _c("hr"),
      _c(
        "card",
        [
          _c("list", {
            attrs: { rowId: "studentId" },
            scopedSlots: _vm._u([
              {
                key: "empty",
                fn: function () {
                  return [_vm._v(" The Student list is empty ")]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }