/* STORE: Applications */
import * as actions from './actions';
import * as getters from './getters';

const state = {
    applications: [],
    activeAttendeeId: null
};

const mutations = {
    SET_APPLICATIONS(state, applications) {
        state.applications = applications;
    },

    SET_USER_DETAILS(state, details) {
        if (!details.attendeeData) return;
        state.activeAttendeeId = details.attendeeData.attendeeId;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
