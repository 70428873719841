<template>
    <section>
        <b-steps v-model="activeStep" :animated="true" :has-navigation="true" icon-prev="chevron-left"
            icon-next="chevron-right" @input="clickStep" size="is-small">
            <hr>
            <b-step-item :label="steps.registration.label" :visible="isStepVisible(steps.registration.index)"
                :clickable="isStepClickable(steps.registration.index)">
                <Card title="Organization Details" v-if="activeStep === steps.registration.index">
                    <form-validator ref="form">
                        <router-view ref="stepComponent" edit />
                    </form-validator>
                </Card>
                <b-loading :active.sync="isStepLoading"></b-loading>
            </b-step-item>
            <b-step-item :label="steps.contacts.label" :visible="isStepVisible(steps.contacts.index)"
                :clickable="isStepClickable(steps.contacts.index)">
                <form-validator ref="form" v-if="activeStep === steps.contacts.index && activeEmployerFairLoaded">
                    <router-view ref="stepComponent" />
                </form-validator>
            </b-step-item>
            <b-step-item :label="steps.participationPlan.label" :visible="isStepVisible(steps.participationPlan.index)"
                :clickable="isStepClickable(steps.participationPlan.index)">
                <form-validator ref="form"
                    v-if="activeStep === steps.participationPlan.index && activeEmployerFairLoaded">
                    <router-view ref="stepComponent" v-model="participation" />
                </form-validator>
            </b-step-item>
            <b-step-item :label="steps.postGradPosition.label" :visible="isStepVisible(steps.postGradPosition.index)"
                :clickable="isStepClickable(steps.postGradPosition.index)">
                <Card :title="`${opportunityTypePostGrad.description} Opportunity`"
                    v-if="activeStep === steps.postGradPosition.index && activeEmployerFairLoaded">
                    <form-validator ref="form">
                        <router-view :key="opportunityTypePostGrad.id" ref="stepComponent"
                            v-model="localOpportunities.fulltime" :opportunityType="opportunityTypePostGrad" />
                    </form-validator>
                </Card>
            </b-step-item>
            <b-step-item :label="steps.internshipPosition.label"
                :visible="isStepVisible(steps.internshipPosition.index)"
                :clickable="isStepClickable(steps.internshipPosition.index)">
                <Card :title="`${opportunityTypeInternship.description} Opportunity`"
                    v-if="activeStep === steps.internshipPosition.index && activeEmployerFairLoaded">
                    <form-validator ref="form">
                        <router-view :key="opportunityTypeInternship.id" ref="stepComponent"
                            v-model="localOpportunities.internship" :opportunityType="opportunityTypeInternship" />
                    </form-validator>
                </Card>
            </b-step-item>
            <b-step-item :label="steps.summary.label" :visible="isStepVisible(steps.summary.index)"
                :clickable="isStepClickable(steps.summary.index)">
                <div v-if="activeStep === steps.summary.index && activeEmployerFairLoaded">
                    <router-view />
                    <br>
                    <Card title="Non-Discrimination Agreement">
                        <p class="content">
                            New York University is committed to maintaining an environment that encourages and fosters
                            appropriate conduct among all persons and respect for individual values. Discrimination or
                            harassment based on race, gender and/or gender identity or expression, color, creed,
                            religion, age, national origin, ethnicity, disability, veteran or military status, sex,
                            sexual orientation, pregnancy, genetic information, marital status, citizenship status, or
                            on any other legally prohibited basis is unlawful and undermines the character and purpose
                            of the University. Such discrimination or harassment violates University policy and will not
                            be tolerated.
                        </p>
                    </Card>
                </div>
            </b-step-item>
            <template slot="navigation">
                <div class="buttons has-margin-bottom-lg has-margin-left-md">
                    <b-button type="is-light" @click.prevent="previousStep">
                        Previous
                    </b-button>
                    <b-button type="is-primary" @click.prevent="nextStep">
                        Next
                    </b-button>
                </div>
            </template>
        </b-steps>
        <!-- <b-loading :active.sync="isPageLoading"></b-loading> -->
    </section>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import merge from 'lodash/merge';

    import FormValidator from '@/components/formControls/base/FormValidator';

    export default {
        name: 'EmployerRegistration',

        components: {
            FormValidator
        },

        data() {
            return {
                activeStep: 0,
                isStepLoading: false,
                participation: [],
                localOpportunities:
                    {
                        fulltime: {},
                        internship: {}
                    },
                steps: {
                    registration: {
                        index: 0,
                        label: 'Organization Details',
                        name: 'employer-registration-details'
                    },
                    contacts: {
                        index: 1,
                        label: 'Employer Contacts',
                        name: 'employer-registration-contacts'
                    },
                    participationPlan: {
                        index: 2,
                        label: 'Participation Plan',
                        name: 'employer-registration-participation'
                    },
                    postGradPosition: {
                        index: 3,
                        label: 'Post-Graduate Opportunity',
                        name: 'employer-registration-post-grad'
                    },
                    internshipPosition: {
                        index: 4,
                        label: 'Internship Opportunity',
                        name: 'employer-registration-internship'
                    },
                    summary: {
                        index: 5,
                        label: 'Summary',
                        name: 'employer-registration-summary'
                    }
                },
            };
        },

        async mounted() {
            await this.init();
        },

        computed: {
        ...mapGetters([
            'activeEmployerFair',
            'employersLoaded',
            'employerFairsLoaded',
            'isEmployerStoreLoading',
            'isEmployerRegistering',
            'opportunities',
            'opportunityTypePostGrad',
            'opportunityTypeInternship'
        ]),

        activeEmployerFairLoaded() {
            return this.activeEmployerFair != null;
        },

        isPageLoading() {
            return this.isEmployerStoreLoading;
        },

        isOrgDetailStepComplete() {
            return this.employersLoaded && this.employerFairsLoaded;
        },

        isParticipationPlanStepComplete() {
            return this.isAttendingFair && (this.requestingInterviews || this.requestingTableTalks);
        },

        isOpportunityStepComplete() {
            return this.offeringOpportunities || (this.requestingTableTalks && !this.requestingInterviews);
        },

        canReviewRegistration() {
            return this.isOrgDetailStepComplete
                && this.isParticipationPlanStepComplete
                && this.isOpportunityStepComplete;
        },

        isAttendingFair() {
            return this.participation.some(plan => plan.isAttending);
        },

        requestingInterviews() {
            let requestingInterviewTable = this.participation.some(plan => plan.requestingInterviewTable);
            let hasRequestedInterviewSession = this.participation.some(plan => plan.requestedInterviewSessionIds.length > 0);

            return this.isAttendingFair && requestingInterviewTable && hasRequestedInterviewSession;
        },

        requestingTableTalks() {
            let requestingTableTalk = this.participation.some(plan => plan.requestingTableTalk);
            let hasRequestedTableTalkSessions = this.participation.some(plan => plan.requestedTableTalkSessionIds.length > 0);

            return this.isAttendingFair && requestingTableTalk && hasRequestedTableTalkSessions;
        },

        offeringOpportunities() {
            return this.requestingInterviews && this.activeEmployerFair.opportunityTypes.length > 0;
        }
        },

        methods: {
        ...mapActions([
            'loadActiveEmployerFair',
            'loadOpportunitiesForEmployerFair',
            'getEmployerContacts',
            'addOpportunity',
            'submitEmployerFairForApproval'
        ]),

        async init() {
            this.isStepLoading = true;
            if (!this.activeEmployerFair) await this.loadActiveEmployerFair();

            if (this.activeEmployerFair) {
                this.participation = this.activeEmployerFair.participationPlans.map(plan => merge({}, plan));

                if (this.activeEmployerFair.opportunityTypes.length > 0) {
                    this.loadOpportunitiesForEmployerFair();
                }
            }

            if (this.activeStep == this.steps.contacts.index) {
                await this.getEmployerContacts();
            }

            this.isStepLoading = false;
        },

        isStepVisible(index) {
            return !((index == this.steps.postGradPosition.index || index == this.steps.internshipPosition.index) && !this.requestingInterviews);
        },

        isStepClickable(index) {
            switch (index) {
                case this.steps.registration.index:
                    return true;
                case this.steps.contacts.index:
                    return this.isOrgDetailStepComplete;
                case this.steps.participationPlan.index:
                    return this.isOrgDetailStepComplete;
                case this.steps.postGradPosition.index:
                    return this.isParticipationPlanStepComplete;
                case this.steps.internshipPosition.index:
                    return this.isParticipationPlanStepComplete;
                case this.steps.summary.index:
                    return this.canReviewRegistration;
                default:
                    return false;
            }
        },

        previousStep() {
            if (this.activeStep != 0) {
                this.clickStep(this.activeStep - 1);
            }
        },

        async nextStep() {
            if (this.$refs.form != null) {
                const valid = await this.$refs.form.validate();
                if (!valid) return;
            }

            this.isStepLoading = true;

            let step = null;

            if (this.$refs.stepComponent != null)
                step = this.$refs.stepComponent;

            const nextStep = Object.values(this.steps).find(step => step.index == this.activeStep + 1);

            if (this.activeStep == this.steps.participationPlan.index && !this.requestingInterviews) {
                await step.save();

                const name = this.steps.summary.name;
                this.$router.push({ name });
            }
            // If we're anywhere but the last step: save, then push the next route 
            else if (this.activeStep < Object.keys(this.steps).length - 1) {
                await step.save();
                // route to go here
                this.$router.push({ name: nextStep.name });
            }
            else {
                await this.submitEmployerFairForApproval();
                this.$router.replace({ name: 'employer-home' });
            }

            this.isStepLoading = false;
        },

        clickStep(index) {
            this.isStepLoading = true;

            const step = Object.values(this.steps).find(step => step.index == index);

            this.$router.push({ name: step.name });
            this.isStepLoading = false;
        }
        },

        watch: {
            '$route.meta': {
                handler(meta) {
                    if (meta != null && meta.index != null) {
                        this.activeStep = meta.index;
                    }
                },
                deep: true,
                immediate: true
            }
        }
    };
</script>

<style scoped>
ul {
    list-style: disc;
    padding-left: inherit;
}
</style>