var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormControl",
    {
      attrs: {
        horizontal: _vm.horizontal,
        controlLabel: _vm.controlLabel,
        toolTip: _vm.toolTip,
        centerText: _vm.centerText,
        wrapHeader: _vm.wrapHeader,
        leftColumnCss: _vm.leftColumnCss,
        rightColumnCss: _vm.rightColumnCss,
      },
    },
    [
      _c(
        "b-input",
        _vm._b(
          {
            attrs: { placeholder: _vm.placeholder, disabled: _vm.readonly },
            on: {
              input: function ($event) {
                return _vm.updateValue()
              },
              blur: _vm.blur,
            },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue",
            },
          },
          "b-input",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }