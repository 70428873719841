<template>
    <a class="is-borderless has-padding-none has-margin-right-md inherit-background-color"
        @click="handleClick" :disabled="disabled">
        <b-icon type="is-primary" icon="edit" size="is-small" />
    </a>
</template>

<script>
    export default {
        name: 'EditButton',

        props: {
            disabled: {
                type: Boolean
            }
        },

        methods: {
            handleClick() {
                if (!this.disabled) this.$emit('click-edit');
            }
        }
    };
</script>
