var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-field",
    _vm._l(_vm.options, function (option) {
      return _c(
        "b-radio-button",
        _vm._b(
          {
            key: option.id,
            attrs: {
              "aria-label": _vm.ariaLabel,
              value: _vm.value,
              "native-value": option.id,
              name: _vm.name,
              disabled: _vm.disableOption(option.id),
            },
            on: {
              input: function ($event) {
                return _vm.input(option.id)
              },
            },
          },
          "b-radio-button",
          _vm.$attrs,
          false
        ),
        [_c("span", [_vm._v(_vm._s(option.description))])]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }