<template>
    <div class="card">
        <header class="card-header background-white-ter">
            <slot name="header">
                <p v-show="title" class="card-header-title">{{ title }}</p>
                <div v-if="showButton" class="v-align-center">
                    <b-button
                        class="has-margin-right-sm"
                        icon-left="plus"
                        @click="trigger"
                        :disabled="disableButton"
                    >
                        {{ buttonLabel }}
                    </b-button>
                </div>
            </slot>
        </header>
        <div
            v-if="showActionContent"
            class="has-padding-md has-background-grey-lighter"
        >
            <slot name="action"></slot>
        </div>
        <div class="card-content">
            <slot></slot>
        </div>
        <footer class="card-footer">
            <slot name="footer"></slot>
        </footer>
    </div>
</template>

<script>
/* eslint-disable */

export default {
    name: "Card",

    props: {
        title: {
            type: String,
        },

        showButton: Boolean,

        disableButton: Boolean,

        buttonLabel: {
            type: String,
            default: "Add",
        },

        showActionContent: Boolean,
    },

    data() {
        return {
            buttonState: false,
        };
    },

    methods: {
        trigger() {
            this.$emit("header-action");
            this.buttonState = !this.buttonState;
        },
    },
};
</script>
