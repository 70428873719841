export default {
    props: {
        value: [Number, String]
    },

    data() {
        return {
            inputValue: this.value
        };
    },

    methods: {
        updateValue() {
            if (typeof this.inputValue === 'string' && this.inputValue.trim().length === 0) {
                this.$emit('input', null);
            }
            else {
                this.$emit('input', this.inputValue);
            }
        }
    },

    watch: {
        value(newVal) {
            this.inputValue = newVal;
        }
    }
};