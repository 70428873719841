var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-select",
    {
      attrs: {
        placeholder: _vm.placeholder,
        loading: _vm.loading,
        expanded: _vm.expanded,
        icon: _vm.icon,
      },
      on: { input: _vm.input },
      model: {
        value: _vm.localValue,
        callback: function ($$v) {
          _vm.localValue = $$v
        },
        expression: "localValue",
      },
    },
    _vm._l(_vm.options, function (option) {
      return _c("option", { key: option.id, domProps: { value: option.id } }, [
        _vm._v(" " + _vm._s(option.description) + " "),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }