<template>
    <b-table
        :data="reservations"
        @dragstart="dragstart"
        @drop="drop"
        @dragover="dragover"
        @dragleave="dragleave" 
        :per-page="5" 
        paginated 
        draggable
        hoverable>
        <template slot-scope="props">
            <b-table-column field="name" label="Employer">
                {{ props.row.name }} 
            </b-table-column>

            <b-table-column field="requestedRoomType" label="Room Type">
                {{ getEnumDescription('RoomType', props.row.requestedRoomType) }}
            </b-table-column>

            <b-table-column field="reservations" label="Day Portion(s)">
                {{ formatSessionTypes(props.row.reservations) }}
            </b-table-column>
        </template>
        <template v-slot:empty>
            There are no tables to assign
        </template>
        <template v-if="reservations.length > 0" v-slot:bottom-left>
            <b-select v-model="perPage">
                <option value="5">5 per page</option>
                <option value="10">10 per page</option>
                <option value="15">15 per page</option>
                <option value="20">20 per page</option>
            </b-select>
        </template>
    </b-table>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { dragAndDropMixin } from '@/mixins/DragAndDropMixin';

    export default {
        name: 'UnassignedReservations',

        mixins: [dragAndDropMixin],

        props: {
            reservations: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                perPage: 20
            };
        },

        methods: {
            ...mapActions(['clearDraggingReservation', 'setDraggingReservation']),

            // Event: When you grab a reservation, it is wrapped in a payload object. The reservation's data is accessed via the row property.
            dragstart(payload) {
                payload.event.dataTransfer.setData('application/x-workaround', payload.row);
                this.setDraggingReservation(payload.row);
            },
            // Event: Triggers when a reservation is dropped on a valid drop target.
            drop(target) {
                this.clearRow(target);
                if (target.row.reservation == undefined) {
                    target.event.preventDefault();
                    this.clearDraggingReservation();
                    return;
                }
            },

            formatSessionTypes(reservations) {
                if (reservations == null) return '';
                const sessionTypes = reservations.map(r => r.sessionType);
                const descriptions = sessionTypes.map(st => this.getEnumDescription('SessionType', st));
                descriptions.sort().reverse();
                return descriptions.join('/'); // so that it goes Morning/Afternoon
            }
        },

        computed: {
            ...mapGetters(['draggingReservation', 'getEnumDescription'])
        }
    };
</script>

<style scoped>
    .b-table >>> .table-wrapper table.table {
        background-color: whitesmoke;
    }

    .b-table >>> a.pagination-link.is-current {
        color: white;
    }
</style>