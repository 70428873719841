<template>
    <form-validator ref="form">
        <Card title="Organization Details" slot-scope="{ valid, dirty }">
            <EmployerProfileEdit ref="orgDetails" edit />
            <template v-slot:footer>
                <div class="card-content buttons">
                    <b-button type="is-success" icon-right="save" @click="submit" :disabled="!valid && dirty">
                        Save Details
                    </b-button>
                </div>
            </template>
        </Card>
    </form-validator>
</template>

<script>
    import { mapGetters } from 'vuex';

    import EmployerProfileEdit from '@/views/employer/profile/EmployerProfileEdit';
    import FormValidator from '@/components/formControls/base/FormValidator';
    import ConfirmLeaveMixin from '@/mixins/ConfirmLeaveMixin';

    export default {
        name: 'EmployerForm',

        components: {
            EmployerProfileEdit,
            FormValidator,
        },

        mixins: [ConfirmLeaveMixin],

        update() {
            this.beganFormValidation = false;
        },

        methods: {
            submit() {
                this.validateAndSave(this.$refs.orgDetails.save);
            }
        },

        computed: {
        ...mapGetters(['isEmployerStoreLoading', 'employerFairsLoaded', 'activeEmployer']),
        },
    };
</script>