<template>
    <field-validator name="Employer">
        <b-autocomplete
            placeholder="Employer Name"
            v-model="selectedEmployerName"
            :open-on-focus="true"
            :data="filteredDataArray"
            field="name"
            @select="selectEmployer">
            <template slot="empty">No employers found</template>
        </b-autocomplete>
    </field-validator>
</template>

<script>
    import { mapGetters } from 'vuex';
    import FieldValidator from '@/components/formControls/base/FieldValidator';

    export default {
        name: 'EmployersDropDown',

        components: {
            FieldValidator
        },

        data() {
            return {
                selectedEmployerName: '',
                selectedEmployer: null
            };
        },

        props: {
            value: {
                type: Object
            }
        },

        computed: {
            ...mapGetters(['employers']),

            filteredDataArray() {
                var employers = this.employers.filter((employer) => {
                    return employer.name
                        .toLowerCase()
                        .indexOf(this.selectedEmployerName.toLowerCase()) >= 0;
                });

                employers.unshift({ employerId: -1, name: '--- New Employer ---' });

                return employers;
            }
        },
        
        methods: {
            selectEmployer(employer) {
                this.selectedEmployer = employer;
                this.$emit('input', this.selectedEmployer);
            }
        },

        watch: {
            value: {
                handler(value) {
                    if (value === null || value === undefined) return null;

                    if (value.name === undefined) {
                        this.selectedEmployerName = '';
                    } else {
                        this.selectedEmployerName = value.name;
                    }
                },
                immediate: true
            }
        }
    };
</script>