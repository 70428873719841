var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass:
        "is-borderless has-padding-none has-margin-none inherit-background-color",
      attrs: { disabled: _vm.disabled },
      on: { click: _vm.handleClick },
    },
    [
      _c("b-icon", {
        attrs: { type: "is-danger", icon: "trash", size: "is-small" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }