var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "List",
        {
          attrs: {
            rowId: "id",
            accordion: "",
            hasDetailColumn: "",
            rowsPerPage: 10,
            paginated: true,
            value: _vm.value,
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function (props) {
                return [
                  _c("opportunity", {
                    attrs: { opportunity: props.row, "has-actions": false },
                  }),
                ]
              },
            },
            {
              key: "detail",
              fn: function (value) {
                return _vm._l(value, function (v) {
                  return _c(
                    "opportunity-detail",
                    { key: v.id, attrs: { opportunity: v } },
                    [
                      !_vm.alreadyApplied(v.id)
                        ? _c("opportunity-application", {
                            attrs: {
                              id: v.id,
                              "document-requests": v.documentRequests,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                })
              },
            },
          ]),
        },
        [
          _c("template", { slot: "empty" }, [
            !_vm.hasData
              ? _c("div", [
                  _c("p", { staticClass: "title is-6" }, [
                    _vm._v(
                      " Sorry, we couldn't find any opportunities matching your search criteria. "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c("doc-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }