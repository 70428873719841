var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.opportunity === null
    ? _c("div", [
        _c("span", { staticClass: "is-italic" }, [_vm._v("No Opportunity")]),
      ])
    : _c("div", [
        _c("div", { class: _vm.columnsCss }, [
          _c("div", { staticClass: "column is-one-third" }, [
            _c(
              "span",
              { staticClass: "has-text-weight-bold has-margin-right-sm" },
              [_vm._v("* Title:")]
            ),
            _c("span", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.opportunity.jobTitle)),
            ]),
          ]),
          _c("div", { staticClass: "column is-one-third" }, [_vm._v("   ")]),
          _c("div", { staticClass: "column is-one-third" }, [
            _c(
              "span",
              { staticClass: "has-text-weight-bold has-margin-right-sm" },
              [_vm._v("Allow Free Sign-Up:")]
            ),
            _c("span", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.opportunity.allowNonApplicant ? "Yes" : "No")),
            ]),
          ]),
        ]),
        _c("div", { class: _vm.columnsCss }, [
          _c("div", { staticClass: "column is-one-third" }, [
            _c(
              "span",
              { staticClass: "has-text-weight-bold has-margin-right-sm" },
              [_vm._v("* Opportunity Type:")]
            ),
            _c("span", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.opportunityType)),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "column is-one-third" },
            [
              _c(
                "span",
                { staticClass: "has-text-weight-bold has-margin-right-sm" },
                [_vm._v("* Class Years to Interview:")]
              ),
              _vm._l(_vm.attendeeTypes, function (attendeeType) {
                return _c("b-tag", { key: attendeeType.id }, [
                  _vm._v(" " + _vm._s(attendeeType.description) + " "),
                ])
              }),
            ],
            2
          ),
          _c("div", { staticClass: "column is-one-third" }, [
            _c(
              "span",
              { staticClass: "has-text-weight-bold has-margin-right-sm" },
              [_vm._v("* U.S. Citizenship Required:")]
            ),
            _c("span", { staticClass: "content" }, [
              _vm._v(
                _vm._s(_vm.opportunity.usCitizenshipRequired ? "Yes" : "No")
              ),
            ]),
          ]),
        ]),
        _c("div", { class: _vm.columnsCss }, [
          _c("div", { staticClass: "column is-one-third" }, [
            _c(
              "span",
              { staticClass: "has-text-weight-bold has-margin-right-sm" },
              [_vm._v("* Paid:")]
            ),
            _c("span", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.opportunity.isPaid ? "Yes" : "No")),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "column is-one-third" },
            [
              _c(
                "span",
                { staticClass: "has-text-weight-bold has-margin-right-sm" },
                [_vm._v("Required Language Skills:")]
              ),
              _vm._l(_vm.requiredLanguages, function (requiredLanguage, index) {
                return _c("b-tag", { key: index }, [
                  _vm._v(" " + _vm._s(requiredLanguage) + " "),
                ])
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "column is-one-third" },
            [
              _c(
                "span",
                { staticClass: "has-text-weight-bold has-margin-right-sm" },
                [_vm._v("Preferred Language Skills:")]
              ),
              _vm._l(
                _vm.preferredLanguages,
                function (preferredLanguage, index) {
                  return _c("b-tag", { key: index }, [
                    _vm._v(" " + _vm._s(preferredLanguage) + " "),
                  ])
                }
              ),
            ],
            2
          ),
        ]),
        _c("div", { class: _vm.columnsCss }, [
          _c("div", { staticClass: "column is-one-third" }, [
            _c(
              "span",
              { staticClass: "has-text-weight-bold has-margin-right-sm" },
              [_vm._v("* City:")]
            ),
            _c("span", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.opportunity.city)),
            ]),
          ]),
          _c("div", { staticClass: "column is-one-third" }, [
            _c(
              "span",
              { staticClass: "has-text-weight-bold has-margin-right-sm" },
              [_vm._v("* State:")]
            ),
            _c("span", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.opportunity.state)),
            ]),
          ]),
          _c("div", { staticClass: "column is-one-third" }, [
            _c(
              "span",
              { staticClass: "has-text-weight-bold has-margin-right-sm" },
              [_vm._v("Postal Code:")]
            ),
            _c("span", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.opportunity.postalCode)),
            ]),
          ]),
        ]),
        _c("div", { class: _vm.columnsCss }, [
          _vm._m(0),
          _c("div", { staticClass: "column" }, [
            _c("span", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.opportunity.desc)),
            ]),
          ]),
        ]),
        _c("div", { class: _vm.columnsCss }, [
          _c("div", { staticClass: "column is-4" }, [
            _c(
              "span",
              { staticClass: "has-text-weight-bold has-margin-right-sm" },
              [_vm._v(_vm._s(_vm.additionalEmploymentOfferingsLabel))]
            ),
          ]),
          _c("div", { class: _vm.columnAutoValue }, [
            _vm.opportunity.additionalEmploymentOfferings === null
              ? _c("span", { staticClass: "is-italic" }, [_vm._v("None")])
              : _c("span", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.opportunity.additionalEmploymentOfferings)),
                ]),
          ]),
        ]),
        _vm.coverLetter != null
          ? _c("div", { class: _vm.columnsCss }, [
              _c("div", { staticClass: "column is-narrow" }, [
                _c(
                  "span",
                  { staticClass: "has-text-weight-bold has-margin-right-sm" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.coverLetter.isRequired ? "*" : "") +
                        " Cover Letter: "
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "column" }, [
                _c("span", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.coverLetter.description)),
                ]),
              ]),
            ])
          : _vm._e(),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("span", { staticClass: "has-text-weight-bold has-margin-right-sm" }, [
        _vm._v("* Description:"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }