<template>
    <card title="Employers">
        Employer data for School Admins goes here
        <hr>

        <card>
            <list rowId="employerId">
                <template v-slot:empty>
                    The Employer list is empty
                </template>
            </list>
        </card>
    </card>
</template>

<script>
    export default {
        name: 'SchoolEmployerView'
    };
</script>