var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      class: _vm.modalClass,
      attrs: { "aria-hidden": _vm.docModalHidden },
    },
    [
      _c("div", {
        staticClass: "modal-background",
        on: { click: _vm.closeDoc },
      }),
      _c(
        "div",
        { staticClass: "modal-card column is-four-fifths doc-container" },
        [
          _c("header", { staticClass: "modal-card-head" }, [
            _c("p", { staticClass: "modal-card-title" }, [
              _vm._v(_vm._s(_vm.modalDoc && _vm.modalDoc.name)),
            ]),
            _c("button", {
              staticClass: "delete",
              attrs: { "aria-label": "close" },
              on: { click: _vm.closeDoc },
            }),
          ]),
          _c(
            "section",
            { staticClass: "modal-card-body" },
            [
              _c("vue-pdf-renderer", {
                ref: "renderer",
                attrs: { file: _vm.modalDoc },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }