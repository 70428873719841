var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("p", { staticClass: "card-header-title" }, [
                _vm._v("Opportunities"),
              ]),
              !_vm.readonly
                ? _c(
                    "div",
                    { staticClass: "v-align-center" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "has-margin-right-sm",
                          attrs: {
                            "icon-left": "plus",
                            disabled: _vm.editing || !_vm.showAddButton,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("add")
                            },
                          },
                        },
                        [_vm._v(" Add ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("List", {
        attrs: {
          rowId: "id",
          hasDetailColumn: "",
          paginated: false,
          value: _vm.opporunityData,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function (props) {
              return [
                _c("opportunity", {
                  attrs: {
                    opportunity: props.row,
                    "has-actions": !_vm.readonly,
                  },
                }),
              ]
            },
          },
          {
            key: "detail",
            fn: function (props) {
              return [
                _c("opportunity-detail", { attrs: { opportunity: props.row } }),
              ]
            },
          },
          {
            key: "empty",
            fn: function () {
              return [_vm._v(" No opportunities have been created... ")]
            },
            proxy: true,
          },
        ]),
      }),
      _c("b-loading", {
        attrs: { "is-full-page": false, active: _vm.isEmployerStoreLoading },
        on: {
          "update:active": function ($event) {
            _vm.isEmployerStoreLoading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }