<template>
    <MainRightSidebar>
        <p>We can put something over here in the Fair Admin main sidebar, too!</p>
    </MainRightSidebar>
</template>

<script>
    export default {

        name: 'FairAdminSidebar'

    };
</script>