var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    { attrs: { title: "Applications" } },
    [
      _c("b-notification", { attrs: { closable: false } }, [
        _vm._v(" Some text (possibly instructions) will go here. "),
      ]),
      _c("a", { staticClass: "button is-size-7 has-margin-bottom-md" }, [
        _vm._v(" Save Ranking "),
      ]),
      _c("b-table", {
        staticClass: "is-size-7",
        attrs: {
          data: _vm.applications,
          "detail-key": "id",
          hoverable: "",
          draggable: "",
          paginated: "",
          narrowed: "",
        },
        on: {
          dragstart: _vm.dragstart,
          drop: _vm.drop,
          dragover: _vm.dragover,
          dragleave: _vm.dragleave,
          dragend: _vm.dragend,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c("b-table-column", { attrs: { label: "Rank" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("indexOf")(props.row, _vm.applications)) +
                      " "
                  ),
                ]),
                _c(
                  "b-table-column",
                  { attrs: { field: "opportunityName", label: "Opportunity" } },
                  [_vm._v(" " + _vm._s(props.row.opportunityName) + " ")]
                ),
                _c(
                  "b-table-column",
                  { attrs: { field: "employerName", label: "Employer" } },
                  [_vm._v(" " + _vm._s(props.row.employerName) + " ")]
                ),
                _c("b-table-column", { attrs: { label: "Actions" } }, [
                  _c(
                    "a",
                    {
                      staticClass: "button is-size-7",
                      on: {
                        click: function ($event) {
                          return _vm.withdraw(props.row.id)
                        },
                      },
                    },
                    [_vm._v(" Withdraw ")]
                  ),
                ]),
              ]
            },
          },
          {
            key: "empty",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.emptyMessage) + " ")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }