<template>
    <card title="Volunteers">
        Volunteer data for School Admins goes here
        <hr>

        <card>
            <list rowId="volunteerId">
                <template v-slot:empty>
                    The Volunteer list is empty
                </template>
            </list>
        </card>
    </card>
</template>

<script>
    export default {
        name: 'SchoolVolunteers'
    };
</script>