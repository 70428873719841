var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-field",
    [
      _c(
        "b-radio-button",
        _vm._b(
          {
            attrs: {
              "aria-label": _vm.affirmLabel,
              size: _vm.buttonSize,
              "native-value": _vm.affirmLabel,
            },
            on: {
              click: function ($event) {
                return _vm.raiseEvent()
              },
              input: function ($event) {
                return _vm.handleAffirm()
              },
            },
            model: {
              value: _vm.hasAffirmed,
              callback: function ($$v) {
                _vm.hasAffirmed = $$v
              },
              expression: "hasAffirmed",
            },
          },
          "b-radio-button",
          _vm.$attrs,
          false
        ),
        [_vm._v(" " + _vm._s(_vm.affirmLabel) + " ")]
      ),
      _c(
        "b-radio-button",
        _vm._b(
          {
            attrs: {
              "aria-label": _vm.denyLabel,
              size: _vm.buttonSize,
              "native-value": _vm.denyLabel,
            },
            on: {
              click: function ($event) {
                return _vm.raiseEvent()
              },
              input: function ($event) {
                return _vm.handleDeny()
              },
            },
            model: {
              value: _vm.hasAffirmed,
              callback: function ($$v) {
                _vm.hasAffirmed = $$v
              },
              expression: "hasAffirmed",
            },
          },
          "b-radio-button",
          _vm.$attrs,
          false
        ),
        [_vm._v(" " + _vm._s(_vm.denyLabel) + " ")]
      ),
      _vm.useOther
        ? _c(
            "b-radio-button",
            _vm._b(
              {
                attrs: {
                  "aria-label": _vm.otherLabel,
                  size: _vm.buttonSize,
                  "native-value": _vm.otherLabel,
                },
                on: {
                  click: function ($event) {
                    return _vm.raiseEvent()
                  },
                  input: function ($event) {
                    return _vm.handleDeny()
                  },
                },
                model: {
                  value: _vm.hasAffirmed,
                  callback: function ($$v) {
                    _vm.hasAffirmed = $$v
                  },
                  expression: "hasAffirmed",
                },
              },
              "b-radio-button",
              _vm.$attrs,
              false
            ),
            [_vm._v(" " + _vm._s(_vm.otherLabel) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }