var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isResume
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "field" },
            [
              _c(
                "field-validator",
                { attrs: { name: "add document request", required: "" } },
                [
                  _c(
                    "b-checkbox",
                    {
                      model: {
                        value: _vm.showDocRequest,
                        callback: function ($$v) {
                          _vm.showDocRequest = $$v
                        },
                        expression: "showDocRequest",
                      },
                    },
                    [
                      _vm._v(
                        " Would you like to request a " +
                          _vm._s(_vm.docType.description) +
                          "? "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showDocRequest
            ? _c("document-request", { attrs: { request: _vm.request } })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }