import { troyesRole } from '@/helpers/staticValues';
import Standard from '@/views/shared/layouts/Standard';

// Fair Routes
import FairManagementRoutes from './management';

// FairAdmin Components
import FairAdminView from '@/views/fairAdmin/Index';
import FairAdminSidebar from '@/views/fairAdmin/Sidebar';
import UserApproval from '@/views/fairAdmin/UserApproval';
import EmployerSidebar from '@/views/fairAdmin/EmployerSidebar';

let children = [
  {
    path: '',
    name: 'fairAdmin-home',
    meta: { showSidebar: true },
    components: {
      default: FairAdminView,
      sidebar: FairAdminSidebar
    }
  },
  {
    path: 'approve',
    name: 'user-approval',
    meta: { showSidebar: true },
    components: {
      default: UserApproval,
      sidebar: EmployerSidebar
    }
  }
];

children.push(FairManagementRoutes);

export default {
  path: '/admin',
  component: Standard,
  meta: {
    isPublic: false,
    accessRole: troyesRole.FairAdmin
  },
  children
};
