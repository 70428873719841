<template>
    <div class="columns is-desktop is-centered">
        <div class="column is-four-fiths-desktop is-four-fiths-fullhd">
            <router-view v-if="isStoreInitialized" />
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'FormWizard',

        computed: {
            ...mapGetters(['isStoreInitialized'])
        }
    };
</script>
