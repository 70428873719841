<template>
    <div>
        <div v-for="day in fairDays" v-bind:key="day">
            {{ day | date("ddd, MMM D") }}
            <b-table
                :data="summariesForDay(day)"
                :striped="true"
                :selected.sync="selected"
            >
                <template @click="selected = null">
                    <b-table-column field="timeOfDay" label="Time" centered>
                        {{
                            getEnumDescription(
                                "SessionType",
                                props.row.timeOfDay
                            )
                        }}
                    </b-table-column>
                    <b-table-column
                        field="roomActivity"
                        label="Activity"
                        centered
                    >
                        {{
                            getEnumDescription(
                                "RoomType",
                                props.row.roomActivity
                            )
                        }}
                    </b-table-column>
                    <b-table-column field="seats" label="Avail/Total" centered>
                        {{ props.row.uncommittedSeats }} /
                        {{
                            props.row.committedSeats +
                            props.row.uncommittedSeats
                        }}
                    </b-table-column>
                </template>
            </b-table>
            <br />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import * as Api from "@/api/employer";
import { mapGetters } from "vuex";

export default {
    name: "SeatSummary",

    data() {
        return {
            summaries: [],
            selected: {},
            fairDays: [],
        };
    },

    async mounted() {
        // TODO: Pull this from an Vuex actions
        let result = await Api.seatSummary();
        this.summaries = result.data;
        this.getFairDays();
    },

    computed: {
        ...mapGetters(["getEnumDescription"]),
    },

    methods: {
        getFairDays() {
            this.fairDays = [...new Set(this.summaries.map((fd) => fd.day))];
        },

        summariesForDay(day) {
            return this.summaries.filter((s) => s.day == day);
        },
    },
};
</script>
