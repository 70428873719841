export const troyesRole = {
    FairAdmin: 'fair_admin',
    SchoolAdmin: 'school_admin',
    Employer: 'employer',
    Attendee: 'attendee'
};

export const troyesRegistrationStatus = {
    Approved: 'approved',
    PendingReview: 'pending_review',
    Rejected: 'rejected'
};

export class OpportunityTypes {
    static internship = 1;
    static fullTime = 2;
}

export class ParticipantStatusTypes {
    static unapproved = 0;
    static approved = 1;
    static rejected = 2;
    static types = [
        { display: 'Unapproved', value: ParticipantStatusTypes.unapproved },
        { display: 'Approved', value: ParticipantStatusTypes.approved },
        { display: 'Rejected', value: ParticipantStatusTypes.rejected }
    ];
}

/**
 * The types of data we expect to search on.
 */
export const fieldSearchTypes = {
    /** @property {string} number We expect to be working with numbers. */
    number: 'number',
    /** @property {string} number We expect to be working with strings. */
    string: 'string',
    /** @property {string} number We expect to be working with bools. */
    boolean: 'boolean',
    /** @property {string} number We expect to be working with enum values. */
    enum: 'enum'
};

export class DocumentTypes {
    static resume = 1;
    static coverLetter = 2;
}