var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    { attrs: { title: "School Admin Dashboard" } },
    [
      _vm._v(" The School Admin dashboard will go here "),
      _c("hr"),
      _c(
        "card",
        [
          _c("list", {
            attrs: { rowId: "attendeeId" },
            scopedSlots: _vm._u([
              {
                key: "empty",
                fn: function () {
                  return [
                    _vm._v(
                      " The Attendee List could appear here with approval status. Approval could also happen in the same list. "
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }