var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box has-background-light" },
    [
      _c(
        "QuestionControl",
        { attrs: { question: "Will you conduct interviews?" } },
        [
          _c(
            "field-validator",
            { attrs: { name: "interview participation" } },
            [
              _c("AffirmOrDenyButtonsControl", {
                attrs: {
                  value: _vm.inputParticipationPlan.requestingInterviewTable
                    ? "Yes"
                    : "No",
                  disabled: _vm.readonly,
                },
                on: { input: _vm.updateRequestingInterviews },
              }),
            ],
            1
          ),
          _vm.inputParticipationPlan.requestingInterviewTable
            ? _c(
                "field-validator",
                { attrs: { name: "interview time" } },
                [
                  _vm.inputParticipationPlan.requestingInterviewTable
                    ? _c("AvailabilityDropdown", {
                        attrs: {
                          value:
                            _vm.inputParticipationPlan
                              .requestedInterviewSessionIds,
                          label: "Select time",
                          disabled: _vm.readonly,
                        },
                        on: { input: _vm.updateInterviewSessionIds },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("hr"),
      _c(
        "QuestionControl",
        {
          attrs: {
            question: "Will you participate in Table Talk?",
            disabled: _vm.readonly,
          },
        },
        [
          _c(
            "field-validator",
            { attrs: { name: "table talk participation" } },
            [
              _c("AffirmOrDenyButtonsControl", {
                attrs: {
                  value: _vm.inputParticipationPlan.requestingTableTalk
                    ? "Yes"
                    : "No",
                  disabled: _vm.readonly,
                },
                on: { input: _vm.updateRequestingTableTalks },
              }),
            ],
            1
          ),
          _vm.inputParticipationPlan.requestingTableTalk
            ? _c(
                "field-validator",
                { attrs: { name: "table talk time" } },
                [
                  _vm.inputParticipationPlan.requestingTableTalk
                    ? _c("AvailabilityDropdown", {
                        attrs: {
                          value:
                            _vm.inputParticipationPlan
                              .requestedTableTalkSessionIds,
                          label: "Select time",
                          disabled: _vm.readonly,
                        },
                        on: { input: _vm.updateTableTalkSessionIds },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }