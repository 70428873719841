var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "ul",
      _vm._l(_vm.elements.slice(0, _vm.elementsToDisplay), function (element) {
        return _c("li", { key: element }, [_vm._v(_vm._s(element))])
      }),
      0
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.elements.length > _vm.numWhenCollapsed,
            expression: "elements.length > numWhenCollapsed",
          },
        ],
      },
      [
        _c("more-less-button", {
          staticStyle: { "padding-left": "2em" },
          on: { "click-more-less": _vm.toggle },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }