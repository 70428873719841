var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass:
        "is-borderless has-padding-none has-margin-none inherit-background-color v-align-center",
      on: { click: _vm.handleClick },
    },
    [
      _c("b-icon", {
        attrs: { type: "is-black", icon: _vm.icon, size: "is-small" },
      }),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showText,
              expression: "showText",
            },
          ],
        },
        [_vm._v(_vm._s(_vm.displayText))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }