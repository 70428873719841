<template>
    <DropdownControl
        :controlLabel="label"
        placeholder="Select Availability"
        :value="inputValue"
        :options="timesOfDay"
        @input="updateValue" />
</template>

<script>
    import { mapGetters } from 'vuex';
    import merge from 'lodash/merge';
    // import intersection from 'lodash/intersection';
    import DropdownControl from '@/components/formControls/dropdown/DropdownControl';

    export default {
        name: 'AvailabilityDropdown',

        components: {
            DropdownControl
        },

        data() {
            return {
                inputValue: {}
            };
        },

        props: {
            label: {
                type: String,
                default: 'Availability'
            },
            value: {
                type: Array
            }
        },

        computed: {
            ...mapGetters(['getEnum', 'getSeshTypeDescription']),

            timesOfDay() {
                const sessionTypes = this.getEnum('SessionType');
                let seq = 1;
                const times = sessionTypes.map(st => {
                    const copy = merge({}, st);
                    copy.id = seq;
                    copy.sessionIds = [copy.id];
                    copy.description = this.getSeshTypeDescription(copy.id);

                    seq++;
                    return copy;
                });

                times.push({
                    id: seq,
                    sessionIds: sessionTypes.map(st => st.id),
                    description: 'All Day (9:00 a.m. - 5:00 p.m.)'
                });

                return times;
            }
        },
        
        methods: {
            updateValue(value) {
                this.$emit('input', value.sessionIds);
            }
        },

        watch: {
            value: {
                handler(values) {
                    const jsonValues = JSON.stringify(values.slice()); // Copy array
                    const reverseValues = JSON.stringify(values.slice().reverse()); // Copy array then flip
                    
                    this.inputValue = this.timesOfDay
                        .find(t => JSON.stringify(t.sessionIds) === jsonValues
                            || JSON.stringify(t.sessionIds) === reverseValues);
                },
                immediate: true
            }
        }
    };
</script>