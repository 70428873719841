<template>
    <div>
        <div style="padding-bottom: 1em;" v-if="!viewonly">
            <b-notification type="is-info" :closable="false">
                Only the Fair Contact will receive correspondence regarding the Fair.
            </b-notification>
        </div>
        <slot></slot>
        <div v-if="!splitview">
            <Card title="Fair Contact">
                <NonUserPersonView :value="userDetails" />
            </Card>
            <br />
            <Card title="Internship Coordinator">
                <NonUserPersonView v-if="viewonly" :value="contacts.internshipCoordinator" />
                <FormValidator ref="internshipContactForm" v-else>
                    <NonUserPersonEdit v-model="contacts.internshipCoordinator" :readonly="readonly" />
                </FormValidator>
            </Card>
            <br />
            <Card title="Hiring Attorney">
                <NonUserPersonView v-if="viewonly" :value="contacts.hiringAttorney" />
                <FormValidator ref="hiringAttorneyContactForm" v-else>
                    <NonUserPersonEdit v-model="contacts.hiringAttorney" :readonly="readonly" />
                </FormValidator>
            </Card>
            <br />
            <RepresentativeList v-model="contacts.otherRepresentatives" :readonly="readonly || viewonly" />
        </div>
        <div v-else>
            <div class="columns">
                <div class="column">
                    <Card title="Fair Contact">
                        <NonUserPersonView :value="userDetails" />
                    </Card>
                </div>
            </div>
            <br />
            <div class="columns">
                <div class="column">
                    <Card title="Internship Coordinator">
                        <NonUserPersonView v-if="viewonly" :value="contacts.internshipCoordinator" stack />
                        <FormValidator ref="internshipContactFormStack" v-else>
                            <NonUserPersonEdit v-model="contacts.internshipCoordinator" :readonly="readonly" stack />
                        </FormValidator>
                    </Card>
                </div>
                <div class="column">
                    <Card title="Hiring Attorney">
                        <NonUserPersonView v-if="viewonly" :value="contacts.hiringAttorney" stack />
                        <FormValidator ref="hiringAttorneyContactFormStack" v-else>
                            <NonUserPersonEdit v-model="contacts.hiringAttorney" :readonly="readonly" stack />
                        </FormValidator>
                    </Card>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <RepresentativeList v-model="contacts.otherRepresentatives" :readonly="readonly || viewonly" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import merge from 'lodash/merge';
    import { mapActions, mapGetters } from 'vuex';

    import NonUserPersonEdit from '@/components/formControls/NonUserPersonEdit';
    import NonUserPersonView from '@/components/formControls/NonUserPersonView';
    import RepresentativeList from '@/components/employer/RepresentativeList';
    import FormValidator from '@/components/formControls/base/FormValidator';
    
    export default {
        name: 'EmployerContacts',

        components: {
            NonUserPersonEdit,
            NonUserPersonView,
            RepresentativeList,
            FormValidator
        },

        props: {
            readonly: {
                type: Boolean,
                default: false
            },
            splitview: {
                type: Boolean,
                default: false
            },
            viewonly: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                contacts: {
                    primaryContact: null,
                    hiringAttorney: null,
                    internshipCoordinator: null,
                    otherRepresentatives: []
                }
            };
        },

        mounted() {
            if (this.activeEmployerFair.contacts === null || this.activeEmployerFair.contacts === undefined) {
                this.getEmployerContacts().then(() => {
                    this.updateLocalContacts(this.activeEmployerFair.contacts);
                });
            } else {
                this.updateLocalContacts(this.activeEmployerFair.contacts);
            }
        }, 

        methods: {
            ...mapActions(['getEmployerContacts', 'updateEmployerContacts']),

            async save() {
                const showingStack = this.splitview;
                let validInternshipCoordinator = true;
                let validHiringAttorney = true;

                if (this.contacts.internshipCoordinator !== null) {
                    validInternshipCoordinator = showingStack
                        ? await this.$refs.internshipContactFormStack.validate()
                        : await this.$refs.internshipContactForm.validate();
                }

                if (this.contacts.hiringAttorney !== null) {
                    validHiringAttorney = showingStack
                        ? await this.$refs.hiringAttorneyContactFormStack.validate()
                        : await this.$refs.hiringAttorneyContactForm.validate();
                }

                if (validInternshipCoordinator && validHiringAttorney) {
                    await this.updateEmployerContacts({ contacts: this.contacts });
                }
            },

            updateLocalContacts(updatedContacts) {
                if (updatedContacts === null || updatedContacts === undefined) return;

                updatedContacts.primaryContact = this.userDetails;

                this.contacts = merge({}, updatedContacts);
            }
        },

        computed: {
            ...mapGetters(['activeEmployerFair', 'userDetails'])
        },

        watch: {
            'activeEmployerFair.contacts': {
                handler(newVal) {
                    this.updateLocalContacts(newVal);
                }
            }
        }
    };
</script>
