<template>
    <component :is="wrappingTag">
        <slot :metadata="metadata" v-if="editing" :edit="toggleEdit" name="write"/>
        <slot :metadata="metadata" v-else :edit="toggleEdit" name="read"/>
    </component>
</template>

<script>
    export default {
        name: 'EditableComponent',
        props: {
            wrappingTag: {
                type: String,
                required: true
            },
            startInEdit: {
                type: Boolean,
                default: false
            },
            metadata: {
                type: Object
            }
        },
        data() {
            return {
                editing: false
            };
        },
        mounted() {
            this.editing = this.startInEdit;
        },
        methods: {
            toggleEdit() {
                this.editing = !this.editing;
            },
            emitEvent(eventName, value) {
                this.$emit(eventName, value);
            }
        },
        provide: function() {
            return {
                toggleEdit: this.toggleEdit,
                emitEvent: this.emitEvent
            };
        }
    };
</script>