var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("h3", { staticClass: "title" }, [_vm._v("My Information")]),
    _c(
      "div",
      [
        _c(
          "b-field",
          { attrs: { grouped: "" } },
          [
            _c(
              "b-field",
              { attrs: { label: "First Name" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.profile.firstName,
                    callback: function ($$v) {
                      _vm.$set(_vm.profile, "firstName", $$v)
                    },
                    expression: "profile.firstName",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "Middle Initial", expanded: "" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.profile.middleInitial,
                    callback: function ($$v) {
                      _vm.$set(_vm.profile, "middleInitial", $$v)
                    },
                    expression: "profile.middleInitial",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "Last Name", expanded: "" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.profile.lastName,
                    callback: function ($$v) {
                      _vm.$set(_vm.profile, "lastName", $$v)
                    },
                    expression: "profile.lastName",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-field",
          { attrs: { grouped: "" } },
          [
            _c(
              "b-field",
              { attrs: { label: "School" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.profile.school,
                    callback: function ($$v) {
                      _vm.$set(_vm.profile, "school", $$v)
                    },
                    expression: "profile.school",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "Student ID", expanded: "" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.profile.studentId,
                    callback: function ($$v) {
                      _vm.$set(_vm.profile, "studentId", $$v)
                    },
                    expression: "profile.studentId",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "Class Year", expanded: "" } },
              [
                _c(
                  "b-select",
                  {
                    attrs: { placeholder: "Select class year" },
                    model: {
                      value: _vm.profile.classYear,
                      callback: function ($$v) {
                        _vm.$set(_vm.profile, "classYear", $$v)
                      },
                      expression: "profile.classYear",
                    },
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [_vm._v("1L")]),
                    _c("option", { attrs: { value: "2" } }, [_vm._v("2L")]),
                    _c("option", { attrs: { value: "3" } }, [_vm._v("3L")]),
                    _c("option", { attrs: { value: "4" } }, [_vm._v("LLM")]),
                    _c("option", { attrs: { value: "5" } }, [_vm._v("Alumni")]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-field",
          { attrs: { label: "Address" } },
          [
            _c("b-input", {
              model: {
                value: _vm.profile.streetAddress,
                callback: function ($$v) {
                  _vm.$set(_vm.profile, "streetAddress", $$v)
                },
                expression: "profile.streetAddress",
              },
            }),
          ],
          1
        ),
        _c(
          "b-field",
          { attrs: { grouped: "" } },
          [
            _c(
              "b-field",
              { attrs: { label: "City", expanded: "" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.profile.city,
                    callback: function ($$v) {
                      _vm.$set(_vm.profile, "city", $$v)
                    },
                    expression: "profile.city",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "State", expanded: "" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.profile.state,
                    callback: function ($$v) {
                      _vm.$set(_vm.profile, "state", $$v)
                    },
                    expression: "profile.state",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "Zip", expanded: "" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.profile.zipCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.profile, "zipCode", $$v)
                    },
                    expression: "profile.zipCode",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-field",
          { attrs: { label: "Email" } },
          [
            _c("b-input", {
              attrs: { type: "email" },
              model: {
                value: _vm.profile.emailAddress,
                callback: function ($$v) {
                  _vm.$set(_vm.profile, "emailAddress", $$v)
                },
                expression: "profile.emailAddress",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("p", { staticClass: "has-text-right" }, [
      _c("br"),
      _c("input", {
        staticClass: "button is-primary",
        attrs: { type: "submit", value: "Update Information" },
        on: { click: _vm.updateProfile },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }