/* STORE: Reservations */
import * as actions from './actions';
import * as getters from './getters';

const state = {
  roomConfigs: [],
  unassignedReservations: [],
  assignedReservations: [],
  draggingReservation: null
};

const mutations = {
  LOAD_TAB_ASSIGN_DATA(state, { data }) {
    state.roomConfigs = data.roomConfigs;
    state.unassignedReservations = data.unassignedRsvp;
    state.assignedReservations = data.assignedRsvp;
  },

  SET_DRAGGING_RES(state, res) {
    state.draggingReservation = res;
  },

  CLEAR_DRAGGING_RES(state) {
    state.draggingReservation = null;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
