import moment from 'moment';

export function date(value, format = 'LL') {
    return moment(value).format(format);
}

export function truncate(value, length) {
    return value.length > length ? value.substr(0, length) + '...' : value;
}

export function stringToTime(value) {
    return new Date(value);
}

export default {
    date,
    truncate,
    stringToTime
};
