<template>
    <card>
        <template v-slot:header>
            <p class="card-header-title">Opportunities</p>
            <div class="v-align-center" v-if="!readonly">
                <b-button class="has-margin-right-sm" icon-left="plus" @click="$emit('add')" :disabled="editing || !showAddButton">
                    Add
                </b-button>
            </div>
        </template>
        <List rowId="id"
              hasDetailColumn
              :paginated="false"
              :value="opporunityData">

            <template v-slot:content="props">
               <opportunity :opportunity="props.row" :has-actions="!readonly" />
            </template>

            <template v-slot:detail="props">
                <opportunity-detail :opportunity="props.row" />
            </template>

            <template v-slot:empty>
                No opportunities have been created...
            </template>

        </List>
        <b-loading :is-full-page="false" :active.sync="isEmployerStoreLoading"></b-loading>
    </card>
</template>

<script>
    import merge from 'lodash/merge';

    import { mapGetters, mapActions } from 'vuex';

    import Opportunity from '@/components/employer/opportunity/Opportunity';
    import OpportunityDetail from '@/components/employer/opportunity/OpportunityDetail';

    export default {
        name: 'OpportunityList',

        components: {
            Opportunity,
            OpportunityDetail
        },

        props: {
            readonly: Boolean
        },

        data() {
            return {
                opporunityData: []
            };
        },

        mounted() {
            this.loadOpportunitiesForEmployerFair();
            this.clearOpportunity();
        },

        computed: {
            ...mapGetters(['activeEmployerFair', 'editingOpportunity', 'getEnum', 'opportunities', 'isEmployerStoreLoading']),

            editing() {
                return this.editingOpportunity != null;
            },

            /** 
             * There is a business rule that says that there can only be one opportunity per type. 
             * The types are Internships and Post-Grad as of right now (12/09/19). The employer should 
             * not be able to add any opportunities once they have one per type. Fair Admins can override this.
             */
            showAddButton() {
                const opportunityTypes = this.getEnum('OpportunityType');
                return this.opportunities.length < opportunityTypes.length;
            }
        },

        methods: {
            ...mapActions(['clearOpportunity', 'loadOpportunitiesForEmployerFair'])
        },

        watch: {
            opportunities: {
                handler(newVal) {
                    if (newVal == null) return;

                    this.opporunityData = [...newVal].map(o => merge({}, o));
                },
                immediate: true
            }
        }
    };
</script>