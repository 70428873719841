var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("opportunity-list", { on: { add: _vm.showForm } }),
      _c("br"),
      _c(
        "card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showOpportunityForm,
              expression: "showOpportunityForm",
            },
          ],
          attrs: {
            title: `Opportunity Form - ${
              _vm.editing ? "Currently Editing" : "Adding New Opportunity"
            }`,
          },
        },
        [
          _c("opportunity-form", {
            key: _vm.formState ? 0 : 1,
            ref: "form",
            on: { save: _vm.save, edit: _vm.edit, cancel: _vm.cancel },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }