var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "notification grid-item has-padding-none has-margin-none" },
    [
      _c("p", { staticClass: "title is-5" }, [
        _vm._v("Table " + _vm._s(_vm.table.number)),
      ]),
      _c("b-table", {
        staticClass: "table-item box has-padding-none",
        class: { focused: _vm.allDayHover },
        attrs: {
          data: _vm.table.reservationsByType,
          hoverable: "",
          draggable: "",
          bordered: "",
          narrowed: "",
          "row-class": (row) =>
            (row.reservation != null && "has-background-grey-lighter") ||
            (_vm.allDayHover && "is-selected"),
        },
        on: {
          dragstart: _vm.dragstart,
          drop: _vm.drop,
          dragover: _vm.dragover,
          dragleave: _vm.dragleave,
          dragend: _vm.dragend,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c("b-table-column", [
                  props.row.reservation != null
                    ? _c("div", { staticClass: "columns" }, [
                        _c("div", { staticClass: "column" }, [
                          _vm._v(
                            " " +
                              _vm._s(props.row.reservation.employerName) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "column is-narrow v-align-center" },
                          [
                            _c("trash", {
                              on: {
                                "click-delete": function ($event) {
                                  return _vm.confirmDelete(
                                    props.row.reservation.id
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getEnumDescription(
                                "SessionType",
                                props.row.sessionType
                              )
                            ) +
                            " "
                        ),
                      ]),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }