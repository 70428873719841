<template>
    <ValidationObserver ref="observer" v-slot="{ valid, errors, dirty }">
        <ul v-if="!valid" class="has-margin-bottom-md">
            <li v-for="(error, index) in flatten(errors)" :key="index">
                <span v-if="!hideErrors" class="has-text-danger">{{ error }}</span>
            </li>
        </ul>
        <slot :valid="valid" :dirty="dirty"></slot>
    </ValidationObserver>
</template>

<script>
    export default {
        name: 'FormValidator',

        props: {
            hideErrors: {
                type: Boolean
            },
            startValidation: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                dirty: false,
                watchingFlags: false
            };
        },

        async mounted() {
            if (this.startValidation) {
                this.watchFlags();
            }
        },

        watch: {
            startValidation(newVal) {
                if (newVal && !this.watchingFlags) {
                    this.watchFlags();
                }
            }
        },
        
        methods: {
            async validate() {
                const valid = await this.$refs.observer.validate();
                return valid;
            },

            reset() {
                this.$refs.observer.reset();
            },

            /** 
             * The errors object of the Validation Observer has a property for each 
             * Validation Provider (input you are validating). The value of each property 
             * is the list of validation errors for that input. This flattens out all of those 
             * errors into one array.
             */
            flatten(errors) {
                const flattenedErrors = Object.values(errors).flat();
                return flattenedErrors;
            },

            watchFlags() {
                this.$watch(
                    () => {
                        return this.$refs.observer.flags.dirty;
                    },
                    newVal => {
                        this.dirty = newVal;
                    }
                );

                this.watchingFlags = true;
            }
        }
    };
</script>