var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-field",
        [
          _c(
            "field-validator",
            { attrs: { name: "title" } },
            [
              _c(
                "b-field",
                {
                  attrs: { label: "Title", "label-for": "title", expanded: "" },
                },
                [
                  _c("b-input", {
                    attrs: {
                      id: "title",
                      name: "title",
                      expanded: "",
                      disabled: _vm.readonly,
                    },
                    on: { input: _vm.update },
                    model: {
                      value: _vm.inputValue.jobTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputValue, "jobTitle", $$v)
                      },
                      expression: "inputValue.jobTitle",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-field",
        { attrs: { grouped: "", "group-multiline": "" } },
        [
          _c(
            "field-validator",
            { attrs: { name: "type" } },
            [
              _c(
                "text-label",
                { attrs: { label: "Type" } },
                [
                  _c("enum-radio-buttons", {
                    attrs: {
                      "aria-label": "Type",
                      name: "type",
                      options: _vm.getOpportunityTypes,
                      disabled:
                        _vm.readonly ||
                        _vm.typeReadOnly ||
                        !_vm.showTypeSelection,
                    },
                    on: { input: _vm.update },
                    model: {
                      value: _vm.inputValue.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputValue, "type", $$v)
                      },
                      expression: "inputValue.type",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "field-validator",
            { attrs: { name: "paid" } },
            [
              _c(
                "text-label",
                { attrs: { label: "Paid" } },
                [
                  _c("enum-radio-buttons", {
                    attrs: {
                      "aria-label": "Paid",
                      name: "paid",
                      options: [
                        { id: true, description: "Yes" },
                        { id: false, description: "No" },
                      ],
                    },
                    on: { input: _vm.update },
                    model: {
                      value: _vm.inputValue.isPaid,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputValue, "isPaid", $$v)
                      },
                      expression: "inputValue.isPaid",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.typeSelected
            ? _c(
                "field-validator",
                { attrs: { name: "class years" } },
                [
                  _c(
                    "b-field",
                    {
                      attrs: {
                        label: "Class Years To Interview",
                        "label-for": "classesAllowed",
                        expanded: "",
                      },
                    },
                    [
                      _c("enum-taginput", {
                        attrs: {
                          id: "classesAllowed",
                          name: "classesAllowed",
                          data: _vm.attendeeTypes,
                          type: "is-primary",
                          disabled: _vm.readonly,
                        },
                        on: { input: _vm.update },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "empty",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " Your search didn't match any classes "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3891962654
                        ),
                        model: {
                          value: _vm.inputValue.attendeeTypes,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputValue, "attendeeTypes", $$v)
                          },
                          expression: "inputValue.attendeeTypes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-field",
        { attrs: { grouped: "", "group-multiline": "" } },
        [
          _c(
            "b-field",
            {
              attrs: { "label-for": "requiredLanguageSkills", expanded: "" },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "v-align-center" }, [
                        _vm._v(" Required Language Skills "),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "field-validator",
                { attrs: { name: "required language skills", required: "" } },
                [
                  _c("b-taginput", {
                    key: _vm.reqLangKey,
                    attrs: {
                      id: "requiredLanguageSkills",
                      name: "requiredLanguageSkills",
                      "on-paste-separators": [",", " "],
                      placeholder: "Add a language skill",
                      autocomplete: "",
                      "allow-new": "",
                      expanded: "",
                      disabled: _vm.readonly,
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.addTagOnBlur(
                          $event,
                          "requiredLanguageSkills"
                        )
                      },
                      input: _vm.update,
                    },
                    model: {
                      value: _vm.requiredLanguageSkills,
                      callback: function ($$v) {
                        _vm.requiredLanguageSkills = $$v
                      },
                      expression: "requiredLanguageSkills",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: { "label-for": "preferredLanguageSkills", expanded: "" },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "v-align-center" }, [
                        _vm._v(" Preferred Language Skills "),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "field-validator",
                { attrs: { name: "preferred language skills", required: "" } },
                [
                  _c("b-taginput", {
                    key: _vm.prefLangKey,
                    attrs: {
                      id: "preferredLanguageSkills",
                      name: "preferredLanguageSkills",
                      "on-paste-separators": [",", " "],
                      placeholder: "Add a language skill",
                      autocomplete: "",
                      "allow-new": "",
                      disabled: _vm.readonly,
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.addTagOnBlur(
                          $event,
                          "preferredLanguageSkills"
                        )
                      },
                      input: _vm.update,
                    },
                    model: {
                      value: _vm.preferredLanguageSkills,
                      callback: function ($$v) {
                        _vm.preferredLanguageSkills = $$v
                      },
                      expression: "preferredLanguageSkills",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "field-validator",
            { attrs: { name: "citizenship" } },
            [
              _c(
                "text-label",
                { attrs: { label: "U.S. Citizenship Required" } },
                [
                  _c("enum-radio-buttons", {
                    attrs: {
                      "aria-label": "US Citizenship Required",
                      name: "US Citizenship Required",
                      options: [
                        { id: true, description: "Yes" },
                        { id: false, description: "No" },
                      ],
                      disabled: _vm.readonly,
                    },
                    on: { input: _vm.update },
                    model: {
                      value: _vm.inputValue.usCitizenshipRequired,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputValue, "usCitizenshipRequired", $$v)
                      },
                      expression: "inputValue.usCitizenshipRequired",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-field",
        { attrs: { grouped: "", "group-multiline": "" } },
        [
          _c(
            "field-validator",
            { attrs: { name: "city" } },
            [
              _c(
                "b-field",
                { attrs: { label: "City", "label-for": "city", expanded: "" } },
                [
                  _c("b-autocomplete", {
                    attrs: {
                      data: _vm.cities,
                      id: "city",
                      name: "city",
                      "open-on-focus": "",
                      disabled: _vm.readonly,
                    },
                    on: { input: _vm.update },
                    model: {
                      value: _vm.inputValue.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputValue, "city", $$v)
                      },
                      expression: "inputValue.city",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "field-validator",
            { attrs: { name: "state" } },
            [
              _c(
                "b-field",
                {
                  attrs: { label: "State", "label-for": "state", expanded: "" },
                },
                [
                  _c("b-autocomplete", {
                    attrs: {
                      data: _vm.states,
                      id: "state",
                      name: "state",
                      "open-on-focus": "",
                      disabled: _vm.readonly,
                    },
                    on: { input: _vm.update },
                    model: {
                      value: _vm.inputValue.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputValue, "state", $$v)
                      },
                      expression: "inputValue.state",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("zip-code-search", {
            attrs: { readonly: _vm.readonly },
            on: { input: _vm.update, search: _vm.populateCityState },
            model: {
              value: _vm.inputValue.postalCode,
              callback: function ($$v) {
                _vm.$set(_vm.inputValue, "postalCode", $$v)
              },
              expression: "inputValue.postalCode",
            },
          }),
        ],
        1
      ),
      _c(
        "field-validator",
        { attrs: { name: "description" } },
        [
          _c(
            "b-field",
            {
              attrs: {
                "label-for": "description",
                "custom-class": "v-align-center",
              },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "v-align-center" },
                        [
                          _vm._v(" Description "),
                          _c(
                            "b-tooltip",
                            {
                              staticClass: "v-align-center has-margin-left-xs",
                              attrs: {
                                label: _vm.descriptionTooltip,
                                multilined: "",
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "info-circle",
                                  size: "is-small",
                                  type: "is-info",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("b-input", {
                attrs: {
                  id: "description",
                  name: "description",
                  type: "textarea",
                  disabled: _vm.readonly,
                },
                on: { input: _vm.update },
                model: {
                  value: _vm.inputValue.desc,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputValue, "desc", $$v)
                  },
                  expression: "inputValue.desc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "field-validator",
        { attrs: { name: "free sign-up" } },
        [
          _c(
            "text-label",
            {
              attrs: {
                label: "Allow Free Sign-Up",
                "has-tooltip": "",
                tooltip: _vm.freeSignUpTooltip,
              },
            },
            [
              _c("enum-radio-buttons", {
                attrs: {
                  "aria-label": "Allow Free Signup",
                  name: "allowUnranked",
                  options: [
                    { id: true, description: "Yes" },
                    { id: false, description: "No" },
                  ],
                  disabled: _vm.readonly,
                },
                on: { input: _vm.update },
                model: {
                  value: _vm.inputValue.allowNonApplicant,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputValue, "allowNonApplicant", $$v)
                  },
                  expression: "inputValue.allowNonApplicant",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.typeSelected
        ? _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _vm.inputValue.type != null
                ? _c(
                    "b-field",
                    {
                      attrs: {
                        label: _vm.additionalEmploymentOfferingsLabel,
                        "label-for": "additionalEmploymentOfferings",
                        expanded: "",
                        disabled: _vm.readonly,
                      },
                    },
                    [
                      _c("b-input", {
                        staticClass: "inline-textarea",
                        attrs: {
                          id: "additionalEmploymentOfferings",
                          name: "additionalEmploymentOfferings",
                          placeholder: "Please explain",
                          type: "textarea",
                          expanded: "",
                          disabled: _vm.readonly,
                        },
                        on: { input: _vm.update },
                        model: {
                          value: _vm.inputValue.additionalEmploymentOfferings,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inputValue,
                              "additionalEmploymentOfferings",
                              $$v
                            )
                          },
                          expression:
                            "inputValue.additionalEmploymentOfferings",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }