<template>
    <div class="has-padding-top-md">
        <form-validator ref="form">
            <div slot-scope="{ valid, dirty }">
                <b-field grouped="grouped" group-multiline="group-multiline">
                    <field-validator name="first name" label="First Name" expanded="expanded">
                        <b-input
                            name="firstName"
                            type="text"
                            v-model="representative.firstName"
                            expanded="expanded"
                        ></b-input>
                    </field-validator>
                    <field-validator name="last name" label="Last Name" expanded="expanded">
                        <b-input
                            name="lastName"
                            type="text"
                            v-model="representative.lastName"
                            expanded="expanded"
                        ></b-input>
                    </field-validator>
                    <field-validator rules="" name="job title" label="Job Title" expanded="expanded">
                        <b-input type="text" v-model="representative.jobTitle" expanded="expanded"></b-input>
                    </field-validator>
                    <b-field></b-field>
                </b-field>
                <div class="buttons has-margin-top-md">
                    <b-button 
                        type="is-success" 
                        icon-left="save" 
                        @click="save" 
                        :disabled="!valid && dirty">
                        {{ saveText }}
                    </b-button>
                    <b-button type="is-danger" icon-left="times" @click="cancel">Cancel</b-button>
                </div>
            </div>
        </form-validator>
    </div>
</template>

<script>
    import isEmpty from 'lodash/isEmpty';
    import merge from 'lodash/merge';

    import FieldValidator from '@/components/formControls/base/FieldValidator';
    import FormValidator from '@/components/formControls/base/FormValidator';

    export default {
        name: 'RepresentativeForm',

        components: {
            FieldValidator,
            FormValidator
        },

        props: {
            existingRep: {
                type: Object
            },
            saveText: {
                type: String,
                default: 'Add'
            }
        },

        data() {
            return {
                representative: {
                    firstName: null,
                    lastName: null,
                    jobTitle: null,
                    nonUserPersonId: 0
                }
            };
        },

        mounted() {
            if (!isEmpty(this.existingRep))
                merge(this.representative, this.existingRep);
        },

        methods: {
            async save() {
                const valid = await this.$refs.form.validate();
                if (valid) this.$emit('save', this.representative);
            },

            cancel() {
                this.$emit('cancel');
            }
        }
    };
</script>

<style scoped>
.buttons {
    margin-bottom: 0.5rem !important;
}
</style>