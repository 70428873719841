<template>
    <div v-if="opportunity === null">
        <span class="is-italic">No Opportunity</span>
    </div>
    <div v-else>
        <div :class="columnsCss">
            <div class="column is-one-third">
                <span class="has-text-weight-bold has-margin-right-sm">* Title:</span>
                <span class="content">{{ opportunity.jobTitle }}</span>
            </div>
            <div class="column is-one-third">
                &nbsp;
            </div>
            <div class="column is-one-third">
                <span class="has-text-weight-bold has-margin-right-sm">Allow Free Sign-Up:</span>
                <span class="content">{{ opportunity.allowNonApplicant ? 'Yes' : 'No' }}</span>
            </div>
        </div>
        <div :class="columnsCss">
            <div class="column is-one-third">
                <span class="has-text-weight-bold has-margin-right-sm">* Opportunity Type:</span>
                <span class="content">{{ opportunityType }}</span>
            </div>
            <div class="column is-one-third">
                <span class="has-text-weight-bold has-margin-right-sm">* Class Years to Interview:</span>
                <b-tag v-for="attendeeType in attendeeTypes" :key="attendeeType.id">
                    {{ attendeeType.description }}
                </b-tag>
            </div>
            <div class="column is-one-third">
                <span class="has-text-weight-bold has-margin-right-sm">* U.S. Citizenship Required:</span>
                <span class="content">{{ opportunity.usCitizenshipRequired ? 'Yes' : 'No' }}</span>
            </div>
        </div>
        <div :class="columnsCss">
            
            <div class="column is-one-third">
                <span class="has-text-weight-bold has-margin-right-sm">* Paid:</span>
                <span class="content">{{ opportunity.isPaid ? 'Yes' : 'No' }}</span>
            </div>
            <div class="column is-one-third">
                <span class="has-text-weight-bold has-margin-right-sm">Required Language Skills:</span>
                <b-tag v-for="(requiredLanguage, index) in requiredLanguages" :key="index">
                    {{ requiredLanguage }}
                </b-tag>
            </div>
            <div class="column is-one-third">
                <span class="has-text-weight-bold has-margin-right-sm">Preferred Language Skills:</span>
                <b-tag v-for="(preferredLanguage, index) in preferredLanguages" :key="index">
                    {{ preferredLanguage }}
                </b-tag>
            </div>
        </div>
        <div :class="columnsCss">
            <div class="column is-one-third">
                <span class="has-text-weight-bold has-margin-right-sm">* City:</span>
                <span class="content">{{ opportunity.city }}</span>
            </div>
            <div class="column is-one-third">
                <span class="has-text-weight-bold has-margin-right-sm">* State:</span>
                <span class="content">{{ opportunity.state }}</span>
            </div>
            <div class="column is-one-third">
                <span class="has-text-weight-bold has-margin-right-sm">Postal Code:</span>
                <span class="content">{{ opportunity.postalCode }}</span>
            </div>
        </div>
        <div :class="columnsCss">
            <div class="column is-narrow">
                <span class="has-text-weight-bold has-margin-right-sm">* Description:</span>
            </div>
            <div class="column">
                <span class="content">{{ opportunity.desc }}</span>
            </div>
        </div>
        <div :class="columnsCss">
            <div class="column is-4">
                <span class="has-text-weight-bold has-margin-right-sm">{{ additionalEmploymentOfferingsLabel }}</span>
            </div>
            <div :class="columnAutoValue">
                <span class="is-italic" v-if="opportunity.additionalEmploymentOfferings === null">None</span>
                <span class="content" v-else>{{ opportunity.additionalEmploymentOfferings }}</span>
            </div>
        </div>
        <div :class="columnsCss" v-if="coverLetter != null">
            <div class="column is-narrow">
                <span class="has-text-weight-bold has-margin-right-sm">
                    {{ coverLetter.isRequired ? '*' : '' }} Cover Letter:
                </span>
            </div>
            <div class="column">
                <span class="content">{{ coverLetter.description }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { DocumentTypes } from '@/helpers/staticValues';

    export default {
        name: 'EmployerOpportunityDetailView',

        props: {
            opportunity: {
                validator: (val) => val == null || typeof val == 'object',
                required: true
            }
        },

        data() {
            return {
                columnsCss: 'columns is-flex-tablet is-multiline',
                columnAutoValue: 'column has-text-left'
            };
        },

        computed: {
            ...mapGetters([
                'getAttendeeTypes',
                'getOpportunityTypes',
                'attendeeTypesInternship']),

            attendeeTypes() {
                return this.opportunity.attendeeTypes.map(at => this.getAttendeeTypes.find(a => a.id === at));
            },

            documentRequests() {
                // Resume is our first enum - sort to try and get that first
                // I have to unpack this array because ESlint complains about unexpected
                // side effects otherwise
                return [...this.opportunity.documentRequests].sort((a, b) => a.documentType - b.documentType);
            },

            opportunityType() {
                let opportunityType = this.getOpportunityTypes.find(ot => ot.id === this.opportunity.type);
                if (opportunityType) return opportunityType.description;

                return null;
            },

            additionalEmploymentOfferingsLabel() {
                if (this.opportunity.type === null) return null;

                return `Do you offer ${this.opportunity.type === this.attendeeTypesInternship.id
                    ? 'internships during the academic year'
                    : 'post-graduate fellowship positions'}?`;
            },

            requiredLanguages() {
                return this.opportunity.requiredLanguages.split(',');
            },

            preferredLanguages() {
                return this.opportunity.preferredLanguages.split(',');
            },

            coverLetter() {
                if (this.opportunity == null || this.opportunity.documentRequests == null) return null;

                return this.opportunity.documentRequests
                    .find(dr => dr.documentType === DocumentTypes.coverLetter);
            }
        }
    };
</script>