var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseAlignmentControl", {
    attrs: {
      horizontal: _vm.horizontal,
      centerText: _vm.centerText,
      leftColumnCss: _vm.leftColumnCss,
      rightColumnCss: _vm.rightColumnCss,
      wrapHeader: _vm.wrapHeader,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "label",
          fn: function () {
            return [
              _c(
                "label",
                { staticClass: "label" },
                [
                  _vm._v(" " + _vm._s(_vm.controlLabel) + " "),
                  _c("ToolTipControl", { attrs: { tip: _vm.toolTip } }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "action",
          fn: function () {
            return [_vm._t("default")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }