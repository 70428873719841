<template>
    <b-field>
        <b-radio-button
            v-model="hasAffirmed"
            v-bind="$attrs"
            :aria-label="affirmLabel"
            :size="buttonSize"
            :native-value="affirmLabel"
            @click="raiseEvent()"
            @input="handleAffirm()">
            {{ affirmLabel }}
        </b-radio-button>
        <b-radio-button
            v-model="hasAffirmed"
            v-bind="$attrs"
            :aria-label="denyLabel"
            :size="buttonSize"
            :native-value="denyLabel"
            @click="raiseEvent()"
            @input="handleDeny()">
            {{ denyLabel }}
        </b-radio-button>
        <b-radio-button
            v-model="hasAffirmed"
            v-bind="$attrs"
            v-if="useOther"
            :aria-label="otherLabel"
            :size="buttonSize"
            :native-value="otherLabel"
            @click="raiseEvent()"
            @input="handleDeny()">
            {{ otherLabel }}
        </b-radio-button>
    </b-field>
</template>

<script>
    export default {
        name: 'AffirmOrDenyButtonsControl',

        props: {
            buttonSize: {
                type: String,
                default: ''
            },
            affirmLabel: {
                type: String,
                default: 'Yes'
            },
            denyLabel: {
                type: String,
                default: 'No'
            },
            otherLabel: {
                type: String,
                default: ''
            },
            value: String
        },

        data() {
            return {
                hasAffirmed: this.value
            };
        },

        computed: {
            useOther() {
                return this.otherLabel != '';
            }
        },

        methods: {
            raiseEvent() {
                this.$emit('input', this.hasAffirmed);
            },
            handleAffirm() {
                this.$emit('affirm', this.hasAffirmed);
                this.raiseEvent();
            },
            handleDeny() {
                this.$emit('deny', this.hasAffirmed);
                this.raiseEvent();
            }
        },

        watch: {
            value(newVal) {
                this.hasAffirmed = newVal;
            }
        }
    };
</script>