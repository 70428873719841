import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=2f684402&"
import script from "./Sidebar.vue?vue&type=script&lang=js&"
export * from "./Sidebar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\rodrigueze\\Documents\\GitHub\\troyes\\Troyes\\Troyes.Web\\client_app\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2f684402')) {
      api.createRecord('2f684402', component.options)
    } else {
      api.reload('2f684402', component.options)
    }
    module.hot.accept("./Sidebar.vue?vue&type=template&id=2f684402&", function () {
      api.rerender('2f684402', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/employer/Sidebar.vue"
export default component.exports