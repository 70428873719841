var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "button is-small is-danger",
        on: { click: _vm.cancelAppointment },
      },
      [_vm._v(" Delete Appointment ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }