<template>
    <BaseAlignmentControl
        :horizontal="horizontal"
        :centerText="centerText"
        :leftColumnCss="leftColumnCss"
        :rightColumnCss="rightColumnCss"
        :wrapHeader="wrapHeader">     
        <template v-slot:label>
            <label class="label">
                {{ controlLabel }}
                <ToolTipControl :tip="toolTip" />
            </label>
        </template>
        <template v-slot:action>
            <slot></slot>
        </template>
    </BaseAlignmentControl>
</template>

<script>
    import AlignmentControlPropsMixin from '@/mixins/AlignmentControlPropsMixin';
    import FormControlPropsMixin from '@/mixins/FormControlPropsMixin';
    import ToolTipControl from './ToolTipControl';
    import BaseAlignmentControl from './BaseAlignmentControl';

    export default {
        name: 'FormControl',

        mixins: [ AlignmentControlPropsMixin, FormControlPropsMixin ],

        components: {
            ToolTipControl,
            BaseAlignmentControl
        }
    };
</script>