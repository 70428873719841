var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "collapsible-card",
        { attrs: { title: "Appointment List" } },
        [
          _c("List", {
            attrs: {
              rowId: "appointmentId",
              hasDetailColumn: true,
              paginated: false,
              value: _vm.getAppointments,
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function (props) {
                  return [
                    _c("attendee-appointment", {
                      attrs: { appointment: props.row },
                    }),
                  ]
                },
              },
              {
                key: "detail",
                fn: function (props) {
                  return [
                    _c("attendee-appointment-detail", {
                      attrs: { appointmentId: props.row.appointmentId },
                      on: { "delete-appointment": _vm.deleteAppointment },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }