var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationProvider", {
    attrs: {
      name: _vm.name,
      vid: _vm.vid,
      rules: _vm.rules,
      bails: false,
      skipIfEmpty: false,
      slim: "",
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ failed, required }) {
            return [
              _c(
                "b-field",
                {
                  class: { failed: failed, required: required },
                  attrs: {
                    title: required ? "Required" : null,
                    label: _vm.label,
                    "label-for": _vm.name,
                    expanded: _vm.expanded,
                  },
                },
                [_vm._t("default")],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }