<template>
    <div>
        <div class="columns is-desktop is-multiline">
            <div :class="columnCss">
                <field-validator :rules="{ required: shouldValidate }" name="first name">
                    <InputControl
                        v-model="inputValue.firstName"
                        @input="updateValue"
                        horizontal
                        controlLabel="First Name"
                        placeholder="First Name"
                        :leftColumnCss="leftColumnCss"
                        :rightColumnCss="rightColumnCss"
                        :readonly="readonly" />
                </field-validator>
            </div>
            <div :class="columnCss">
                <field-validator :rules="{ required: shouldValidate }" name="last name">
                    <InputControl
                        v-model="inputValue.lastName"
                        @input="updateValue"
                        horizontal
                        controlLabel="Last Name"
                        placeholder="Last Name"
                        :leftColumnCss="leftColumnCss"
                        :rightColumnCss="rightColumnCss"
                        :readonly="readonly" />
                </field-validator>
            </div>
        </div>
        <div class="columns is-desktop is-multiline">
            <div :class="columnCss">
                <field-validator rules="" name="phone">
                    <PhoneInputControl
                        v-model="inputValue.phone"
                        @input="updateValue"
                        horizontal
                        :leftColumnCss="leftColumnCss"
                        :rightColumnCss="rightColumnCss"
                        :disabled="readonly" />
                </field-validator>
            </div>
            <div :class="columnCss">
                <field-validator rules="" name="email">
                    <EmailInputControl
                        v-model="inputValue.email"
                        @input="updateValue"
                        horizontal
                        :leftColumnCss="leftColumnCss"
                        :rightColumnCss="rightColumnCss"
                        :disabled="readonly" />
                </field-validator>
            </div>
        </div>
    </div>
</template>

<script>
    import merge from 'lodash/merge';

    import AlignmentControlPropsMixin from '@/mixins/AlignmentControlPropsMixin';
    import InputControl from '@/components/formControls/base/InputControl';
    import EmailInputControl from '@/components/formControls/input/EmailInputControl';
    import PhoneInputControl from '@/components/formControls/input/PhoneInputControl';
    import FieldValidator from '@/components/formControls/base/FieldValidator';

    export default {
        name: 'NonUserPersonEdit',

        mixins: [
            AlignmentControlPropsMixin
        ],

        components: {
            InputControl,
            EmailInputControl,
            PhoneInputControl,
            FieldValidator
        },

        props: {
            value: {
                validator: (val) => val == null || typeof val == 'object'
            },
            label: String,
            stack: {
                type: Boolean,
                default: false
            },
            readonly: Boolean,
            leftColumnCss: {
                type: String,
                default: 'column is-4 has-text-right'
            },
            rightColumnCss: {
                type: String,
                default: 'column'
            }
        },

        data() {
            return {
                inputValue: merge({
                    nonUserPersonId: 0,
                    firstName: null,
                    lastName: null,
                    email: null,
                    phone: null
                }, this.value)
            };
        },

        methods: {
            updateValue() {
                if (this.inputValue.firstName == null && this.inputValue.lastName == null && 
                    this.inputValue.email == null && this.inputValue.phone == null) {
                    this.$emit('input', null);
                }
                else {
                    this.$emit('input', this.inputValue);
                }
            }
        },

        computed: {
            columnCss() {
                return {
                    'column': true,
                    'is-12': this.stack
                };
            },
            /** If the input is not null and it's partially filled out - perform validation */
            shouldValidate() {
                return this.inputValue != null && !Object.values(this.inputValue).every(v => v == null || v == 0);
            }
        },

        watch: {
            value(newVal) {
                this.inputValue = merge({
                    nonUserPersonId: 0,
                    firstName: null,
                    lastName: null,
                    email: null,
                    phone: null
                }, newVal);
            }
        }
    };
</script>