var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "columns is-desktop is-multiline" }, [
      _c(
        "div",
        { class: _vm.columnCss },
        [
          _c(
            "field-validator",
            {
              attrs: {
                rules: { required: _vm.shouldValidate },
                name: "first name",
              },
            },
            [
              _c("InputControl", {
                attrs: {
                  horizontal: "",
                  controlLabel: "First Name",
                  placeholder: "First Name",
                  leftColumnCss: _vm.leftColumnCss,
                  rightColumnCss: _vm.rightColumnCss,
                  readonly: _vm.readonly,
                },
                on: { input: _vm.updateValue },
                model: {
                  value: _vm.inputValue.firstName,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputValue, "firstName", $$v)
                  },
                  expression: "inputValue.firstName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { class: _vm.columnCss },
        [
          _c(
            "field-validator",
            {
              attrs: {
                rules: { required: _vm.shouldValidate },
                name: "last name",
              },
            },
            [
              _c("InputControl", {
                attrs: {
                  horizontal: "",
                  controlLabel: "Last Name",
                  placeholder: "Last Name",
                  leftColumnCss: _vm.leftColumnCss,
                  rightColumnCss: _vm.rightColumnCss,
                  readonly: _vm.readonly,
                },
                on: { input: _vm.updateValue },
                model: {
                  value: _vm.inputValue.lastName,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputValue, "lastName", $$v)
                  },
                  expression: "inputValue.lastName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "columns is-desktop is-multiline" }, [
      _c(
        "div",
        { class: _vm.columnCss },
        [
          _c(
            "field-validator",
            { attrs: { rules: "", name: "phone" } },
            [
              _c("PhoneInputControl", {
                attrs: {
                  horizontal: "",
                  leftColumnCss: _vm.leftColumnCss,
                  rightColumnCss: _vm.rightColumnCss,
                  disabled: _vm.readonly,
                },
                on: { input: _vm.updateValue },
                model: {
                  value: _vm.inputValue.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputValue, "phone", $$v)
                  },
                  expression: "inputValue.phone",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { class: _vm.columnCss },
        [
          _c(
            "field-validator",
            { attrs: { rules: "", name: "email" } },
            [
              _c("EmailInputControl", {
                attrs: {
                  horizontal: "",
                  leftColumnCss: _vm.leftColumnCss,
                  rightColumnCss: _vm.rightColumnCss,
                  disabled: _vm.readonly,
                },
                on: { input: _vm.updateValue },
                model: {
                  value: _vm.inputValue.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputValue, "email", $$v)
                  },
                  expression: "inputValue.email",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }