import store from '@/stores';

export const parseJwt = token => {
    try {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    } catch (error) {
        return {};
    }
};

export const userBasedNavigationGuards = (to, next) => {
    let isPublic = false;
    let hasPublicSetting = to.matched.some(record => 'isPublic' in record.meta);
    let hasAccessRoleDefined = to.matched.some(
        record => 'accessRole' in record.meta
    );

    if (!hasPublicSetting && !hasAccessRoleDefined) {
        next();
        return;
    }

    if (hasPublicSetting) {
        isPublic = to.matched.filter(path => 'isPublic' in path.meta)[0].meta
            .isPublic;
    }

    if (!hasPublicSetting && hasAccessRoleDefined) isPublic = false;

    if (isPublic) {
        next();
        return;
    }

    if (hasAccessRoleDefined) {
        let accessRole = to.matched.filter(path => 'accessRole' in path.meta)[0]
            .meta.accessRole;
        let userRoles = getUserRoles();
        let token = getAccessToken();

        console.log('role');
        console.log(token.troyes_role);

        if (userRoles === null || token === null) {
            next({ path: '/NotFound', replace: true });
            return;
        }

        if ('troyes_role' in token) {

            if (token.troyes_role == 'employer' && !hasUserBeenApproved()) {
                // next({ name: 'employer-registration', replace: true });
                next();
                return;
            }

            if (token.troyes_role.indexOf(accessRole) != -1) {
                if (!hasUserBeenApproved()) {
                    next({ name: 'pending-user-approval', replace: true });
                    return;
                }

                next();
                return;
            }
        }
    }

    next({ path: '/NotFound', replace: true });
    return;
};

export const getAccessToken = () => {
    if (store !== undefined && store.state.oidcStore) {
        if (store.getters.oidcIsAuthenticated) {
            return parseJwt(store.getters.oidcAccessToken);
        }
    }

    return null;
};

export const getUserRoles = () => {
    let token = getAccessToken();

    if (token === null) return null;

    if ('troyes_role' in token) {
        return token.troyes_role;
    }

    return null;
};

export const hasUserBeenApproved = () => {
    let token = getAccessToken();

    if (token === null) return null;

    if ('troyes_registration_status' in token) {
        return token.troyes_registration_status === 'approved';
    }

    return false;
};
