import * as Api from '@/api/reservation';
import * as Notifier from '@/helpers/globalNotifications';

export const loadTableSummary = async ({ commit }) => {
  try {
    const response = await Api.getTableAssignmentSummary();
    commit('LOAD_TAB_ASSIGN_DATA', response);
  } catch (error) {
    Notifier.Error(
      'We had some trouble loading reservation & table assignment data.'
    );
  }
};

export const assignReservation = async (
  { dispatch },
  { reservationId, roomId, tableNumber }
) => {
  try {
    // Get the acknowledgement that the assignment happened
    await Api.assignReservation(reservationId, roomId, tableNumber);
    // Reload the data that populates the table assignment view for fair admins
    return dispatch('loadTableSummary');
  } catch (error) {
    Notifier.Error(
      'We had some trouble assigning that reservation to a table.'
    );
  }
};

/** This is for assigning several reservations at one - doing an ALL DAY assignment */
export const assignReservations = async (
  { dispatch },
  { reservationIds, roomId, tableNumber }
) => {
  try {
    const apiCalls = [];

    reservationIds.forEach(id => {
      apiCalls.push(Api.assignReservation(id, roomId, tableNumber));
    });

    await Promise.all(apiCalls);
    return dispatch('loadTableSummary');
  } catch (error) {
    Notifier.Error(
      'We had some trouble assigning those reservations to a table.'
    );
  }
};

export const unassignReservation = async ({ dispatch }, reservationId) => {
  try {
    await Api.unassignReservation(reservationId);
    return dispatch('loadTableSummary');
  } catch (error) {
    Notifier.Error('We had some trouble unassigning that reservation.');
  }
};

export const moveReservation = async (
  { dispatch },
  { reservationId, roomId, tableNumber }
) => {
  try {
    await Api.moveReservation(reservationId, roomId, tableNumber);
    return dispatch('loadTableSummary');
  } catch (error) {
    Notifier.Error('We had some trouble moving that reservation.');
  }
};

export const swapReservations = async (
  { dispatch },
  { sourceId, targetId }
) => {
  try {
    await Api.swapReservations(sourceId, targetId);
    return dispatch('loadTableSummary');
  } catch (error) {
    Notifier.Error('We had some trouble swapping those reservations.');
  }
};

export const setDraggingReservation = ({ commit }, res) => {
  commit('SET_DRAGGING_RES', res);
};

export const clearDraggingReservation = ({ commit }) => {
  commit('CLEAR_DRAGGING_RES');
};
