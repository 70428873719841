<template>
    <div>
        <attendee-profile-information></attendee-profile-information>
        <attendee-profile-availability></attendee-profile-availability>
    </div>
</template>

<script>
    import AttendeeProfileInformation from '@/components/attendee/AttendeeProfileInformation.vue';
    import AttendeeProfileAvailability from '@/components/attendee/AttendeeProfileAvailability.vue';

    export default {
        components: {
            AttendeeProfileInformation,
            AttendeeProfileAvailability
        },

        name: 'AttendeeProfile'

    };
</script>