var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("UserList", {
    attrs: {
      detailKey: "guid",
      defaultFilterValue: _vm.defaultFilterStatus,
      value: _vm.employerRegistrations,
      isLoading: _vm.loadingUserRegistrations,
    },
    on: { "row-toggled": _vm.clearSelectedEmployer },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (props) {
          return [
            _c(
              "b-table-column",
              { attrs: { field: "fair_contact", label: "Fair Contact" } },
              [
                _vm._v(
                  " " +
                    _vm._s(props.row.lastName) +
                    ", " +
                    _vm._s(props.row.firstName) +
                    " "
                ),
              ]
            ),
            _c(
              "b-table-column",
              {
                attrs: {
                  field: "organization_name",
                  label: "Organization Name",
                },
              },
              [_vm._v(" " + _vm._s(props.row.employerName) + " ")]
            ),
          ]
        },
      },
      {
        key: "detail",
        fn: function (props) {
          return [
            _c("UserRegistrationDetails", {
              attrs: { user: props.row },
              scopedSlots: _vm._u(
                [
                  {
                    key: "firstRow",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "column is-narrow" }, [
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Employer Name:"),
                          ]),
                        ]),
                        _c("div", { staticClass: "column has-text-left" }, [
                          _c("span", { staticClass: "content" }, [
                            _vm._v(_vm._s(props.row.employerName)),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  _vm.canApproveContact(props.row.status)
                    ? {
                        key: "lastRow",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "column" },
                              [
                                _c(
                                  "form-validator",
                                  { ref: "employerForm" },
                                  [
                                    _c("EmployersAutoCompleteInputControl", {
                                      model: {
                                        value: _vm.selectedEmployer,
                                        callback: function ($$v) {
                                          _vm.selectedEmployer = $$v
                                        },
                                        expression: "selectedEmployer",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "column" }, [
                              _c(
                                "div",
                                { staticClass: "buttons is-right" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        type: "is-primary",
                                        disabled: _vm.disableActionButtons,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.approveUser(props.row)
                                        },
                                      },
                                    },
                                    [_vm._v("Approve")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        type: "is-danger",
                                        disabled: _vm.disableActionButtons,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rejectUser(props.row)
                                        },
                                      },
                                    },
                                    [_vm._v("Reject")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }