<template>
    <b-field v-if="!isResume" grouped group-multiline>
        <field-validator name="'document required'">
            <text-label
                label="Is this document required?"
                has-tooltip
                :tooltip="requiredTooltip"
            >
                <b-field>
                    <b-radio-button
                        name="isRequired"
                        :native-value="true"
                        :disabled="isResume"
                    >
                        Required
                    </b-radio-button>
                    <b-radio-button :native-value="false" :disabled="isResume">
                        Optional
                    </b-radio-button>
                </b-field>
            </text-label>
        </field-validator>

        <b-field label="Description (optional)" expanded>
            <field-validator name="document description" required="">
                <b-input
                    id="documentDescription"
                    name="description"
                    type="textarea"
                />
            </field-validator>
        </b-field>
    </b-field>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";

import DocumentTypes from "@/enums/DocumentTypes";
import FieldValidator from "@/components/formControls/base/FieldValidator";

export default {
    name: "DocumentRequest",

    components: {
        FieldValidator,
    },

    props: {
        request: {
            type: Object,
            required: true,
        },
        editable: {
            type: Boolean,
        },
    },

    data() {
        return {
            requiredTooltip:
                'By selecting "Required", Applicants will be required to upload this document. Selecting "Optional" will make this upload optional for Applicants.',
        };
    },

    computed: {
        ...mapGetters(["getEnumDescription"]),

        typeDescription() {
            return this.getEnumDescription(
                "DocumentType",
                this.request.documentType
            );
        },

        isResume() {
            return this.request.documentType == DocumentTypes.Resume;
        },
    },

    watch: {
        request: {
            handler(newVal) {
                if (newVal.documentType == DocumentTypes.Resume) {
                    newVal.isRequired = true;
                }
            },
            immediate: true,
        },
    },
};
</script>

<style scoped>
div.card >>> header.card-header {
    background-color: white;
}
</style>
