var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "columns is-multiline is-gapless is-vcentered is-tablet" },
    [
      _c("div", { staticClass: "column is-full" }, [
        _c("div", { staticClass: "has-padding-bottom-sm" }, [
          _vm._v(
            " " +
              _vm._s(_vm.opportunity.jobTitle) +
              " - " +
              _vm._s(_vm.opportunity.city) +
              ", " +
              _vm._s(_vm.opportunity.state) +
              " "
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "b-taglist",
            [
              _c("b-tag", { attrs: { type: "is-primary", rounded: "" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getEnumDescription(
                        "OpportunityType",
                        _vm.opportunity.type
                      )
                    ) +
                    " "
                ),
              ]),
              _vm.opportunity.allowNonApplicant
                ? _c("b-tag", { attrs: { rounded: "" } }, [
                    _vm._v(" " + _vm._s(_vm.allowUnrankedDesc) + " "),
                  ])
                : _vm._e(),
              _vm._l(_vm.opportunity.attendeeTypes, function (attendeeType) {
                return _c(
                  "b-tag",
                  {
                    key: attendeeType,
                    attrs: { type: "is-primary", rounded: "" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getEnumDescription("AttendeeType", attendeeType)
                        ) +
                        " "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm.hasActions
        ? _c(
            "div",
            { staticClass: "column is-narrow v-align-center" },
            [
              _c("EditButton", {
                attrs: { disabled: _vm.editing },
                on: { "click-edit": _vm.edit },
              }),
              _c("TrashButton", {
                staticClass: "has-margin-right-md",
                attrs: { disabled: _vm.editing },
                on: { "click-delete": _vm.remove },
              }),
              _c(
                "b-button",
                { attrs: { disabled: _vm.editing }, on: { click: _vm.rank } },
                [_vm._v(" Rank ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }