import { OpportunityTypes } from '@/helpers/staticValues';
import { ParticipantStatusTypes } from '@/helpers/staticValues';

export const enums = state => () => state.enums;

export const getEnum = state => enumName => {
    if (state.enums == null) return [];
    let enumValues = [];

    try {
        if (!Array.isArray(state.enums)) throw Error(`${state.enums}`);

        const enumCollection = state.enums.find(e => e.key == enumName);

        if (enumCollection == null) return enumValues;

        enumValues = enumCollection.enumValues;
    } catch (error) {
        // eslint-disable-next-line no-empty
    }

    return enumValues;
};

export const hasEnums = state => state.enums.length > 0;

export const getEnumDescription = state => (enumName, id) => {
    let description = '';

    try {
        if (!Array.isArray(state.enums)) throw Error(`${state.enums}`);

        const collection = state.enums.find(e => e.key == enumName).enumValues;
        const enumeration = collection.find(e => e.id == id);

        description = enumeration.description;
    } catch (error) {
        // eslint-disable-next-line no-empty
    }

    return description;
};

export const getSeshTypeDescription = (state, getters) => id => {
    const defaultDescription = getters.getEnumDescription('SessionType', id);
    let description = defaultDescription;

    switch (true) {
        case defaultDescription.toUpperCase().includes('MORNING'):
            description = 'Morning Only (9:00 a.m. - 12:30 p.m.)';
            break;
        case defaultDescription.toUpperCase().includes('AFTERNOON'):
            description = 'Afternoon Only (2:00 p.m. - 5:00 p.m.)';
            break;
    }

    return description;
};

export const getSessionTypeShortDescription = (state, getters) => id => {
    const defaultDescription = getters.getEnumDescription('SessionType', id);

    if (defaultDescription.toUpperCase().includes('MORNING')) return 'AM';
    else if (defaultDescription.toUpperCase().includes('AFTERNOON'))
        return 'PM';
};

export const getRoomTypeShortDescription = (state, getters) => id => {
    const defaultDescription = getters.getEnumDescription('RoomType', id);

    if (defaultDescription.toUpperCase().includes('INTERVIEW TABLE'))
        return 'Int';
    else if (defaultDescription.toUpperCase().includes('TABLE TALK'))
        return 'TT';
};

export const getOpportunityTypes = (state, getters) => {
    return getters.getEnum('OpportunityType');
};

export const opportunityTypePostGrad = (state, getters) => {
    return getters
        .getEnum('OpportunityType')
        .find(ot => ot.id === OpportunityTypes.fullTime);
};

export const opportunityTypeInternship = (state, getters) => {
    return getters
        .getEnum('OpportunityType')
        .find(ot => ot.id === OpportunityTypes.internship);
};

export const attendeeTypesFullTime = (state, getters) => {
    return getters.getEnum('FullTimeAttendeeTypes');
};

export const attendeeTypesInternship = (state, getters) => {
    return getters.getEnum('InternshipAttendeeTypes');
};

export const getAttendeeTypesByOpportunityType = (state, getters) => opportunityTypeId => {
    if (opportunityTypeId === OpportunityTypes.fullTime) {
        return getters.attendeeTypesFullTime;
    }

    if (opportunityTypeId === OpportunityTypes.internship) {
        return getters.attendeeTypesInternship;
    }
};

export const getAttendeeTypes = (state, getters) => {
    return getters.getEnum('AttendeeType');
};

export const getPracticeTypes = (state, getters) => {
    return getters.getEnum('PracticeType');
};

export const getSubjectAreas = (state, getters) => {
    return getters.getEnum('SubjectArea');
};

export const getOrganizationTypes = (state, getters) => {
    return getters.getEnum('OrganizationType');
};

export const getUnapprovedParticipantStatus = (state, getters) => {
    return getters.getEnum('ParticipantStatus')
        .find(ps => ps.id === ParticipantStatusTypes.unapproved);
};
