/** A substitute for the closest function found in most browsers */
function closest(el, selector) {
  var matchesFn;

  // find vendor prefix
  [
    'matches',
    'webkitMatchesSelector',
    'mozMatchesSelector',
    'msMatchesSelector',
    'oMatchesSelector'
  ].some(function (fn) {
    if (typeof document.body[fn] == 'function') {
      matchesFn = fn;
      return true;
    }
    return false;
  });

  var parent;

  // traverse parents
  while (el) {
    parent = el.parentElement;
    if (parent && parent[matchesFn](selector)) {
      return parent;
    }
    el = parent;
  }

  return null;
}

/** The underlying assumption with this mixin is that the draggable elements you're working with are b-table rows */
export const dragAndDropMixin = {
  data() {
    return {
      dragOverClass: 'is-selected',
      dragOverElement: 'tr'
    };
  },

  methods: {
    /** Event: When you first grab a draggable element we want to store the payload in the store. */
    dragstart(payload) {
      this.$super().dragstart(payload);
    },

    /** Event: Triggers when a draggable element is being hovered over a valid drop target. */
    dragover(target) {
      this.$super().dragover(target);
    },

    /** Event: Triggers when a reservation leaves a valid drop target. */
    dragleave(target) {
      this.$super().dragleave(target);
    },

    // eslint-disable-next-line
    dragend(payload) {
      this.$super().dragend(payload);
    },

    /** Event: Triggers when a reservation is dropped on a valid drop target. */
    drop(target) {
      this.$super().drop(target);
    },

    copyDraggingRowDataToTarget(target) {
      this.$super().copyDraggingRowDataToTarget(target);
    },

    /** Apply selected styling to the row you're targeting */
    selectRow(payload) {
      this.$super().selectRow(payload);
    },

    /** Clear selected styling for the row you are targeting */
    clearRow(payload) {
      this.$super().clearRow(payload);
    },

    /** Adds a visual indicator via the cursor to let the user know that what they're dropping is being copied */
    setDropCursorToCopy(payload) {
      this.$super().setDropCursorToCopy(payload);
    },

    $super() {
      return {
        dragstart: payload => {
          throw new Error(`${payload}\nNot Implemented Exception.`);
        },

        /** Event: Triggers when a draggable element is being hovered over a valid drop target. */
        dragover: target => {
          this.setDropCursorToCopy(target);
          this.selectRow(target);
          target.event.preventDefault();
        },

        /** Event: Triggers when a reservation leaves a valid drop target. */
        dragleave: target => {
          this.clearRow(target);
          target.event.preventDefault();
        },

        // eslint-disable-next-line
        dragend: _payload => { },

        /** Event: Triggers when a reservation is dropped on a valid drop target. */
        drop: target => {
          this.clearRow(target);
          this.copyDraggingRowDataToTarget(target);
        },

        copyDraggingRowDataToTarget: target => target,

        /** Apply selected styling to the row you're targeting */
        selectRow: payload => {
          const target = payload.event.target;

          if (target.closest != null) {
            target.closest(this.dragOverElement).classList.add(this.dragOverClass);
          } else {
            closest(target, this.dragOverElement).classList.add(this.dragOverClass);
          }
        },

        /** Clear selected styling for the row you are targeting */
        clearRow: payload => {
          const target = payload.event.target;

          if (target.closest != null) {
            target.closest(this.dragOverElement).classList.remove(this.dragOverClass);
          } else {
            closest(target, this.dragOverElement).classList.remove(this.dragOverClass);
          }
        },

        /** Adds a visual indicator via the cursor to let the user know that what they're dropping is being copied */
        setDropCursorToCopy: payload => {
          payload.event.dataTransfer.dropEffect = 'copy';
        }
      };
    }
  }
};
