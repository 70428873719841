<template>
    <section>
        <h3 class="title">My Availability</h3>
        <p>Enter your availability for each Fair Date listed. Don't worry about excluding breaks (eg for full day availability, use 9:00AM - 5:00pm)</p><br />

        <div v-for="day in userAvailability.dailyAvailability" :key="day.date">
            <h4 class="subtitle is-4">{{ day.date | date() }}</h4>

            <user-availability-session v-for="userAvailabilitySession in day.userAvailabilitySessions" :key="userAvailabilitySession.userAvailabilitySessionId"
                                       :userAvailabilitySession="userAvailabilitySession"
                                       @refresh-user-availability="loadUserAvailability">
            </user-availability-session>
            <user-availability-session :date="day.date"></user-availability-session>
        </div>
    </section>
</template>

<script>
    import UserAvailabilitySession from '@/components/attendee/UserAvailabilitySession.vue';
    import { getUserAvailability } from '@/api/attendee';

    export default {
        components: { UserAvailabilitySession },

        name: 'AttendeeProfileAvailability',

        data() {
            return {
                userAvailability: []
            };
        },

        methods: {
            loadUserAvailability() {
                getUserAvailability(13).then(response => { // TODO: remove magic number here and inject actual attendeeId
                    this.userAvailability = response.data;
                });

            }
        },

        mounted() {
            this.loadUserAvailability();
        }
    };
</script>