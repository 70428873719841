<template>
    <section>
        <List rowId="id" accordion hasDetailColumn :rowsPerPage="10" :paginated="true" :value="value">
            <template slot="content" slot-scope="props">
                <opportunity :opportunity="props.row" :has-actions="false" />
            </template>
            <template slot="detail" slot-scope="value">
                <opportunity-detail v-for="v in value" :key="v.id" :opportunity="v">
                    <opportunity-application v-if="!alreadyApplied(v.id)" :id="v.id"
                        :document-requests="v.documentRequests" />
                </opportunity-detail>
            </template>
            <template slot="empty">
                <div v-if="!hasData">
                    <p class="title is-6">
                        Sorry, we couldn't find any opportunities matching your
                        search criteria.
                    </p>
                </div>
            </template>
        </List>
        <doc-modal />
    </section>
</template>

<script>
    import { mapGetters } from 'vuex';
    import DocModal from '@/components/document/DocModal';
    import Opportunity from '@/components/employer/opportunity/Opportunity';
    import OpportunityApplication from '@/components/employer/opportunity/OpportunityApplication';
    import OpportunityDetail from '@/components/employer/opportunity/OpportunityDetail';

    export default {
        name: 'OpportunitySearchList',

        components: {
            DocModal,
            Opportunity,
            OpportunityApplication,
            OpportunityDetail,
        },

        props: {
            value: Array,
        },

        computed: {
        ...mapGetters(['alreadyApplied']),

        hasData() {
            return this.value != null && this.value.length > 0;
        },
        },
    };
</script>
