<template>
    <b-field>
        <b-radio-button 
            :aria-label="ariaLabel"
            :value="value" 
            v-for="option in options" 
            :key="option.id" 
            :native-value="option.id"
            @input="input(option.id)"
            :name="name"
            :disabled="disableOption(option.id)"
            v-bind="$attrs">
            <span>{{ option.description }}</span>
        </b-radio-button>
    </b-field>
</template>

<script>
    export default {
        name: 'EnumRadioButtons',

        props: {
            ariaLabel: {
                type: String,
                required: true
            },
            options: {
                type: Array,
                required: true
            },
            value: {
                required: true
            },
            name: {
                type: String
            },
            omitOptions: {
                type: Array,
                default: () => []
            }
        },

        methods: {
            input(value) {
                this.$emit('input', value);
            },

            disableOption(id) {
                if (this.omitOptions.length == 0) {
                    return false;
                } 

                const omitTypes = this.omitOptions.map(o => o.documentType);
                return omitTypes.includes(id);
            }
        }
    };
</script>