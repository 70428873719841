<template>
    <FormControl
        :horizontal="showHorizontal"
        :leftColumnCss="questionCss"
        :rightColumnCss="actionCss"
        :controlLabel="question"
        :centerText="centerText"
        :toolTip="toolTip">
        <div v-if="!showCard">
            <AffirmOrDenyButtonsControl
                :value="value"
                :buttonSize="buttonSize"
                :affirmLabel="affirmLabel"
                :denyLabel="denyLabel"
                :otherLabel="otherLabel"
                @input="updateValue"
                :disabled="readonly" />

            <InputControl
                v-if="answer == otherLabel"
                placeholder="Reason"
                type="textarea"
                :disabled="readonly" />
        </div>
        <div v-else>
            <div class="columns is-mobile">
                <div class="column is-three-fifths is-offset-one-fifth">
                    <b-notification aria-close-label="Close notification" :closable="false">
                        <AffirmOrDenyButtonsControl
                            :value="value"
                            :buttonSize="buttonSize"
                            :affirmLabel="affirmLabel"
                            :denyLabel="denyLabel"
                            :otherLabel="otherLabel"
                            @input="updateValue"
                            :disabled="readonly" />
                        
                        <InputControl
                            v-if="answer == otherLabel"
                            placeholder="Reason"
                            type="textarea"
                            :disabled="readonly" />
                    </b-notification>
                </div>
            </div>
        </div>
    </FormControl>
</template>

<script>
    import AlignmentControlPropsMixin from '@/mixins/AlignmentControlPropsMixin';
    import FormControl from '@/components/formControls/base/FormControl';
    import InputControl from '@/components/formControls/base/InputControl';
    import AffirmOrDenyButtonsControl from './button/AffirmOrDenyButtonsControl';

    export default {
        name: 'AffirmOrDenyQuestionControl',

        mixins: [ AlignmentControlPropsMixin ],

        components: {
            AffirmOrDenyButtonsControl,
            FormControl,
            InputControl
        },

        props: {
            question: {
                type: String
            },
            affirmLabel: {
                type: String,
                default: 'Yes'
            },
            denyLabel: {
                type: String,
                default: 'No'
            },
            otherLabel: String,
            /**
             * Style of event handler. Radio button, regular button, or swtich
             */
            buttonStyle: {
                type: String,
                default: 'button'
            },
            /**
            * Indicate how the question will be displayed
            * 1. inline - All on one line. 
            *    <<question>>: <<buttonStyle>>
            * 2. block - Label on first line followed by <<buttonStyle>> on the next
            *    <<question>>:
            *    <<buttonStyle>>
            * 3. cardInline: Same as 'inline' but centered in a card (without a header)
            * 4. cardBlock: Same as 'block' but centered in a card (without a header)
            */
            outputStyle: {
                type: String,
                default: 'inline'
            },
            toolTip: {
                type: String,
                default: ''
            },
            buttonSize: {
                type: String,
                default: ''
            },
            center: {
                type: Boolean,
                default: false
            },
            questionCss: {
                type: String,
                default: 'column'
            },
            actionCss: {
                type: String,
                default: 'column'
            },
            value: {
                type: String,
                default: 'No'
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                answer: ''
            };
        },

        computed: {
            showHorizontal() {
                return this.outputStyle === 'inline' || this.outputStyle === 'cardInline';
            },

            showCard() {
                return this.outputStyle === 'cardInline' || this.outputStyle === 'cardBlock';
            }
        },

        methods: {
            updateValue(input) {
                this.$emit('input', input);
            }
        }
    };
</script>