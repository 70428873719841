var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-validator", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ valid, dirty }) {
          return _c(
            "Card",
            {
              attrs: { title: "Organization Details" },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "card-content buttons" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "is-success",
                                "icon-right": "save",
                                disabled: !valid && dirty,
                              },
                              on: { click: _vm.submit },
                            },
                            [_vm._v(" Save Details ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("EmployerProfileEdit", {
                ref: "orgDetails",
                attrs: { edit: "" },
              }),
            ],
            1
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }