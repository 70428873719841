var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.documentTypes, function (docType) {
      return _c("document-request-option", {
        key: docType.id,
        attrs: { docType: docType },
        on: {
          input: function ($event) {
            return _vm.$emit("input", _vm.inputValue)
          },
        },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }