import { troyesRole } from '@/helpers/staticValues';
import Standard from '@/views/shared/layouts/Standard';

import EmployerView from '@/views/employer/Index';

import EmployerRegistrationInformation from '@/views/employer/EmployerRegistrationInformation';
import Sidebar from '@/views/employer/Sidebar';

import EmployerForm from '@/components/employer/EmployerForm';
import ContactForm from '@/components/employer/ContactForm';
import ParticipationForm from '@/components/employer/ParticipationForm';

import EmployerOpportunities from '@/views/employer/EmployerOpportunities';
import EmployerApplications from '@/views/employer/EmployerApplications';
import EmployerFaq from '@/views/employer/EmployerFaq';

var children = [
    {
        path: '',
        name: 'employer-home',
        meta: {
            showSidebar: true,
        },
        components: {
            default: EmployerView,
            sidebar: Sidebar
        }
    },
    {
        path: 'registration-info',
        meta: { showSidebar: false },
        component: EmployerRegistrationInformation,
        children: [
            {
                path: '',
                name: 'employer-registration-info',
                redirect: 'organization'
            },
            {
                path: 'organization',
                name: 'employer-organization-tab',
                component: EmployerForm
            },
            {
                path: 'contacts',
                name: 'employer-contact-tab',
                component: ContactForm
            },
            {
                path: 'participation',
                name: 'employer-participation-tab',
                component: ParticipationForm
            },
            {
                path: 'opportunities',
                name: 'employer-opportunity-tab',
                component: EmployerOpportunities
            }
        ]
    },
    {
        path: 'applications',
        name: 'employer-applications',
        meta: { showSidebar: false },
        components: {
            default: EmployerApplications
        }
    },
    {
        path: 'faq',
        name: 'employer-faq',
        meta: { showSidebar: false },
        components: {
            default: EmployerFaq
        }
    }
];

export default {
    path: '/employer',
    component: Standard,
    meta: {
        isPublic: false,
        accessRole: troyesRole.Employer
    },
    children
};
