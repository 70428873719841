import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
// This code will suppress the uncaught exception thrown when calling either
// with $router.push or $router.replace methods.
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);

    return originalPush.call(this, location).catch(err => err);
};

const originalReplace = Router.prototype.replace;

Router.prototype.replace = function replace(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);

    return originalReplace.call(this, location).catch(err => err);
};

Vue.use(Router);

const router = new Router({
    routes,

    mode: 'history',

    base: process.env.BASE_URL,

    linkActiveClass: 'is-active',

    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        if (to.hash) {
            return { selector: to.hash };
        }

        if (to.meta.scrollToTop) {
            return { x: 0, y: 0 };
        }
    }
});

export default router;
