<template>
    <article class="notification grid-item has-padding-none has-margin-none">
        <p class="title is-5">Table {{ table.number }}</p>
        <b-table
            class="table-item box has-padding-none"
            :data="table.reservationsByType"
            hoverable
            draggable
            bordered
            @dragstart="dragstart"
            @drop="drop"
            @dragover="dragover"
            @dragleave="dragleave"
            @dragend="dragend"
            narrowed
            :class="{ focused: allDayHover }"
            :row-class="
                (row) =>
                    (row.reservation != null &&
                        'has-background-grey-lighter') ||
                    (allDayHover && 'is-selected')
            "
        >
            <template slot-scope="props">
                <b-table-column>
                    <div class="columns" v-if="props.row.reservation != null">
                        <div class="column">
                            {{ props.row.reservation.employerName }}
                        </div>
                        <div class="column is-narrow v-align-center">
                            <trash
                                @click-delete="
                                    confirmDelete(props.row.reservation.id)
                                "
                            />
                        </div>
                    </div>
                    <div v-else>
                        {{
                            getEnumDescription(
                                "SessionType",
                                props.row.sessionType
                            )
                        }}
                    </div>
                </b-table-column>
            </template>
        </b-table>
    </article>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from "vuex";
import { dragAndDropMixin } from "@/mixins/DragAndDropMixin";
import Trash from "@/components/actions/Trash";

export default {
    name: "Locale",

    mixins: [dragAndDropMixin],

    components: {
        Trash,
    },

    props: {
        table: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            allDayHover: false,
        };
    },

    methods: {
        ...mapActions(["clearDraggingReservation", "setDraggingReservation"]),

        // Event: When you grab a reservation, it is wrapped in a payload object. The reservation's data is accessed via the row property.
        dragstart(payload) {
            // @TODO When Mozilla fixes the following bug: https://bugzilla.mozilla.org/show_bug.cgi?id=725156
            // remove the dataTransfer.setData workaround below. It looks like it's been open for 8 years - we'll see what happens
            payload.event.dataTransfer.setData(
                "application/x-workaround",
                payload.row
            );
            if (payload.row.reservation == null) {
                payload.event.preventDefault();
                return;
            }
            this.setDraggingReservation(payload.row.reservation);
        },

        dragover(target) {
            this.$super().dragover(target);
            if ("reservations" in this.draggingReservation) {
                this.allDayHover =
                    this.draggingReservation.reservations.length ==
                        this.table.reservationsByType.length &&
                    this.table.reservationsByType.every(
                        (rbt) => rbt.reservation == null
                    );
            }
        },

        dragleave(target) {
            this.$super().dragleave(target);
            this.allDayHover = false;
        },

        copyDraggingRowDataToTarget(target) {
            const isAssignment = "reservations" in this.draggingReservation;
            // from one table to another
            const isMoveOrSwap = "assignedTable" in this.draggingReservation;
            // Are we dropping a reservation into an open space
            const targetIsEmpty = target.row.reservation == null;

            if (isAssignment) {
                this.assign(
                    target.row,
                    this.draggingReservation,
                    this.allDayHover
                );
            } else if (isMoveOrSwap) {
                this.moveOrSwap(
                    target.row,
                    this.draggingReservation,
                    targetIsEmpty
                );
            }

            this.allDayHover = false;
            this.clearDraggingReservation();
        },

        // Processes a call to assign reservation(s) to a table
        assign(target, source, allDayAssignment) {
            // If there's a session type mismatch when assigning a reservation - we have a problem
            if (
                !allDayAssignment &&
                !source.reservations.some(
                    (r) => r.sessionType == target.sessionType
                )
            ) {
                this.toastSessionMismatch();
                return;
            } else if (allDayAssignment) {
                this.$emit("allDayAssign", this.table.number);
            } else {
                this.$emit("assign", {
                    tableNumber: this.table.number,
                    sessionType: target.sessionType,
                });
            }
        },

        // Processes a call to move or swap reservations. Only applicable while moving reservations across tables.
        moveOrSwap(target, source, targetIsEmpty) {
            if (target.sessionType != source.sessionType) {
                this.toastSessionMismatch();
                return;
            } else if (targetIsEmpty) {
                this.$emit("move", this.table.number);
            } else if (
                target.reservation.assignedTable == source.assignedTable
            ) {
                // we do nothing here - Please don't cross the streams
            }
            // There's an existing reservation there - so swap these things
            else {
                this.$emit("swap", target.reservation.id);
            }
        },

        toastSessionMismatch() {
            this.$toast({
                message:
                    "The time of day doesn't line up for that reservation.",
                type: "is-danger",
            });
        },

        // only fires off when you let go of a reservation and there's no valid drop target underneath it
        dragend() {
            if (this.draggingReservation != null) {
                this.confirmDelete(this.draggingReservation.id);
            }
        },

        confirmDelete(reservationId) {
            this.$confirm({
                message: "Delete this reservation?",
                onConfirm: () => this.$emit("remove", reservationId),
            });
        },
    },

    computed: {
        ...mapGetters(["draggingReservation", "getEnumDescription"]),
    },
};
</script>
