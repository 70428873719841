<template>
    <b-select v-model="localValue" @input="input" :placeholder="placeholder" :loading="loading" :expanded="expanded" :icon="icon">
        <option  v-for="option in options" :value="option.id" :key="option.id">
            {{ option.description }}
        </option>
    </b-select>
</template>

<script>
    export default {
        props: {
            // expect enums to be passed in as options. v-for="enum in enums" can't be used because of eslint freaking out
            // probably a problem with using a reserved word.
            options: {
                type: Array,
                required: true
            },
            value: {
                type: Number
            },
            placeholder: {
                type: String
            },
            loading: {
                type: Boolean,
                default: false
            },
            expanded: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String
            }
        },
        data() {
            return {
                localValue: 0
            };
        },
        mounted() {
            this.localValue = this.value;
        },
        methods: {
            input() {
                this.$emit('input', this.localValue);
            }
        }
    };
</script>