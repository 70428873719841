import router from './router';
import store from '@/stores';
import { sync } from 'vuex-router-sync';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import { userBasedNavigationGuards } from '@/helpers/userRouting';

router.beforeEach(vuexOidcCreateRouterMiddleware(store));
router.beforeEach((to, from, next) => userBasedNavigationGuards(to, next));

sync(store, router);

export default {
    router,
    store
};
