/* STORE: Document */
import * as actions from './actions';
import * as getters from './getters';

const state = {
    modalDoc: null
};

const mutations = {
    SET_MODAL_DOC(state, file) {
        state.modalDoc = file;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
