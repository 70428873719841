var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormControl",
    {
      attrs: {
        horizontal: _vm.horizontal,
        centerText: _vm.centerText,
        leftColumnCss: _vm.leftColumnCss,
        rightColumnCss: _vm.rightColumnCss,
        wrapHeader: _vm.wrapHeader,
        controlLabel: _vm.controlLabel,
        toolTip: _vm.toolTip,
      },
    },
    [
      _c(
        "b-field",
        { attrs: { grouped: "" } },
        [
          _c(
            "b-select",
            _vm._b(
              {
                attrs: {
                  value: _vm.inputValue,
                  placeholder: _vm.placeholder,
                  expanded: "",
                },
                on: { input: _vm.updateValue },
              },
              "b-select",
              _vm.$attrs,
              false
            ),
            _vm._l(_vm.options, function (option) {
              return _c(
                "option",
                { key: option.id, domProps: { value: option.id } },
                [_vm._v(" " + _vm._s(option.description) + " ")]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }