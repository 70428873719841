import { troyesRole } from '@/helpers/staticValues';
import { getUserRoles } from '@/helpers/userRouting';
import * as Api from '@/api/userSession';

export const setUserHomepage = ({ commit, getters }) => {
    if (getters.userHomepage !== '') return getters.userHomePage;

    let userRoles = getUserRoles();
    let route = null;

    if (userRoles === null) {
        return;
    }

    if (userRoles.indexOf(troyesRole.FairAdmin) != -1) {
        route = { name: 'fairAdmin-home', replace: true };
    } else if (userRoles.indexOf(troyesRole.SchoolAdmin) != -1) {
        route = { name: 'schoolAdmin-home', replace: true };
    } else if (userRoles.indexOf(troyesRole.Employer) != -1) {
        //  route = { name: 'employer-home', replace: true };
        route = { path: '/employer/registration-info', replace: true };
    } else if (userRoles.indexOf(troyesRole.Attendee) != -1) {
        route = { name: 'attendee-home', replace: true };
    } else {
        route = { path: '/NotFound', replace: true };
    }

    commit('SET_USER_HOMEPAGE', route);
};

export const loadInitialUserData = async ({ commit }) => {
    let details = await Api.getInitialUserDetails();

    commit('SET_USER_DETAILS', details.data);
};
