var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AffirmOrDenyQuestionControl", {
        attrs: {
          outputStyle: "inline",
          center: "",
          question: `Will you be offering a ${_vm.positionType} position?`,
        },
        model: {
          value: _vm.offeringOpportunity,
          callback: function ($$v) {
            _vm.offeringOpportunity = $$v
          },
          expression: "offeringOpportunity",
        },
      }),
      _vm.isOfferingOpportunity ? _c("hr") : _vm._e(),
      _vm.isOfferingOpportunity && _vm.opportunityTypeLoaded
        ? _c("opportunity-step", {
            ref: "opportunityStep",
            attrs: {
              opportunityTypeId: _vm.opportunityType.id,
              value: _vm.opportunity,
              typeReadOnly: true,
            },
            on: { input: _vm.updateLocalOpportunity },
          })
        : _vm._e(),
      _c("br"),
      _vm.isOfferingOpportunity
        ? _c("DocumentRequestStep", {
            attrs: { value: _vm.opportunity.documentRequests },
            on: { input: _vm.updateDocumentRequests },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }