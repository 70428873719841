var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("footer", { staticClass: "footer footer-border has-margin-top-lg" }, [
      _c("div", { staticClass: "has-text-centered" }, [
        _c("p", [
          _vm._v(
            "© " +
              _vm._s(new Date().getFullYear()) +
              " - Public Interest Law Center at NYU"
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }