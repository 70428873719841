<template>
    <div :class="horizontalColumnsCssClass">
        <div :class="horizontalLabelColumnCssClass">
            <slot name="label"></slot>
        </div>
        <div :class="horizontalActionsColumnCssClass" :style="actionStyle">
            <slot name="action"></slot>
        </div>
    </div>
</template>

<script>
    import AlignmentControlPropsMixin from '@/mixins/AlignmentControlPropsMixin';

    export default {
        name: 'BaseAlignmentControl',

        mixins: [AlignmentControlPropsMixin],

        data() {
            return {
                isSwitched: false
            };
        },

        computed: {
            horizontalColumnsCssClass() {
                return {
                    'columns': this.horizontal,
                    'has-text-centered': this.centerText,
                    'is-vcentered': this.horizontal
                };
            },

            horizontalLabelColumnCssClass() {
                let cssClass = this.leftColumnCss != '' ? this.leftColumnCss : '';

                if (!this.wrapHeader)
                    cssClass += ' no-wrap';

                if (this.horizontal)
                    cssClass += ' has-margin-right-sm';

                return this.horizontal ? cssClass : ' has-margin-top-sm has-margin-bottom-sm';
            },

            horizontalActionsColumnCssClass() {
                let css = this.rightColumnCss != '' ? this.rightColumnCss : '';

                return this.horizontal ? css : '';
            },
            
            actionStyle() {
                return this.centerText ? 'display: flex; justify-content: center' : '';
            }
        }
    };
</script>