var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "TaggedMultiSelectControl",
    _vm._b(
      {
        attrs: {
          horizontal: _vm.horizontal,
          controlLabel: "Practice Types",
          toolTip: "Select as many as apply using Ctrl/Command + Click",
          valueColumn: "description",
          placeholder: "Add Practice Type",
          centerText: false,
          wrapHeader: _vm.wrapHeader,
          leftColumnCss: _vm.leftColumnCss,
          rightColumnCss: _vm.rightColumnCss,
          options: _vm.availiablePracticeTypes,
          value: _vm.selectedPracticeTypes,
        },
        on: { input: _vm.update },
      },
      "TaggedMultiSelectControl",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }