var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        {
          attrs: { type: "is-toggle", animated: false },
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "b-tab-item",
            { attrs: { label: "Organization Details" } },
            [_c("organization-summary")],
            1
          ),
          _c(
            "b-tab-item",
            { attrs: { label: "Participation Plan" } },
            [
              _vm.activeTab === _vm.steps.participation
                ? _c("participation-plan-summary")
                : _vm._e(),
              _c(
                "section",
                { staticClass: "section" },
                [
                  _c("b-loading", {
                    attrs: {
                      "is-full-page": false,
                      active: _vm.isEmployerStoreLoading,
                      "can-cancel": false,
                    },
                    on: {
                      "update:active": function ($event) {
                        _vm.isEmployerStoreLoading = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-tab-item",
            { attrs: { label: "Opportunities" } },
            [
              _vm.activeTab === _vm.steps.opportunity
                ? _c("opportunity-list", {
                    attrs: { showHeader: false, readonly: "" },
                  })
                : _vm._e(),
              _c(
                "section",
                { staticClass: "section" },
                [
                  _c("b-loading", {
                    attrs: {
                      "is-full-page": false,
                      active: _vm.isEmployerStoreLoading,
                      "can-cancel": false,
                    },
                    on: {
                      "update:active": function ($event) {
                        _vm.isEmployerStoreLoading = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.hasSignedNDA
        ? _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column has-text-right" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "has-margin-right-md",
                    attrs: { type: "is-info" },
                    on: { click: _vm.remindEmployer },
                  },
                  [_vm._v("Send Reminder Email")]
                ),
              ],
              1
            ),
          ])
        : _vm.hasSignedNDA && _vm.isUnapproved
        ? _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column has-text-right" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "has-margin-right-md",
                    attrs: { type: "is-success" },
                    on: { click: _vm.approveEmployer },
                  },
                  [_vm._v("Approve Employer")]
                ),
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-danger" },
                    on: { click: _vm.rejectEmployer },
                  },
                  [_vm._v("Reject Employer")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }