var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a",
        {
          staticClass: "button is-danger is-inverted",
          attrs: { title: _vm.typeDescription },
          on: { click: _vm.openFile },
        },
        [
          _c("span", { staticClass: "has-text-grey-dark" }, [
            _vm._v(_vm._s(_vm.typeDescription)),
          ]),
          _c("b-icon", { attrs: { icon: "file-pdf" } }),
        ],
        1
      ),
      _c("doc-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }