var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-steps",
        {
          attrs: {
            animated: true,
            "has-navigation": true,
            "icon-prev": "chevron-left",
            "icon-next": "chevron-right",
            size: "is-small",
          },
          on: { input: _vm.clickStep },
          model: {
            value: _vm.activeStep,
            callback: function ($$v) {
              _vm.activeStep = $$v
            },
            expression: "activeStep",
          },
        },
        [
          _c("hr"),
          _c(
            "b-step-item",
            {
              attrs: {
                label: _vm.steps.registration.label,
                visible: _vm.isStepVisible(_vm.steps.registration.index),
                clickable: _vm.isStepClickable(_vm.steps.registration.index),
              },
            },
            [
              _vm.activeStep === _vm.steps.registration.index
                ? _c(
                    "Card",
                    { attrs: { title: "Organization Details" } },
                    [
                      _c(
                        "form-validator",
                        { ref: "form" },
                        [
                          _c("router-view", {
                            ref: "stepComponent",
                            attrs: { edit: "" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("b-loading", {
                attrs: { active: _vm.isStepLoading },
                on: {
                  "update:active": function ($event) {
                    _vm.isStepLoading = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "b-step-item",
            {
              attrs: {
                label: _vm.steps.contacts.label,
                visible: _vm.isStepVisible(_vm.steps.contacts.index),
                clickable: _vm.isStepClickable(_vm.steps.contacts.index),
              },
            },
            [
              _vm.activeStep === _vm.steps.contacts.index &&
              _vm.activeEmployerFairLoaded
                ? _c(
                    "form-validator",
                    { ref: "form" },
                    [_c("router-view", { ref: "stepComponent" })],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-step-item",
            {
              attrs: {
                label: _vm.steps.participationPlan.label,
                visible: _vm.isStepVisible(_vm.steps.participationPlan.index),
                clickable: _vm.isStepClickable(
                  _vm.steps.participationPlan.index
                ),
              },
            },
            [
              _vm.activeStep === _vm.steps.participationPlan.index &&
              _vm.activeEmployerFairLoaded
                ? _c(
                    "form-validator",
                    { ref: "form" },
                    [
                      _c("router-view", {
                        ref: "stepComponent",
                        model: {
                          value: _vm.participation,
                          callback: function ($$v) {
                            _vm.participation = $$v
                          },
                          expression: "participation",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-step-item",
            {
              attrs: {
                label: _vm.steps.postGradPosition.label,
                visible: _vm.isStepVisible(_vm.steps.postGradPosition.index),
                clickable: _vm.isStepClickable(
                  _vm.steps.postGradPosition.index
                ),
              },
            },
            [
              _vm.activeStep === _vm.steps.postGradPosition.index &&
              _vm.activeEmployerFairLoaded
                ? _c(
                    "Card",
                    {
                      attrs: {
                        title: `${_vm.opportunityTypePostGrad.description} Opportunity`,
                      },
                    },
                    [
                      _c(
                        "form-validator",
                        { ref: "form" },
                        [
                          _c("router-view", {
                            key: _vm.opportunityTypePostGrad.id,
                            ref: "stepComponent",
                            attrs: {
                              opportunityType: _vm.opportunityTypePostGrad,
                            },
                            model: {
                              value: _vm.localOpportunities.fulltime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localOpportunities,
                                  "fulltime",
                                  $$v
                                )
                              },
                              expression: "localOpportunities.fulltime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-step-item",
            {
              attrs: {
                label: _vm.steps.internshipPosition.label,
                visible: _vm.isStepVisible(_vm.steps.internshipPosition.index),
                clickable: _vm.isStepClickable(
                  _vm.steps.internshipPosition.index
                ),
              },
            },
            [
              _vm.activeStep === _vm.steps.internshipPosition.index &&
              _vm.activeEmployerFairLoaded
                ? _c(
                    "Card",
                    {
                      attrs: {
                        title: `${_vm.opportunityTypeInternship.description} Opportunity`,
                      },
                    },
                    [
                      _c(
                        "form-validator",
                        { ref: "form" },
                        [
                          _c("router-view", {
                            key: _vm.opportunityTypeInternship.id,
                            ref: "stepComponent",
                            attrs: {
                              opportunityType: _vm.opportunityTypeInternship,
                            },
                            model: {
                              value: _vm.localOpportunities.internship,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localOpportunities,
                                  "internship",
                                  $$v
                                )
                              },
                              expression: "localOpportunities.internship",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-step-item",
            {
              attrs: {
                label: _vm.steps.summary.label,
                visible: _vm.isStepVisible(_vm.steps.summary.index),
                clickable: _vm.isStepClickable(_vm.steps.summary.index),
              },
            },
            [
              _vm.activeStep === _vm.steps.summary.index &&
              _vm.activeEmployerFairLoaded
                ? _c(
                    "div",
                    [
                      _c("router-view"),
                      _c("br"),
                      _c(
                        "Card",
                        { attrs: { title: "Non-Discrimination Agreement" } },
                        [
                          _c("p", { staticClass: "content" }, [
                            _vm._v(
                              " New York University is committed to maintaining an environment that encourages and fosters appropriate conduct among all persons and respect for individual values. Discrimination or harassment based on race, gender and/or gender identity or expression, color, creed, religion, age, national origin, ethnicity, disability, veteran or military status, sex, sexual orientation, pregnancy, genetic information, marital status, citizenship status, or on any other legally prohibited basis is unlawful and undermines the character and purpose of the University. Such discrimination or harassment violates University policy and will not be tolerated. "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c("template", { slot: "navigation" }, [
            _c(
              "div",
              {
                staticClass: "buttons has-margin-bottom-lg has-margin-left-md",
              },
              [
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-light" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.previousStep.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" Previous ")]
                ),
                _c(
                  "b-button",
                  {
                    attrs: { type: "is-primary" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.nextStep.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" Next ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }