<template>
    <div class="site">
        <top-nav-bar :showLinks="canShowPage" />
        <div class="site-content is-fluid has-margin-top-lg">
            <router-view v-if="canShowPage"></router-view>
        </div>
        <main-footer></main-footer>
        <b-loading :active.sync="isStoreLoading"></b-loading>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import MainFooter from '@/views/shared/MainFooter.vue';
    import TopNavBar from '@/components/navigation/TopNavBar';

    export default {
        name: 'App',

        components: {
            MainFooter,
            TopNavBar
        },

        data() {
            return {
                userHasBeenLoaded: false,
            };
        },

        created() {
            document.title = 'PILC Fair';
            document.documentElement.lang = 'en';
        },

        mounted() {
            window.addEventListener('vuexoidc:userLoaded', this.userLoaded);
            window.addEventListener('vuexoidc:accessTokenExpired', this.logout);
            window.addEventListener('vuexoidc:accessTokenExpiring', this.expiringToken);
            window.addEventListener('vuexoidc:oidcError', this.logout);
        },

        destroyed() {
            window.removeEventListener('vuexoidc:userLoaded', this.userLoaded);
            window.removeEventListener('vuexoidc:accessTokenExpired', this.logout);
            window.removeEventListener('vuexoidc:accessTokenExpiring', this.expiringToken);
            window.removeEventListener('vuexoidc:oidcError', this.logout);
        },

        computed: {
            ...mapState(['route']),
            ...mapGetters(['isStoreLoading', 'isStoreInitialized', 'oidcIsAuthenticated']),

            canShowPage() {
                let isPagePublic = this.route.meta.isPublic === undefined || this.route.meta.isPublic;
                let isPageRoot = this.route !== undefined && this.route !== null && this.route.fullPath === '/';
                let hasAccess = isPagePublic || (this.oidcIsAuthenticated && this.userHasBeenLoaded);

                return (isPageRoot || hasAccess) && !this.isStoreLoading;
            }
        },

        methods: {
            ...mapActions(['setLoading', 'setStoreState', 'loadEnums', 'loadFairDates',
                           'setUserHomepage', 'authenticateOidcSilent', 'signOutOidc', 'removeOidcUser',
                           'loadInitialUserData']),

            userLoaded() {
                this.userHasBeenLoaded = true;
            },

            async initVuexStores() {
                try {
                    this.setLoading(true);

                    await this.setUserHomepage();
                    await this.loadEnums();
                    await this.loadFairDates();
                    await this.loadInitialUserData();
                    
                    this.setLoading(false);
                    this.setStoreState(true);
                } finally {
                    this.setLoading(false);
                }
            },

            async loadVuexStores(isAuthenticated) {
                if (isAuthenticated && this.userHasBeenLoaded) {

                    if (!this.isStoreInitialized) {
                        await this.initVuexStores();
                    }
                }
            },

            async expiringToken() {
                await this.authenticateOidcSilent();
            },

            logout() {
                this.signOutOidc();
                this.removeOidcUser();
            }
        },

        watch: {
            async oidcIsAuthenticated(isAuthenticated) {
                await this.loadVuexStores(isAuthenticated);
            },

            async isStoreInitialized(isInitialized) {
                if (!isInitialized) {
                    await this.loadVuexStores(this.oidcIsAuthenticated);
                }
            }
        }
    };
</script>
