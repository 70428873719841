<template>
    <OpportunitySearch />
</template>

<script>
    import OpportunitySearch from '@/components/opportunity/OpportunitySearch';

    export default {
        name: 'AttendeeView',
    
        components: {
            OpportunitySearch
        }
    };
</script>
