import axios from './index';
const _routePrefix = 'api/attendees';

export const getAttendee = attendeeId => {
    return axios.get(`${_routePrefix}/${attendeeId}/availability`);
};

export const getUserAvailability = attendeeId => {
    // TODO: update to include attendeeId in API Call as needed
    return axios.get(`${_routePrefix}/${attendeeId}/availability`);
};

export const upsertUserAvailabilitySession = userAvailabilitySession => {
    // TODO: update to include attendeeId in API Call as needed
    return axios.post(
        `${_routePrefix}/314/availability`,
        userAvailabilitySession
    );
};

export const deleteUserAvailabilitySession = userAvailabilitySessionId => {
    return axios.delete(
        `${_routePrefix}/19/availability/${userAvailabilitySessionId}`
    );
};

export const getProfileInformation = attendeeId => {
    return axios.get(`${_routePrefix}/${attendeeId}/profile`);
};

export const updateProfileInformation = attendeeProfileInformation => {
    return axios.put(`${_routePrefix}/13/profile`, attendeeProfileInformation);
};
