<template>
    <div>
        <b-field grouped>
            <b-field label="Start" horizontal>
                <b-timepicker
                    v-model="session.sessionStart"
                    placeholder="Click to select..."
                    hour-format="12"
                    size="is-small"
                    :inline="true"
                    :increment-minutes="5"
                ></b-timepicker>
            </b-field>
            <b-field label="End" horizontal>
                <b-timepicker
                    v-model="session.sessionEnd"
                    placeholder="Click to select..."
                    hour-format="12"
                    size="is-small"
                    :inline="true"
                    :increment-minutes="5"
                ></b-timepicker>
            </b-field>

            <p class="control" style="padding: 0.5em">
                <button class="button is-primary" @click="submit()">{{ buttonText }}</button>
                <button
                    v-if="exists()"
                    class="button is-warning"
                    style="margin-left: 0.5em"
                    @click="deleteSession(userAvailabilitySession.userAvailabilitySessionId)"
                >
                    <b-icon icon="trash" />
                </button>
            </p>
        </b-field>
    </div>
</template>

<script>
    import { upsertUserAvailabilitySession, deleteUserAvailabilitySession } from '@/api/attendee';
    import _ from 'lodash';
    import moment from 'moment';

    export default {
        name: 'UserAvailabilitySession',

        props: {
            userAvailabilitySession: {
                type: Object,
                default: () => {
                    return {
                        sessionStart: null,
                        sessionEnd: null,
                    };
                },
            },
            date: String,
        },

        data() {
            return {
                session: _.merge({}, this.userAvailabilitySession),
            };
        },

        watch: {
            userAvailabilitySession: {
                handler: function(newSession) {
                    this.session.sessionStart = _.isUndefined(
                        newSession.userAvailabilitySessionId,
                    )
                        ? null
                        : new Date(newSession.sessionStart);
                    this.session.sessionEnd = _.isUndefined(
                        newSession.userAvailabilitySessionId,
                    )
                        ? null
                        : new Date(newSession.sessionEnd);
                },
                immediate: true,
            },
        },

        computed: {
            buttonText: function() {
                return this.exists() ? 'Update' : 'Add';
            },
        },

        methods: {
            exists() {
                return !_.isUndefined(
                    this.userAvailabilitySession.userAvailabilitySessionId,
                );
            },

            reset() {
                this.session = {
                    sessionStart: null,
                    sessionEnd: null,
                };
            },

            submit() {
                let updatedSession = _.merge({}, this.session);
                let startHours = moment(this.session.sessionStart).hours();
                let startMinutes = moment(this.session.sessionStart).minutes();
                let endHours = moment(this.session.sessionEnd).hours();
                let endMinutes = moment(this.session.sessionEnd).minutes();

                // Moment starts as a moment object, toDate() returns an object, and finally toString to return properly to controller
                updatedSession.sessionStart = moment(this.date)
                    .add(startHours, 'h')
                    .add(startMinutes, 'm')
                    .toDate()
                    .toString();
                updatedSession.sessionEnd = moment(this.date)
                    .add(endHours, 'h')
                    .add(endMinutes, 'm')
                    .toDate()
                    .toString();

                upsertUserAvailabilitySession(updatedSession)
                    .then(() => {
                        this.$toast({
                            message: 'Data successfully updated',
                        });
                        this.$emit('refresh-user-availability');
                        this.reset();
                    })
                    .catch(error => {
                        this.$toast({
                            message: 'Oops. Something went wrong. ' + error,
                            type: 'is-warning',
                        });
                    });
            },

            deleteSession(id) {
                deleteUserAvailabilitySession(id).then(() => {
                    this.$toast({
                        message: 'Availability deleted.',
                    });
                    this.$emit('refresh-user-availability');
                });
            }
        },
    };
</script>