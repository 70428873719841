var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "field",
      class: { "is-expanded": _vm.expanded, columns: _vm.horizontal },
    },
    [
      _c(
        "div",
        {
          staticClass: "label v-align-center",
          class: { "column is-narrow": _vm.horizontal },
        },
        [
          _vm._v(" " + _vm._s(_vm.label) + " "),
          _vm.hasTooltip
            ? _c(
                "b-tooltip",
                {
                  staticClass: "v-align-center has-margin-left-xs",
                  attrs: { label: _vm.tooltip, multilined: "" },
                },
                [
                  _c("b-icon", {
                    attrs: {
                      icon: "info-circle",
                      size: "is-small",
                      type: "is-info",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { class: { "column is-narrow": _vm.horizontal } },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }