<template>
    <div v-if="!isEmployerRegistering">
        <!--TODO: Make this notification sticky across all pages, show only if EmployerFair.Status == Unapproved-->
        <b-message title="Registration Status" :closable="false" type="is-warning">
            Thank you for registering for the PILC Fair! Your registration is currently pending approval. You will receive an email when your registration has been approved.
        </b-message>
        <card>
            <p>The PILC Fair will be held on {{ fairDate1 }} and {{ fairDate2 }}</p><br />
            <p>Location: New York University School of Law, Vanderbilt Hall, 40 Washington Square South</p>
        </card>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'EmployerView',

        data() {
            return {
                fairDate1: 'Thursday, February 6, 2020',
                fairDate2: 'Friday, February 7, 2020'
            };
        },

        computed: {
            ...mapGetters(['isEmployerStoreLoading', 'isEmployerRegistering'])
        },

        beforeRouteEnter (to, from, next) {
            next(vm => {
                if (vm.isEmployerRegistering) {
                    next({ name: 'employer-registration', replace: true });
                } else {
                    next();
                }
            });
        }
    };
</script>
