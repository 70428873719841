<template>
    <div>
        The Fair Schedule will go here
    </div>
</template>

<script>
    export default {
        name: 'FairScheduleView'
    };
</script>