var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns" }, [
    _c(
      "div",
      { staticClass: "column" },
      [
        _c("b-field", { attrs: { label: "Organization Type" } }, [
          _vm._v(
            " " + _vm._s(_vm.activeEmployer.organizationType.description) + " "
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "column" },
      [
        _c(
          "b-field",
          { attrs: { label: "Practice Types" } },
          [
            _c("expandible-unordered-list", {
              attrs: { elements: _vm.practiceTypeArray },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "column" },
      [
        _c(
          "b-field",
          { attrs: { label: "Subject Areas" } },
          [
            _c("expandible-unordered-list", {
              attrs: { elements: _vm.subjectAreaArray },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }