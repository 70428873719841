<template>
  <form-validator ref="form">
    <div slot-scope="{ valid, dirty }">
        <opportunity-step v-model="opportunity" />

        <hr>

        <document-request-step v-model="opportunity.documentRequests" />

        <hr>

        <div class="buttons">
            <b-button type="is-success" icon-left="save" @click="save" :disabled="!valid && dirty">
                Save
            </b-button>
            <b-button type="is-danger" icon-left="times" @click="cancel">Cancel</b-button>
        </div>
    </div>
  </form-validator>
</template>

<script>
    import merge from 'lodash/merge';

    import { mapActions, mapGetters } from 'vuex';

    import FormValidator from '@/components/formControls/base/FormValidator';
    import DocumentRequestStep from '@/components/employer/opportunity/DocumentRequestStep';
    import OpportunityStep from '@/components/employer/opportunity/OpportunityStep';
    import DocumentTypes from '@/enums/DocumentTypes';
    import ConfirmLeaveMixin from '@/mixins/ConfirmLeaveMixin';

    export default {
        name: 'OpportunityForm',

        components: {
            FormValidator,
            DocumentRequestStep,
            OpportunityStep
        },

        mixins: [ConfirmLeaveMixin],

        data() {
            return {
                opportunity: {
                    id: 0,
                    jobTitle: null,
                    type: null,
                    desc: null,
                    attendeeTypes: [],
                    documentRequests: [],
                    allowNonApplicant: null,
                    preferredLanguages: null,
                    requiredLanguages: null,
                    additionalEmploymentOfferings: null,
                    city: null,
                    state: null,
                    postalCode: null,
                    isPaid: null
                },
                formSubmitted: false,
                editing: false
            };
        },

        mounted() {
            this.addResumeIfNoneExist();
            this.$refs.form.reset(); // fixes a weird issue where the form is dirty when loaded
        },

        updated() {
            this.addResumeIfNoneExist();
        },

        methods: {
            ...mapActions(['clearOpportunity']),

            async save() {
                const valid = await this.$refs.form.validate();

                if (valid) {
                    if (this.editing) {
                        this.$emit('edit', this.opportunity);
                        this.clearOpportunity();
                    }
                    else {
                        this.$emit('save', this.opportunity);
                    }
                }
                this.$refs.form.reset();
            },

            cancel() {
                this.$emit('cancel');
                if (this.editing) this.clearOpportunity();
            },

            addResumeIfNoneExist() {
                const opportunityHasResume = this.opportunity.documentRequests.some(dr => dr.documentType == DocumentTypes.Resume);
                if (!opportunityHasResume) {
                    this.opportunity.documentRequests.push({
                        description: null,
                        documentType: DocumentTypes.Resume,
                        id: 0,
                        isRequired: true
                    });
                }
            }
        },

        computed: {
            ...mapGetters(['editingOpportunity'])
        },

        watch: {
            editingOpportunity: {
                handler(newVal) {
                    this.editing = newVal != null;
                    if (newVal == null) return;
                    this.opportunity = merge({}, newVal);
                },
                deep: true,
                immediate: true
            },
            'opportunity.type': {
                handler(newVal, oldVal) {
                    if (oldVal != null) {
                        // reset attendee types when the opportunity type is changed
                        this.opportunity.attendeeTypes = [];
                    }
                }
            }
        }
    };
</script>