<template>
    <div class="columns">
        <div class="column">
            <strong>{{ employerName }}</strong>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: "Employer",

    props: {
        employerName: String,
    },
};
</script>
