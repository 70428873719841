var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DropdownControl",
    _vm._b(
      {
        attrs: {
          horizontal: _vm.horizontal,
          controlLabel: "Organization Type",
          placeholder: "Select Organization Type",
          options: _vm.getOrganizationTypes,
          value: _vm.orgTypeValue,
          toolTip: _vm.toolTip,
          centerText: _vm.centerText,
          leftColumnCss: _vm.leftColumnCss,
          rightColumnCss: _vm.rightColumnCss,
        },
        on: { input: _vm.updateValue },
      },
      "DropdownControl",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }