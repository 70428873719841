export default {
    props: {
        horizontal: {
            type: Boolean,
            default: false
        },
        controlLabel: {
            type: String,
            default: ''
        },
        toolTip: {
            type: String,
            default: ''
        }
    }
};
