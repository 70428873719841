<template>
    <div>
        <b-table :data="activeEmployerFair.participationPlans" hoverable>
            <template slot-scope="props">
                <b-table-column field="date" label="Date">
                    {{ $options.filters.date(props.row.fairDate, 'MM/DD/YY') }}
                </b-table-column>
                <b-table-column field="interviews" label="Interviewing">
                    <span v-if="props.row.requestingInterviewTable">
                        {{ getInterviewSessionDescriptions(props.row.requestedInterviewSessionIds) }}
                    </span>
                    <span v-else>
                        -
                    </span>
                </b-table-column>
                <b-table-column field="tableTalks" label="Table Talks">
                    <span v-if="props.row.requestingTableTalk">
                        {{ getInterviewSessionDescriptions(props.row.requestedTableTalkSessionIds) }}
                    </span>
                    <span v-else>
                        -
                    </span>
                </b-table-column>
            </template>
            <template v-slot:empty>
                This employer has not declared their participation plan...
            </template>
        </b-table>
        <b-loading :active.sync="isEmployerStoreLoading"></b-loading>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'ParticipationPlanSummary',

        mounted() {
            this.loadEmployerFairParticipationPlan();
        },

        methods: {
            ...mapActions(['loadEmployerFairParticipationPlan']),

            getSessionDescription(sessionId) {
                return this.getSeshTypeDescription(sessionId);
            },

            getInterviewSessionDescriptions(sessionIds) {
                if (sessionIds.length == 2) {
                    return 'All Day (9:00 a.m. - 5:00 p.m.)';
                } else {
                    return sessionIds.map(si => this.getSessionDescription(si)).join('/');
                }
            }
        },

        computed: {
            ...mapGetters([
                'activeEmployerFair',
                'getEnumDescription',
                'isEmployerStoreLoading',
                'getSeshTypeDescription'
            ])
        }
    };
</script>

<style scoped>
    nav.level.has-margin-bottom-none {
        margin-bottom: 0;
    }

    nav.level .level-item div p.heading {
        margin-bottom: 0;
    }
</style>