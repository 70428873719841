<template>
    <div>
        <footer class="footer footer-border has-margin-top-lg">
            <div class="has-text-centered">
                <p>&copy; {{ new Date().getFullYear() }} - Public Interest Law Center at NYU</p>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'MainFooter'
    };
</script>