<template>
    <div>
        <AffirmOrDenyQuestionControl outputStyle="inline" center
            v-model="offeringOpportunity"
            :question="`Will you be offering a ${positionType} position?`" />
        <hr v-if="isOfferingOpportunity">
        <opportunity-step ref="opportunityStep"
            v-if="isOfferingOpportunity && opportunityTypeLoaded "
            :opportunityTypeId="opportunityType.id"
            :value="opportunity"
            :typeReadOnly="true"
            @input="updateLocalOpportunity" />
        <br>
        <DocumentRequestStep v-if="isOfferingOpportunity"
            :value="opportunity.documentRequests"
            @input="updateDocumentRequests" />
    </div>
</template>

<script>
    import AffirmOrDenyQuestionControl from '@/components/formControls/AffirmOrDenyQuestionControl';
    import DocumentRequestStep from '@/components/employer/opportunity/DocumentRequestStep';
    import OpportunityStep from '@/components/employer/opportunity/OpportunityStep';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'OpportunityStepItem',

        components: {
            AffirmOrDenyQuestionControl,
            DocumentRequestStep,
            OpportunityStep
        },

        props: {
            opportunityType: Object,
            value: {
                validator: (val) => val == null || typeof val == 'object'
            }
        },

        data() {
            return {
                isDirty: false,
                isWatchValueUpdated: false,
                isStepLoading: false,
                offeringOpportunity: 'No',
                opportunity: {
                    documentRequests: []
                }
            };
        },

        mounted() {
            if (this.opportunityType == null) {
                this.offeringOpportunity = 'No';
            }
            else {
                let hasOpportunity = this.activeEmployerFair.opportunityTypes.includes(this.opportunityType.id);

                this.offeringOpportunity = hasOpportunity ? 'Yes' : 'No';

                if (hasOpportunity) {
                    this.isStepLoading = true;

                    this.loadOpportunitiesForEmployerFair().then(() => {
                        this.opportunity = this.opportunities.find(o => o.type === this.opportunityType.id);
                        this.isStepLoading = false;
                    });
                }
            }
        },

        computed: {
            ...mapGetters(['activeEmployerFair', 'opportunities']),

            isOfferingOpportunity() {
                return this.offeringOpportunity === 'Yes';
            },

            positionType() {
                return this.opportunityType != null ? this.opportunityType.description : '';
            },

            opportunityTypeLoaded() {
                return this.opportunityType != null;
            }
        },

        methods: {
            ...mapActions([
                'addOpportunity',
                'updateOpportunity',
                'loadOpportunitiesForEmployerFair',
                'deleteOpportunity'
            ]),

            updateLocalOpportunity(updatedOpportunity) {
                this.opportunity = updatedOpportunity;
                this.raiseUpdateEvent();
            },

            updateDocumentRequests(documents) {
                this.opportunity.documentRequests = documents;
                this.raiseUpdateEvent();
            },

            raiseUpdateEvent() {
                this.$emit('input', this.opportunity);
                this.isDirty = true;
            },

            async save() {
                if (this.isDirty) {
                    if (this.opportunity.id > 0) {
                        if (this.isOfferingOpportunity) {
                            await this.updateOpportunity({ opportunity: this.opportunity });
                        } else {
                            await this.deleteOpportunity(this.opportunity.id);
                        }
                    } else {
                        if (this.isOfferingOpportunity) {
                            await this.addOpportunity({ opportunity: this.opportunity });
                        }
                    }
                }

                this.isDirty = false;
            }
        },

        watch: {
            offeringOpportunity() {
                this.isDirty = true;
                this.isWatchValueUpdated = true;
            }
        },

        updated() {
            this.isDirty = this.isWatchValueUpdated;
        }
    };
</script>