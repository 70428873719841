<template>
    <section>
        <div class="field has-addons">
            <div class="control is-expanded">
                <input v-on:keyup.enter="search" v-model="searchTerms" class="input" type="text" placeholder="e.g. Search Opportunities">
            </div>
            <div class="control">
                <a class="button" @click="search">
                    <b-icon icon="search" size="is-small"></b-icon>
                    <span>Search</span>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
    import debounce from 'lodash/debounce';
    import { mapGetters } from 'vuex';

    export default {
        name: 'OpportunitySearch',
        
        data() {
            return {
                searchTerms: '',
                fieldsToSearch: ['desc', 'city', 'state', 'employerName', 'jobTitle', 'additionalEmploymentOfferings'],
            };
        },

        methods: {
            search: debounce(function () {
                // in case none of the searching or filtering gets run on this... return all the things
                let lclSearchResults = [...this.opportunities];
                
                if (this.searchTerms != null && this.searchTerms.trim().length > 0) {
                    lclSearchResults = lclSearchResults.filter(opp => {
                        return this.fieldsToSearch.some(field => {
                            const fieldValue = opp[field];
                            return fieldValue != null && fieldValue.toUpperCase().includes(this.searchTerms.toUpperCase());
                        });
                    });
                }
                this.$emit('search', lclSearchResults);
            }, 500),
        },

        computed: {
            ...mapGetters(['opportunities'])
        }
    };
</script>
