<template>
    <div>
        <div class="buttons">
            <uploaded-document v-for="document in documents" :key="document.id" :documentId="document.id" :type="document.documentType" />
        </div>
        <button class="button is-small is-link" @click="editApplication">
            Edit Application
        </button>
        &nbsp;
        <button class="button is-small is-danger" @click="cancelApplication">
            Delete Application
        </button>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import UploadedDocument from '@/components/document/UploadedDocument';

    export default {
        name: 'AttendeeApplicationDetail',

        components: {
            UploadedDocument
        },

        props: {
            applicationId: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                documents: []
            };
        },

        async mounted() {
            this.documents = await this.getDocumentsByApplication({ applicationId: this.applicationId });
        },

        computed: {
            ...mapGetters(['activeAttendeeId'])
        },

        methods: {
            ...mapActions(['getDocumentsByApplication', 'withdrawApplication']),

            async cancelApplication() {
                const applicationId = this.applicationId;

                this.$confirm({
                    title: 'Withdraw Application',
                    message: `<li>By confirming this request, you
                    will withdraw this application.</li>`,
                    cancelText: 'Cancel',
                    confirmText: 'Withdraw',
                    type: 'is-success',
                    onConfirm: async () => {
                        try {
                            await this.withdrawApplication({ attendeeId: this.activeAttendeeId, applicationId });
                            this.$toast({
                                message: 'Your application has been withdrawn.',
                                duration: 5000
                            });
                        }
                        catch(error) {
                            this.$toast({
                                message: 'We had some trouble withdrawing your application. If problem perists, please contact <a href=mailto:law.tdg@nyu.edu>law.tdg@nyu.edu</a><br/>',
                                type: 'is-warning',
                                duration: 5000
                            });
                        }
                    }
                });
            },

            editApplication() {
                this.$emit('edit');
            }
        }
    };

</script>