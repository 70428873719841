var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isEmployerRegistering
    ? _c(
        "div",
        [
          _c(
            "b-message",
            {
              attrs: {
                title: "Registration Status",
                closable: false,
                type: "is-warning",
              },
            },
            [
              _vm._v(
                " Thank you for registering for the PILC Fair! Your registration is currently pending approval. You will receive an email when your registration has been approved. "
              ),
            ]
          ),
          _c("card", [
            _c("p", [
              _vm._v(
                "The PILC Fair will be held on " +
                  _vm._s(_vm.fairDate1) +
                  " and " +
                  _vm._s(_vm.fairDate2)
              ),
            ]),
            _c("br"),
            _c("p", [
              _vm._v(
                "Location: New York University School of Law, Vanderbilt Hall, 40 Washington Square South"
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }