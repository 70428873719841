<template>
    <b-taginput
        :id="id"
        ref="taginput"
        v-model="stagedItems"
        :data="filteredData"
        autocomplete
        :allow-new="allowNew"
        :field="displayField"
        :icon="icon"
        @typing="setField($event, 'search')"
        @add="addOption($event, value)"
        @remove="removeOption($event, value)"
        :type="type"
        :open-on-focus="openOnFocus"
        clear-on-select
        keep-first
        :has-counter="false"
        :disabled="disabled"
        v-bind="$attrs">
        
        <template v-slot:header>
            <slot name="header"></slot> 
        </template>

        <template v-slot:default="props">
            {{ props.option.description }}
        </template>
        
        <template v-slot:empty>
            <slot name="empty"></slot>    
        </template>
        
        <template v-slot:footer>
            <slot name="footer"></slot> 
        </template>

    </b-taginput>
</template>

<script>
    import differenceBy from 'lodash/differenceBy';
    import remove from 'lodash/remove';
    import union from 'lodash/union';

    export default {
        name: 'EnumTagInput',

        model: {
            prop: 'value',
            event: 'input'
        },

        props: {
            /** A collection of enums */
            id: {
                type: String
            },
            data: {
                type: Array,
                required: true
            },
            allowNew: {
                type: Boolean,
                default: false
            },
            /** The field we display on the input and the field to search by */
            displayField: {
                type: String,
                default: 'description'
            },
            /** The function that filters tags based off user input and what has already been selected */
            filter: {
                type: Function,
                /** We need a function that takes in two arguments: The Collection & Search Term */
                validator(value) {
                    return typeof value === 'function' && value.length === 2;
                },
                default: (collection, input) => input == null ? collection : collection.filter((eNum) => 
                    eNum.description.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
            },
            optionField: { // When evaluating what has been selected - we need to be able to identify options
                type: String,
                default: 'id'
            },
            icon: String,
            type: String,
            openOnFocus: {
                type: Boolean,
                default: true
            },
            value: Array,
            disabled: Boolean
        },

        data() {
            return {
                search: null,
                stagedItems: []
            };
        },

        mounted() {
            this.prefillTags(this.value, 'id');
        },

        watch: {
            value: {
                handler(newVal) {
                    this.stagedItems = [];
                    this.prefillTags(newVal, 'id');
                },
                immediate: true
            }
        },

        methods: {
            setField(input, field) {
                this[field] = input;
            },

            addOption(option, enumCollection) {
                this.$emit('input', union(enumCollection, [option[this.optionField]]));
            },

            removeOption(option, enumCollection) {
                remove(enumCollection, (e) => e == option[this.optionField]);
                this.$emit('input', enumCollection);
            },

            prefillTags(selectedOptionIds, optionId) {
                if (selectedOptionIds === null || selectedOptionIds === undefined) return;
                
                const collectionSet = new Set([...this.stagedItems]);

                selectedOptionIds.forEach(id => {
                    const match = this.data.find(o => o[optionId] === id);
                    if (match !== undefined) collectionSet.add(match);
                });
                this.stagedItems = [...collectionSet];
            }
        },

        computed: {
            filteredData() {
                const unselectedData = differenceBy(this.data, this.stagedItems, this.optionField);
                return this.filter(unselectedData, this.search);
            }
        }
    };
</script>

<style scoped>
    .taginput >>> .autocomplete .dropdown-menu > .dropdown-content {
        /* Should fix the issue where tag input menus get cut off */
        width: 100%;
    }
</style>