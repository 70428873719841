var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns is-desktop is-centered" }, [
    _c(
      "div",
      { staticClass: "column is-four-fiths-desktop is-four-fiths-fullhd" },
      [_vm.isStoreInitialized ? _c("router-view") : _vm._e()],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }