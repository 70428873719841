<template>
    <b-button icon-left="times" type="is-danger" @click="handleClick"></b-button>
</template>

<script>
    export default {
        name: 'CloseButton',

        props: {
            disabled: {
                type: Boolean
            }
        },

        methods: {
            handleClick() {
                if (!this.disabled) this.$emit('close');
            }
        }
    };
</script>
