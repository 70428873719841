var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-table",
        _vm._b(
          {
            ref: "list",
            class: { "omit-header": _vm.omitHeader },
            attrs: {
              data: _vm.inputData,
              loading: _vm.loading,
              "current-page": _vm.currentPage,
              paginated: _vm.paginated,
              "per-page": _vm.rowsPerPage,
              detailed: _vm.hasDetailColumn,
              "detail-key": _vm.rowId,
              "opened-detailed": _vm.openDetails,
              "show-detail-icon": false,
              striped: _vm.isStriped,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "detail",
                  fn: function (props) {
                    return [
                      _vm._t(
                        "detail",
                        function () {
                          return [_vm._v("Default detail section")]
                        },
                        { row: { ...props.row } }
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          },
          "b-table",
          _vm.$attrs,
          false
        ),
        [
          _c("b-table-column", {
            attrs: { label: " ", field: "rowId" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      _c("template", { slot: "header" }, [_vm._t("header")], 2),
                      _c("div", { staticClass: "columns" }, [
                        _c(
                          "div",
                          {
                            staticClass: "column",
                            class: {
                              "is-four-fifths":
                                _vm.hasDetailColumn && !_vm.hideArrow,
                            },
                          },
                          [_vm._t("content", null, { row: { ...props.row } })],
                          2
                        ),
                        _vm.hasDetailColumn && !_vm.hideArrow
                          ? _c(
                              "div",
                              { staticClass: "column has-text-right" },
                              [
                                _c(
                                  "a",
                                  [
                                    _c("b-icon", {
                                      class: props.row.open
                                        ? "is-expanded"
                                        : "",
                                      attrs: { icon: "angle-right" },
                                      nativeOn: {
                                        click: function ($event) {
                                          if (
                                            $event.target !==
                                            $event.currentTarget
                                          )
                                            return null
                                          $event.stopPropagation()
                                          return _vm.toggleRow(props.row)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c("template", { slot: "empty" }, [_vm._t("empty")], 2),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }