var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "form-validator",
        { ref: "form" },
        [
          _vm._l(_vm.reqDocMap, function (entry, index) {
            return _c(
              "b-field",
              { key: index },
              [
                _c(
                  "field-validator",
                  {
                    attrs: {
                      name: _vm.getEnumDescription("DocumentType", entry.type),
                      rules: _vm.requestByType(entry.type).isRequired
                        ? "required"
                        : "",
                    },
                  },
                  [
                    _c(
                      "b-upload",
                      {
                        attrs: {
                          required: _vm.requestByType(entry.type).isRequired,
                        },
                        model: {
                          value: entry.document,
                          callback: function ($$v) {
                            _vm.$set(entry, "document", $$v)
                          },
                          expression: "entry.document",
                        },
                      },
                      [
                        _c(
                          "a",
                          { staticClass: "button is-primary" },
                          [
                            _c("b-icon", {
                              attrs: { icon: "upload", size: "is-small" },
                            }),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getEnumDescription(
                                      "DocumentType",
                                      entry.type
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                entry.document != null
                  ? _c(
                      "span",
                      {
                        staticClass: "file-name",
                        on: {
                          click: function ($event) {
                            return _vm.openDoc(entry.document)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(entry.document.name) + " ")]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c("b-field"),
        ],
        2
      ),
      _c("b-button", { on: { click: _vm.apply } }, [_vm._v("Apply")]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "has-margin-bottom-md" }, [
      _c("span", { staticClass: "title is-6" }, [_vm._v("Application")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }