var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isResume
    ? _c(
        "b-field",
        { attrs: { grouped: "", "group-multiline": "" } },
        [
          _c(
            "field-validator",
            { attrs: { name: "'document required'" } },
            [
              _c(
                "text-label",
                {
                  attrs: {
                    label: "Is this document required?",
                    "has-tooltip": "",
                    tooltip: _vm.requiredTooltip,
                  },
                },
                [
                  _c(
                    "b-field",
                    [
                      _c(
                        "b-radio-button",
                        {
                          attrs: {
                            name: "isRequired",
                            "native-value": true,
                            disabled: _vm.isResume,
                          },
                        },
                        [_vm._v(" Required ")]
                      ),
                      _c(
                        "b-radio-button",
                        {
                          attrs: {
                            "native-value": false,
                            disabled: _vm.isResume,
                          },
                        },
                        [_vm._v(" Optional ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { label: "Description (optional)", expanded: "" } },
            [
              _c(
                "field-validator",
                { attrs: { name: "document description", required: "" } },
                [
                  _c("b-input", {
                    attrs: {
                      id: "documentDescription",
                      name: "description",
                      type: "textarea",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }