import * as Api from '@/api/fairdate';

export const loadFairDates = async ({ commit }) => {
    try {
        const response = await Api.getFairDates();

        commit('LOAD_FAIR_DATES', response);
    }
    catch (error) {
        return { data: [] };
    }
};