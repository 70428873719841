export const fairDates = state => state.fairDates;

/* The days that the fair is in attendance */
export const fairDays = state => {
  if (state.fairDates == null) return [];

  return state.fairDates.filter(
    fd => fd.fairDateType == 10 || fd.fairDateType == 20
  );
};

export const activeFairId = state => state.fair.fairId;

export const activeFair = state => state.fair;
