var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "b-tabs",
        [
          _c(
            "b-tab-item",
            { attrs: { label: "Employers" } },
            [_c("employer-approval-list")],
            1
          ),
          _c(
            "b-tab-item",
            { attrs: { label: "Primary Contacts" } },
            [_c("FairContactList")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }