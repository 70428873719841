<template>
    <MainRightSidebar>
        <AttendeeAppointmentList />
        <AttendeeApplicationList />
    </MainRightSidebar>
</template>

<script>
    import AttendeeApplicationList from '@/components/attendee/AttendeeApplicationList.vue';
    import AttendeeAppointmentList from '@/components/attendee/AttendeeAppointmentList.vue';

    export default {
        name: 'AttendeeSidebar',

        components: {
            AttendeeAppointmentList,
            AttendeeApplicationList
        }
    };
</script>