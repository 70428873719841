var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-field",
        { attrs: { grouped: "grouped", "group-multiline": "" } },
        [
          _c(
            "b-field",
            { attrs: { label: "Select a Fair Date" } },
            [
              _c(
                "b-select",
                {
                  attrs: {
                    "aria-label": "Select a fair date",
                    placeholder: "e.g. 02/06/2019",
                    expanded: "",
                  },
                  on: { input: _vm.changeFairDate },
                  model: {
                    value: _vm.fairDate,
                    callback: function ($$v) {
                      _vm.fairDate = $$v
                    },
                    expression: "fairDate",
                  },
                },
                _vm._l(_vm.fairDays, function (date, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: date.date } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("date")(date.date, "MM/DD/YYYY")) +
                          " "
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { label: "Select a building" } },
            [
              _c(
                "b-select",
                {
                  attrs: {
                    "aria-label": "Select a building",
                    placeholder: "e.g. Vanderbilt Hall",
                    expanded: "",
                  },
                  on: { input: _vm.changeBuilding },
                  model: {
                    value: _vm.building,
                    callback: function ($$v) {
                      _vm.building = $$v
                    },
                    expression: "building",
                  },
                },
                _vm._l(_vm.buildings, function (b) {
                  return _c(
                    "option",
                    { key: b.buildingId, domProps: { value: b } },
                    [_vm._v(" " + _vm._s(b.name) + " ")]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm.canSelectRoom
            ? _c(
                "b-field",
                { attrs: { label: "Select a room" } },
                [
                  _c(
                    "b-select",
                    {
                      attrs: {
                        "aria-label": "Select a room",
                        placeholder: "e.g. Some Hall",
                        expanded: "",
                      },
                      model: {
                        value: _vm.room,
                        callback: function ($$v) {
                          _vm.room = $$v
                        },
                        expression: "room",
                      },
                    },
                    _vm._l(_vm.roomsInBuilding, function (r) {
                      return _c(
                        "option",
                        { key: r.roomId, domProps: { value: r } },
                        [_vm._v(" " + _vm._s(r.name) + " ")]
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("br"),
      _c("p", [
        _vm._v(
          " You can assign reservations by dragging rows on the right hand side to any of the tables below. You can move reservations around and even unassign them by clicking on the trash icon or alternatively dragging a reservation outside of its table. "
        ),
      ]),
      _c("br"),
      _c("div", { staticClass: "columns" }, [
        _vm.showReservations && _vm.roomConfig != null
          ? _c("div", { staticClass: "column" }, [
              _c(
                "div",
                { staticClass: "grid well" },
                [
                  _vm._l(_vm.tableWithReservations, function (table, index) {
                    return _c("locale", {
                      key: index,
                      attrs: { table: table },
                      on: {
                        assign: _vm.assign,
                        move: _vm.move,
                        swap: _vm.swap,
                        remove: _vm.remove,
                        allDayAssign: _vm.assignAllDay,
                      },
                    })
                  }),
                  _vm.roomConfig.numSeats == 0
                    ? _c("section", { staticClass: "section" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "content has-text-grey has-text-centered",
                          },
                          [
                            _c("p", { staticClass: "title is-6" }, [
                              _vm._v(
                                " " + _vm._s("This room has no seats.") + " "
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ])
          : _vm._e(),
        _vm.showReservations
          ? _c(
              "div",
              { staticClass: "column is-narrow notification" },
              [
                _c("unassigned-reservations", {
                  attrs: { reservations: _vm.empFairReservations },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("b-loading", {
        attrs: { isFullPage: false, active: _vm.loading },
        on: {
          "update:active": function ($event) {
            _vm.loading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }