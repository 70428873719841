var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-table", {
        attrs: {
          data: _vm.activeEmployerFair.participationPlans,
          hoverable: "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c(
                  "b-table-column",
                  { attrs: { field: "date", label: "Date" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$options.filters.date(
                            props.row.fairDate,
                            "MM/DD/YY"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "b-table-column",
                  { attrs: { field: "interviews", label: "Interviewing" } },
                  [
                    props.row.requestingInterviewTable
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getInterviewSessionDescriptions(
                                  props.row.requestedInterviewSessionIds
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _c("span", [_vm._v(" - ")]),
                  ]
                ),
                _c(
                  "b-table-column",
                  { attrs: { field: "tableTalks", label: "Table Talks" } },
                  [
                    props.row.requestingTableTalk
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getInterviewSessionDescriptions(
                                  props.row.requestedTableTalkSessionIds
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _c("span", [_vm._v(" - ")]),
                  ]
                ),
              ]
            },
          },
          {
            key: "empty",
            fn: function () {
              return [
                _vm._v(
                  " This employer has not declared their participation plan... "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("b-loading", {
        attrs: { active: _vm.isEmployerStoreLoading },
        on: {
          "update:active": function ($event) {
            _vm.isEmployerStoreLoading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }