var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-field",
    { staticClass: "search-input", attrs: { expanded: _vm.expanded } },
    [
      _c("template", { slot: "label" }, [
        _c("div", { staticClass: "columns is-gapless" }, [
          _c("div", { staticClass: "column" }, [_vm._v(_vm._s(_vm.label))]),
          _vm.$slots.default != null
            ? _c("div", { staticClass: "column" }, [_vm._t("default")], 2)
            : _vm._e(),
        ]),
      ]),
      _c(
        _vm.cmpType,
        _vm._b(
          {
            tag: "component",
            attrs: { size: _vm.size, data: _vm.autocompleteFilter },
            model: {
              value: _vm.computedValue,
              callback: function ($$v) {
                _vm.computedValue = $$v
              },
              expression: "computedValue",
            },
          },
          "component",
          _vm.cmpProps,
          false
        )
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }