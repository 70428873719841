var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c(
      "header",
      { staticClass: "card-header background-white-ter" },
      [
        _vm._t("header", function () {
          return [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.title,
                    expression: "title",
                  },
                ],
                staticClass: "card-header-title",
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _vm.showButton
              ? _c(
                  "div",
                  { staticClass: "v-align-center" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "has-margin-right-sm",
                        attrs: {
                          "icon-left": "plus",
                          disabled: _vm.disableButton,
                        },
                        on: { click: _vm.trigger },
                      },
                      [_vm._v(" " + _vm._s(_vm.buttonLabel) + " ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
    _vm.showActionContent
      ? _c(
          "div",
          { staticClass: "has-padding-md has-background-grey-lighter" },
          [_vm._t("action")],
          2
        )
      : _vm._e(),
    _c("div", { staticClass: "card-content" }, [_vm._t("default")], 2),
    _c("footer", { staticClass: "card-footer" }, [_vm._t("footer")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }