<template>
    <div>
        <b-tabs v-model="activeTab" type="is-toggle" :animated="false" @click.stop>
            <b-tab-item label="Organization Details">
                <organization-summary />
            </b-tab-item>
            <b-tab-item label="Participation Plan">
                <participation-plan-summary v-if="activeTab === steps.participation" />
                <section class="section">
                    <b-loading :is-full-page="false" :active.sync="isEmployerStoreLoading" :can-cancel="false"></b-loading>
                </section>
            </b-tab-item>
            <b-tab-item label="Opportunities">
                <opportunity-list v-if="activeTab === steps.opportunity" :showHeader="false" readonly />
                <section class="section">
                    <b-loading :is-full-page="false" :active.sync="isEmployerStoreLoading" :can-cancel="false"></b-loading>
                </section>
            </b-tab-item>
        </b-tabs>

        <div v-if="!hasSignedNDA" class="columns">
            <div class="column has-text-right">
                <b-button @click="remindEmployer" type="is-info" class="has-margin-right-md">Send Reminder Email</b-button>
            </div>
        </div>
        <div v-else-if="hasSignedNDA && isUnapproved" class="columns">
            <div class="column has-text-right">
                <b-button @click="approveEmployer" type="is-success" class="has-margin-right-md">Approve Employer</b-button>
                <b-button @click="rejectEmployer" type="is-danger">Reject Employer</b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import ParticipationPlanSummary from '@/components/employer/participationPlan/ParticipationPlanSummary.vue';
    import OpportunityList from '@/components/employer/opportunity/OpportunityList.vue';
    import OrganizationSummary from '@/components/employer/OrganizationSummary.vue';
    import { ParticipantStatusTypes } from '@/helpers/staticValues';

    export default {
        name: 'EmployerDetail',

        components: {
            ParticipationPlanSummary,
            OpportunityList,
            OrganizationSummary
        },

        data() {
            return {
                activeTab: 0,
                steps: {
                    orgDetails: 0,
                    participation: 1,
                    opportunity: 2
                }
            };
        },

        methods: {
            approveEmployer() {
                this.$emit('approve-employer');
            },

            rejectEmployer() {
                this.$emit('reject-employer');
            },

            remindEmployer() {
                this.$emit('remind-employer');
            }
        },

        computed: {
            ...mapGetters(['activeEmployerFair', 'isEmployerStoreLoading']),

            isUnapproved() {
                return this.activeEmployerFair.participationStatus == ParticipantStatusTypes.unapproved;
            },

            hasSignedNDA() {
                return this.activeEmployerFair.hasSignedNDA;
            }
        }
    };
</script>