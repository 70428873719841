<template>
    <div>
        <div class="columns is-desktop is-multiline">
            <slot name="firstRow"></slot>
        </div>
        <div class="columns is-desktop is-multiline">
            <div class="column is-narrow">
                <label class="label">Name:</label>
            </div>
            <div class="column has-text-left">
                <span class="content">
                    {{ user.firstName }}, {{ user.lastName }}
                </span>
            </div>
            <div class="column is-narrow">
                <label class="label">Preferred Name:</label>
            </div>
            <div class="column has-text-left">
                <span class="content">
                    {{ user.preferredName }}
                </span>
            </div>
            <div class="column is-narrow">
                <label class="label">
                    Phone:
                </label>
            </div>
            <div class="column has-text-left">
                <span class="content">
                    {{ user.phone }}
                </span>
            </div>
        </div>
        <div class="columns is-desktop is-multiline">
            <div class="column is-narrow">
                <label class="label">Email:</label>
            </div>
            <div class="column has-text-left">
                <span class="content">
                    {{ user.email }}
                </span>
            </div>
        </div>
        <div class="columns is-desktop is-multiline">
            <slot name="lastRow"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UserRegistrationDetails',

        props: {
            user: {
                required: true
            }
        }
    };
</script>