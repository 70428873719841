<template>
    <div>
        <b-field class="filters">
            <div class="columns is-multiline is-gapless" :key="rerenderKey">
                <div class="column is-full-mobile">
                    <p class="title is-5 has-margin-bottom-sm has-padding-bottom-xs">Refine By</p>
                </div>
                <div class="column is-full-desktop" 
                    v-for="(field, index) in fields" 
                    :key="index">
                    <field-search-input                     
                        v-model="field.searchParameters"
                        :type="field.type"
                        :options="field.options != null ? field.options : dataByField(field.path)"
                        :label="field.label"
                        :allowMultiple="field.allowMultiple"
                        :autocomplete="field.autocomplete"
                        :expanded="field.expanded"
                        :misc="field.misc"
                        @input="updateFilter"
                        size="is-small">
                        <b-field v-if="field.allowMultiple && field.inclusive != null" class="has-text-weight-normal">
                            <b-checkbox v-model="field.inclusive" size="is-small" class="has-padding-right-xs">
                                <span>Include Any Match</span>
                            </b-checkbox>
                        </b-field>
                    </field-search-input>
                </div>
            </div>
        </b-field>
    </div>
</template>

<script>
    import get from 'lodash/get';
    import { mapGetters } from 'vuex';
    import { fieldSearchTypes } from '@/helpers/staticValues';
    import FieldSearchInput from '@/components/formControls/input/FieldSearchInput';

    export default {
        name: 'OpportunitySearchFilters',

        components: {
            FieldSearchInput
        },

        props: {
            value: Array,
            fieldData: Array
        },

        data() {
            return {
                fields: [],
                rerenderKey: Date.now()
            };
        },

        computed: {
            ...mapGetters([
                'getEnum',
                'getAttendeeTypes',
                'getOrganizationTypes',
                'getPracticeTypes',
                'getSubjectAreas',
                'getOpportunityTypes'
            ]),

            dataByFields() {
                return this.fields.map(field => {
                    if (this.fieldData == null) return;

                    const fieldData = this.fieldData.map(v => {
                        return get(v, field.path);
                    });

                    let dataSet = new Set(fieldData);
                    dataSet = [...dataSet];
                    dataSet.sort();

                    return {
                        path: field.path,
                        data: dataSet
                    };
                });
            }
        },

        methods: {
            dataByField(fieldPath) {
                const field = this.dataByFields.find(dbf => dbf.path == fieldPath);
                return field && field.data;
            },

            updateFilter() {
                this.$emit('input', this.fields);
            },

            clear() {
                this.fields.forEach(field => {
                    const fieldCopy = Object.assign({}, field);

                    if (Array.isArray(fieldCopy.searchParameters)) {
                        field.searchParameters = [];
                    }
                    else {
                        field.searchParameters = null;
                    }
                });

                this.rerenderKey = Date.now();
                this.updateFilter();
            }
        },

        mounted() {
            this.fields = [
                {
                    path: 'employerName',
                    label: 'Employer',
                    autocomplete: true,
                    searchParameters: null,
                    type: fieldSearchTypes.string,
                },
                {
                    path: 'organizationType',
                    label: 'Organization Type',
                    searchParameters: null,
                    type: fieldSearchTypes.enum,
                    options: this.getOrganizationTypes,
                },
                {
                    path: 'practiceTypes',
                    label: 'Practice Types',
                    autocomplete: true,
                    searchParameters: [],
                    allowMultiple: true,
                    inclusive: true,
                    type: fieldSearchTypes.enum,
                    options: this.getPracticeTypes,
                    expanded: false,
                },
                {
                    path: 'subjectAreas',
                    label: 'Subject Areas',
                    autocomplete: true,
                    searchParameters: [],
                    allowMultiple: true,
                    inclusive: true,
                    type: fieldSearchTypes.enum,
                    options: this.getSubjectAreas,
                    expanded: false,
                },
                {
                    path: 'city',
                    label: 'City',
                    autocomplete: true,
                    searchParameters: null,
                    type: fieldSearchTypes.string,
                },
                {
                    path: 'state',
                    label: 'State',
                    autocomplete: true,
                    searchParameters: null,
                    type: fieldSearchTypes.string,
                },
                {
                    path: 'attendeeTypes',
                    label: 'Class Year',
                    searchParameters: null,
                    type: fieldSearchTypes.enum,
                    // Exclude alumni since they won't be listed as an eligible class year for opportunities
                    options: this.getAttendeeTypes.filter(at => at.id != 5),
                },
                {
                    path: 'isPaid',
                    label: 'Paid Position',
                    searchParameters: null,
                    type: fieldSearchTypes.boolean,
                    options: [
                        {
                            value: true,
                            label: 'Paid'
                        },
                        {
                            value: false,
                            label: 'Unpaid'
                        }
                    ]
                },
                {
                    path: 'type',
                    label: 'Opportunity Type',
                    searchParameters: null,
                    type: fieldSearchTypes.boolean, // kind of a hack since this actually represents enums
                    options: this.getOpportunityTypes.map(ot => ({ value: ot.id, label: ot.description }))
                },
                {
                    path: 'allowNonApplicant',
                    label: 'Free Signup',
                    searchParameters: null,
                    type: fieldSearchTypes.boolean,
                    options: [
                        {
                            value: true,
                            label: 'Free Signup'
                        },
                        {
                            value: false,
                            label: 'No Free Signup'
                        }
                    ]
                }
            ];
        },
    };
</script>

<style scoped>
    div p.title.is-5.has-margin-bottom-sm {
        margin-bottom: 0.5rem;
    }

    .filters >>> .dropdown-menu {
        display: inline-table
    }

    .filters >>> .dropdown-content {
        display: inline-block;
    }

    .filters >>> .autocomplete .dropdown-item {
        text-overflow: unset;
    }
</style>