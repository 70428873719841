import {
    NotificationProgrammatic as Notification,
    ToastProgrammatic as Toast,
    DialogProgrammatic as Dialog
} from 'buefy';

export const Error = (message, indefinite = false) => {
    Notification.open({
        message,
        type: 'is-danger',
        closable: true,
        position: 'is-top',
        indefinite,
        duration: 600000
    });
};

export const ToastInfo = message => {
    Toast.open({
        type: 'is-info',
        message
    });
};

export const ToastSuccess = message => {
    Toast.open({
        type: 'is-success',
        message
    });
};

export const Confirm = (title, message, confirmText, onConfirm, onCancel) => {
    Dialog.confirm({
        title,
        message,
        confirmText,
        type: 'is-danger',
        hasIcon: true,
        onConfirm,
        onCancel
    });
};

export const ErrorAlert = (message, onConfirm) => {
    Dialog.alert({
        title: 'Error',
        message,
        confirmText: 'Ok',
        type: 'is-danger',
        hasIcon: true,
        onConfirm
    });
};

export default 'globalNotifications';
