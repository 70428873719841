import axios from 'axios';
import store from '@/stores';
import { getUserRoles } from '@/helpers/userRouting';
import { troyesRole } from '@/helpers/staticValues';

export default class ApiService {
    static get axiosInstance() {
        if (store !== undefined && store.state.oidcStore) {
            let accessToken = 'Bearer ' + store.state.oidcStore.access_token;

            let guid = store.state.oidcStore.user.sub;

            let userRoles = getUserRoles();

            let role;

            if (userRoles.indexOf(troyesRole.FairAdmin) != -1) {
                role = troyesRole.FairAdmin;
            } else if (userRoles.indexOf(troyesRole.SchoolAdmin) != -1) {
                role = troyesRole.SchoolAdmin;
            } else if (userRoles.indexOf(troyesRole.Employer) != -1) {
                role = troyesRole.Employer;
            } else if (userRoles.indexOf(troyesRole.Attendee) != -1) {
                role = troyesRole.Attendee;
            }

            return axios.create({
                baseURL: process.env.VUE_APP_API_ENDPOINT,
                headers: {
                    Authorization: accessToken,
                    'Content-Type': 'application/json',
                    'Guid': guid,
                    'user-role': role
                },
                'guid': guid,
                'user-role': role
            });
        }

        return axios.create({
            baseURL: process.env.VUE_APP_API_ENDPOINT
        });
    }

    static get(...args) {
        return ApiService.axiosInstance.get(...args);
    }

    static post(...args) {
        return ApiService.axiosInstance.post(...args);
    }

    static put(...args) {
        return ApiService.axiosInstance.put(...args);
    }

    static delete(...args) {
        return ApiService.axiosInstance.delete(...args);
    }
}
