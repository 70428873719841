var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormControl",
    {
      attrs: {
        horizontal: _vm.showHorizontal,
        leftColumnCss: _vm.questionCss,
        rightColumnCss: _vm.actionCss,
        controlLabel: _vm.question,
        centerText: _vm.centerText,
        toolTip: _vm.toolTip,
      },
    },
    [
      !_vm.showCard
        ? _c(
            "div",
            [
              _c("AffirmOrDenyButtonsControl", {
                attrs: {
                  value: _vm.value,
                  buttonSize: _vm.buttonSize,
                  affirmLabel: _vm.affirmLabel,
                  denyLabel: _vm.denyLabel,
                  otherLabel: _vm.otherLabel,
                  disabled: _vm.readonly,
                },
                on: { input: _vm.updateValue },
              }),
              _vm.answer == _vm.otherLabel
                ? _c("InputControl", {
                    attrs: {
                      placeholder: "Reason",
                      type: "textarea",
                      disabled: _vm.readonly,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("div", [
            _c("div", { staticClass: "columns is-mobile" }, [
              _c(
                "div",
                { staticClass: "column is-three-fifths is-offset-one-fifth" },
                [
                  _c(
                    "b-notification",
                    {
                      attrs: {
                        "aria-close-label": "Close notification",
                        closable: false,
                      },
                    },
                    [
                      _c("AffirmOrDenyButtonsControl", {
                        attrs: {
                          value: _vm.value,
                          buttonSize: _vm.buttonSize,
                          affirmLabel: _vm.affirmLabel,
                          denyLabel: _vm.denyLabel,
                          otherLabel: _vm.otherLabel,
                          disabled: _vm.readonly,
                        },
                        on: { input: _vm.updateValue },
                      }),
                      _vm.answer == _vm.otherLabel
                        ? _c("InputControl", {
                            attrs: {
                              placeholder: "Reason",
                              type: "textarea",
                              disabled: _vm.readonly,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }