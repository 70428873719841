var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-taginput",
    _vm._b(
      {
        ref: "taginput",
        attrs: {
          id: _vm.id,
          data: _vm.filteredData,
          autocomplete: "",
          "allow-new": _vm.allowNew,
          field: _vm.displayField,
          icon: _vm.icon,
          type: _vm.type,
          "open-on-focus": _vm.openOnFocus,
          "clear-on-select": "",
          "keep-first": "",
          "has-counter": false,
          disabled: _vm.disabled,
        },
        on: {
          typing: function ($event) {
            return _vm.setField($event, "search")
          },
          add: function ($event) {
            return _vm.addOption($event, _vm.value)
          },
          remove: function ($event) {
            return _vm.removeOption($event, _vm.value)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [_vm._t("header")]
              },
              proxy: true,
            },
            {
              key: "default",
              fn: function (props) {
                return [_vm._v(" " + _vm._s(props.option.description) + " ")]
              },
            },
            {
              key: "empty",
              fn: function () {
                return [_vm._t("empty")]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [_vm._t("footer")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.stagedItems,
          callback: function ($$v) {
            _vm.stagedItems = $$v
          },
          expression: "stagedItems",
        },
      },
      "b-taginput",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }