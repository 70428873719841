var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("strong", [_vm._v(_vm._s(_vm.appointment.employerName))]),
    _c("br"),
    _vm._v(_vm._s(_vm.appointment.opportunityTitle) + " "),
    _c("br"),
    _vm._v("Date: " + _vm._s(_vm.appointment.startTime) + " "),
    _c("br"),
    _vm._v("Location: " + _vm._s(_vm.appointment.location) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }