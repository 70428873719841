<template>
    <div class="columns is-multiline is-gapless is-vcentered is-tablet">
        <div class="column is-full">
            <div class="has-padding-bottom-sm">
                {{ opportunity.jobTitle }} - {{ opportunity.city }}, {{ opportunity.state }}
            </div>
        </div>
        <div class="column">
            <b-taglist>
                <b-tag type="is-primary" rounded>
                    {{ getEnumDescription('OpportunityType', opportunity.type) }}
                </b-tag>
                <b-tag v-if="opportunity.allowNonApplicant" rounded>
                    {{ allowUnrankedDesc }}
                </b-tag>
                <b-tag type="is-primary" v-for="attendeeType in opportunity.attendeeTypes" :key="attendeeType" rounded>
                    {{ getEnumDescription('AttendeeType', attendeeType) }}
                </b-tag>
            </b-taglist>
        </div>
        <div v-if="hasActions" class="column is-narrow v-align-center">
            <EditButton :disabled="editing" @click-edit="edit" />
            <TrashButton class="has-margin-right-md" :disabled="editing" @click-delete="remove" />

            <b-button @click="rank" :disabled="editing">
                Rank
            </b-button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    import EditButton from '@/components/actions/Edit';
    import TrashButton from '@/components/actions/Trash';

    export default {
        name: 'EmployerOpportunity',

        components: {
            EditButton,
            TrashButton
        },

        props: {
            opportunity: {
                type: Object,
                required: true
            },
            hasActions: {
                type: Boolean,
                default: true
            }
        },

        methods: {
            ...mapActions(['deleteOpportunity', 'editOpportunity']),

            edit() {
                this.editOpportunity(this.opportunity.id);
            },

            remove() {
                const input = {};
                input.opportunityId = this.opportunity.id;
                
                this.$confirm({ 
                    message: 'Do you want to delete this opportunity?', 
                    onConfirm: () => this.deleteOpportunity(input.opportunityId)
                });
            },

            rank() {
                
            }
        },

        computed: {
            ...mapGetters(['editingOpportunity', 'getEnumDescription']),

            allowUnrankedDesc() {
                return this.opportunity.allowNonApplicant ? 'Allowing Free Signup' : 'No Free Signup';
            },
            
            editing() {
                return this.editingOpportunity != null;
            }
        }
    };
</script>

<style scoped>
    div.column >>> span.icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
</style>