var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-validator", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ valid, dirty }) {
          return _c(
            "div",
            {},
            [
              _c("opportunity-step", {
                model: {
                  value: _vm.opportunity,
                  callback: function ($$v) {
                    _vm.opportunity = $$v
                  },
                  expression: "opportunity",
                },
              }),
              _c("hr"),
              _c("document-request-step", {
                model: {
                  value: _vm.opportunity.documentRequests,
                  callback: function ($$v) {
                    _vm.$set(_vm.opportunity, "documentRequests", $$v)
                  },
                  expression: "opportunity.documentRequests",
                },
              }),
              _c("hr"),
              _c(
                "div",
                { staticClass: "buttons" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "is-success",
                        "icon-left": "save",
                        disabled: !valid && dirty,
                      },
                      on: { click: _vm.save },
                    },
                    [_vm._v(" Save ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { type: "is-danger", "icon-left": "times" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }