<template>
    <div class="columns">
        <div class="column is-one-quarter">
            <opportunity-search-filters ref="filter" class="has-padding-bottom-md" v-model="filters"
                :fieldData="opportunities" />
            <hr class="has-margin-top-md has-margin-bottom-md" />
            <div class="buttons">
                <a class="button" @click="clear">
                    <span>Clear Filters</span>
                </a>
                <a class="button" @click="filter">
                    <span>Apply Filters</span>
                </a>
            </div>
        </div>
        <div class="column">
            <OpportunitySearchBox />
            <br />
            <OpportunitySearchList :value="searchResults" />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { searchByField } from '@/helpers/search';
    import OpportunitySearchBox from '@/components/opportunity/OpportunitySearchBox';
    import OpportunitySearchFilters from '@/components/opportunity/OpportunitySearchFilters';
    import OpportunitySearchList from '@/components/opportunity/OpportunitySearchList';

    export default {
        name: 'OpportunitySearch',

        components: {
            OpportunitySearchBox,
            OpportunitySearchFilters,
            OpportunitySearchList,
        },

        data() {
            return {
                filters: [],
                searchResults: []
            };
        },

        async mounted() {
            const loadSearchTask = this.loadSearchResults();
            const loadApplicationsTask = this.loadApplications({ attendeeId: this.activeAttendeeId });
            await Promise.all([loadSearchTask, loadApplicationsTask]);
        },

        methods: {
        ...mapActions(['loadApplications', 'loadSearchResults']),

        clear() {
            this.$refs.filter.clear();
            this.filter();
        },

        filter() {
            let results = this.opportunities;

            this.filters.forEach(field => {
                results = searchByField(field, results);
            });

            this.searchResults = results;
        }
        },

        computed: {
        ...mapGetters(['activeAttendeeId', 'opportunities'])
        },

        watch: {
            opportunities: {
                handler() {
                    this.filter();
                }
            }
        }
    };
</script>

