var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-validator",
    { ref: "form", attrs: { "hide-errors": "" } },
    [
      _c(
        "ParticipationPlanEdit",
        {
          ref: "participationForm",
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "card-content buttons" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { type: "is-success", "icon-right": "save" },
                          on: { click: _vm.submit },
                        },
                        [_vm._v(" Save Participation Plan ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-loading", {
            attrs: { isFullPage: false, active: _vm.isEmployerStoreLoading },
            on: {
              "update:active": function ($event) {
                _vm.isEmployerStoreLoading = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }