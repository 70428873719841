import * as Api from '@/api/registration';
import * as Notifier from '@/helpers/globalNotifications';

export const loadUserRegistrations = async ({ commit }) => {
    try {
        commit('SET_LOADING_STATUS', true);

        const userData = await Api.loadUserRegistrations();

        commit('LOAD_USER_REGISTRATIONS', userData.data);
    } catch (error) {
        Notifier.Error('A problem occurred loading user registrations.');
    } finally {
        commit('SET_LOADING_STATUS', false);
    }
};

export const approveFairContactRegistration = async ({ commit }, { userGuid, employerId }) => {
    try {
        commit('SET_LOADING_STATUS', true);

        await Api.approvePrimaryFairContactForEmployer(userGuid, employerId);

        commit('APPROVE_USER_REG', userGuid);
    } catch (error) {
        Notifier.Error('A problem occurred approving user registrations.');
    } finally {
        commit('SET_LOADING_STATUS', false);
    }
};

export const rejectFairContactRegistration = async ({ commit }, userGuid) => {
    try {
        commit('SET_LOADING_STATUS', true);

        await Api.rejectPrimaryFairContactForEmployer(userGuid);

        commit('REJECT_USER_REG', userGuid);
    } catch (error) {
        Notifier.Error('A problem occurred rejecting user registrations.');
    } finally {
        commit('SET_LOADING_STATUS', false);
    }
};

