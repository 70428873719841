<template>
    <div>
        <ul>
            <li v-for="element in elements.slice(0, elementsToDisplay)" :key="element">{{element}}</li>
        </ul>
        <div v-show="elements.length > numWhenCollapsed">
            <more-less-button style="padding-left: 2em;" @click-more-less="toggle"></more-less-button>
        </div>
    </div>
</template>

<script>
    import MoreLessButton from '@/components/actions/MoreLess.vue';

    export default {
        name: 'ExpandableUnorderedList',

        components: {
            MoreLessButton
        },

        props: {
            elements: {
                type: Array,
                required: true
            },

            numWhenCollapsed: { // pass this in to set # elements displayed when collapsed
                type: Number,
                default: 3
            }
        },

        data() {
            return {
                elementsToDisplay: this.numWhenCollapsed
            };
        },

        methods: {
            toggle() {
                this.elementsToDisplay = this.elementsToDisplay == this.numWhenCollapsed ? this.elements.length : this.numWhenCollapsed;
            }
        }
    };
</script>

<style scoped>
    ul {
        margin-top: -0.5em !important;
        margin-bottom: 0em !important;
    }
</style>