<template>
    <div>
        <b-field>
            <field-validator name="title">
                <b-field label="Title" label-for="title" expanded>
                    <b-input 
                        id="title"
                        name="title"
                        v-model="inputValue.jobTitle"
                        @input="update"
                        expanded
                        :disabled="readonly" />
                </b-field>
            </field-validator>
        </b-field>

        <b-field grouped group-multiline>
            <field-validator name="type">
                <text-label label="Type">
                    <enum-radio-buttons 
                        aria-label="Type"
                        name="type"
                        v-model="inputValue.type"
                        @input="update"
                        :options="getOpportunityTypes"
                        :disabled="readonly || typeReadOnly || !showTypeSelection" />
                </text-label>
            </field-validator>
            
            <field-validator name="paid">
                <text-label label="Paid">
                    <enum-radio-buttons 
                        aria-label="Paid"
                        name="paid"
                        v-model="inputValue.isPaid"
                        @input="update"
                        :options="[{ id: true, description: 'Yes' }, { id: false, description: 'No' }]" />
                </text-label>
            </field-validator>

            <field-validator name="class years" v-if="typeSelected">
                <b-field label="Class Years To Interview" label-for="classesAllowed" expanded>
                    <enum-taginput 
                        id="classesAllowed"
                        name="classesAllowed"
                        v-model="inputValue.attendeeTypes"
                        @input="update"
                        :data="attendeeTypes"
                        type="is-primary"
                        :disabled="readonly">
                        <template v-slot:empty>
                            Your search didn't match any classes
                        </template>
                    </enum-taginput>
                </b-field>
            </field-validator>
        </b-field>

        <b-field grouped group-multiline>
            <b-field label-for="requiredLanguageSkills" expanded>
                <template v-slot:label>
                    <div class="v-align-center">
                        Required Language Skills
                    </div>
                </template>
                <field-validator name="required language skills" required="">
                    <b-taginput 
                        :key="reqLangKey"
                        @blur="addTagOnBlur($event, 'requiredLanguageSkills')"
                        id="requiredLanguageSkills"
                        name="requiredLanguageSkills"
                        v-model="requiredLanguageSkills"
                        :on-paste-separators="[',',' ']"
                        @input="update"
                        placeholder="Add a language skill"
                        autocomplete
                        allow-new
                        expanded
                        :disabled="readonly" />
                </field-validator>
            </b-field>

            <b-field label-for="preferredLanguageSkills" expanded>
                <template v-slot:label>
                    <div class="v-align-center">
                        Preferred Language Skills
                    </div>
                </template>
                    <field-validator name="preferred language skills" required="">
                        <b-taginput 
                            :key="prefLangKey"
                            @blur="addTagOnBlur($event, 'preferredLanguageSkills')"
                            id="preferredLanguageSkills"
                            name="preferredLanguageSkills"
                            v-model="preferredLanguageSkills"
                            :on-paste-separators="[',',' ']"
                            @input="update"
                            placeholder="Add a language skill"
                            autocomplete
                            allow-new
                            :disabled="readonly" />
                    </field-validator>
            </b-field>

            <field-validator name="citizenship">
                <text-label label="U.S. Citizenship Required">
                        <enum-radio-buttons 
                            aria-label="US Citizenship Required"
                            name="US Citizenship Required"
                            v-model="inputValue.usCitizenshipRequired"
                            @input="update"
                            :options="[{ id: true, description: 'Yes' }, { id: false, description: 'No' }]"
                            :disabled="readonly" />
                </text-label>
            </field-validator>
        </b-field>

        <b-field grouped group-multiline>
            <field-validator name="city">
                <b-field label="City" label-for="city" expanded>
                    <b-autocomplete 
                        :data="cities" 
                        id="city" 
                        name="city" 
                        v-model="inputValue.city"
                        @input="update" 
                        open-on-focus 
                        :disabled="readonly" />
                </b-field>
            </field-validator>

            <field-validator name="state">
                <b-field label="State" label-for="state" expanded>
                    <b-autocomplete 
                        :data="states" 
                        id="state" 
                        name="state" 
                        v-model="inputValue.state" 
                        @input="update"
                        open-on-focus 
                        :disabled="readonly" />
                </b-field>
            </field-validator>

            <zip-code-search v-model="inputValue.postalCode" @input="update" @search="populateCityState" :readonly="readonly" />
        </b-field>

        <field-validator name="description">
            <b-field label-for="description" custom-class="v-align-center">
                <template v-slot:label>
                    <div class="v-align-center">
                        Description
                        <b-tooltip :label="descriptionTooltip" class="v-align-center has-margin-left-xs" multilined>
                            <b-icon icon="info-circle" size="is-small" type="is-info" />
                        </b-tooltip>
                    </div>
                </template>
                <b-input 
                    id="description" 
                    v-model="inputValue.desc" 
                    @input="update" 
                    name="description" 
                    type="textarea" 
                    :disabled="readonly" />
            </b-field>
        </field-validator>
        
        <field-validator name="free sign-up">
            <text-label label="Allow Free Sign-Up" has-tooltip :tooltip="freeSignUpTooltip">
                <enum-radio-buttons 
                    aria-label="Allow Free Signup"
                    name="allowUnranked"
                    v-model="inputValue.allowNonApplicant"
                    @input="update"
                    :options="[{ id: true, description: 'Yes' }, { id: false, description: 'No' }]"
                    :disabled="readonly" />
            </text-label>
        </field-validator>

        <b-field grouped v-if="typeSelected">
            <b-field 
                v-if="inputValue.type != null" 
                :label="additionalEmploymentOfferingsLabel" 
                label-for="additionalEmploymentOfferings" 
                expanded 
                :disabled="readonly">
                <b-input 
                    id="additionalEmploymentOfferings"
                    name="additionalEmploymentOfferings"
                    placeholder="Please explain"
                    v-model="inputValue.additionalEmploymentOfferings"
                    @input="update"
                    class="inline-textarea"
                    type="textarea"
                    expanded
                    :disabled="readonly" />
            </b-field>
        </b-field>
    </div>
</template>

<script>
    import merge from 'lodash/merge';
    import { mapGetters } from 'vuex';

    import FieldValidator from '@/components/formControls/base/FieldValidator';
    import ZipCodeSearch from '@/components/employer/opportunity/ZipCodeSearch';

    export default {
        name: 'OpportunityStep',

        components: {
            FieldValidator,
            ZipCodeSearch
        },

        props: {
            opportunityTypeId: {
                validator: (val) => val == null || typeof val == 'number'
            },
            value: {
                type: Object,
                required: true
            },
            readonly: Boolean,
            typeReadOnly: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                inputValue: {},
                states: [],
                cities: [],
                reqLangKey: Date.now(),
                prefLangKey: Date.now(),
                descriptionTooltip: 'Please include a brief description the opportunity, including the specific qualifications or skills you seek in candidates',
                freeSignUpTooltip:
                    `By selecting "Yes", interview matching will include applicants that you do not rank during
                    candidate selection, as well as students who apply after interview schedules are released.
                    As always, ranked candidates will be preferred.
                    \n
                    If you click yes here, you will allow applicants that you do not rank, as well as students who
                    apply after interview schedules are released, to be filled in for any open slots in your interview schedule.
                    Open slots may occur if you do not have as many applicants as there are slots, if selected applicants are
                    not available due to scheduling conflicts, or if you do not rank enough applicants to fill your interview slots.
                    Applicants whom you have rejected will never be included in your interview schedule, and ranked applicants will always receive priority.`
            };
        },

        methods: {
            populateCityState(searchResults) {
                this.states = searchResults.states;
                this.cities = searchResults.cities;

                if (this.states.length > 0) this.inputValue.state = this.states[0];
                if (this.cities.length > 0) this.inputValue.city = this.cities[0];
            },

            update() {
                this.$emit('input', this.inputValue);
            },

            addTagOnBlur(event, propName) {
                const input = event.target;

                if (input.value && input.value.length > 0) {
                    this[propName] = this[propName].concat(input.value);
                    this.update();
                    // force a rerender of the language fields
                    // 01/16/20 - I tried several other ways including null out the input's value
                    // this is the only method that has clear out the input text after adding tag on blur
                    this.reqLangKey = Date.now();
                    this.prefLangKey = Date.now();
                }
            },

            updateInputValue(newValue) {
                if (newValue === null) return;

                this.inputValue = merge({}, newValue);

                if (this.opportunityTypeId != null) {
                    this.inputValue.type = this.opportunityTypeId;
                }
            }
        },

        computed: {
            ...mapGetters(['getAttendeeTypesByOpportunityType', 'getOpportunityTypes', 'getAttendeeTypes']),

            additionalEmploymentOfferingsLabel() {
                if (this.inputValue.type == null) return null;

                return `Do you offer ${this.inputValue.type == 1 ? 'internships during the academic year' : 'post-graduate fellowship positions'}?`;
            },

            attendeeTypes() {
                if (!this.typeSelected) return this.getAttendeeTypes;

                return this.getAttendeeTypesByOpportunityType(this.inputValue.type);
            },

            typeSelected() {
                return this.inputValue.type !== null;
            },

            preferredLanguageSkills: {
                get() {
                    if (this.inputValue.preferredLanguages === null ||
                        this.inputValue.preferredLanguages === undefined) return [];

                    return this.inputValue.preferredLanguages.split(', ');
                },
                set(value) {
                    this.inputValue.preferredLanguages = value.join(', ');
                }
            },

            requiredLanguageSkills: {
                get() {
                    if (this.inputValue.requiredLanguages === null ||
                        this.inputValue.requiredLanguages === undefined ||
                        this.inputValue.requiredLanguages.length === 0) return [];
                    
                    return this.inputValue.requiredLanguages.split(', ');
                },
                set(value) {
                    this.inputValue.requiredLanguages = value.join(', ');
                }
            },

            showTypeSelection() {
                return this.opportunityTypeId === null || this.opportunityTypeId === undefined;
            }
        },

        watch: {
            value: {
                handler(newVal) {
                    this.updateInputValue(newVal);
                },
                immediate: true
            }
        }
    };
</script>

<style scoped>
    .field + .columns {
        margin: 0 -0.75rem;
    }

    @media screen and (max-width: 1023px) {
        .field + .columns .column.field:last-child {
            padding-top: 0;
        }
    }

    .field + .columns .column.field:not(:last-child) {
        margin-bottom: 0;
    }

    @media screen and (min-width: 1216px) {
        .has-margin-top-line {
            margin-top: 1.5em;
        }
    }

    div.control.half-height-textarea >>> textarea {
        min-height: 4em;
    }
</style>