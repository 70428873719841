import { troyesRole } from '@/helpers/staticValues';
import FormWizard from '@/views/shared/layouts/FormWizard';
import RegistrationView from '@/views/employer/Registration';

import EmployerProfileEdit from '@/views/employer/profile/EmployerProfileEdit';
import OpportunityStepItem from '@/components/employer/opportunity/OpportunityStepItem';
import ParticipationPlanEdit from '@/components/employer/participationPlan/ParticipationPlanEdit';
import RegistrationReview from '@/components/employer/RegistrationReview';
import EmployerContacts from '@/views/employer/EmployerContacts';

var children = [
    {
        path: '',
        component: RegistrationView,
        meta: {
            showSidebar: false,
            isPublic: false,
            accessRole: troyesRole.Employer
        },
        children: [
            {
                path: '',
                name: 'employer-registration',
                redirect: 'organization-details'
            },
            {
                path: 'organization-details',
                name: 'employer-registration-details',
                component: EmployerProfileEdit,
                meta: {
                    index: 0
                }
            },
            {
                path: 'employer-contacts',
                name: 'employer-registration-contacts',
                component: EmployerContacts,
                meta: {
                    index: 1
                }
            },
            {
                path: 'participation',
                name: 'employer-registration-participation',
                component: ParticipationPlanEdit,
                meta: {
                    index: 2
                }
            },
            {
                path: 'post-grad',
                name: 'employer-registration-post-grad',
                component: OpportunityStepItem,
                meta: {
                    index: 3
                }
            },
            {
                path: 'internship',
                name: 'employer-registration-internship',
                component: OpportunityStepItem,
                meta: {
                    index: 4
                }
            },
            {
                path: 'summary',
                name: 'employer-registration-summary',
                component: RegistrationReview,
                meta: {
                    index: 5
                }
            }
        ]
    },
];

export default {
    path: '/employer/register',
    component: FormWizard,
    children
};
