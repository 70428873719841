<template>
    <MainRightSidebar>
        <collapsible-card title="School Admin Sidebar">
            Sidebar content TBD
        </collapsible-card>
  </MainRightSidebar>
</template>

<script>
    export default {
        name: 'SchoolSidebar'
    };
</script>