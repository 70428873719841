var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("div", { staticClass: "field has-addons" }, [
      _c("div", { staticClass: "control is-expanded" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchTerms,
              expression: "searchTerms",
            },
          ],
          staticClass: "input",
          attrs: { type: "text", placeholder: "e.g. Search Opportunities" },
          domProps: { value: _vm.searchTerms },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.search.apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.searchTerms = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "control" }, [
        _c(
          "a",
          { staticClass: "button", on: { click: _vm.search } },
          [
            _c("b-icon", { attrs: { icon: "search", size: "is-small" } }),
            _c("span", [_vm._v("Search")]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }