<template>
    <text-label label="Zip Code Search" has-tooltip :tooltip="tooltip">
        <b-field>
            <b-input 
                v-on:keyup.enter.native="zipcodeLookup" 
                v-model="zipcode" 
                v-bind="$attrs"
                placeholder="Search State/City by Zip Code" 
                :maxlength="10"
                :disabled="readonly"
                @input="$emit('input', zipcode)"
                expanded />
            <p class="control">
                <b-button
                    class="button"
                    icon-left="search"
                    @click="zipcodeLookup"
                    :disabled="readonly" />
            </p>
        </b-field>
    </text-label>
</template>

<script>
    export default {
        name: 'StateSelect',

        data() {
            return {
                zipcode: this.value,
                isLoading: false,
                tooltip: 'Enter Zip Code to search for City/State'
            };
        },

        props: {
            value: [String, Number],

            readonly: {
                type: Boolean,
                default: false
            }
        },

        updated() {
            this.zipcode = this.value;
        },

        methods: {
            async zipcodeLookup() {
                try {
                    const noResultMessage = 'We didn\'t find anything matching that zip code.';
                    const zipcodeValidator = /^\d{5}(?:[-\s]\d{4})?$/;

                    if (!zipcodeValidator.test(this.zipcode)) {
                        this.$toast({ message: noResultMessage, type: 'is-warning' });
                        return;
                    }

                    let cities = [];
                    let states = [];

                    const baseUrl = 'https://nominatim.openstreetmap.org';

                    // United States, Minor Outlying Islands, Puerto Rico, Virgin Islands, Guam,
                    // American Samoa, MP = Northern Mariana Islands
                    const countryCodes = 'us,um,pr,vi,gu,as,mp';
                    
                    const zipCodeLookup = `${baseUrl}/search?countrycodes=${countryCodes}&postalcode=${this.zipcode}&format=json&addressdetails=1`;

                    const response = await fetch(zipCodeLookup, { mode: 'cors' });
                    
                    if (!response.ok) {
                        throw response;
                    }
                    else {
                        const jsonResponse = await response.json();

                        if (jsonResponse.length > 0) {
                            jsonResponse.forEach(result => {
                                if (result.address.city != null) cities.push(result.address.city);
                                if (result.address.state != null) states.push(result.address.state);
                            });

                            const results = { states, cities };
                            results.postalCode = this.zipCode;

                            this.$emit('search', results);
                        }
                        else {
                            this.$toast({ message: noResultMessage, type: 'is-warning' });
                        }
                    }
                }
                catch (error) {
                    const errorMessage = 'We had some trouble with the zip code lookup. Please try again in a few moments';
                    this.$toast({ message: errorMessage, type: 'is-danger' });
                }
            }
        }
    };
</script>