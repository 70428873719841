<template>
    <card title="Applications">
        <b-notification :closable="false">
            Some text (possibly instructions) will go here.
        </b-notification>
        <a class="button is-size-7 has-margin-bottom-md">
            Save Ranking
        </a>
        <b-table
            class="is-size-7"
            :data="applications"
            detail-key="id"
            hoverable
            draggable
            @dragstart="dragstart" 
            @drop="drop" 
            @dragover="dragover" 
            @dragleave="dragleave"
            @dragend="dragend"
            paginated
            narrowed>
            <template slot-scope="props">
                <b-table-column label="Rank">
                    {{ props.row | indexOf(applications) }}
                </b-table-column>
                <b-table-column field="opportunityName" label="Opportunity">
                    {{ props.row.opportunityName }}
                </b-table-column>
                <b-table-column field="employerName" label="Employer">
                    {{ props.row.employerName }}
                </b-table-column>
                <b-table-column label="Actions">
                    <a class="button is-size-7" @click="withdraw(props.row.id)">
                        Withdraw
                    </a>
                </b-table-column>
            </template>
            <template v-slot:empty>
                {{ emptyMessage }}
            </template>
        </b-table>
    </card>
</template>

<script>
    import { dragAndDropMixin } from '@/mixins/DragAndDropMixin';

    export default {
        name: 'AttendeeApplicationView',

        mixins: [dragAndDropMixin],
        
        filters: {
            indexOf(row, collection) {
                return collection.map(a => a.id).indexOf(row.id) + 1;
            }
        },

        methods: {
            // Event: When you grab a b-table row, it is wrapped in a payload object. The payload's data is accessed via the row property.
            dragstart(payload) {
                // @TODO When Mozilla fixes the following bug: https://bugzilla.mozilla.org/show_bug.cgi?id=725156  
                // remove the dataTransfer.setData workaround below. It looks like it's been open for 8 years - we'll see what happens
                payload.event.dataTransfer.setData('application/x-workaround', payload.row);
                this.appBeingDragged = payload.row;
            },

            dragover(target) {
                if (this.appBeingDragged.id != target.row.id) {
                    this.$super().dragover(target);
                    this.draggingOverElement = target.row;
                }
            },

            dragleave(target) {
                this.$super().dragleave(target);
                this.draggingOverElement = null;
            },

            copyDraggingRowDataToTarget(target) {
                const findIndex = (item, collection) => collection.findIndex(c => c.id == item.id);

                const dataCopy = [...this.applications];

                const draggingIndex = findIndex(this.appBeingDragged, dataCopy);

                dataCopy.splice(draggingIndex, 1);

                const targetIndex = findIndex(target.row, dataCopy);

                if (targetIndex < 0) {
                    this.$toast('not found');
                }
                else {
                    dataCopy.splice(targetIndex, 0, this.appBeingDragged);
                    this.applications = dataCopy;
                }
                
                // clear
                this.appBeingDragged = null;
            },

            remove(id) {
                const index = this.applications.findIndex(a => a.id == id);
                this.applications.splice(index, 1);
            },

            withdraw(id) {
                this.$confirm({
                    message: 'Are you sure you want to withdraw this application?',
                    onConfirm: () => {
                        this.remove(id);
                        this.$toast(`Withdrew application with id ${id}`);
                    }
                });
            },
        },

        data() {
            return {
                appBeingDragged: null,
                applications: [{'id':1,'employerName':'Homenick, Cremin and Grady','opportunityName':'Social Worker'},
                               {'id':2,'employerName':'Gutmann, Roob and Medhurst','opportunityName':'Senior Financial Analyst'},
                               {'id':3,'employerName':'Sawayn, Roob and Schoen','opportunityName':'Account Executive'},
                               {'id':4,'employerName':'Jacobi Group','opportunityName':'Civil Engineer'},
                               {'id':5,'employerName':'Murray LLC','opportunityName':'Pharmacist'},
                               {'id':6,'employerName':'Kemmer-Jacobi','opportunityName':'Media Manager IV'},
                               {'id':7,'employerName':'Huels Group','opportunityName':'Programmer Analyst IV'},
                               {'id':8,'employerName':'McKenzie, Jacobi and Stokes','opportunityName':'Speech Pathologist'},
                               {'id':9,'employerName':'Schinner, Reinger and Beahan','opportunityName':'Health Coach II'},
                               {'id':10,'employerName':'Yundt LLC','opportunityName':'Analog Circuit Design manager'}],
                dragOverClass: 'border-gap-top'
            };
        }
    };
</script>