import Vue from 'vue';
import strings from '@/filters/strings';
import dates from '@/filters/dates';
import lists from '@/filters/lists';

const filters = [strings, dates, lists];

filters.forEach(filterModule => {
  Object.keys(filterModule).forEach(key => {
    Vue.filter(key, filterModule[key]);
  });
});
