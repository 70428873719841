<template>
    <section>
        <b-table ref="list" v-bind="$attrs" :data="inputData" :loading="loading" :current-page.sync="currentPage"
            :paginated="paginated" :per-page="rowsPerPage" :detailed="hasDetailColumn" :detail-key="rowId"
            :opened-detailed="openDetails" :show-detail-icon="false" :striped="isStriped"
            :class="{ 'omit-header': omitHeader }">
            <b-table-column label=" " field="rowId" v-slot="props">
                <template slot="header">
                    <slot name="header"></slot>
                </template>
                <div class="columns">
                    <div class="column" :class="{ 'is-four-fifths': hasDetailColumn && !hideArrow }">
                        <slot name="content" :row="{ ...props.row }"></slot>
                    </div>
                    <div v-if="hasDetailColumn && !hideArrow" class="column has-text-right">
                        <a>
                            <b-icon :class="props.row.open ? 'is-expanded' : ''" icon="angle-right"
                                @click.native.self.stop="toggleRow(props.row)"></b-icon>
                        </a>
                    </div>
                </div>
            </b-table-column>

            <template slot="detail" slot-scope="props">
                <slot name="detail" :row="{ ...props.row }">Default detail section</slot>
            </template>

            <template slot="empty">
                <slot name="empty"></slot>
            </template>
        </b-table>
    </section>
</template>

<script>
    import merge from 'lodash/merge';

    export default {
        name: 'BuefyListWrapper',

        props: {
            value: {
                type: Array
            },

            rowId: {
                type: String,
                required: true,
            },

            paginated: {
                type: Boolean,
                default: false,
            },

            hasDetailColumn: {
                type: Boolean,
                default: false,
            },

            rowsPerPage: {
                type: Number,
                default: 20,
            },

            loading: {
                type: Boolean,
                default: false,
            },

            openDetails: {
                type: Array,
                default: () => []
            },

            isStriped: {
                type: Boolean,
                default: true
            },

            omitHeader: {
                type: Boolean,
                default: true
            },

            hideArrow: {
                type: Boolean
            },

            accordion: {
                type: Boolean
            }
        },

        data() {
            return {
                listData: [],
                currentPage: 1,
                totalCount: 0,
                inputData: [],
                selectedRows: []
            };
        },

        methods: {
            toggleRow(row) {
                let selectedRow = this.selectedRows.find(r => r[this.rowId] == row[this.rowId]);

                if (this.accordion) {
                    this.closeOpenRows();
                    this.selectedRows = [];
                }

                if (selectedRow) {
                    this.selectedRows.splice(this.selectedRows.indexOf(sr => sr[this.rowId]), 1);
                } else {
                    this.selectedRows.push(row);
                }

                if (this.selectedRows.length > 0) this.$refs.list.toggleDetails(row);
                this.$emit('toggle-details', row);
            },

            closeOpenRows() {
                if (this.selectedRows.length <= 0) return;

                this.selectedRows.map(row => {
                    this.$refs.list.closeDetailRow(row);
                });
            }
        },

        watch: {
            value: {
                handler(value) {
                    if (value == null) return;

                    this.inputData = value.map(v => {
                        let newValue;

                        newValue = merge({}, v);

                        return newValue;
                    });
                },
                immediate: true
            }
        }
    };
</script>