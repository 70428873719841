var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationObserver", {
    ref: "observer",
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ valid, errors, dirty }) {
            return [
              !valid
                ? _c(
                    "ul",
                    { staticClass: "has-margin-bottom-md" },
                    _vm._l(_vm.flatten(errors), function (error, index) {
                      return _c("li", { key: index }, [
                        !_vm.hideErrors
                          ? _c("span", { staticClass: "has-text-danger" }, [
                              _vm._v(_vm._s(error)),
                            ])
                          : _vm._e(),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm._t("default", null, { valid: valid, dirty: dirty }),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }