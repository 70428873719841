import axios from './index';
const _routePrefix = '/api/reservations';

export const getReservation = async reservationId => {
  return await axios.get(`${_routePrefix}/${reservationId}`);
};

export const getReservations = async () => {
  return await axios.get(_routePrefix);
};

/**
 * Gets all unassigned tables, unassigned reservations, and assigned reservations.
 * This request is what will be used to populate the data in the fair admin view for
 * table assignment.
 **/
export const getTableAssignmentSummary = async () => {
  return await axios.get(`${_routePrefix}/table-summary`);
};

export const assignReservation = async (reservationId, roomId, tableNumber) => {
  return await axios.post(
    `${_routePrefix}/${reservationId}/assign?roomId=${roomId}&tableNumber=${tableNumber}`
  );
};

export const unassignReservation = async reservationId => {
  return await axios.post(`${_routePrefix}/${reservationId}/unassign`);
};

export const moveReservation = async (reservationId, roomId, tableNumber) => {
  return await axios.post(
    `${_routePrefix}/${reservationId}/move?roomId=${roomId}&tableNumber=${tableNumber}`
  );
};

export const swapReservations = async (sourceId, targetId) => {
  return await axios.post(`${_routePrefix}/${sourceId}/swap/${targetId}`);
};

export default 'ReservationApi';
