function setPropsData(params) {
    let message;

    // allows the user to pass along just a message or explicitly define the options for this component
    if (typeof params === 'string') message = params; 

    const defaultParams = {
        message
    };

    const propsData = Object.assign(defaultParams, params);

    return propsData;
}

const notifyMixin = {
    methods: {
        $notification(params) {
            const propsData = setPropsData(params);

            this.$buefy.notification.open(propsData);
        },

        $toast(params) {
            const propsData = setPropsData(params);

            this.$buefy.toast.open(propsData);
        },

        $snackbar(params) {
            const propsData = setPropsData(params);

            this.$buefy.snackbar.open(propsData);
        },

        $alert(params) {
            const propsData = setPropsData(params);

            this.$buefy.dialog.alert(propsData);
        },

        $confirm(params) {
            const defaultParams = {};
            
            const propsData = Object.assign(defaultParams, params);

            this.$buefy.dialog.confirm(propsData);
        },

        $prompt(params) {
            const defaultParams = {};
            
            const propsData = Object.assign(defaultParams, params);

            this.$buefy.dialog.prompt(propsData);
        }
    }
};

export default notifyMixin;
