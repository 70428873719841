import * as Api from '@/api/enum';
import * as Notify from '@/helpers/globalNotifications';

export const loadEnums = async ({ commit }) => {
    try {
        const response = await Api.getEnums();

        commit('LOAD_ENUMS', response);
    }
    catch (error) {
        Notify.Error('We had some trouble loading data in the background.');
        throw new Error('Enums were not loaded');
    }
};