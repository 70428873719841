var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "columns is-gapless is-multiline" }, [
      _c("div", { staticClass: "column is-full has-padding-left-none" }, [
        _c(
          "div",
          { staticClass: "columns is-multiline is-gapless is-tablet" },
          [
            _c("div", { staticClass: "column" }, [
              _c("div", { staticClass: "has-padding-bottom-sm" }, [
                _vm._v(" " + _vm._s(_vm.opportunity.desc) + " "),
              ]),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "column is-full" }, [
        _c("div", { staticClass: "columns is-tablet has-padding-bottom-sm" }, [
          _vm.opportunity.requiredLanguages != null
            ? _c("div", { staticClass: "column is-narrow v-align-center" }, [
                _c("strong", [_vm._v("Required Language Skills:")]),
              ])
            : _vm._e(),
          _vm.opportunity.requiredLanguages != null
            ? _c("div", { staticClass: "column is-narrow" }, [
                _vm._v(" " + _vm._s(_vm.opportunity.requiredLanguages) + " "),
              ])
            : _vm._e(),
          _vm.opportunity.preferredLanguages != null
            ? _c("div", { staticClass: "column is-narrow v-align-center" }, [
                _c("strong", [_vm._v("Preferred Language Skills:")]),
              ])
            : _vm._e(),
          _vm.opportunity.preferredLanguages != null
            ? _c("div", { staticClass: "column is-narrow" }, [
                _vm._v(" " + _vm._s(_vm.opportunity.preferredLanguages) + " "),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "column is-full has-padding-left-none" }, [
        _c(
          "div",
          { staticClass: "columns is-multiline is-gapless is-tablet" },
          [
            _c("div", { staticClass: "column is-full v-align-center" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.opportunity.type == 1
                      ? "Academic year internship information"
                      : "Post-graduate fellowship information"
                  )
                ),
              ]),
            ]),
            _c("div", { staticClass: "column" }, [
              _c("div", { staticClass: "has-padding-bottom-sm" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.opportunity.additionalEmploymentOfferings) +
                    " "
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "column is-full" }, [
        _c("div", { staticClass: "columns is-tablet has-padding-right-md" }, [
          _vm._m(0),
          _c("div", { staticClass: "column is-narrow" }, [
            _vm._v(" " + _vm._s(_vm.citizenshipDesc) + " "),
          ]),
        ]),
      ]),
    ]),
    _vm.opportunity.documentRequests.length > 0
      ? _c("div", { staticClass: "column" }, [
          _c("div", { staticClass: "columns is-multiline" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "column is-full has-padding-none" },
              _vm._l(_vm.documentRequests, function (request) {
                return _c(
                  "div",
                  {
                    key: request.unique,
                    staticClass:
                      "columns is-multiline is-gapless is-tablet is-marginless has-padding-bottom-sm",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "column is-full v-align-center" },
                      [
                        _c("div", { staticClass: "v-align-center" }, [
                          _c("span", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.getEnumDescription(
                                    "DocumentType",
                                    request.documentType
                                  )
                                )
                              ),
                            ]),
                            request.isRequired
                              ? _c("span", [_vm._v(" (required)")])
                              : _c("span", [_vm._v(" (optional)")]),
                          ]),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "column auto" }, [
                      _vm._v(" " + _vm._s(request.description) + " "),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "columns has-margin-top-sm" }, [
      _c(
        "div",
        { staticClass: "column is-full has-padding-top-none" },
        [_vm._t("default")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow v-align-center" }, [
      _c("strong", [_vm._v("U.S. Citizenship:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "column is-full is-marginless has-padding-none has-padding-bottom-sm",
      },
      [
        _c("div", { staticClass: "title is-6" }, [
          _vm._v("Documents Requested"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }