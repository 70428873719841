<template>
    <div class="columns">
        <div class="column">
            <b-field label="Organization Type">
                {{ activeEmployer.organizationType.description }}
            </b-field>
        </div>
        <div class="column">
            <b-field label="Practice Types">
                <expandible-unordered-list :elements="practiceTypeArray" />
            </b-field>
        </div>
        <div class="column">
            <b-field label="Subject Areas">
                <expandible-unordered-list :elements="subjectAreaArray" />
            </b-field>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'OrganizationSummary',

        computed: {
            ...mapGetters(['activeEmployer']),

            practiceTypeArray() {
                if (!this.activeEmployer.practiceTypes) return [];
                
                return this.activeEmployer.practiceTypes.map(pt => pt.description);
            },

            subjectAreaArray() {
                if (!this.activeEmployer.subjectAreas) return [];

                return this.activeEmployer.subjectAreas.map(pt => pt.description);
            }
        }
    };
</script>