import App from './App.vue';
import router from '@/router';
import axios from 'axios';
import Buefy from 'buefy';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@/assets/fonts/fontAwesome';
import '@/plugins/validation';

import '@/components/shared/_globals';
import '@/filters/index';
import '@/views/shared/_globals';

require('./assets/css/Site.scss');

import NotifyMixin from '@/mixins/NotifyMixin';

Vue.config.productionTip = false;

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueAxios, axios);

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultIconComponent: 'font-awesome-icon'
});

Vue.mixin(NotifyMixin);

new Vue({
  router: router.router,
  store: router.store,
  render: h => h(App)
}).$mount('#app');
