<template>
    <card title="Representatives" :showButton="!readonly"
        :disableButton="addingRep || editingRep || readonly"
        :showActionContent="addingRep"
        @header-action="toggleAdd">
        <template v-slot:action>
            <representative-form v-if="addingRep" @save="save" @cancel="toggleAdd" />
        </template>
        <List rowId="nonUserPersonId" :paginated="false" isStriped :value="inputValue">
            <template v-slot:content="props">
                <div class="columns is-gapless">
                    <div class="column is-full has-margin-top-xs">
                        <editable-component :metadata="props.row" wrapping-tag="div" class="columns">
                            <template v-slot:read="props">
                                <div class="column is-four-fifths">
                                    <span class="has-text-weight-normal">
                                        {{ props.metadata.firstName }}
                                    </span>
                                    <span class="has-text-weight-normal">
                                        {{ props.metadata.lastName }}
                                    </span>
                                    <span class="has-text-weight-light is-italic">
                                        &nbsp;- {{ props.metadata.jobTitle }}
                                    </span>
                                </div>
                                <div class="column is-one-fifths has-text-right">
                                    <EditButton @click-edit="toggleEdit(props.edit)" :disabled="addingRep || editingRep || readonly" />
                                    <TrashButton @click-delete="deleteRep(props.metadata.personId)" :disabled="readonly" />
                                </div>
                            </template>
                            <template v-slot:write="props">
                                <representative-form 
                                    saveText="Save"
                                    :existingRep="props.metadata" 
                                    @save="saveEdit($event, props.edit)" 
                                    @edit="props.edit" 
                                    @cancel="toggleEdit(props.edit)" />
                            </template>
                        </editable-component>
                    </div>
                </div>
            </template>
            <template v-slot:empty>
                No representatives have been specified
            </template>
        </List>
    </card>
</template>

<script>
    import unionBy from 'lodash/unionBy';

    import FormInputMixin from '@/mixins/FormInputMixin';
    import RepresentativeForm from '@/components/employer/RepresentativeForm';
    import TrashButton from '@/components/actions/Trash';
    import EditButton from '@/components/actions/Edit';

    export default {
        name: 'RepresentativeList',

        mixins: [FormInputMixin],

        components: {
            RepresentativeForm,
            TrashButton,
            EditButton
        },

        props: {
            value: [Array],
            readonly: Boolean
        },

        data() {
            return {
                addingRep: false,
                editingRep: false,
                inputValue: this.value != null ? this.value : []
            };
        },

        methods: {
            toggleAdd() {
                if (!this.editingRep) this.addingRep = !this.addingRep;
            },

            save(representative) {
                this.toggleAdd();
                this.inputValue.push(representative);
                this.updateValue();
            },

            saveEdit(representative, func = () => null) {
                func();
                this.toggleEdit();
                this.inputValue = unionBy([representative], this.inputValue, 'personId');
                this.updateValue();
            },

            deleteRep(id) {
                this.inputValue = this.inputValue.filter(val => val.personId != id);
                this.updateValue();
            },

            toggleEdit(func = () => null) {
                func();
                if (!this.addingRep) this.editingRep = !this.editingRep;
            }
        },

        watch: {
            value(newVal) {
                this.inputValue = newVal != null ? newVal : [];
            }
        }
    };
</script>
