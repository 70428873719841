var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    {
      attrs: {
        title: "Representatives",
        showButton: !_vm.readonly,
        disableButton: _vm.addingRep || _vm.editingRep || _vm.readonly,
        showActionContent: _vm.addingRep,
      },
      on: { "header-action": _vm.toggleAdd },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function () {
            return [
              _vm.addingRep
                ? _c("representative-form", {
                    on: { save: _vm.save, cancel: _vm.toggleAdd },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("List", {
        attrs: {
          rowId: "nonUserPersonId",
          paginated: false,
          isStriped: "",
          value: _vm.inputValue,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function (props) {
              return [
                _c("div", { staticClass: "columns is-gapless" }, [
                  _c(
                    "div",
                    { staticClass: "column is-full has-margin-top-xs" },
                    [
                      _c("editable-component", {
                        staticClass: "columns",
                        attrs: { metadata: props.row, "wrapping-tag": "div" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "read",
                              fn: function (props) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "column is-four-fifths" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "has-text-weight-normal",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(props.metadata.firstName) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "has-text-weight-normal",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(props.metadata.lastName) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "has-text-weight-light is-italic",
                                        },
                                        [
                                          _vm._v(
                                            "  - " +
                                              _vm._s(props.metadata.jobTitle) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "column is-one-fifths has-text-right",
                                    },
                                    [
                                      _c("EditButton", {
                                        attrs: {
                                          disabled:
                                            _vm.addingRep ||
                                            _vm.editingRep ||
                                            _vm.readonly,
                                        },
                                        on: {
                                          "click-edit": function ($event) {
                                            return _vm.toggleEdit(props.edit)
                                          },
                                        },
                                      }),
                                      _c("TrashButton", {
                                        attrs: { disabled: _vm.readonly },
                                        on: {
                                          "click-delete": function ($event) {
                                            return _vm.deleteRep(
                                              props.metadata.personId
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "write",
                              fn: function (props) {
                                return [
                                  _c("representative-form", {
                                    attrs: {
                                      saveText: "Save",
                                      existingRep: props.metadata,
                                    },
                                    on: {
                                      save: function ($event) {
                                        return _vm.saveEdit($event, props.edit)
                                      },
                                      edit: props.edit,
                                      cancel: function ($event) {
                                        return _vm.toggleEdit(props.edit)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
          {
            key: "empty",
            fn: function () {
              return [_vm._v(" No representatives have been specified ")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }