import Vue from 'vue';
import { extend, setInteractionMode, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import upperFirst from 'lodash/upperFirst';

setInteractionMode('eager');

extend('required', {
    ...required,
    message: fieldName => {
        return `The '${upperFirst(fieldName)}' field is required`;
    }
});

// Validation components
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);