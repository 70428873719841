import * as Api from '@/api/employer';
import * as Notifier from '@/helpers/globalNotifications';
import merge from 'lodash/merge';

/**
 * Employer Actions
 */

export const loadActiveEmployer = async ({ commit, getters }) => {
    try {
        if (getters.activeEmployerId === null || getters.activeEmployerId === undefined) return;

        commit('SET_EMPLOYER_LOADING', true);
        let employer = await Api.getEmployer(getters.activeEmployerId);

        commit('ADD_EMPLOYER', employer.data);
    } catch (error) {
        Notifier.Error('We had some trouble deleting that employer.');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};

export const addEmployer = async ({ commit }, employer ) => {
    try {
        commit('SET_EMPLOYER_LOADING', true);
        let employerDto = merge({}, employer);
        
        employerDto.organizationType = employerDto.organizationType.id;
        employerDto.practiceTypes = employerDto.practiceTypes.map(pt => pt.id);
        employerDto.subjectAreas = employerDto.subjectAreas.map(pt => pt.id);

        const response = await Api.addEmployer(employerDto);
                
        commit('ADD_EMPLOYER', response.data.employer);
        commit('ADD_EMPLOYER_FAIR', response.data.employerFair);
    } catch (error) {
        Notifier.Error('We had some trouble adding that employer.');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};

export const updateEmployer = async ({ commit, getters }, employer) => {
    try {
        if (getters.activeEmployerId === null || getters.activeEmployerId === undefined) return;

        if (!getters.employersLoaded)
            throw 'Employers have not been loaded';
        
        commit('SET_EMPLOYER_LOADING', true);

        const employerId = getters.activeEmployerId;
        const response = await Api.updateEmployer(employerId, employer);
        
        commit('UPDATE_EMPLOYER', response.data);
    } catch (error) {
        
        Notifier.Error('An error occurred when updating the employer.');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};

/**
 * EmployerFair Actions
 */

export const loadEmployerFairs = async ({ commit }) => {
    try {
        commit('SET_EMPLOYER_LOADING', true);
        let employer = await Api.getEmployerFairs();

        commit('LOAD_EMPLOYERS', employer.data.employers);
        commit('LOAD_EMPLOYER_FAIRS', employer.data.employerFairs);
    } catch (error) {
        Notifier.Error('We had trouble loading employer fair data.');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};


/**
 * Load employer fairs for the Active Employer
 * 
 * @param {*} Vuex objects
 */
export const loadActiveEmployerFair = async ({ commit, getters, dispatch }) => {
    try {
        if (getters.activeEmployerFairId == null) return;
        
        if (!getters.employersLoaded) {
            await dispatch('loadActiveEmployer');
        }

        commit('SET_EMPLOYER_LOADING', true);
        
        const employerId = getters.activeEmployerId;
        const activeFairId = getters.activeFairId;
        const response = await Api.getEmployerFair(employerId, activeFairId);
        
        commit('ADD_EMPLOYER_FAIR', response.data);
    } catch (error) {
        Notifier.Error('We had some trouble loading the list of employers.');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};

export const setActiveEmployerFair = ({ commit, getters }, employerFairId) => {
    try {
        commit('SET_EMPLOYER_LOADING', true);
        let employerFair = getters.employerFairs.find(ef => ef.employerFairId === employerFairId);

        commit('SET_ACTIVE_EMPLOYER_FAIR', employerFairId);
        commit('SET_ACTIVE_EMPLOYER', employerFair.employerId);
    } catch (error) {
        Notifier.Error('We had trouble setting the active employer.');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};

export const submitEmployerFairForApproval = async ({ commit, getters }) => {
    try {
        commit('SET_EMPLOYER_LOADING', true);

        const employerId = getters.activeEmployerId;
        const activeFairId = getters.activeFairId;

        await Api.submitEmployerFairForApproval(employerId, activeFairId);

        commit('SET_ACTIVE_EMPLOYER_FAIR_PARTICIPANT_STATUS');
        Notifier.ToastSuccess('Your registration has been successfully submitted for review!');
    } catch (error) {
        Notifier.Error('We had trouble submitting your registration for review.');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};

export const remindEmployerFair = async ({ commit, getters }) =>
{
    try {
        const employerId = getters.activeEmployerId;
        const activeFairId = getters.activeFairId;
        commit('SET_EMPLOYER_LOADING', true);
        await Api.remindEmployerFair(employerId, activeFairId);
        Notifier.ToastSuccess('Reminder Notification sent to Fair Contact');
    } catch (error) {
        Notifier.Error('We encountered an issue sending the notification.');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};

export const approveEmployerFair = async ({ commit, getters }) => {
    try {
        commit('SET_EMPLOYER_LOADING', true);

        const employerId = getters.activeEmployerId;
        const activeFairId = getters.activeFairId;
        const response = await Api.approveEmployerFair(employerId, activeFairId);

        commit('UPDATE_EMPLOYER_FAIR', response.data);
        Notifier.ToastSuccess(`Success: ${getters.activeEmployer.name} approved.`);
    } catch (error) {
        Notifier.Error('We encountered an error while trying to approve that employer.');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};

export const rejectEmployerFair = async ({ commit, getters }) => {
    try {
        commit('SET_EMPLOYER_LOADING', true);

        const employerId = getters.activeEmployerId;
        const activeFairId = getters.activeFairId;
        const response = await Api.rejectEmployerFair(employerId, activeFairId);

        commit('UPDATE_EMPLOYER_FAIR', response.data);
        Notifier.ToastSuccess(`Success: ${getters.activeEmployer.name} rejected.`);
    } catch (error) {
        Notifier.Error('We encountered an error while trying to reject that employer');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};

/**
 * EmployerFair Participation Plan Actions
 */

export const loadEmployerFairParticipationPlan = async ({ commit, getters }) => {
    try {
        commit('SET_EMPLOYER_LOADING', true);
        
        const employerId = getters.activeEmployerId;
        const activeFairId = getters.activeFairId;
        const response = await Api.getEmployerFairParticipation(employerId, activeFairId);
        const participation = response.data;

        commit('LOAD_PARTICIPATION_PLAN', participation);
    } catch (error) {
        Notifier.Error('We had some trouble loading your participation plan');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};

export const createParticipationPlan = async ({ commit, getters }, participation) => {
    try {
        commit('SET_EMPLOYER_LOADING', true);

        const employerId = getters.activeEmployerId;
        const activeFairId = getters.activeFairId;
        var response = await Api.addEmployerFairParticipation(employerId, activeFairId, participation);

        commit('LOAD_PARTICIPATION_PLAN', response.data);
        Notifier.ToastSuccess('Participation plan was successfully updated!');
    } catch (error) {
        Notifier.Error('We had some trouble saving the organization\'s participation plan.');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};

export const updateEmployerFairParticipation = async ({ commit, getters }, participation) => {
    try {
        commit('SET_EMPLOYER_LOADING', true);

        const employerId = getters.activeEmployerId;
        const activeFairId = getters.activeFairId;
        var response = await Api.updateEmployerFairParticipation(employerId, activeFairId, participation);

        commit('LOAD_PARTICIPATION_PLAN', response.data);
        Notifier.ToastSuccess('Participation plan was successfully updated!');
    } catch (error) {
        Notifier.Error('We had some trouble saving the organization\'s participation plan.');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};

export const updateActiveEmployerFairParticipationStatus = ({ commit, getters }, statusId) => {
    try {
        commit('SET_EMPLOYER_LOADING', true);

        let activeEmployerFair = merge({}, getters.activeEmployerFair);

        if (activeEmployerFair != null) {
            activeEmployerFair.participationStatus = statusId;

            commit('UPDATE_EMPLOYER_FAIR', activeEmployerFair);
        }
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};

/**
 * EmployerFair Contact Actions
 */

export const getEmployerContacts = async ({ commit, getters }) => {
    try {
        commit('SET_EMPLOYER_LOADING', true);

        const employerId = getters.activeEmployerId;
        const activeFairId = getters.activeFairId;
        const response = await Api.getEmployerFairContacts(employerId, activeFairId);
        
        commit('UPDATE_EMPLOYER_FAIR_CONTACTS', response.data);
    } catch (error) {
        Notifier.Error('Error retrieving organization\'s contacts.');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};

export const updateEmployerContacts = async ({ commit, getters }, { contacts }) => {
    try {
        commit('SET_EMPLOYER_LOADING', true);

        const employerId = getters.activeEmployerId;
        const activeFairId = getters.activeFairId;
        const response = await Api.updateEmployerFairContacts(employerId, activeFairId, contacts);

        commit('UPDATE_EMPLOYER_FAIR_CONTACTS', response.data);
        Notifier.ToastSuccess('Your contacts were successfully updated!');
    } catch (error) {
        Notifier.Error('We had some trouble saving the organization\'s contacts.');
    } finally {
        commit('SET_EMPLOYER_LOADING', false);
    }
};
