/* STORE: Users */
import * as actions from './actions';
import * as getters from './getters';

import unionBy from 'lodash/unionBy';

const state = {
    users: [],
    isLoading: false
};

const mutations = {
    LOAD_USER_REGISTRATIONS(state, users) {
        state.users = users;
    },

    SET_LOADING_STATUS(state, isLoading) {
        state.isLoading = isLoading;
    },

    APPROVE_USER_REG(state, userGuid) {
        state.users = unionBy([{guid: userGuid}], state.users, 'guid');
    },

    REJECT_USER_REG(state, userGuid) {
        state.users = state.users.filter(u => u.guid != userGuid);
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
