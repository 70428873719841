<template>
    <InputControl
        v-model="inputValue"
        @input="updateValue"
        type="email"
        :horizontal="horizontal"
        :toolTip="toolTip"
        :centerText="centerText"
        :wrapHeader="wrapHeader"
        :leftColumnCss="leftColumnCss"
        :rightColumnCss="rightColumnCss"
        :placeholder="label"
        :controlLabel="label"
        :readonly="readonly"
        v-bind="$attrs" />
</template>

<script>
    import AlignmentControlPropsMixin from '@/mixins/AlignmentControlPropsMixin';
    import FormControlPropsMixin from '@/mixins/FormControlPropsMixin';
    import FormInputMixin from '@/mixins/FormInputMixin';
    import InputControl from '@/components/formControls/base/InputControl';
    
    export default {
        name: 'EmailInputControl',

        mixins: [ AlignmentControlPropsMixin, FormControlPropsMixin, FormInputMixin ],

        components: {
            InputControl
        },

        props: {
            label: {
                type: String,
                default: 'Email'
            },
            readonly: Boolean
        }
    };
</script>