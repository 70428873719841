var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormControl",
    {
      attrs: {
        horizontal: _vm.horizontal,
        controlLabel: _vm.controlLabel,
        toolTip: _vm.toolTip,
        centerText: _vm.centerText,
        wrapHeader: _vm.wrapHeader,
        leftColumnCss: _vm.leftColumnCss,
        rightColumnCss: _vm.rightColumnCss,
      },
    },
    [
      _c(
        "b-field",
        [
          _c(
            "b-taginput",
            _vm._b(
              {
                attrs: {
                  autocomplete: "",
                  field: "description",
                  data: _vm.filteredTags,
                  placeholder: _vm.placeholder,
                  disabled: _vm.disabled,
                },
                on: { typing: _vm.getFilteredTags, input: _vm.updateValues },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " + _vm._s(props.option[_vm.valueColumn]) + " "
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.inputValue,
                  callback: function ($$v) {
                    _vm.inputValue = $$v
                  },
                  expression: "inputValue",
                },
              },
              "b-taginput",
              _vm.$attrs,
              false
            ),
            [
              _c("template", { slot: "empty" }, [
                _vm._v(" There are no items "),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "b-field",
        [
          !_vm.disabled
            ? _c(
                "b-select",
                {
                  attrs: { multiple: "", "native-size": "8", expanded: "" },
                  on: { input: _vm.updateSelect },
                  model: {
                    value: _vm.selectedOptions,
                    callback: function ($$v) {
                      _vm.selectedOptions = $$v
                    },
                    expression: "selectedOptions",
                  },
                },
                _vm._l(_vm.unselectedData, function (option) {
                  return _c(
                    "option",
                    {
                      key: option[_vm.idColumn],
                      domProps: { value: option[_vm.idColumn] },
                    },
                    [_vm._v(" " + _vm._s(option[_vm.valueColumn]) + " ")]
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }