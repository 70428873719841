// Views
import DefaultHomepageView from '@/views/Index';

import PageNotFound from '@/views/404';

// Routes
import attendeeRoutes from './attendee';
import authRoutes from './auth';
import employerRegistrationRoutes from './employer/registration';
import employerRoutes from './employer';
import schoolRoutes from './school';
import fairAdminRoutes from './fairAdmin';
import fairSchedule from './schedule';

let routes = [
    {
        path: '/',
        name: 'root',
        meta: {
            isPublic: true
        },
        component: DefaultHomepageView
    },
    {
        path: '*',
        component: PageNotFound,
        meta: {
            isPublic: true
        }
    }
];

routes.push(fairSchedule);
routes.push(attendeeRoutes);
routes.push(authRoutes);
routes.push(employerRegistrationRoutes);
routes.push(employerRoutes);
routes.push(schoolRoutes);
routes.push(fairAdminRoutes);

export default routes;
