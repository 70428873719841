<template>
    <FormControl
        :horizontal="horizontal"
        :leftColumnCss="questionCss"
        :rightColumnCss="actionCss"
        :controlLabel="question"
        :centerText="centerText"
        :toolTip="toolTip">
        <slot></slot>
    </FormControl>
</template>

<script>
    import AlignmentControlPropsMixin from '@/mixins/AlignmentControlPropsMixin';
    import FormControl from '@/components/formControls/base/FormControl';

    export default {
        name: 'QuestionControl',

        mixins: [ AlignmentControlPropsMixin ],

        components: {
            FormControl
        },

        props: {
            question: {
                type: String
            },
            toolTip: {
                type: String,
                default: ''
            },
            center: {
                type: Boolean,
                default: false
            },
            questionCss: {
                type: String,
                default: 'column has-margin-bottom-auto'
            },
            actionCss: {
                type: String,
                default: 'column'
            },
            value: String
        }
    };
</script>