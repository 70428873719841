<template>
    <a class="is-borderless has-padding-none has-margin-none inherit-background-color v-align-center"
       @click="handleClick">
        <b-icon type="is-black" :icon="icon" size="is-small" />
        <span v-show="showText">{{displayText}}</span>
    </a>
</template>

<script>
    export default {
        name: 'MoreLessButton',

        props: {
            disabled: {
                type: Boolean
            },

            showText: { // pass this value in as 'false' in order to hide the more/less text
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                icon: 'caret-down',
                displayText: '(more)'
            };
        },

        methods: {
            handleClick() {
                if (!this.disabled) {
                    this.$emit('click-more-less');
                    this.toggle();
                }
            },

            toggle() {
                this.icon = this.icon == 'caret-down' ? 'caret-up' : 'caret-down';
                this.displayText = this.displayText == '(more)' ? '(less)' : '(more)';
            }
        }
    };
</script>
