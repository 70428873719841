<template>
    <section>
        <b-field grouped>
            <b-field>
                <b-select placeholder="Employer Status" v-model="selectedStatus" @input="filter">
                    <option v-for="(status, index) in participationStatuses"
                            :value="status"
                            :key="index">
                        {{ status.display }}
                    </option>
                </b-select>
            </b-field>
        </b-field>

        <list rowId="employerFairId"
              hasDetailColumn
              accordion
              :value="filteredEmployerFairs"
              @toggle-details="setActiveEmployer">
            <template v-slot:content="props">
                <employer-component :employerName="props.row.employerName" />
            </template>

            <template v-slot:detail="props">
                <employer-detail v-if="showDetails"
                                 :employerFair="props.row"
                                 @approve-employer="approve"
                                 @reject-employer="reject"
                                 @remind-employer="remind"
                                 @details-open="setActiveEmployer" />
            </template>

            <template v-slot:empty>
                No matching Employers
            </template>
        </list>
    </section>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    import EmployerComponent from '@/components/fairAdmin/EmployerComponent.vue';
    import EmployerDetail from '@/components/fairAdmin/EmployerDetail.vue';
    import { ParticipantStatusTypes } from '@/helpers/staticValues';

    export default {
        name: 'EmployerApprovalList',

        components: {
            EmployerComponent,
            EmployerDetail
        },

        async mounted() {
            await this.loadEmployerFairs();
            this.filter();
            this.participationStatuses.push({ display: 'Incomplete', value: 99 }); // This is a fake value to represent something that isn't an actual ParticipationStatus. This component only!
        },

        data() {
            return {
                openDetails: [],
                showDetails: false,
                loadingData: false,
                participationStatuses: ParticipantStatusTypes.types,
                selectedStatus: ParticipantStatusTypes.types.find(s => s.value == 0),
                filteredEmployerFairs: []
            };
        },

        methods: {
            ...mapActions([
                'loadEmployerFairs',
                'approveEmployerFair',
                'rejectEmployerFair',
                'remindEmployerFair',
                'setActiveEmployerFair'
            ]),

            setActiveEmployer(employerFair) {
                this.showDetails = false;

                this.setActiveEmployerFair(employerFair.employerFairId).then(() => {
                    this.showDetails = true;
                });
            },

            async approve() {
                await this.approveEmployerFair();
                this.filter();
            },

            async reject() {
                await this.rejectEmployerFair();
                this.filter();
            },

            remind() {
                this.remindEmployerFair();
            },

            filter() {
                if (this.selectedStatus.value == 99) {
                    this.filteredEmployerFairs = this.incompleteEmployerFairRegistrations;
                } else {
                    this.filteredEmployerFairs = this.completedEmployerFairRegistrations.filter(ef => ef.participationStatus == this.selectedStatus.value);
                }
            }
        },

        computed: {
            ...mapGetters([
                'completedEmployerFairRegistrations',
                'incompleteEmployerFairRegistrations',
                'getEnumDescription',
                'activeEmployer'
            ]),
        }
    };
</script>
