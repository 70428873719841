var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "buttons" },
      _vm._l(_vm.documents, function (document) {
        return _c("uploaded-document", {
          key: document.id,
          attrs: { documentId: document.id, type: document.documentType },
        })
      }),
      1
    ),
    _c(
      "button",
      {
        staticClass: "button is-small is-link",
        on: { click: _vm.editApplication },
      },
      [_vm._v(" Edit Application ")]
    ),
    _vm._v("   "),
    _c(
      "button",
      {
        staticClass: "button is-small is-danger",
        on: { click: _vm.cancelApplication },
      },
      [_vm._v(" Delete Application ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }