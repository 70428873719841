var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.viewonly
        ? _c(
            "div",
            { staticStyle: { "padding-bottom": "1em" } },
            [
              _c(
                "b-notification",
                { attrs: { type: "is-info", closable: false } },
                [
                  _vm._v(
                    " Only the Fair Contact will receive correspondence regarding the Fair. "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._t("default"),
      !_vm.splitview
        ? _c(
            "div",
            [
              _c(
                "Card",
                { attrs: { title: "Fair Contact" } },
                [
                  _c("NonUserPersonView", {
                    attrs: { value: _vm.userDetails },
                  }),
                ],
                1
              ),
              _c("br"),
              _c(
                "Card",
                { attrs: { title: "Internship Coordinator" } },
                [
                  _vm.viewonly
                    ? _c("NonUserPersonView", {
                        attrs: { value: _vm.contacts.internshipCoordinator },
                      })
                    : _c(
                        "FormValidator",
                        { ref: "internshipContactForm" },
                        [
                          _c("NonUserPersonEdit", {
                            attrs: { readonly: _vm.readonly },
                            model: {
                              value: _vm.contacts.internshipCoordinator,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contacts,
                                  "internshipCoordinator",
                                  $$v
                                )
                              },
                              expression: "contacts.internshipCoordinator",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c("br"),
              _c(
                "Card",
                { attrs: { title: "Hiring Attorney" } },
                [
                  _vm.viewonly
                    ? _c("NonUserPersonView", {
                        attrs: { value: _vm.contacts.hiringAttorney },
                      })
                    : _c(
                        "FormValidator",
                        { ref: "hiringAttorneyContactForm" },
                        [
                          _c("NonUserPersonEdit", {
                            attrs: { readonly: _vm.readonly },
                            model: {
                              value: _vm.contacts.hiringAttorney,
                              callback: function ($$v) {
                                _vm.$set(_vm.contacts, "hiringAttorney", $$v)
                              },
                              expression: "contacts.hiringAttorney",
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c("br"),
              _c("RepresentativeList", {
                attrs: { readonly: _vm.readonly || _vm.viewonly },
                model: {
                  value: _vm.contacts.otherRepresentatives,
                  callback: function ($$v) {
                    _vm.$set(_vm.contacts, "otherRepresentatives", $$v)
                  },
                  expression: "contacts.otherRepresentatives",
                },
              }),
            ],
            1
          )
        : _c("div", [
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c(
                    "Card",
                    { attrs: { title: "Fair Contact" } },
                    [
                      _c("NonUserPersonView", {
                        attrs: { value: _vm.userDetails },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("br"),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c(
                    "Card",
                    { attrs: { title: "Internship Coordinator" } },
                    [
                      _vm.viewonly
                        ? _c("NonUserPersonView", {
                            attrs: {
                              value: _vm.contacts.internshipCoordinator,
                              stack: "",
                            },
                          })
                        : _c(
                            "FormValidator",
                            { ref: "internshipContactFormStack" },
                            [
                              _c("NonUserPersonEdit", {
                                attrs: { readonly: _vm.readonly, stack: "" },
                                model: {
                                  value: _vm.contacts.internshipCoordinator,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contacts,
                                      "internshipCoordinator",
                                      $$v
                                    )
                                  },
                                  expression: "contacts.internshipCoordinator",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c(
                    "Card",
                    { attrs: { title: "Hiring Attorney" } },
                    [
                      _vm.viewonly
                        ? _c("NonUserPersonView", {
                            attrs: {
                              value: _vm.contacts.hiringAttorney,
                              stack: "",
                            },
                          })
                        : _c(
                            "FormValidator",
                            { ref: "hiringAttorneyContactFormStack" },
                            [
                              _c("NonUserPersonEdit", {
                                attrs: { readonly: _vm.readonly, stack: "" },
                                model: {
                                  value: _vm.contacts.hiringAttorney,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contacts,
                                      "hiringAttorney",
                                      $$v
                                    )
                                  },
                                  expression: "contacts.hiringAttorney",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                { staticClass: "column" },
                [
                  _c("RepresentativeList", {
                    attrs: { readonly: _vm.readonly || _vm.viewonly },
                    model: {
                      value: _vm.contacts.otherRepresentatives,
                      callback: function ($$v) {
                        _vm.$set(_vm.contacts, "otherRepresentatives", $$v)
                      },
                      expression: "contacts.otherRepresentatives",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }