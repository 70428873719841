<template>
    <div>
        <strong>{{ application.opportunityTitle }}</strong>
        <br />{{ application.employerName }}
        <br />Ranking: {{ application.ranking }}


    </div>
</template>

<script>
    export default {
        name: 'AttendeeApplication',

        props: {
            application: {
                type: Object,
                required: true
            }
        }
    };

</script>