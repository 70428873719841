var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-field",
        { attrs: { grouped: "" } },
        [
          _c(
            "b-field",
            [
              _c(
                "b-select",
                {
                  attrs: { placeholder: "User Status" },
                  on: {
                    input: function ($event) {
                      return _vm.clearSelectedRow()
                    },
                  },
                  model: {
                    value: _vm.selectedStatus,
                    callback: function ($$v) {
                      _vm.selectedStatus = $$v
                    },
                    expression: "selectedStatus",
                  },
                },
                _vm._l(_vm.userStatuses, function (status, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: status.value } },
                    [_vm._v(" " + _vm._s(status.display) + " ")]
                  )
                }),
                0
              ),
              _c(
                "p",
                { staticClass: "control" },
                [
                  _vm.selectedStatus != null
                    ? _c("Close", {
                        on: {
                          close: function ($event) {
                            return _vm.clearFilter()
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-table",
        {
          ref: "list",
          attrs: {
            detailed: "",
            striped: "",
            narrowed: "",
            hoverable: "",
            "detail-key": _vm.detailKey,
            "show-detail-icon": false,
            data: _vm.filteredView,
            loading: _vm.isLoading,
          },
          on: {
            "details-open": function ($event) {
              return _vm.$emit("toggle-row")
            },
            "details-close": function ($event) {
              return _vm.$emit("toggle-row")
            },
            select: function ($event) {
              return _vm.$emit("toggle-row")
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function (props) {
                  return [
                    _vm._t(
                      "default",
                      function () {
                        return [
                          _c(
                            "b-table-column",
                            {
                              attrs: {
                                field: "first_name",
                                label: "First Name",
                              },
                            },
                            [_vm._v(" " + _vm._s(props.row.first_name) + " ")]
                          ),
                          _c(
                            "b-table-column",
                            {
                              attrs: { field: "last_name", label: "Last Name" },
                            },
                            [_vm._v(" " + _vm._s(props.row.last_name) + " ")]
                          ),
                          _c(
                            "b-table-column",
                            { attrs: { field: "email", label: "Email" } },
                            [_vm._v(" " + _vm._s(props.row.email) + " ")]
                          ),
                        ]
                      },
                      { row: { ...props.row } }
                    ),
                    _vm.showStatusRow
                      ? _c(
                          "b-table-column",
                          { attrs: { field: "status", label: "Status" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("ToDisplay")(
                                    props.row.status,
                                    _vm.userStatuses
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "b-table-column",
                      {
                        staticClass: "has-text-right",
                        attrs: { label: _vm.actionLabel },
                      },
                      [_vm._t("actions", null, { row: { ...props.row } })],
                      2
                    ),
                    _c("b-table-column", [
                      _c("div", { staticClass: "column has-text-right" }, [
                        _c(
                          "a",
                          [
                            _c("b-icon", {
                              class: props.row.open ? "is-expanded" : "",
                              attrs: { icon: "angle-right" },
                              nativeOn: {
                                click: function ($event) {
                                  if ($event.target !== $event.currentTarget)
                                    return null
                                  $event.stopPropagation()
                                  return _vm.toggleRow(props.row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                },
              },
              {
                key: "detail",
                fn: function (props) {
                  return [
                    _vm._t(
                      "detail",
                      function () {
                        return [_vm._v("Default detail section")]
                      },
                      { row: { ...props.row } }
                    ),
                  ]
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _c(
            "template",
            { slot: "empty" },
            [
              _vm._t("empty", function () {
                return [
                  _c("section", { staticClass: "section" }, [
                    _c("p", [_vm._v("No users.")]),
                  ]),
                ]
              }),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }