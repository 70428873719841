import { getUserRoles, hasUserBeenApproved } from '@/helpers/userRouting';

export const userHomepage = state => state.homepage;

export const hasHomepage = state => state.homepage != '';

export const userRoles = () => getUserRoles();

export const isApproved = () => hasUserBeenApproved();

export const userDetails = (state, getters) => {
    return {
        firstName: getters.oidcUser.given_name,
        lastName: getters.oidcUser.family_name,
        name: getters.oidcUser.name,
        email: state.user.email,
        phone: state.user.phone
    };
};
