export default {
    data() {
        return {
            dirty: false
        };
    },

    mounted() {
        this.trackFormState();
    },

    beforeRouteLeave(to, from, next) {

        if (this.dirty) {
            this.$confirm({
                title: 'Confirm',
                message: 'You have unsaved changes, are you sure you want to leave?',
                onConfirm: () => next(),
                onCancel: () => next(from)
            });
        }
        else {
            next();
        }
    },

    methods: {
        trackFormState() {
            this.$watch(
                () => {
                    // make sure you set up ref="form" on whatever form you aim to track
                    return this.$refs.form.dirty;
                },
                newVal => {
                    this.dirty = newVal;
                }
            );
        },

        async validateAndSave(callback) {
            const valid = await this.$refs.form.validate();

            if (valid) {
                // the mixin expects the component it is used in to have an async save method
                await callback();
                this.$refs.form.reset();
            }
        },
    },
};