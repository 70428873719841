<template>
    <div>
        <strong>{{ appointment.employerName }}</strong>
        <br />{{ appointment.opportunityTitle }}
        <br />Date: {{ appointment.startTime }}
        <br />Location: {{ appointment.location }}
    </div>
</template>

<script>

    export default {

        name: 'AttendeeAppointment',

        props: {
            appointment: {
                type: Object,
                required: true
            }
        }

    };

</script>