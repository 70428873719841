export default {
    props: {
        horizontal: {
            type: Boolean,
            default: true
        },
        centerText: {
            type: Boolean,
            default: false
        },
        leftColumnCss: {
            type: String,
            default: ''
        },
        rightColumnCss: {
            type: String,
            default: ''
        },
        wrapHeader: {
            type: Boolean,
            default: true
        }
    }
};
