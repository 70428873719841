<template>
    <MainRightSidebar>
        <Card title="Seat Summary">
            <seat-summary/>
        </Card>
    </MainRightSidebar>
</template>

<script>
    import SeatSummary from '@/components/fairAdmin/SeatSummary';

    export default {
        name: 'EmployerSidebar',

        components: { SeatSummary }
    };
</script>