<template>
    <div v-if="value === null">
        <span class="content is-italic">No Contact Details Provided</span>
    </div>
    <div v-else-if="!stack || singleLine">
        <div class="columns is-desktop is-multiline">
            <div class="column is-narrow">
                <label class="label">Name:</label>
            </div>
            <div class="column has-text-left">
                <span class="content">
                    {{ value.lastName }}, {{ value.firstName }}
                </span>
            </div>
            <div class="column is-narrow">
                <label class="label">
                    Phone:
                </label>
            </div>
            <div class="column has-text-left">
                <span class="content">
                    {{ value.phone }}
                </span>
            </div>
        </div>
        <div class="columns is-desktop is-multiline">
            <div class="column is-narrow">
                <label class="label">Email:</label>
            </div>
            <div class="column has-text-left">
                <span class="content">
                    {{ value.email }}
                </span>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="columns is-desktop is-multiline">
            <div class="column is-half is-narrow">
                <span class="has-text-weight-bold has-margin-right-sm">Name:</span>
                <span class="content">{{ value.lastName }}, {{ value.firstName }}</span>
            </div>
            <div class="column is-narrow">
                <span class="has-text-weight-bold has-margin-right-sm">Phone:</span>
                <span class="content">{{ value.phone }}</span>
            </div>
        </div>
        <div class="columns is-desktop is-multiline">
            <div class="column is-narrow">
                <span class="has-text-weight-bold has-margin-right-sm">Email:</span>
                <span class="content">{{ value.email }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NonUserPersonView',

        props: {
            value: {
                validator: (val) => val == null || typeof val == 'object'
            },
            stack: {
                type: Boolean,
                default: false
            },
            singleLine: {
                type: Boolean,
                default: true
            }
        }
    };
</script>