import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import VuexPersistence from 'vuex-persist';

const requireComponent = require.context('.', true, /store\.js$/);
const oidcSettings = JSON.parse(process.env.VUE_APP_OIDC_CONFIG);

let modules = {};

modules['oidcStore'] = vuexOidcCreateStoreModule(oidcSettings, {
    dispatchEventsOnWindow: true
});

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage,
    reducer: state => ({
        EnumStore: state.EnumStore,
        UserSessionStore: state.UserSessionStore
    }),
    filter: mutation => mutation.type == 'LOAD_ENUMS' || 'SET_USER_HOMEPAGE'
});

requireComponent.keys().forEach(fileName => {
    const storeConfig = requireComponent(fileName);

    const storeName = upperFirst(
        camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
    );

    modules[storeName] = storeConfig.default || storeConfig;
});

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

let store = new Vuex.Store({
    modules,

    strict: debug,

    plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin],

    state: {
        isLoading: false,
        hasLoaded: false
    },

    mutations: {
        SET_GLOBAL_LOADING(state, value) {
            state.isLoading = value;
        },

        SET_HAS_LOADED(state, value) {
            state.hasLoaded = value;
        }
    },

    getters: {
        isStoreLoading: state => state.isLoading,
        isStoreInitialized: state => state.hasLoaded
    },

    actions: {
        setLoading({ commit }, value) {
            commit('SET_GLOBAL_LOADING', value);
        },

        setStoreState({ commit }, value) {
            commit('SET_HAS_LOADED', value);
        }
    }
});

export default store;
