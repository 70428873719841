var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-table", {
    attrs: {
      data: _vm.reservations,
      "per-page": 5,
      paginated: "",
      draggable: "",
      hoverable: "",
    },
    on: {
      dragstart: _vm.dragstart,
      drop: _vm.drop,
      dragover: _vm.dragover,
      dragleave: _vm.dragleave,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (props) {
            return [
              _c(
                "b-table-column",
                { attrs: { field: "name", label: "Employer" } },
                [_vm._v(" " + _vm._s(props.row.name) + " ")]
              ),
              _c(
                "b-table-column",
                { attrs: { field: "requestedRoomType", label: "Room Type" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.getEnumDescription(
                          "RoomType",
                          props.row.requestedRoomType
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "b-table-column",
                { attrs: { field: "reservations", label: "Day Portion(s)" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.formatSessionTypes(props.row.reservations)) +
                      " "
                  ),
                ]
              ),
            ]
          },
        },
        {
          key: "empty",
          fn: function () {
            return [_vm._v(" There are no tables to assign ")]
          },
          proxy: true,
        },
        _vm.reservations.length > 0
          ? {
              key: "bottom-left",
              fn: function () {
                return [
                  _c(
                    "b-select",
                    {
                      model: {
                        value: _vm.perPage,
                        callback: function ($$v) {
                          _vm.perPage = $$v
                        },
                        expression: "perPage",
                      },
                    },
                    [
                      _c("option", { attrs: { value: "5" } }, [
                        _vm._v("5 per page"),
                      ]),
                      _c("option", { attrs: { value: "10" } }, [
                        _vm._v("10 per page"),
                      ]),
                      _c("option", { attrs: { value: "15" } }, [
                        _vm._v("15 per page"),
                      ]),
                      _c("option", { attrs: { value: "20" } }, [
                        _vm._v("20 per page"),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }