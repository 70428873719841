import axios from './index';

export const getAppointmentsByAttendee = attendeeId => {
    return axios.get(`/api/attendees/${attendeeId}/appointments`);
};

export const getAppointment = ({ attendeeId, appointmentId }) => {
    return axios.get(
        `/api/attendees/${attendeeId}/appointments/${appointmentId}`
    );
};

export const cancelAppointmentById = ({ attendeeId, appointmentId }) => {
    return axios.delete(
        `/api/attendees/${attendeeId}/appointments/${appointmentId}`
    );
};

export default 'AppointmentApi';
