var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-field",
        { attrs: { grouped: "" } },
        [
          _c(
            "b-field",
            [
              _c(
                "b-select",
                {
                  attrs: { placeholder: "Employer Status" },
                  on: { input: _vm.filter },
                  model: {
                    value: _vm.selectedStatus,
                    callback: function ($$v) {
                      _vm.selectedStatus = $$v
                    },
                    expression: "selectedStatus",
                  },
                },
                _vm._l(_vm.participationStatuses, function (status, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: status } },
                    [_vm._v(" " + _vm._s(status.display) + " ")]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("list", {
        attrs: {
          rowId: "employerFairId",
          hasDetailColumn: "",
          accordion: "",
          value: _vm.filteredEmployerFairs,
        },
        on: { "toggle-details": _vm.setActiveEmployer },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function (props) {
              return [
                _c("employer-component", {
                  attrs: { employerName: props.row.employerName },
                }),
              ]
            },
          },
          {
            key: "detail",
            fn: function (props) {
              return [
                _vm.showDetails
                  ? _c("employer-detail", {
                      attrs: { employerFair: props.row },
                      on: {
                        "approve-employer": _vm.approve,
                        "reject-employer": _vm.reject,
                        "remind-employer": _vm.remind,
                        "details-open": _vm.setActiveEmployer,
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "empty",
            fn: function () {
              return [_vm._v(" No matching Employers ")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }