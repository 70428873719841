var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InputControl",
    _vm._b(
      {
        attrs: {
          horizontal: _vm.horizontal,
          toolTip: _vm.toolTip,
          centerText: _vm.centerText,
          wrapHeader: _vm.wrapHeader,
          leftColumnCss: _vm.leftColumnCss,
          rightColumnCss: _vm.rightColumnCss,
          placeholder: _vm.label,
          controlLabel: _vm.label,
          readonly: _vm.readonly,
        },
        on: { input: _vm.updateValue },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      },
      "InputControl",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }