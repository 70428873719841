<template>
    <div>
        <collapsible-card title="My Applications">
            <List rowId="applicationId"
                  :hasDetailColumn="true"
                  :paginated="false"
                  :value="applications">

                <template v-slot:content="props">
                    <attendee-application :application="props.row" />
                </template>

                <template v-slot:detail="props">
                    <attendee-application-detail 
                        :applicationId="props.row.applicationId"/>
                </template>

                <template v-slot:empty>
                    It looks like you haven't applied to any positions
                </template>
            </List>
        </collapsible-card>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AttendeeApplication from './AttendeeApplication.vue';
    import AttendeeApplicationDetail from './AttendeeApplicationDetail.vue';

    export default {
        components: { AttendeeApplication, AttendeeApplicationDetail },

        name: 'AttendeeApplicationList',

        computed: {
            ...mapGetters(['applications'])
        }
    };
</script>
