/* STORE: Employers */
import * as actions from './actions';
import * as getters from './getters';

import unionBy from 'lodash/unionBy';

/**
 * @summary Employer store to handle interaction with the Employer resource
 */
const state = {
    employerLoading: false,
    editingEmployer: false,
    hasEmployerAttendedPreviousFairs: false,
    isRegisteringForFair: false,

    activeEmployerId: null,
    activeEmployerFairId: null,

    employers: [],
    employerFairs: []
};

const mutations = {

    /**
     * @summary Mutation to hydrate the employers collection.
     * 
     * @param {Object | Function} state - Vuex state object.
     * @param {Collection} employers - Collection of employer objects not tied to a Fair.
     */
    LOAD_EMPLOYERS(state, employers) {
        state.employers = employers;
    },

    /**
     * @summary Mutation to add an employer to the employers collection
     * 
     * @param {Object | Function} state - Vuex state object.
     * @param {Object} employer - The Employer object to be added to the collection. This object must have an employerId property.
     */
    ADD_EMPLOYER(state, employer) {
        if (employer === null || employer.employerId === undefined || employer.employerId === null)
            throw 'Employer object cannot be null and must have an employerId.';
        
        if (state.employers.find(emp => emp.employerId == employer.employerId))
            throw 'Employer already exists.';
        
        state.employers = unionBy([employer], state.employers, 'employerId');
        state.activeEmployerId = employer.employerId;
    },

    /**
     * @summary Mutation to update an existing employer.
     * 
     * @param {Object | Function} state - Vuex state object.
     * @param {Object} employer - The Employer object to be updated.
     */
    UPDATE_EMPLOYER(state, employer) {
        if (employer === null || employer.employerId === undefined || employer.employerId === null)
            throw 'Invalid Employer object. Employer cannot be null and must have an employerId.';

        if (!state.employers.find(emp => emp.employerId == employer.employerId))
            throw 'Employer has not been loaded. Make sure employer data has been loaded to perform this action.';
        
        state.employers = unionBy([employer], state.employers, 'employerId');
    },

    /**
     * @summary Mutation to indicate if an employer is currently being edited.
     * 
     * @param {Object | Function} state - Vuex state object.
     */
    TOGGLE_EMPLOYER_EDIT_MODE(state) {
        state.editingEmployer = !state.editingEmployer;
    },

    /**
     * @summary Mutation to hydrate the employerFairs collection. Each EmployerFair MUST have an employerId and the Employer must have been loaded prior to making this mutation.
     * 
     * @param {Object | Function} state - Vuex state object.
     * @param {Collection} employerFairs - Collection of employerFairs objects not tied to a Fair.
     */
    LOAD_EMPLOYER_FAIRS(state, employerFairs) {
        if (employerFairs === null)
            throw 'Invalid EmployerFairs collection.';

        state.employerFairs = [];

        employerFairs.forEach(employerFair => {
            if (employerFair.employerId === undefined || employerFair.employerId === null)
                throw 'Invalid EmployerFair. EmployerFair cannot be null and must have an employerId.';

            if (!state.employers.find(emp => emp.employerId === employerFair.employerId))
                throw 'Employer does not exist. Make sure employer data has been loaded to perform this action.';

            state.employerFairs = unionBy([employerFair], state.employerFairs, 'employerFairId');
        });
    },

    /**
     * @summary Mutation to add an employerFair to the employerFairs collection
     * 
     * @param {Object | Function} state - Vuex state object.
     * @param {Object} employerFair - The EmployerFair object to be added to the collection. This object must have an employerFairId property.
     */
    ADD_EMPLOYER_FAIR(state, employerFair) {
        if (!employerFair || employerFair.employerFairId === null)
            throw 'EmployerFair cannot be null and must have an employerFairId.';

        state.employerFairs = unionBy(state.employerFairs, [employerFair], 'employerFairId');
        state.activeEmployerFairId = employerFair.employerFairId;
    },

    /**
     * @summary Mutation to update an existing employerFair in the employerFairs collection.
     * 
     * @param {Object | Function} state - Vuex state object.
     * @param {Object} employerFair - The EmployerFair object to be updated. This object must have an employerFairId property and must have been previously loaded into the store.
     */
    UPDATE_EMPLOYER_FAIR(state, employerFair) {
        if (!employerFair || employerFair.employerFairId === null)
            throw 'EmployerFair cannot be null and must have an employerFairId.';

        if (!state.employerFairs.find(ef => ef.employerFairId == employerFair.employerFairId))
            throw 'EmployerFair has not been loaded.';

        state.employerFairs = unionBy([employerFair], state.employerFairs, 'employerFairId');
        state.activeEmployerFairId = employerFair.employerFairId;
    },

    /**
     * Load the contacts for the active EmployerFair
     * 
     * @param {Object | Function} state - Vuex state object. 
     * @param {Object} contacts - An object containing all contacts to be added to the active EmployerFair.
     */
    UPDATE_EMPLOYER_FAIR_CONTACTS(state, contacts) {
        if (state.activeEmployerFairId === null)
            throw 'There is no active EmployerFair.';
        
        const employerFair = state.employerFairs
            .find(ef => ef.employerFairId === state.activeEmployerFairId);

        if (!employerFair || employerFair.length === 0)
            throw 'EmployerFair has not been loaded.';

        employerFair.contacts = contacts;
        state.employerFairs = unionBy([employerFair], state.employerFairs, 'employerFairId');
    },

    /**
     * Load the participation plan for the active EmployerFair
     * 
     * @param {Object | Function} state - Vuex state object. 
     * @param {Object} contacts - An object containing the participation plan for the active EmployerFair.
     */
    LOAD_PARTICIPATION_PLAN(state, participationPlans) {
        if (!state.activeEmployerFairId)
            throw 'There is no active EmployerFair.';

        let employerFair = state.employerFairs
            .find(ef => ef.employerFairId === state.activeEmployerFairId);

        if (!employerFair)
            throw 'EmployerFair has not been loaded.';

        employerFair.participationPlans = participationPlans;
    },

    /**
     * Set the Active Employer id.
     * 
     * @param {Object | Function} state - Vuex state object. 
     * @param {int} employerId - Id of the employer being promoted to Active status.
     */
    SET_ACTIVE_EMPLOYER(state, employerId) {
        state.activeEmployerId = employerId;
    },

    /**
     * Set the Active EmployerFair id.
     * 
     * @param {Object | Function} state - Vuex state object. 
     * @param {int} employerFairId - Id of the employerFair being promoted to Active status.
     */
    SET_ACTIVE_EMPLOYER_FAIR(state, employerFairId) {
        state.activeEmployerFairId = employerFairId;
    },

    /**
     * @summary Mutation to indicate if the Employer Store is currently loading data.
     * 
     * @param {Object | Function} state - Vuex state object.
     */
    SET_EMPLOYER_LOADING(state, value) {
        state.employerLoading = value;
    },

    /**
     * @summary Mutation to set the Active EmployerId once the employer logs in. This will only set the active employer if the user logging in is an Employer.
     * 
     * @param {Object | Function} state - Vuex state object.
     * @param {Object} details - User details specific to the user logged in.
     */
    SET_USER_DETAILS(state, details) {
        if (!details.employerData) return;

        var fairContactDetails = details.employerData;

        state.isRegisteringForFair = !fairContactDetails.hasSubmittedRegistration;
        state.hasEmployerAttendedPreviousFairs = fairContactDetails.isReturningEmployer;
        
        if (fairContactDetails.employerId === -1 && fairContactDetails.employerFairId === -1) {
            state.activeEmployerId = null;
            state.activeEmployerFairId = null;
        } else {
            state.activeEmployerId = fairContactDetails.employerId;
            state.activeEmployerFairId = fairContactDetails.employerFairId;
        }
    },

    ADD_OPPORTUNITY(state, opportunity) {
        let activeEmployerFair = state.employerFairs.find(ef => ef.employerFairId === state.activeEmployerFairId);

        if (activeEmployerFair) {
            activeEmployerFair.opportunityTypes.push(opportunity.data.type);
        }
    },

    SET_ACTIVE_EMPLOYER_FAIR_PARTICIPANT_STATUS(state) {
        if (!state.activeEmployerFairId)
            throw 'There is no active EmployerFair.';

        let employerFair = state.employerFairs.find(ef => ef.employerFairId === state.activeEmployerFairId);

        if (employerFair) {
            
            state.hasSubmittedRegistration = true;
            state.isRegisteringForFair = false;
        }
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
