/* STORE: Opportunities */
import * as actions from './actions';
import * as getters from './getters';

import unionBy from 'lodash/unionBy';

const state = {
    opportunities: [],
    editingOpportunity: null,
    attendeeTypes: [],
    isLoading: false,
};

const mutations = {
    LOAD_OPPORTUNITIES(state, result) {
        state.opportunities = result.data.opportunities;
        state.attendeeTypes = result.data.attendeeTypes;
    },

    // Search results will be the list of available opportunities by default
    // That list can change but we always need the list of all opportunities here in the store
    // since by the time students are searching, these opportunities are mostly set in stone.
    LOAD_SEARCH_RESULTS(state, result) {
        state.opportunities = result.data.opportunities;
    },

    SET_SEARCH_RESULTS(state, results) {
        state.searchResults = results;
        // using this for the count of hits we got in search
        state.opportunityCount = results.length;
    },

    ADD_OPPORTUNITY(state, result) {
        state.opportunities.push(result.data);
    },

    EDIT_OPPORTUNITY(state, id) {
        state.editingOpportunity = state.opportunities.find(o => o.id == id);
    },

    CLEAR_OPPORTUNITY(state) {
        state.editingOpportunity = null;
    },

    DELETE_OPPORTUNITY(state, id) {
        state.opportunities = state.opportunities.filter(o => o.id != id);
    },

    UPDATE_OPPORTUNITY(state, result) {
        state.opportunities = unionBy([result.data], state.opportunities, 'id');
    },

    SET_LOADING_OPPORTUNITY(state, loadingState) {
        state.isLoading = loadingState;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
