var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns" }, [
    _c("div", { staticClass: "column" }, [
      _c("strong", [_vm._v(_vm._s(_vm.employerName))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }