import FairAdminManagement from '@/views/fairAdmin/management/Index';

import TableAssignmentView from '@/views/fairAdmin/management/TableAssignment';

let children = [
    {
        path: 'table-assignment',
        name: 'fa-mgmt-table-assignment',
        component: TableAssignmentView
    }
];

export default {
    path: 'manage',
    name: 'fa-mgmt-fair-management',
    component: FairAdminManagement,
    children
};