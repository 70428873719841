<template>
    <div class="modal" :class="modalClass" :aria-hidden="docModalHidden">
        <div class="modal-background" @click="closeDoc"></div>
        <div class="modal-card column is-four-fifths doc-container">
            <header class="modal-card-head">
            <p class="modal-card-title">{{ modalDoc && modalDoc.name }}</p>
            <button class="delete" aria-label="close" @click="closeDoc"></button>
            </header>
            <section class="modal-card-body">
                <vue-pdf-renderer ref="renderer" :file="modalDoc" />
            </section>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'DocModal',

        components: {
            VuePdfRenderer: () => import('vue-pdf-renderer')
        },

        computed: {
            ...mapGetters(['docModalHidden', 'modalClass', 'modalDoc'])
        },

        methods: {
            ...mapActions(['setModalDoc']),

            closeDoc() {
                this.setModalDoc(null);
            }
        }
    };
</script>

<style scoped>
    .modal .modal-card.doc-container {
        display: flex;
        flex-grow: 1;
    }

    .modal .modal-card.doc-container .modal-card-body {
        overflow: hidden;
    }
</style>
