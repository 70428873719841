<template>
    <h1 class="title is-4">
        <slot></slot>
    </h1>
</template>

<script>
    export default {
        name: 'BTitle'
    };
</script>