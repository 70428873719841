import head from 'lodash/head';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import uniqWith from 'lodash/uniqWith';

export const draggingReservation = state => state.draggingReservation;

export const assignedReservations = state => state.assignedReservations;
export const unassignedReservations = state => state.unassignedReservations;

export const buildings = state => {
  const buildings = state.roomConfigs.map(config => {
    return {
      id: config.buildingId,
      name: config.buildingName
    };
  });
  return uniqBy(buildings, 'id');
};

export const rooms = state => buildingId => {
  const rooms = state.roomConfigs
    .map(config => {
      return {
        id: config.roomId,
        name: config.roomName,
        buildingId: config.buildingId
      };
    })
    .filter(room => room.buildingId == buildingId);
  return uniqBy(rooms, 'id');
};

export const fairRoomConfig = state => (roomId) => {
  const roomConfigs = state.roomConfigs.filter(
    config =>
      config.roomId == roomId
  );

  const flatConfigs = roomConfigs.map(config => ({
    roomActivity: config.roomActivity,
    numSeats: config.tableCount
  }));
  return head(uniqWith(flatConfigs, isEqual));
};
