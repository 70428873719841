var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site" },
    [
      _c("top-nav-bar", { attrs: { showLinks: _vm.canShowPage } }),
      _c(
        "div",
        { staticClass: "site-content is-fluid has-margin-top-lg" },
        [_vm.canShowPage ? _c("router-view") : _vm._e()],
        1
      ),
      _c("main-footer"),
      _c("b-loading", {
        attrs: { active: _vm.isStoreLoading },
        on: {
          "update:active": function ($event) {
            _vm.isStoreLoading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }