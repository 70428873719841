var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MainRightSidebar",
    [
      _c("collapsible-card", { attrs: { title: "School Admin Sidebar" } }, [
        _vm._v(" Sidebar content TBD "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }