import { troyesRole } from '@/helpers/staticValues';
import Standard from '@/views/shared/layouts/Standard';

// Attendee Components
import AttendeeView from '@/views/attendee/Index';
import AttendeeSidebar from '@/views/attendee/Sidebar';
import AttendeeProfileView from '@/views/attendee/AttendeeProfile';
import AttendeeApplicationView from '@/views/attendee/AttendeeApplications';

var children = [
    {
        path: '',
        name: 'attendee-home',
        meta: {
            showSidebar: true,
        },
        components: {
            default: AttendeeView,
            sidebar: AttendeeSidebar
        }
    },
    {
        path: 'profile',
        name: 'attendee-profile',
        meta: {
            showSidebar: true,
        },
        components: {
            default: AttendeeProfileView,
            sidebar: AttendeeSidebar
        }
    },
    {
        path: 'applications',
        name: 'attendee-applications',
        meta: {
            showSidebar: true,
        },
        components: {
            default: AttendeeApplicationView,
            sidebar: AttendeeSidebar
        }
    }
];

export default {
    path: '/attendee',
    component: Standard,
    meta: {
        isPublic: false,
        accessRole: troyesRole.Attendee
    },
    children
};
