var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormControl",
    {
      attrs: {
        horizontal: _vm.horizontal,
        leftColumnCss: _vm.questionCss,
        rightColumnCss: _vm.actionCss,
        controlLabel: _vm.question,
        centerText: _vm.centerText,
        toolTip: _vm.toolTip,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }