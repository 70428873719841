import VuexPersistence from 'vuex-persist';

/* STORE: UserSession */
import * as actions from './actions';
import * as getters from './getters';

const vuexLocal = new VuexPersistence({
    key: 'userStore',
    storage: window.sessionStorage
});

const state = {
    homepage: '',
    user: null
};

const mutations = {
    SET_USER_HOMEPAGE(state, pageRoute) {
        state.homepage = pageRoute;
    },

    SET_USER_DETAILS(state, details) {
        if (details.user === null || details.user === undefined) return;

        state.user = details.user;
    }
};

export default {
    state,
    mutations,
    actions,
    getters,
    plugins: [vuexLocal.plugin]
};
