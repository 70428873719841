import Standard from '@/views/shared/layouts/Standard';
import FairScheduleView from '@/views/shared/FairSchedule';

var children = [
    {
        path: '',
        name: 'fair-schedule',
        components: {
            default: FairScheduleView
        }
    }
];

export default {
    path: '/fair-schedule',
    component: Standard,
    children
};
