<template>
    <div>
        <div class="columns is-desktop">
            <div class="column">
                <field-validator name="name">
                    <InputControl
                        controlLabel="Name"
                        v-model="profile.name"
                        :disabled="readonly" />
                </field-validator>

                <field-validator name="practice types" rules="">
                    <PracticeTypesMultiSelectControl
                        v-model="profile.practiceTypes"
                        :disabled="readonly"
                        controlLabel="Practice Types" />
                </field-validator>
            </div>
            <div class="column">
                <field-validator  name="organization type" rules="">
                    <OrganizationTypeDropdown 
                        v-model="profile.organizationType"
                        :disabled="readonly" />
                </field-validator>

                <field-validator name="subject areas" rules="">
                    <SubjectAreasMultiSelectControl
                        :value="profile.subjectAreas"
                        @input="updateSubjectAreas"
                        :disabled="readonly"
                        controlLabel="Subject Areas" />
                </field-validator>
            </div>
        </div>
        <div class="columns is-desktop">
            <div class="column">
                <field-validator name="url" rules="">
                    <InputControl                         
                        controlLabel="Main site URL"
                        v-model="profile.url"
                        :disabled="readonly" />
                </field-validator>
            </div>
        </div>
        <div class="columns is-desktop">
            <div class="column">
                <field-validator name="city">
                    <InputControl
                        id="city"
                        controlLabel="City"
                        v-model="profile.city"
                        :disabled="readonly" />
                </field-validator>
            </div>

            <div class="column">
                <div class="field">
                    <field-validator name="state">
                        <InputControl
                            id="state"
                            controlLabel="State"
                            v-model="profile.state"
                            :disabled="readonly" />
                    </field-validator>
                </div>
            </div>

            <div class="column">
                <field-validator name="zip code" rules="">
                    <zip-code-search class="has-padding-top-sm" @search="populateCityState" v-model="profile.postalCode" :readonly="readonly" />
                </field-validator>
            </div>
        </div>
    </div>
</template>

<script>
    import merge from 'lodash/merge';
    import { mapActions, mapGetters } from 'vuex';

    import InputControl from '@/components/formControls/base/InputControl';
    import FieldValidator from '@/components/formControls/base/FieldValidator';
    import OrganizationTypeDropdown from '@/components/employer/controls/OrganizationTypeDropdown';
    import PracticeTypesMultiSelectControl from '@/components/employer/controls/PracticeTypesMultiSelectControl';
    import SubjectAreasMultiSelectControl from '@/components/employer/controls/SubjectAreasMultiSelectControl';
    import ZipCodeSearch from '@/components/employer/opportunity/ZipCodeSearch';

    export default {
        name: 'EmployerProfileEdit',

        components: {
            OrganizationTypeDropdown,
            PracticeTypesMultiSelectControl,
            SubjectAreasMultiSelectControl,
            InputControl,
            FieldValidator,
            ZipCodeSearch
        },

        data() {
            return {
                leftColumnCssClass: 'column is-4 has-text-right',
                rightColumnCssClass: 'column',
                profile: {
                    state: null,
                    city: null,
                    practiceTypes: [],
                    subjectAreas: []
                },
                states: [],
                cities: []
            };
        },

        props: {
            readonly: Boolean
        },

        computed: {
            ...mapGetters(['activeEmployer', 'employersLoaded'])
        },

        methods: {
            ...mapActions(['addEmployer', 'updateEmployer']),

            populateCityState(searchResults) {
                this.states = searchResults.states;
                this.cities = searchResults.cities;
                if (this.states.length > 0) this.profile.state = this.states[0];
                if (this.cities.length > 0) this.profile.city = this.cities[0];
            },

            async save() {
                if (this.activeEmployer.employerId == null) {
                    await this.addEmployer(this.profile);
                } else{
                    await this.updateEmployer(this.profile);
                }

                this.profile = merge({}, this.activeEmployer);
            },
            
            updatePracticeTypes(value) {
                this.profile.practiceTypes = value;
            },
            
            updateSubjectAreas(value) {
                this.profile.subjectAreas = value;
            },
            
            updateOrgType(value) {
                this.profile.organizationType = value;
            }
        },

        watch: {
            'activeEmployer': {
                handler(updatedEmployer) {
                    if (updatedEmployer == null) return;
                    
                    this.profile = merge({}, updatedEmployer);
                },
                immediate: true
            }
        }
    };
</script>