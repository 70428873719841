<template>
    <div>
        <div class="columns is-gapless is-multiline">
            <div class="column is-full has-padding-left-none">
                <div class="columns is-multiline is-gapless is-tablet">
                    <div class="column">
                        <div class="has-padding-bottom-sm">
                            {{ opportunity.desc }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-full">
                <div class="columns is-tablet has-padding-bottom-sm">
                    <div v-if="opportunity.requiredLanguages != null" class="column is-narrow v-align-center">
                        <strong>Required Language Skills:</strong>
                    </div>
                    <div v-if="opportunity.requiredLanguages != null" class="column is-narrow">
                        {{ opportunity.requiredLanguages }}
                    </div>
                    <div v-if="opportunity.preferredLanguages != null" class="column is-narrow v-align-center">
                        <strong>Preferred Language Skills:</strong>
                    </div>
                    <div v-if="opportunity.preferredLanguages != null" class="column is-narrow">
                        {{ opportunity.preferredLanguages }}
                    </div>
                </div>
            </div>

            <div class="column is-full has-padding-left-none">
                <div class="columns is-multiline is-gapless is-tablet">
                    <div class="column is-full v-align-center">
                        <strong>{{ opportunity.type == 1 ? 'Academic year internship information' : 'Post-graduate fellowship information' }}</strong>
                    </div>
                    <div class="column">
                        <div class="has-padding-bottom-sm">
                            {{ opportunity.additionalEmploymentOfferings }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-full">
                <div class="columns is-tablet has-padding-right-md">
                    <div class="column is-narrow v-align-center">
                        <strong>U.S. Citizenship:</strong>
                    </div>
                    <div class="column is-narrow">
                        {{ citizenshipDesc }}
                    </div>
                </div>
            </div>
        </div>

        <div class="column" v-if="opportunity.documentRequests.length > 0">
            <div class="columns is-multiline">
                <div class="column is-full is-marginless has-padding-none has-padding-bottom-sm">
                    <div class="title is-6">Documents Requested</div>
                </div>
                <div class="column is-full has-padding-none">
                    <div v-for="request in documentRequests" :key="request.unique" class="columns is-multiline is-gapless is-tablet is-marginless has-padding-bottom-sm">
                        <div class="column is-full v-align-center">
                            <div class="v-align-center">
                                <span>
                                    <strong>{{ getEnumDescription('DocumentType', request.documentType) }}</strong>
                                    <span v-if="request.isRequired">&nbsp;&lpar;required&rpar;</span>
                                    <span v-else>&nbsp;&lpar;optional&rpar;</span>
                                </span>
                            </div>
                        </div>
                        <div class="column auto">
                            {{ request.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns has-margin-top-sm">
            <div class="column is-full has-padding-top-none">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'EmployerOpportunityDetail',

        props: {
            opportunity: {
                type: Object,
                required: true
            }
        },

        computed: {
            ...mapGetters(['getEnum', 'getEnumDescription',]),

            citizenshipDesc() {
                return this.opportunity.usCitizenshipRequired === true ? 'Required' : 'Not Required';
            },

            attendeeTypes() {
                return this.getEnum('AttendeeType');
            },

            documentTypes() {
                return this.getEnum('DocumentType');
            },

            documentRequests() {
                // Resume is our first enum - sort to try and get that first
                // I have to unpack this array because ESlint complains about unexpected
                // side effects otherwise
                return [...this.opportunity.documentRequests].sort((a, b) => a.documentType - b.documentType);
            }
        }
    };
</script>