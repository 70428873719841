<!-- 
This component has a slot for input - or whatever you want to stuff in here. 
This is functionally a label, but has no label tag. Use this component when you want to 
wrap something like a set of radio buttons. You want to provide a text label for this group 
of inputs, but you can only ever map one label to one input. Orphaned labels are an accessibility issue,
so this works around that by not using the actual label tag.
-->

<template>
    <div class="field" :class="{ 'is-expanded' : expanded, 'columns' : horizontal }">
        <div class="label v-align-center" :class="{ 'column is-narrow' : horizontal }">
            {{ label }}
            <b-tooltip v-if="hasTooltip" :label="tooltip" class="v-align-center has-margin-left-xs" multilined>
                <b-icon icon="info-circle" size="is-small" type="is-info" />
            </b-tooltip>
        </div>
        <div :class="{ 'column is-narrow' : horizontal }">
            <slot>

            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TextLabel',

        props: {
            label: {
                type: String,
                required: true
            },
            expanded: {
                type: Boolean,
                default: false
            },
            horizontal: {
                type: Boolean
            },
            hasTooltip: {
                type: Boolean
            },
            tooltip: {
                type: String
            }
        }
    };
</script>