var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DropdownControl", {
    attrs: {
      controlLabel: _vm.label,
      placeholder: "Select Availability",
      value: _vm.inputValue,
      options: _vm.timesOfDay,
    },
    on: { input: _vm.updateValue },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }