<template>
    <div>
        <button class="button is-small is-danger" @click="cancelAppointment">
            Delete Appointment
        </button>
    </div>
</template>

<script>
    import { cancelAppointmentById } from '@/api/appointment';

    export default {
        name: 'AttendeeAppointmentDetail',

        props: {
            appointmentId: {
                type: Number,
                required: true
            }
        },

        methods: {
            cancelAppointment() {
                this.$confirm({
                    title: 'Delete Appointment',
                    message: `<li>By confirming this request, you
                    will cancel your existing Appointment and be added back to
                    the queue for this Opportunity.</li>
                    <li>Your availability will be adjusted so you are not rescheduled in this time slot. 
                    You must adjust any other relevant changes to your availability.</li>
                    <li>If you do not wish to try to reshedule this Appointment, please
                    cancel this dialog and rescind your Application to this Opportunity</li>`, // TODO: provide <a href> link to application/{applicationId}?
                    cancelText: 'Cancel',
                    confirmText: 'Delete Appointment',
                    type: 'is-success',
                    onConfirm: () => cancelAppointmentById(this.appointmentId).
                        then(() => {
                            this.$toast({
                                message: 'Success: Interview cancelled and queued for rescheduling.',
                                duration: 5000
                            });
                            this.$emit('delete-appointment'); // TODO: forward them on to the Availability page/section of config. This may be moot in such case.
                        }).
                        catch(error => {
                            this.$toast({
                                message: 'Failed to cancel interview. If problem perists, please contact <a href=mailto:law.tdg@nyu.edu>law.tdg@nyu.edu</a><br/>Error Message:' + error,
                                type: 'is-warning',
                                duration: 5000
                            });
                        })
                });
            }
        }

    };

</script>