<template>
    <div class="box has-background-light">
        <QuestionControl question="Will you conduct interviews?">
            <field-validator name="interview participation">
                <AffirmOrDenyButtonsControl :value="inputParticipationPlan.requestingInterviewTable ? 'Yes' : 'No'"
                    :disabled="readonly"
                    @input="updateRequestingInterviews" />
            </field-validator>
            <field-validator name="interview time" v-if="inputParticipationPlan.requestingInterviewTable">
                <AvailabilityDropdown
                    v-if="inputParticipationPlan.requestingInterviewTable"
                    :value="inputParticipationPlan.requestedInterviewSessionIds"
                    label="Select time"
                    :disabled="readonly"
                    @input="updateInterviewSessionIds" />
            </field-validator>
        </QuestionControl>
        <hr>
        <QuestionControl question="Will you participate in Table Talk?" :disabled="readonly">
            <field-validator name="table talk participation">
                <AffirmOrDenyButtonsControl :value="inputParticipationPlan.requestingTableTalk ? 'Yes' : 'No'"
                    :disabled="readonly"
                    @input="updateRequestingTableTalks" />
            </field-validator>
            <field-validator name="table talk time" v-if="inputParticipationPlan.requestingTableTalk">
                <AvailabilityDropdown
                    v-if="inputParticipationPlan.requestingTableTalk"
                    :value="inputParticipationPlan.requestedTableTalkSessionIds"
                    label="Select time"
                    :disabled="readonly" 
                    @input="updateTableTalkSessionIds" />
            </field-validator>
        </QuestionControl>
    </div>
</template>

<script>
    import merge from 'lodash/merge';
    import AffirmOrDenyButtonsControl from '@/components/formControls/button/AffirmOrDenyButtonsControl';
    import QuestionControl from '@/components/formControls/base/QuestionControl';
    import AvailabilityDropdown from '@/components/formControls/dropdown/AvailabilityDropdown';
    import FieldValidator from '@/components/formControls/base/FieldValidator';

    export default {
        name: 'ParticipationPlanRequestControl',

        components: {
            AffirmOrDenyButtonsControl,
            AvailabilityDropdown,
            QuestionControl,
            FieldValidator
        },

        props: {
            readonly: Boolean,
            value: Object
        },

        data() {
            return {
                alignment: {
                    leftColumn: '',
                    rightColumn: ''
                },
                inputParticipationPlan: null
            };
        },

        methods: {
            updateRequestingInterviews(requesting) {
                this.inputParticipationPlan.requestingInterviewTable = requesting === 'Yes';

                if (!this.inputParticipationPlan.requestingInterviewTable) {
                    this.updateInterviewSessionIds([]);
                } else {
                    this.raiseUpdateEvent();
                }
            },

            updateRequestingTableTalks(requesting) {
                this.inputParticipationPlan.requestingTableTalk = requesting === 'Yes';

                if (!this.inputParticipationPlan.requestingTableTalk) {
                    this.updateTableTalkSessionIds();
                } else {
                    this.raiseUpdateEvent();
                }
            },

            updateInterviewSessionIds(sessionTypes) {
                this.inputParticipationPlan.requestedInterviewSessionIds = sessionTypes;
                this.raiseUpdateEvent();
            },

            updateTableTalkSessionIds(sessionTypes) {
                this.inputParticipationPlan.requestedTableTalkSessionIds = sessionTypes;
                this.raiseUpdateEvent();
            },
            
            raiseUpdateEvent() {
                this.$emit('input', this.inputParticipationPlan);
            }
        },

        watch: {
            value: {
                handler(newPlan) {
                    if (newPlan === null || newPlan === undefined) return;
                    
                    this.inputParticipationPlan = merge({}, newPlan);
                },
                immediate: true
            }
        }
    };
</script>