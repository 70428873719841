import get from 'lodash/get';
import { fieldSearchTypes } from '@/helpers/staticValues';

/**
 * @param {Object} field POJO
 * @param {string} field.path The 'path' to the property we'll be searching on.
 * This path if at the root level can be as simple as the string 'type', or if nested looks like 
 * this: 'employer.id'.
 * @param {boolean} field.allowMultiple If this is set to true, it's expected that we're searching on 
 * multiple values for a field. All of the provided values must be present in an opportunity for our search 
 * to get a hit.
 * @param {(string[]|number[]|boolean[]|string|number|boolean)} field.searchParameters The value(s) we're searching on. 
 * This could be text input or an array of options selected by the user.
 * @param {string} field.type The type of data we're searching on. This can be any of the values defined 
 * in the 'fieldSearchTypes' object. This is found alongside the rest of our static data in 'helpers/staticValues'.
 * @param {*[]} dataSet The set of data we're searching on. */
export const searchByField = (field, dataSet) => {
    return dataSet.filter(data => {
        // Here we use this lodash method to walk the 'path' to the property whose value
        // we wish to look up.
        const value = get(data, field.path);

        if (field.allowMultiple) {
            // If we have no search terms to work with - ignore this field
            if (field.searchParameters.length == 0) return true;

            if (field.inclusive) {
                return searchHasMatchInCollection(field.searchParameters, field.type, value);
            }
            else {
                return searchExistsInCollection(field.searchParameters, field.type, value);
            }
        }
        else if (!field.allowMultiple) {
            // If we have no search terms to work with - ignore this field
            if (field.searchParameters == null) return true;
            return fuzzyMatch(field.searchParameters, field.type, value);
        }
    });
};

/**
 * This method looks at a set of search terms entered by the user and returns 
 * whether or not all of the values being searched for are present in the collection 
 * we're comparing it to.
 * @param {(string[]|number[]|boolean[])} search The collection of values the user is looking for
 * @param {string} type The data type of the collections we'll be comparing
 * @param {(string[]|number[]|boolean[])} collection We'll compare this to the collection being searched for
 */
const searchExistsInCollection = (search, type, collection) => {
    switch (type) {
        case fieldSearchTypes.string:
            return search.every(term => collection.map(data => data.toUpperCase()).includes(term.toUpperCase()));
        case fieldSearchTypes.enum || fieldSearchTypes.number:
            // Make sure every enum that's been included in the search is present in the opportunity
            return search.every(term => collection.includes(term));
        default:
            throw new Error('Search performed on unknown field type.');
    }
};

const searchHasMatchInCollection = (search, type, collection) => {
    switch (type) {
        case fieldSearchTypes.string:
            return search.some(term => collection.map(data => data.toUpperCase()).includes(term.toUpperCase()));
        case fieldSearchTypes.enum || fieldSearchTypes.number:
            // Make sure every enum that's been included in the search is present in the opportunity
            return search.some(term => collection.includes(term));
        default:
            throw new Error('Search performed on unknown field type.');
    }
};

/**
 * This method looks at a search term entered by the user and compares it to a value.
 * This value comes from some searchable item. The search is fuzzy because we're not always 
 * doing exact matching. For string data we just check to see if the user's search is included 
 * somewhere in the string value of a searchable item.
 * @example
 * fuzzyMatch('sauce', 'string', 'hot sauce'); // true
 * @param {(string|number|boolean)} search What the user entered as a search term
 * @param {string} type The data type of the values we'll be comparing
 * @param {(string|number|boolean)} value We'll compare this value against the search term.  
 */
const fuzzyMatch = (search, type, value) => {
    switch (type) {
        case fieldSearchTypes.number:
            return value == search;
        case fieldSearchTypes.string:
            return value.toUpperCase().includes(search.toUpperCase());
        case fieldSearchTypes.boolean:
            return value == search;
        case fieldSearchTypes.enum:
            return Array.isArray(value) ? value.includes(search.id) : value == search.id;
        default:
            throw new Error('Search performed on unknown field type.');
    }
};