<template>
    <div>
        <a class="button is-danger is-inverted" @click="openFile" :title="typeDescription">
            <span class="has-text-grey-dark">{{ typeDescription }}</span>
            <b-icon icon="file-pdf"></b-icon>
        </a>
        <doc-modal />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import DocModal from '@/components/document/DocModal';

    export default {
        name: 'UploadedDocument',

        components: {
            DocModal
        },

        props: {
            documentId: {
                required: true, 
                type: Number
            },
            type: {
                required: true,
                type: Number
            }
        },

        data() {
            return {
                showDocument: false,
                document: null
            };
        },

        methods: {
            ...mapActions(['displayUploadedDocument', 'setModalDoc']),

            async openFile() {
                this.showDocument = true;

                if (this.document == null) {
                    // displays the pdf and returns the file for local storage
                    this.document = this.displayUploadedDocument({ attendeeId: this.activeAttendeeId, documentId: this.documentId, type: this.type });
                }
                else {
                    this.setModalDoc(this.document);
                }
            }
        },

        computed: {
            ...mapGetters(['activeAttendeeId', 'getEnumDescription']),

            typeDescription() {
                return this.getEnumDescription('DocumentType', this.type);
            }
        }
    };
</script>