import * as Api from '@/api/application';
import * as Notify from '@/helpers/globalNotifications';

export const createApplication = async ({ dispatch }, { attendeeId, opportunityId, docs }) => {
    try {
        await Api.createApplication(attendeeId, opportunityId, docs);
        dispatch('loadApplications', { attendeeId });
    }
    catch (error) {
        Notify.Error('We had some trouble sending your application.');
    }
};

export const withdrawApplication = async ({ dispatch }, { attendeeId, applicationId }) => {
    try {
        await Api.withdrawApplication(attendeeId, applicationId);
        dispatch('loadApplications', { attendeeId });
    }
    catch (error) {
        Notify.Error('We had some trouble withdrawing your application.');
    }
};

export const loadApplications = async ({ commit }, { attendeeId }) => {
    try {
        const applications = await Api.getApplicationsByAttendee(attendeeId);

        commit('SET_APPLICATIONS', applications.data);
    }
    catch (error) {
        Notify.Error('We had some trouble loading your applications.');
    }
};

export const getDocumentsByApplication = async (context, { attendeeId, applicationId }) => {
    const documentRequest = await Api.getDocumentsByApplication(attendeeId, applicationId);
    return documentRequest.data;
};

// This action pulls down a document, turns the blob back into a File object that can then be displayed to 
// the end user. 
export const displayUploadedDocument = async ({ dispatch, getters }, { attendeeId, documentId, type }) => {
    try {
        const documentBlob = (await Api.getDocument(attendeeId, documentId)).data;

        const parts = [documentBlob];

        const documentName = getters.getEnumDescription('DocumentType', type);

        const fileOptions = {
            type: 'application/pdf'
        };

        const file = new File(parts, documentName, fileOptions);

        dispatch('setModalDoc', file);

        return file;
    }
    catch (error) {
        Notify.Error('We had some trouble displaying that document.');

        return null;
    }
};