<template>
    <b-navbar-item tag="div" v-if="oidcIsAuthenticated">
        <b-dropdown hoverable position="is-bottom-left" aria-role="menu">
            <button class="button is-primary" slot="trigger" role="button">
                <b-icon icon="user-circle" size="is-medium"></b-icon>
            </button>
            <b-dropdown-item custom aria-role="menuitem">
                Logged as
                <b>{{ oidcUser.given_name }}</b>
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item
                value="logout"
                aria-role="menuitem"
                :has-link="true">
                <a href="#" @click="logout">Logout</a>
            </b-dropdown-item>
        </b-dropdown>
    </b-navbar-item>
    <a v-else class="navbar-item" @click="authenticateOidc">Login</a>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'AuthContextMenu',

        computed: {
            ...mapGetters(['oidcUser', 'oidcIsAuthenticated'])
        },
        
        methods: {
            ...mapActions(['signOutOidc', 'authenticateOidc']),
            
            logout() {
                this.signOutOidc();
            }
        }
    };
</script>