var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns" }, [
    _c(
      "div",
      { staticClass: "column is-one-quarter" },
      [
        _c("opportunity-search-filters", {
          ref: "filter",
          staticClass: "has-padding-bottom-md",
          attrs: { fieldData: _vm.opportunities },
          model: {
            value: _vm.filters,
            callback: function ($$v) {
              _vm.filters = $$v
            },
            expression: "filters",
          },
        }),
        _c("hr", { staticClass: "has-margin-top-md has-margin-bottom-md" }),
        _c("div", { staticClass: "buttons" }, [
          _c("a", { staticClass: "button", on: { click: _vm.clear } }, [
            _c("span", [_vm._v("Clear Filters")]),
          ]),
          _c("a", { staticClass: "button", on: { click: _vm.filter } }, [
            _c("span", [_vm._v("Apply Filters")]),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "column" },
      [
        _c("OpportunitySearchBox"),
        _c("br"),
        _c("OpportunitySearchList", { attrs: { value: _vm.searchResults } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }