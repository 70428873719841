var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar",
    { attrs: { type: "is-primary" } },
    [
      _c(
        "template",
        { slot: "brand" },
        [
          _c(
            "router-link",
            { staticClass: "has-padding-md", attrs: { to: { name: "root" } } },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/logo.png"),
                  width: "112",
                  height: "28",
                  alt: "New York University School of Law",
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm.showLinks
        ? _c(
            "template",
            { slot: "end" },
            [
              _vm.displayProtectedLink
                ? _c(
                    "a",
                    {
                      staticClass: "navbar-item is-primary",
                      on: { click: _vm.routeUser },
                    },
                    [_vm._v(" Home ")]
                  )
                : _vm._e(),
              _vm._l(_vm.navLinks, function (link) {
                return _c(
                  "router-link",
                  {
                    key: link.id,
                    staticClass: "navbar-item",
                    attrs: { exact: "", to: link.route },
                  },
                  [_vm._v(" " + _vm._s(link.displayName) + " ")]
                )
              }),
              _vm._l(_vm.navDropdowns, function (menu) {
                return _c(
                  "b-navbar-dropdown",
                  {
                    key: menu.id,
                    attrs: { label: menu.name, hoverable: "", arrowless: "" },
                  },
                  _vm._l(menu.links, function (link) {
                    return _c(
                      "router-link",
                      {
                        key: link.id,
                        staticClass: "navbar-item",
                        attrs: { exact: "", to: link.route },
                      },
                      [_vm._v(" " + _vm._s(link.displayName) + " ")]
                    )
                  }),
                  1
                )
              }),
              _vm.displayProtectedLink
                ? _c(
                    "router-link",
                    {
                      staticClass: "navbar-item",
                      attrs: { exact: "", to: { name: "fair-schedule" } },
                    },
                    [_vm._v(" Fair Schedule ")]
                  )
                : _vm._e(),
              _c("hr", { staticClass: "dropdown-divider" }),
              _c("AuthContextMenu"),
              !_vm.oidcIsAuthenticated
                ? _c(
                    "a",
                    {
                      staticClass: "navbar-item",
                      attrs: { href: "/Identity/Account/Register" },
                    },
                    [_vm._v("Register")]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }