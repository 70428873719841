var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "collapsible-card",
        { attrs: { title: "My Applications" } },
        [
          _c("List", {
            attrs: {
              rowId: "applicationId",
              hasDetailColumn: true,
              paginated: false,
              value: _vm.applications,
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function (props) {
                  return [
                    _c("attendee-application", {
                      attrs: { application: props.row },
                    }),
                  ]
                },
              },
              {
                key: "detail",
                fn: function (props) {
                  return [
                    _c("attendee-application-detail", {
                      attrs: { applicationId: props.row.applicationId },
                    }),
                  ]
                },
              },
              {
                key: "empty",
                fn: function () {
                  return [
                    _vm._v(
                      " It looks like you haven't applied to any positions "
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }