<template>
  <div>
    <document-request-option
        v-for="docType in documentTypes"
        :key="docType.id"
        :docType="docType"
        v-model="inputValue"
        @input="$emit('input', inputValue)" />
  </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import DocumentRequestOption from '@/components/document/DocumentRequestOption';

    export default {
        name: 'DocumentRequestStep',

        components: {
            DocumentRequestOption
        },

        props: {
            value: {
                type: Array,
                required: true
            }
        },

        data() {
            return {
                inputValue: [...this.value]
            };
        },

        computed: {
            ...mapGetters(['getEnum']),

            documentTypes() {
                return this.getEnum('DocumentType');
            }
        },

        watch: {
            value(newVal) {
                this.inputValue = [...newVal];
            }
        }
    };
</script>