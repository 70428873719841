const capitalize = value => {
    if (!value) return '';

    return value
        .toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
};

const replace = (str, replace_char, new_char) => {
    if (!str) return '';
    return str
        .toString()
        .split(replace_char)
        .join(new_char);
};

export default {
    capitalize,
    replace
};
