<template>
    <div>
        <p>Welcome Administrator! This is the home page for Fair Administrators. There was previously some text
            regarding fair activation and some warnings. This might not be as relevant with the current system so let me
            know what you want to see here on this page.</p>
        <h3 style="color:blue">If you need to access the data from previous fairs, please use the "Previous Fairs" link
            on the left navigation. That will allow you to view data from previous fairs, while keeping the current fair
            active.</h3>

        <P>
            Current Career Fair is the 2025 Public Interest Legal Career Fair
        </P>
    </div>
</template>

<script>
    export default {
        name: 'FairAdminView'
    };
</script>