<template>
    <card title="School Admin Dashboard">
        The School Admin dashboard will go here
        <hr>

        <card>
            <list rowId="attendeeId">
                <template v-slot:empty>
                    The Attendee List could appear here with approval status.

                    Approval could also happen in the same list.
                </template>
            </list>
        </card>
    </card>
</template>

<script>
    export default {
        name: 'SchoolView'
    };
</script>