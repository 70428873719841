var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.wrappingTag,
    { tag: "component" },
    [
      _vm.editing
        ? _vm._t("write", null, {
            metadata: _vm.metadata,
            edit: _vm.toggleEdit,
          })
        : _vm._t("read", null, {
            metadata: _vm.metadata,
            edit: _vm.toggleEdit,
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }