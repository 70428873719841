import axios from './index';
import merge from 'lodash/merge';

/**
 * Employer Specific APIs
 */
export const getEmployers = () => {
    return axios.get('/api/employers');
};

export const getEmployer = employerId => {
    return axios.get(`/api/employers/${employerId}`);
};

export const addEmployer = employer => {
    return axios.post('/api/employers', employer);
};

export const updateEmployer = (employerId, employer) => {
    let employerDto = merge({}, employer);

    employerDto.organizationType = merge({}, employer.organizationType).id;
    employerDto.practiceTypes = employer.practiceTypes.map(pt => merge({}, pt).id);
    employerDto.subjectAreas = employer.subjectAreas.map(pt => merge({}, pt).id);
    
    return axios.put(`/api/employers/${employerId}`, employerDto);
};

/**
 * EmployerFair Specific APIs
 */

export const getEmployerFairs = () => {
    return axios.get('/api/employers');
};

export const getEmployerFair = (employerId, fairId) => {
    return axios.get(`/api/employers/${employerId}/fairs/${fairId}`);
};

export const submitEmployerFairForApproval = (employerId, fairId) => {
    return axios.put(
        `/api/employers/${employerId}/fairs/${fairId}/submit-registration`
    );
};

export const approveEmployerFair = (employerId, fairId) => {
    return axios.post(`/api/employers/${employerId}/fairs/${fairId}/approve`);
};

export const rejectEmployerFair = (employerId, fairId) => {
    return axios.post(`/api/employers/${employerId}/fairs/${fairId}/reject`);
};

export const remindEmployerFair = (employerId, fairId) => {
    return axios.get(`/api/employers/${employerId}/fairs/${fairId}/remind`);
};

/**
 * EmployerFair Contacts APIs
 */
export const getEmployerFairContacts = (employerId, fairId) => {
    return axios.get(`/api/employers/${employerId}/fairs/${fairId}/contacts`);
};

export const updateEmployerFairContacts = (employerId, fairId, contacts) => {
    return axios.put(`/api/employers/${employerId}/fairs/${fairId}/contacts`, contacts);
};

/**
 * EmployerFair Participation Plan APIs
 */
export const getEmployerFairParticipation = (employerId, fairId) => {
    return axios.get(`/api/employers/${employerId}/fairs/${fairId}/participation`);
};

export const addEmployerFairParticipation = (employerId, fairId, participation) => {
    return axios.post(`/api/employers/${employerId}/fairs/${fairId}/participation`, participation);
};

export const updateEmployerFairParticipation = (employerId, fairId, participation) => {
    return axios.put(`/api/employers/${employerId}/fairs/${fairId}/participation`, participation);
};

export const deleteEmployerFairParticipation = (employerId, fairId) => {
    return axios.delete(`/api/employers/${employerId}/fairs/${fairId}/participation`);
};

export default 'EmployerApi';

// TODO: Move out of this file
export const seatSummary = () => {
    return axios.get('/api/reservations/seat-summary');
};
