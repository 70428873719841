<template>
    <div>
        <collapsible-card title="Appointment List">
            <List rowId="appointmentId"
                :hasDetailColumn="true"
                :paginated="false"
                :value="getAppointments">
                <template v-slot:content="props">
                    <attendee-appointment :appointment="props.row" />
                </template>

                <template v-slot:detail="props">
                    <attendee-appointment-detail
                        :appointmentId="props.row.appointmentId"
                        @delete-appointment="deleteAppointment"
                    />
                </template>
            </List>
        </collapsible-card>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import AttendeeAppointment from './AttendeeAppointment.vue';
    import AttendeeAppointmentDetail from './AttendeeAppointmentDetail.vue';

    export default {
        components: {
            AttendeeAppointment,
            AttendeeAppointmentDetail,
        },

        name: 'AttendeeAppointmentList',

        data() {
            return {
                attendeeId: 13 // TODO: Make actual use of attendeeId here once we have Auth and can access user attributes
            };
        },

        computed: {
            ...mapGetters(['getAppointments']),
        },

        methods: {
            ...mapActions(['loadAppointments']),

            deleteAppointment() {
                this.loadAppointments(this.attendeeId);
            }
        },

        mounted() {
            this.loadAppointments(this.attendeeId);
        },
    };
</script>
