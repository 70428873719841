var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MainRightSidebar",
    [_c("Card", { attrs: { title: "Seat Summary" } }, [_c("seat-summary")], 1)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }