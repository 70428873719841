<template>
    <span>
        <b-tooltip v-if="hasToolTip" type="is-dark" :label="tip">
            <b-icon size="is-small" icon="info-circle"></b-icon>
        </b-tooltip>
    </span>
</template>

<script>
    export default {
        name: 'ToolTipControl',

        props: {
            tip: {
                type: String,
                default: ''
            }
        },

        computed: {
            hasToolTip() {
                return this.tip !== '';
            }
        }
    };
</script>