<template>
    <div>
        <opportunity-list @add="showForm" />
        
        <br>

        <card v-show="showOpportunityForm" :title="`Opportunity Form - ${ editing ? 'Currently Editing' : 'Adding New Opportunity' }`">
            <opportunity-form ref="form" :key="formState ? 0 : 1" @save="save" @edit="edit" @cancel="cancel" />
        </card>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    import OpportunityList from '@/components/employer/opportunity/OpportunityList';
    import OpportunityForm from '@/components/employer/opportunity/OpportunityForm';
    import ConfirmLeaveMixin from '@/mixins/ConfirmLeaveMixin';

    export default {
        name: 'EmployerOpportunitiesList',

        components: {
            OpportunityList,
            OpportunityForm
        },

        mixins: [ConfirmLeaveMixin],

        data() {
            return {
                formState: false,
                showOpportunityForm: false
            };
        },

        async mounted() {
            await this.loadOpportunitiesForEmployerFair();
        },

        methods: {
            ...mapActions(['addOpportunity', 'clearOpportunity', 'loadOpportunitiesForEmployerFair', 'updateOpportunity']),

            toggleForm() {
                this.formState = !this.formState;
            },

            async save(opportunity) {
                const input = {};
                input.opportunity = opportunity;

                try {
                    await this.addOpportunity(input);
                } catch (error) {
                    // Ignore
                }
                
                this.showOpportunityForm = false;
            },

            async edit(opportunity) {
                this.showForm();
                const input = {};
                input.opportunity = opportunity;
                input.opportunityId = opportunity.id;
                try {
                    await this.updateOpportunity(input);    
                } catch (error) {
                    // Ignore
                }
                
                this.showOpportunityForm = false;
            },

            showForm() {
                this.showOpportunityForm = true;
            },

            cancel() {
                this.toggleForm();
                this.showOpportunityForm = false;
                this.clearOpportunity();
            }
        },

        computed: {
            ...mapGetters(['editingOpportunity', 'opportunities']),

            editing() {
                return this.editingOpportunity != null;
            }
        },

        watch: {
            editingOpportunity(newVal) {
                if (newVal != null) {
                    this.showForm();
                }
            },

            showOpportunityForm(show) {
                if (!show) {
                    this.dirty = false;
                }
            },
        }
    };
</script>