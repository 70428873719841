<template>
    <ValidationProvider 
        :name="name" 
        :vid="vid"
        :rules="rules" 
        :bails="false" 
        :skipIfEmpty="false" 
        v-slot="{ failed, required }" 
        slim>
        <b-field 
            :title="required ? 'Required' : null" 
            :label="label" 
            :label-for="name" 
            :expanded="expanded" 
            :class="{ 'failed': failed, 'required': required }">
            <slot></slot>
        </b-field>
    </ValidationProvider>
</template>

<script>
    export default {
        name: 'FieldValidator',

        props: {
            name: {
                type: String,
                required: true
            },
            rules: {
                type: [String, Object],
                default: 'required'
            },
            label: {
                type: String
            },
            expanded: {
                type: Boolean
            },
            vid: {
                type: String
            }
        }
    };
</script>

<style scoped>
    .failed >>> input, .failed >>> textarea, .failed >>> .b-radio.radio.button {
        border-color: #ff3860 !important;
    }

    .required >>> .label::after {
        content: '*';
        margin-left: 2px;
    }
</style>