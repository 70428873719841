/* STORE: Appointments */
import * as actions from './actions';
import * as getters from './getters';

const state = {
    appointments: []
};

const mutations = {
    LOAD_APPOINTMENTS(state, result) {
        state.appointments = result.data;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
