<template>
    <div class="columns">
        <div class="column is-one-fifth">
            <card>
                <aside class="menu">
                    <p class="menu-label">
                        Administration
                    </p>
                    <ul class="menu-list">
                        <li class="is-active"
                            v-for="(link, index) in tabRoutes"
                            :key="index">
                            <router-link :to="{ name: link.name }">
                                {{ link.displayLabel }}
                            </router-link>
                        </li>
                    </ul>
                </aside>
            </card>
        </div>
        <div class="column">
            <card :title="activeTabHeaderLabel()">
                <router-view />
            </card>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'FairAdminManagement',

        data() {
            return {
                activeTab: 0,
                tabRoutes: [
                    {
                        name: 'fa-mgmt-table-assignment',
                        displayLabel: 'Table Assignment'
                    }
                ]
            };
        },

        created() {
            this.setRouteBasedOnActiveTab();
        },

        computed: {
            ...mapState({ route: state => state.route}),
            
        },

        methods: {
            activeTabHeaderLabel() {
                if (this.activeTab < 0) this.activeTab = 0;
                
                return this.tabRoutes[this.activeTab].displayLabel;
            },

            setRouteBasedOnActiveTab() {
                let currentRouteIndex = this.tabRoutes.findIndex(r => r.name === this.route.name);
                
                if (this.activeTab < 0 || currentRouteIndex < 0) {
                    this.activeTab = 0;
                    this.$router.replace(this.tabRoutes[this.activeTab]);
                    return;
                }

                if (currentRouteIndex != this.activeTab) {
                    this.activeTab = currentRouteIndex;
                    let route = this.tabRoutes[this.activeTab];

                    if (route !== null)
                        this.$router.push(this.tabRoutes[this.activeTab]);
                }
            }
        },

        watch: {
            '$route': 'setRouteBasedOnActiveTab'
        }
    };
</script>