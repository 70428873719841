var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.hasToolTip
        ? _c(
            "b-tooltip",
            { attrs: { type: "is-dark", label: _vm.tip } },
            [
              _c("b-icon", {
                attrs: { size: "is-small", icon: "info-circle" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }