<template>
    <section class="section">
        <div class="container is-fluid">
            <div class="columns">
                <div class="column" :class="{ 'is-three-quarters': showSidebar }">
                    <router-view />
                </div>
                <div class="column" v-if="showSidebar">
                    <router-view name="sidebar" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'StandardLayout',

        computed: {
            showSidebar() {
                return this.$route.meta.showSidebar;
            }
        }
    };
</script>

