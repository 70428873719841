<template>
    <card title="Applications">
        <b-notification :closable="false">
            You may begin reviewing student applications on {{ reviewStart }}  You must complete your rankings by {{ rankingDeadline }}
        </b-notification>
        <b-notification :closable="false" type="is-warning">
            Note: It is very important that you complete your selections by the deadline. The interview scheduler cannot run until all employers complete their selections. 
            If you do not complete your selections by the deadline, you will delay both students and other employers from being able to access their interview schedules.
        </b-notification>
        <b-tabs>
            <b-tab-item label="Rank Applicants" class="columns is-multiline">
                <b-field grouped class="column">
                    <b-field :custom-class="'has-text-weight-normal is-size-7'" label="Actions">
                        <p class="control v-align-center">
                            <button @click="reject(checkedRows)" class="button is-small">
                                Reject
                            </button>
                        </p>
                    </b-field>
                </b-field>
                <b-field grouped position="is-right" class="column">
                    <b-field :custom-class="'has-text-weight-normal is-size-7'" label="Filters">
                        <p class="control">
                            <b-checkbox 
                                v-for="filter in rankingFilters" 
                                :key="filter.gavelCount" 
                                v-model="filter.display"
                                class="has-padding-right-sm"
                                size="is-small">
                                {{ filter.gavelCount | filterLabelText }}
                            </b-checkbox>
                        </p>
                    </b-field>

                    <b-field :custom-class="'has-text-weight-normal is-size-7'" label="Results Per Page">
                        <b-select v-model="perPage" size="is-small">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="40">40</option>
                        </b-select>
                    </b-field>

                </b-field>
                <div class="columns is-centered column is-full">
                    <div class="column is-size-7">
                        <b-table
                            :data="filteredApplicants"
                            detail-key="id"
                            hoverable
                            draggable
                            paginated
                            :per-page="perPage"
                            checkable
                            :checked-rows.sync="checkedRows"
                            narrowed>
                            <template slot-scope="props">
                                <b-table-column field="name" label="Name" sortable>
                                    {{ props.row.name }}
                                </b-table-column>
                                <b-table-column field="attendeeType" label="Class Year" sortable>
                                    {{ getEnumDescription('AttendeeType', props.row.attendeeType) }}
                                </b-table-column>
                                <b-table-column label="Documents">
                                    <a class="is-borderless is-danger" :title="getEnumDescription('DocumentType', 1)" @click="openFile">
                                        <b-icon icon="file-pdf"></b-icon>
                                    </a>
                                </b-table-column>
                                <b-table-column field="ranking" label="Ranking" sortable numeric>
                                    <b-rate :key="props.row.id" v-model="props.row.ranking" v-bind="rateProps"></b-rate>
                                </b-table-column>
                            </template>
                            <template v-slot:empty>
                                {{ emptyMessage }}
                            </template>
                        </b-table>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item v-if="rejected.length > 0" label="Rejected Applicants">
                <b-table 
                    :data="rejected"
                    class="is-size-7"
                    hoverable
                    narrowed>
                    <template slot-scope="props">
                        <b-table-column field="name" label="Name">
                            {{ props.row.name }}
                        </b-table-column>
                        <b-table-column field="attendeeType" label="Class Year">
                            {{ getEnumDescription('AttendeeType', props.row.attendeeType) }}
                        </b-table-column>
                    </template>
                </b-table>
            </b-tab-item>
        </b-tabs>
        <doc-modal />
    </card>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    import DocModal from '@/components/document/DocModal';

    export default {
        name: 'EmployerApplications',

        components: {
            DocModal,
        },

        methods: {
            ...mapActions(['setModalDoc']),            

            reject(applicants) {
                this.data = this.data.filter(d => !applicants.map(a => a.id).includes(d.id));
                this.rejected = this.rejected.concat(applicants);
                this.checkedRows = [];
            },

            openFile() {
                this.setModalDoc(this.testFile);
            },
        },

        filters: {
            filterLabelText(rating) {
                if (rating > 1) {
                    return `${rating} Gavels`;
                }
                else if (rating < 1) {
                    return 'Unranked';
                }
                else {
                    return `${rating} Gavel`;
                }
            },
        },

        computed: {
            ...mapGetters(['getEnumDescription']),

            filteredApplicants() {
                if (this.rankingFilters.every(f => !f.display)) return this.data;
                return this.data.filter(d => {      
                    const appliedFilters = this.rankingFilters.filter(f => f.display).map(f => f.gavelCount);
                    return appliedFilters.includes(d.ranking);
                });
            },

            allResults() {
                return this.filteredApplicants != null ? this.filteredApplicants.length : 0;
            },

            emptyMessage() {
                return this.rankingFilters.every(f => !f.display) ? 
                    'There are not yet applicants for this opportunity.' : 
                    'The applied filters didn\'t turn up any results.';
            },
        },

        async mounted() {
            // this gives us an array of integers from 0 up to and including the max rank
            const range = [...Array(this.rateProps.max + 1).keys()];
            this.rankingFilters = range.map(n => ({ gavelCount: n, display: false }));

            const response = await fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/documents/1`, {
                method: 'GET',
                mode: 'cors',
            });

            const blob = await response.blob();

            const parts = [blob];

            const document = {
                documentType: 1,
                lastModified: Date.now()
            };

            const documentName = this.getEnumDescription('DocumentType', document.documentType);

            const fileOptions = {
                lastModified: document.lastModified,
                type: 'application/pdf'
            };

            const file = new File(parts, documentName, fileOptions);

            // let us say that the Dto for Applications has a list of document Ids and Names
            // We can make use of the file name here since the best we can send back from a 
            // controller is just a blob with the raw file data. 

            this.testFile = file;
        },

        data() {
            return {
                rateProps: {
                    iconPack: 'fas',
                    icon: 'gavel',
                    max: 5,
                },
                testFile: null,
                perPage: 20,
                dragOverClass: 'border-gap-top',
                appBeingDragged: null,
                draggingOverElement: null,
                reviewStart: 'January 17 at 9:00 a.m.', //TODO: fetch FairDate.ApplicationReviewStart
                rankingDeadline: 'January 29, 2020, 5:00 p.m.', //TODO: fetch FairDate.ApplicationRankingDeadline,
                rejected: [],
                rankingFilters: [],
                checkedRows: [],
                data: [{
                    'id': 1,
                    'name': 'Teodora Murford',
                    'attendeeType': 1,
                    'ranking': 0
                }, {
                    'id': 2,
                    'name': 'Malanie Gepheart',
                    'attendeeType': 2,
                    'ranking': 0
                }, {
                    'id': 3,
                    'name': 'Corina Pirdy',
                    'attendeeType': 2,
                    'ranking': 0
                }, {
                    'id': 4,
                    'name': 'Ana Gajownik',
                    'attendeeType': 3,
                    'ranking': 0
                }, {
                    'id': 5,
                    'name': 'Ashbey Darragh',
                    'attendeeType': 4,
                    'ranking': 0
                }, {
                    'id': 6,
                    'name': 'Aurelea Sconce',
                    'attendeeType': 2,
                    'ranking': 0
                }, {
                    'id': 7,
                    'name': 'Rollo Bowshire',
                    'attendeeType': 3,
                    'ranking': 0
                }, {
                    'id': 8,
                    'name': 'Hernando Crippill',
                    'attendeeType': 5,
                    'ranking': 0
                }, {
                    'id': 9,
                    'name': 'Alys Catlow',
                    'attendeeType': 4,
                    'ranking': 0
                }, {
                    'id': 10,
                    'name': 'Gamaliel Cahill',
                    'attendeeType': 2,
                    'ranking': 0
                }, {
                    'id': 11,
                    'name': 'Keene Gremain',
                    'attendeeType': 4,
                    'ranking': 0
                }, {
                    'id': 12,
                    'name': 'Marijo Matas',
                    'attendeeType': 5,
                    'ranking': 0
                }, {
                    'id': 13,
                    'name': 'Dynah McKeveney',
                    'attendeeType': 5,
                    'ranking': 0
                }, {
                    'id': 14,
                    'name': 'Adelbert Kinnard',
                    'attendeeType': 5,
                    'ranking': 0
                }, {
                    'id': 15,
                    'name': 'Lief Drayton',
                    'attendeeType': 3,
                    'ranking': 0
                }, {
                    'id': 16,
                    'name': 'Basil Frick',
                    'attendeeType': 2,
                    'ranking': 0
                }, {
                    'id': 17,
                    'name': 'Brose Ammer',
                    'attendeeType': 2,
                    'ranking': 0
                }, {
                    'id': 18,
                    'name': 'Bogey Ruddom',
                    'attendeeType': 5,
                    'ranking': 0
                }, {
                    'id': 19,
                    'name': 'Courtnay Duckels',
                    'attendeeType': 1,
                    'ranking': 0
                }, {
                    'id': 20,
                    'name': 'Josefina Borless',
                    'attendeeType': 2,
                    'ranking': 0
                }, {
                    'id': 21,
                    'name': 'Carina Aronov',
                    'attendeeType': 3,
                    'ranking': 0
                }, {
                    'id': 22,
                    'name': 'Archie Hansmann',
                    'attendeeType': 1,
                    'ranking': 0
                }, {
                    'id': 23,
                    'name': 'Lizzy Nijssen',
                    'attendeeType': 3,
                    'ranking': 0
                }, {
                    'id': 24,
                    'name': 'Renaldo Jouanet',
                    'attendeeType': 3,
                    'ranking': 0
                }, {
                    'id': 25,
                    'name': 'Francine Antrag',
                    'attendeeType': 1,
                    'ranking': 0
                }, {
                    'id': 26,
                    'name': 'Gabie Youens',
                    'attendeeType': 2,
                    'ranking': 0
                }, {
                    'id': 27,
                    'name': 'Marga Galero',
                    'attendeeType': 4,
                    'ranking': 0
                }, {
                    'id': 28,
                    'name': 'Jemmy Casse',
                    'attendeeType': 1,
                    'ranking': 0
                }, {
                    'id': 29,
                    'name': 'Germain Simeoli',
                    'attendeeType': 3,
                    'ranking': 0
                }, {
                    'id': 30,
                    'name': 'Rutger Curdell',
                    'attendeeType': 1,
                    'ranking': 0
                }, {
                    'id': 31,
                    'name': 'Wilma Dudlestone',
                    'attendeeType': 3,
                    'ranking': 0
                }, {
                    'id': 32,
                    'name': 'Locke Olwen',
                    'attendeeType': 4,
                    'ranking': 0
                }, {
                    'id': 33,
                    'name': 'Lusa Stroulger',
                    'attendeeType': 4,
                    'ranking': 0
                }, {
                    'id': 34,
                    'name': 'Dalli Faughny',
                    'attendeeType': 5,
                    'ranking': 0
                }, {
                    'id': 35,
                    'name': 'Carlina Kobisch',
                    'attendeeType': 3,
                    'ranking': 0
                }, {
                    'id': 36,
                    'name': 'Vick Bernardon',
                    'attendeeType': 5,
                    'ranking': 0
                }, {
                    'id': 37,
                    'name': 'Leona Klaussen',
                    'attendeeType': 3,
                    'ranking': 0
                }, {
                    'id': 38,
                    'name': 'Vevay Janota',
                    'attendeeType': 3,
                    'ranking': 0
                }, {
                    'id': 39,
                    'name': 'Thedrick Shiliton',
                    'attendeeType': 5,
                    'ranking': 0
                }, {
                    'id': 40,
                    'name': 'Rodolfo Gillivrie',
                    'attendeeType': 5,
                    'ranking': 0
                }, {
                    'id': 41,
                    'name': 'Pauletta Acey',
                    'attendeeType': 3,
                    'ranking': 0
                }, {
                    'id': 42,
                    'name': 'Leyla Laytham',
                    'attendeeType': 3,
                    'ranking': 0
                }, {
                    'id': 43,
                    'name': 'Sibelle Chellenham',
                    'attendeeType': 1,
                    'ranking': 0
                }, {
                    'id': 44,
                    'name': 'Kalila Fido',
                    'attendeeType': 2,
                    'ranking': 0
                }, {
                    'id': 45,
                    'name': 'Kingston Southcombe',
                    'attendeeType': 4,
                    'ranking': 0
                }, {
                    'id': 46,
                    'name': 'Rabbi Kurth',
                    'attendeeType': 2,
                    'ranking': 0
                }, {
                    'id': 47,
                    'name': 'Ibby Davidoff',
                    'attendeeType': 1,
                    'ranking': 0
                }, {
                    'id': 48,
                    'name': 'Flint Arrault',
                    'attendeeType': 1,
                    'ranking': 0
                }, {
                    'id': 49,
                    'name': 'Mickey Leades',
                    'attendeeType': 1,
                    'ranking': 0
                }, {
                    'id': 50,
                    'name': 'Mina Yashaev',
                    'attendeeType': 2,
                    'ranking': 0
                }]
            };
        }
    };
</script>