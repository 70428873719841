var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.employerFairsLoaded
        ? _c("div", { staticClass: "tabs" }, [
            _c(
              "ul",
              _vm._l(_vm.tabs, function (tab) {
                return _c(
                  "router-link",
                  {
                    key: tab.index,
                    attrs: {
                      to: { name: tab.routeName },
                      exact: "",
                      tag: "li",
                    },
                  },
                  [_c("a", [_vm._v(" " + _vm._s(tab.label) + " ")])]
                )
              }),
              1
            ),
          ])
        : _vm._e(),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }