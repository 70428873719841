<template>
    <b-navbar type="is-primary">
        <template slot="brand">
            <router-link :to="{ name: 'root' }" class="has-padding-md">
                <img :src="require('@/assets/images/logo.png')"
                    width="112"
                    height="28"
                    alt="New York University School of Law" />
            </router-link>
        </template>
        <template slot="end" v-if="showLinks">
            <a v-if="displayProtectedLink" @click="routeUser" class="navbar-item is-primary">
                Home
            </a>
            <router-link class="navbar-item" exact
                v-for="link in navLinks"
                :key="link.id"
                :to="link.route">
                {{ link.displayName }}
            </router-link>

            <b-navbar-dropdown
                v-for="menu in navDropdowns"
                :key="menu.id"
                :label="menu.name"
                hoverable
                arrowless>
                <router-link class="navbar-item" exact
                    v-for="link in menu.links"
                    :key="link.id"
                    :to="link.route">
                    {{ link.displayName }}
                </router-link>
            </b-navbar-dropdown>

            <router-link class="navbar-item" exact
                :to="{ name: 'fair-schedule' }"
                v-if="displayProtectedLink">
                Fair Schedule
            </router-link>
            <hr class="dropdown-divider">
            <AuthContextMenu />
            <a v-if="!oidcIsAuthenticated" class="navbar-item" href="/Identity/Account/Register">Register</a>
        </template>
    </b-navbar>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { troyesRole } from '@/helpers/staticValues';
    import AuthContextMenu from '@/components/account/AuthContextMenu';
    import union from 'lodash/union';

    export default {
        name: 'TopNavBar',

        components: {
            AuthContextMenu
        },

        data() {
            return {
                schoolAdminLinks: [
                    {
                        id: 10,
                        route: { name: 'school-students' },
                        displayName: 'Students'
                    },
                    {
                        id: 11,
                        route: { name: 'school-employers' },
                        displayName: 'Employers'
                    },
                    {
                        id: 12,
                        route: { name: 'school-volunteers' },
                        displayName: 'Volunteers'
                    }
                ],
                fairAdminNav: [
                    {
                        id: 20,
                        displayName: 'Schools',
                        route: { name: 'school-students' }
                    },
                    {
                        id: 21,
                        displayName: 'Employers',
                        route: { name: 'user-approval' }
                    },
                    {
                        id: 22,
                        displayName: 'NYU Students',
                        links: [
                            {
                                id: 220,
                                route: { name: 'school-students' },
                                displayName: 'Students'
                            },
                            {
                                id: 221,
                                route: { name: 'school-volunteers' },
                                displayName: 'Volunteers'
                            }
                        ]
                    },
                    {
                        id: 6,
                        displayName: 'Fair Management',
                        route: { name: 'fa-mgmt-fair-management' }
                    }
                ],
                employerLinks: [
                    {
                        id: 30,
                        route: { name: 'employer-registration-info' },
                        displayName: 'Registration Information'
                    },
                    {
                        id: 31,
                        route: { name: 'employer-applications' },
                        displayName: 'Applications'
                    },
                    {
                        id: 32,
                        route: { name: 'employer-faq' },
                        displayName: 'FAQ'
                    }
                ],
                attendeeLinks: [
                    {
                        id: 41,
                        route: { name: 'attendee-profile' },
                        displayName: 'My Info & Availability'
                    },
                    {
                        id: 42,
                        route: { name: 'attendee-applications' },
                        displayName: 'Applications'
                    }
                ]                
            };
        },

        props: {
            showLinks: {
                type: Boolean,
                default: true
            }
        },

        computed: {
            ...mapGetters(['userHomepage', 'oidcIsAuthenticated', 'userRoles', 'isApproved', 'isEmployerRegistering']),

            displayProtectedLink() {
                if (this.oidcIsAuthenticated && this.isEmployer && this.isApproved) {
                    return !this.isEmployerRegistering;
                } else {
                    return this.oidcIsAuthenticated && this.isApproved;
                }
            },

            isFairAdmin() {
                if (this.userRoles == null) return false;

                return this.userRoles.indexOf(troyesRole.FairAdmin) != -1;
            },

            isSchoolAdmin() {
                if (this.userRoles == null) return false;

                return this.userRoles.indexOf(troyesRole.SchoolAdmin) != -1;
            },

            isEmployer() {
                if (this.userRoles == null) return false;

                return this.userRoles.indexOf(troyesRole.Employer) != -1;
            },

            isAttendee() {
                if (this.userRoles == null) return false;

                return this.userRoles.indexOf(troyesRole.Attendee) != -1;
            },

            navDropdowns() {
                let navDropdowns = [];

                if (this.isFairAdmin) {
                    this.fairAdminNav.forEach((route, index) => {
                        if('links' in route) {
                            navDropdowns.push({
                                id: index + 1,
                                name: route.displayName,
                                links: route.links
                            });
                        }
                    });
                }

                return navDropdowns;
            },

            navLinks() {
                if (!this.oidcIsAuthenticated || this.userRoles == null) return null;

                let navLinks = [];

                if (this.isFairAdmin) {
                    this.fairAdminNav.forEach(route => {
                        if(route.links == null) {
                            navLinks.push(route);
                        }
                    });
                } else if (this.isSchoolAdmin && !this.isFairAdmin) {
                    navLinks = union(navLinks, this.schoolAdminLinks);
                } else if (this.isEmployer) {
                    if (this.isApproved && !this.isEmployerRegistering) {
                        navLinks = union(navLinks, this.employerLinks);
                    }
                } else if (this.isAttendee) {
                    navLinks = union(navLinks, this.attendeeLinks);
                }

                return navLinks;
            }
        },

        methods: {
            ...mapActions(['authenticateOidc', 'setUserHomepage']),

            routeUser() {
                if (this.oidcIsAuthenticated) {
                    this.setUserHomepage().then(() => {
                        // Vuerouter does not like pushing to the same address
                        if (this.$store.state.route.name != this.userHomepage.name) {
                            this.$router.push(this.userHomepage);
                        }
                    });
                } else {
                    this.authenticateOidc();
                }
            }
        }
    };
</script>