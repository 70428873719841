<template>
    <form-validator ref="form" hide-errors>
        <ParticipationPlanEdit ref="participationForm">
            <template v-slot:footer>
                <div class="card-content buttons">
                    <b-button type="is-success" icon-right="save" @click="submit">
                        Save Participation Plan
                    </b-button>
                </div>
            </template>
            <b-loading :isFullPage="false" :active.sync="isEmployerStoreLoading"></b-loading>
        </ParticipationPlanEdit>
    </form-validator>
</template>

<script>
    import { mapGetters } from 'vuex';

    import FormValidator from '@/components/formControls/base/FormValidator';
    import ParticipationPlanEdit from '@/components/employer/participationPlan/ParticipationPlanEdit';
    import ConfirmLeaveMixin from '@/mixins/ConfirmLeaveMixin';

    export default {
        name: 'ParticipationForm',

        components: {
            FormValidator,
            ParticipationPlanEdit
        },

        mixins: [ConfirmLeaveMixin],

        methods: {
            submit() {
                this.validateAndSave(this.$refs.participationForm.save); 
            }
        },

        computed: {
            ...mapGetters(['employerFairsLoaded', 'isEmployerStoreLoading', 'activeEmployer']),
        },
    };
</script>