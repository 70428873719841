var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form-validator", {
    ref: "form",
    attrs: { "hide-errors": "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ valid, dirty }) {
          return _c(
            "div",
            {},
            [
              _c("EmployerContacts", {
                ref: "contactForm",
                attrs: { splitview: "" },
              }),
              _c("br"),
              _c(
                "div",
                { staticClass: "buttons is-marginless" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        type: "is-success",
                        "icon-right": "save",
                        disabled: !valid && dirty,
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" Save Contacts ")]
                  ),
                ],
                1
              ),
              _c("b-loading", {
                attrs: {
                  isFullPage: false,
                  active: _vm.isEmployerStoreLoading,
                },
                on: {
                  "update:active": function ($event) {
                    _vm.isEmployerStoreLoading = $event
                  },
                },
              }),
            ],
            1
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }