<template>
    <card title="Students">
        Student data for School Admins goes here.
        <hr>

        <card>
            <list rowId="studentId">
                <template v-slot:empty>
                    The Student list is empty
                </template>
            </list>
        </card>
    </card>
</template>

<script>
    export default {
        name: 'SchoolStudentView'
    };
</script>