import axios from './index';

export const loadUserRegistrations = () => {
    return axios.get('/api/registrations');
};

export const approvePrimaryFairContactForEmployer = (newUserGuid, employerId = null) => {
    if (employerId != null) {
        return axios.post(`/api/registrations/${newUserGuid}/employers/${employerId}/approve`);
    } else {
        return axios.post(`/api/registrations/${newUserGuid}/approve`);
    }
};

export const rejectPrimaryFairContactForEmployer = (userGuid) => {
    return axios.post(`/api/registrations/${userGuid}/reject`);
};

export default 'RegistrationApi';
