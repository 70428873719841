var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "nav",
      {
        staticClass:
          "navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow",
      },
      [
        _c(
          "a",
          {
            staticClass: "navbar-brand col-sm-3 col-md-2 mr-0",
            attrs: { href: "#/" },
          },
          [_vm._v("VueJs")]
        ),
        _c("ul", { staticClass: "navbar-nav px-3" }, [
          _c("li", { staticClass: "nav-item text-nowrap" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                on: {
                  click: function ($event) {
                    return _vm.mgr.signOut()
                  },
                },
              },
              [_vm._v("Sign out")]
            ),
          ]),
        ]),
      ]
    ),
    _c("br"),
    _vm._v("> "),
    _c("br"),
    _c("br"),
    _c("br"),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm", attrs: { align: "center" } }, [
          _c("span", { staticStyle: { "font-size": "30px" } }, [
            _vm._v("Access Denied"),
          ]),
          _c("br"),
          _c("span", { staticStyle: { "font-size": "15px" } }, [
            _vm._v("Click"),
          ]),
          _c(
            "a",
            { staticStyle: { "font-size": "15px" }, attrs: { href: "#/" } },
            [_vm._v("Hear")]
          ),
          _c("span", { staticStyle: { "font-size": "15px" } }, [
            _vm._v("Return to home page"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }