<template>
    <section>
        <h3 class="title">My Information</h3>
        <div>
            <b-field grouped>
                <b-field label="First Name">
                    <b-input v-model="profile.firstName"></b-input>
                </b-field>

                <b-field label="Middle Initial" expanded>
                    <b-input v-model="profile.middleInitial"></b-input>
                </b-field>

                <b-field label="Last Name" expanded>
                    <b-input v-model="profile.lastName"></b-input>
                </b-field>
            </b-field>

            <b-field grouped>
                <b-field label="School">
                    <b-input v-model="profile.school"></b-input>
                </b-field>
                <b-field label="Student ID" expanded>
                    <b-input v-model="profile.studentId"></b-input>
                </b-field>

                <b-field label="Class Year" expanded>
                    <b-select v-model="profile.classYear" placeholder="Select class year">
                        <option value="1">1L</option>
                        <option value="2">2L</option>
                        <option value="3">3L</option>
                        <option value="4">LLM</option>
                        <option value="5">Alumni</option>
                    </b-select>
                </b-field>
            </b-field>

            <b-field label="Address">
                <b-input v-model="profile.streetAddress"></b-input>
            </b-field>

            <b-field grouped>
                <b-field label="City" expanded>
                    <b-input v-model="profile.city"></b-input>
                </b-field>

                <b-field label="State" expanded>
                    <b-input v-model="profile.state"></b-input>
                </b-field>

                <b-field label="Zip" expanded>
                    <b-input v-model="profile.zipCode"></b-input>
                </b-field>
            </b-field>

            <b-field label="Email">
                <b-input type="email" v-model="profile.emailAddress"></b-input>
            </b-field>
        </div>

        <p class="has-text-right">
            <br />
            <input
                class="button is-primary"
                type="submit"
                @click="updateProfile"
                value="Update Information"
            />
        </p>
    </section>
</template>

<script>
    import {
        getProfileInformation,
        updateProfileInformation,
    } from '@/api/attendee';
    export default {
        name: 'AttendeeProfileInformation',

        data() {
            return {
                profile: [],
            };
        },

        methods: {
            loadProfileInformation() {
                getProfileInformation(13).then(response => {
                    this.profile = response.data;
                });
            },

            updateProfile() {
                updateProfileInformation(this.profile).then(() => {
                    this.$toast({
                        message:
                            'Success: Profile information successfully updated.',
                        duration: 5000,
                    });
                    this.loadProfileInformation();
                });
            },
        },

        created() {
            this.loadProfileInformation();
        },
    };
</script>