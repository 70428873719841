var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("MainRightSidebar", [
    _c("p", [
      _vm._v(
        "We can put something over here in the Fair Admin main sidebar, too!"
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }