var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Card",
    {
      attrs: { title: "Participation Plan" },
      scopedSlots: _vm._u(
        [
          {
            key: "footer",
            fn: function () {
              return [_vm._t("footer")]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    _vm._l(_vm.participationPlans, function (plan) {
      return _c(
        "form-validator",
        { key: plan.fairDate },
        [
          _c(
            "field-validator",
            {
              attrs: {
                name: `attendance on ${_vm.$options.filters.date(
                  plan.fairDate,
                  "MM/DD/YY"
                )}`,
              },
            },
            [
              _c("AffirmOrDenyQuestionControl", {
                attrs: {
                  center: "",
                  outputStyle: "inline",
                  value: plan.isAttending ? "Yes" : "No",
                  question: `Will you attend the Fair on (${_vm.$options.filters.date(
                    plan.fairDate,
                    "MM/DD/YY"
                  )})?`,
                  readonly: _vm.readonly,
                },
                on: {
                  input: function ($event) {
                    return _vm.updatePlan($event === "Yes", plan)
                  },
                },
              }),
            ],
            1
          ),
          plan.isAttending
            ? _c("RequestControl", {
                attrs: { value: plan, disabled: _vm.readonly },
                on: {
                  input: function ($event) {
                    return _vm.updatePlan(plan.isAttending, $event)
                  },
                },
              })
            : _vm._e(),
          _c("hr"),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }