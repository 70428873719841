<template>
    <div class="content">
        <b-tabs>
            <b-tab-item label="Employers">
                <employer-approval-list />
            </b-tab-item>

            <b-tab-item label="Primary Contacts">
                <FairContactList />
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
    import EmployerApprovalList from '@/components/fairAdmin/EmployerApprovalList.vue';
    import FairContactList from '@/views/fairAdmin/employers/FairContactList';

    export default {
        name: 'UserApproval',

        components: { EmployerApprovalList, FairContactList }
    };
</script>