var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._v(
      "Your user account has not yet been approved. Please check back later."
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }