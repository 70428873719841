<template>
    <div>
        <b-field grouped="grouped" group-multiline>
            <b-field label="Select a Fair Date">
                <b-select 
                    aria-label="Select a fair date" 
                    v-model="fairDate" 
                    placeholder="e.g. 02/06/2019" 
                    @input="changeFairDate"
                    expanded>
                    <option v-for="(date, index) in fairDays" :key="index" :value="date.date">
                        {{ date.date | date('MM/DD/YYYY') }}
                    </option>
                </b-select>
            </b-field>
            <b-field label="Select a building">
                <b-select 
                    aria-label="Select a building" 
                    v-model="building" 
                    placeholder="e.g. Vanderbilt Hall" 
                    @input="changeBuilding"
                    expanded>
                    <option v-for="b in buildings" :key="b.buildingId" :value="b">
                        {{ b.name }}
                    </option>
                </b-select>
            </b-field>
            <b-field v-if="canSelectRoom" label="Select a room">
                <b-select aria-label="Select a room" v-model="room" placeholder="e.g. Some Hall" expanded>
                    <option v-for="r in roomsInBuilding" :key="r.roomId" :value="r">
                        {{ r.name }}
                    </option>
                </b-select>
            </b-field>
        </b-field>
        
        <br>
        
        <p>
            You can assign reservations by dragging rows on the right hand side to any of the tables below.
            You can move reservations around and even unassign them by clicking on the trash icon or alternatively dragging a reservation
            outside of its table.
        </p>

        <br>

        <div class="columns">
            <div class="column" v-if="showReservations && roomConfig != null">
                <div class="grid well">
                    <locale 
                        v-for="(table, index) in tableWithReservations" 
                        :table="table" 
                        :key="index" 
                        @assign="assign"
                        @move="move"
                        @swap="swap"
                        @remove="remove"
                        @allDayAssign="assignAllDay" />

                    <section class="section" v-if="roomConfig.numSeats == 0 ">
                        <div class="content has-text-grey has-text-centered">
                            <p class="title is-6"> 
                                {{ 'This room has no seats.' }}
                            </p>
                        </div>
                    </section>
                </div>
            </div>
            <div class="column is-narrow notification" v-if="showReservations" >
                <unassigned-reservations :reservations="empFairReservations" />
            </div>
        </div>
        <b-loading :isFullPage="false" :active.sync="loading"></b-loading>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Locale from '@/components/fairAdmin/Locale';
    import UnassignedReservations from '@/components/fairAdmin/UnassignedReservations';

    export default {
        name: 'FairAdminTableAssignment',

        components: {
            Locale,
            UnassignedReservations
        },

        data() {
            return {
                fairDate: null,
                building: null,
                room: null,
                loading: false
            };
        },

        async mounted() {
            await this.loadTableSummary();
        },

        methods: {
            ...mapActions([
                'assignReservation', 
                'assignReservations', 
                'loadTableSummary', 
                'moveReservation', 
                'swapReservations',
                'unassignReservation'
            ]),

            changeFairDate() {
                this.building = null;
                this.room = null;
            },

            changeBuilding() {
                this.room = null;
            },

            getMoveOrAssignPayload: (reservationId, roomId, tableNumber) => ({ reservationId, roomId, tableNumber }),

            assign({ tableNumber, sessionType }) {
                const reservation = this.draggingReservation.reservations.find(r => r.sessionType == sessionType);
                const payload = this.getMoveOrAssignPayload(reservation.id, this.room.id, tableNumber);
                this.load(this.assignReservation(payload));
            },

            assignAllDay(tableNumber) {
                const reservationIds = this.draggingReservation.reservations.map(r => r.id);
                const payload = {reservationIds, roomId: this.room.id, tableNumber};
                this.load(this.assignReservations(payload));
            },

            move(tableNumber) {
                const payload = this.getMoveOrAssignPayload(this.draggingReservation.id, this.room.id, tableNumber);
                this.load(this.moveReservation(payload));
            },

            /** The reservation id of the other table */
            swap(targetId) {
                this.load(this.swapReservations({ sourceId: this.draggingReservation.id, targetId }));
            },

            remove(reservationId) {
                this.load(this.unassignReservation(reservationId));
            },

            async load(func) {
                this.loading = true;
                await func;
                this.loading = false;
            }
        },

        computed: {
            ...mapGetters([
                'assignedReservations',
                'buildings', 
                'draggingReservation',
                'fairDays', 
                'fairRoomConfig',
                'getEnum',
                'rooms', 
                'unassignedReservations'
            ]),

            canSelectRoom() {
                return this.building != null && this.fairDate != null;
            },

            showReservations() {
                return this.canSelectRoom && this.room != null;
            },

            empFairReservations() {
                let filtered = this.unassignedReservations.filter(r => new Date(r.day).getDate() == new Date(this.fairDate).getDate());

                if (this.roomConfig != null && this.roomConfig.roomActivity != null) {
                    filtered = filtered.filter(r => r.roomType == this.roomConfig.roomActivity);
                }

                let reduction = filtered.reduce((accumulator, currentValue) => {
                    const empFair = accumulator.find(obj => obj.id == currentValue.employerFairId);

                    const reservation = { id: currentValue.id, sessionType: currentValue.sessionType };
                    
                    if (empFair != null) {
                        empFair.reservations.push(reservation);
                    }
                    else {
                        accumulator.push({
                            id: currentValue.employerFairId,
                            name: currentValue.employerName,
                            requestedRoomType: currentValue.roomType,
                            reservations: [reservation]
                        });
                    }
                    return accumulator;
                }, []);

                return reduction;
            },

            tableWithReservations() {
                const tables = [];

                if (this.roomConfig != null) {
                    for (let i = 1; i <= this.roomConfig.numSeats; i++) {
                        const table ={
                            number: i,
                            reservationsByType: []
                        };

                        this.sessionTypes.forEach(sessionType => {
                            /** 
                             * If a reservation exists in this room on this day for this day portion and at this table index:
                             * Add it to the table along with its matching session type
                             */
                            const reservation = this.assignedReservations.find(res => {
                                // equivalent to: 
                                //      res.roomConfig.room.id == this.room.id
                                const roomsMatch = res.roomId == this.room.id;
                                const tablesMatch = res.assignedTable == i;
                                const sessionTypesMatch = res.sessionType == sessionType.id;
                                const daysMatch = new Date(res.day).getDate() == new Date(this.fairDate).getDate();

                                return tablesMatch && roomsMatch && sessionTypesMatch && daysMatch;
                            });

                            table.reservationsByType.push({
                                sessionType: sessionType.id,
                                reservation
                            });
                        });

                        tables.push(table);
                    }
                }

                return tables;
            },

            roomsInBuilding() {
                return this.rooms(this.building && this.building.id);
            },

            roomConfig() {
                return this.fairRoomConfig(this.room && this.room.id);
            },

            sessionTypes() {
                return this.getEnum('SessionType');
            }
        }
    };
</script>