<template>
    <TaggedMultiSelectControl
        :horizontal="horizontal"
        controlLabel="Subject Areas"
        toolTip="Select as many as apply using Ctrl/Command + Click"
        valueColumn="description"
        placeholder="Add Subject Area"
        :centerText="false"
        :wrapHeader="wrapHeader"
        :leftColumnCss="leftColumnCss"
        :rightColumnCss="rightColumnCss"
        :options="availiableSubjectAreas"
        :value="selectedSubjectAreas"
        @input="update"
        v-bind="$attrs" />
</template>

<script>
    import { mapGetters } from 'vuex';
    import difference from 'lodash/difference';

    import AlignmentControlPropsMixin from '@/mixins/AlignmentControlPropsMixin';
    import FormControlPropsMixin from '@/mixins/FormControlPropsMixin';
    import FormInputMixin from '@/mixins/FormInputMixin';
    import TaggedMultiSelectControl from '@/components/formControls/base/TaggedMultiSelectControl';

    export default {
        name: 'SubjectAreasDropdown',

        mixins: [ AlignmentControlPropsMixin, FormControlPropsMixin, FormInputMixin ],

        components: {
            TaggedMultiSelectControl
        },

        props: {
            value: Array
        },

        data() {
            return {
                inputValue: this.value
            };
        },

        methods: {
            update(value) {
                this.$emit('input', value);
            }
        },

        computed: {
            ...mapGetters(['getSubjectAreas']),

            selectedSubjectAreas() {
                if (this.inputValue == null) return [];
                
                return this.getSubjectAreas.filter(pt => this.inputValue.some(v => v.id === pt.id));
            },

            availiableSubjectAreas() {
                return difference(this.getSubjectAreas, this.selectedSubjectAreas);
            }
        },

        watch: {
            value: {
                handler(newVal) {
                    this.inputValue = newVal;
                }
            }
        }
    };
</script>
