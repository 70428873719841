<template>
    <section>
        <div v-if="employerFairsLoaded" class="tabs">
            <ul>
                <router-link :to="{ name: tab.routeName }" v-for="tab in tabs" :key="tab.index" exact tag="li">
                    <a>
                        {{ tab.label }}
                    </a>
                </router-link>
            </ul>
        </div>
        <router-view></router-view>
    </section>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'EmployerRegistrationInformation',

        data() {
            return {
                willConductInterviews: false,
                tabs: {
                    orgDetails: {
                        index: 0,
                        label: 'Organization Details',
                        routeName: 'employer-organization-tab'
                    },
                    contacts: {
                        index: 1,
                        label: 'Contacts',
                        routeName: 'employer-contact-tab'
                    },
                    participation: {
                        index: 2,
                        label: 'Participation',
                        routeName: 'employer-participation-tab'
                    },
                    opportunities: {
                        index: 3,
                        label: 'Opportunities',
                        routeName: 'employer-opportunity-tab'
                    }
                },
            };
        },

        async mounted() {
            await this.loadActiveEmployerFair();
        },

        methods: {
        ...mapActions(['loadActiveEmployerFair']),
        },

        computed: {
        ...mapGetters(['isEmployerStoreLoading', 'employerFairsLoaded', 'activeEmployer']),

        isPageLoading() {
            return this.isEmployerStoreLoading || !this.employerFairsLoaded;
        },
        }
    };
</script>

<style scoped>
div.card>>>footer.card-footer {
    background-color: whitesmoke;
}
</style>