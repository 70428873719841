<template>
    <div>
        <div class="columns">
            <div class="column is-half">
                <span class="has-text-weight-bold has-margin-right-sm">Name:</span>
                <span class="content">{{ activeEmployer.name }}</span>
            </div>
            <div class="column">
                <span class="has-text-weight-bold has-margin-right-sm">Organization Type:</span>
                <span class="content">{{ activeEmployer.organizationType.description }}</span>
            </div>
        </div>
        <div class="columns">
            <div class="column is-half">
                <span class="has-text-weight-bold has-margin-right-sm">City:</span>
                <span class="content">{{ activeEmployer.city }}</span>
            </div>
            <div class="column">
                <span class="has-text-weight-bold has-margin-right-sm">State:</span>
                <span class="content">{{ activeEmployer.state }}</span>
            </div>
        </div>
        <div class="columns">
            <div class="column is-narrow has-padding-right-none">
                <span class="has-text-weight-bold has-margin-right-sm">Url:</span>
            </div>
            <div class="column has-padding-left-none">
                <span class="content">{{ activeEmployer.url }}</span>
            </div>
        </div>
        <div class="columns">
            <div class="column is-half">
                <span class="has-text-weight-bold has-margin-right-sm">Practice Types:</span>
                <span class="content">
                    <b-tag v-for="practiceType in activeEmployer.practiceTypes" :key="practiceType.id">
                        {{ practiceType.description }}
                    </b-tag>
                </span>
            </div>
            <div class="column is-half">
                <span class="has-text-weight-bold has-margin-right-sm">Subject Areas:</span>
                <span class="content">
                    <b-tag v-for="subjectArea in activeEmployer.subjectAreas" :key="subjectArea.id">
                        {{ subjectArea.description }}
                    </b-tag>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'EmployerProfileView',

        data() {
            return {
                leftColumnCssClass: 'column is-4 has-text-right',
                rightColumnCssClass: 'column'
            };
        },

        computed: {
            ...mapGetters(['activeEmployer'])
        }
    };
</script>