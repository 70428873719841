var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-collapse",
    {
      staticClass: "card",
      scopedSlots: _vm._u([
        {
          key: "trigger",
          fn: function (props) {
            return _c(
              "div",
              { staticClass: "card-header background-white-ter" },
              [
                _c("p", { staticClass: "card-header-title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _c(
                  "a",
                  { staticClass: "card-header-icon" },
                  [
                    _c("b-icon", {
                      attrs: { icon: props.open ? "angle-down" : "angle-up" },
                    }),
                  ],
                  1
                ),
              ]
            )
          },
        },
      ]),
    },
    [
      _c("div", { staticClass: "card-content" }, [_vm._t("default")], 2),
      _c("footer", { staticClass: "card-footer" }, [_vm._t("footer")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }