var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column is-half" }, [
        _c(
          "span",
          { staticClass: "has-text-weight-bold has-margin-right-sm" },
          [_vm._v("Name:")]
        ),
        _c("span", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.activeEmployer.name)),
        ]),
      ]),
      _c("div", { staticClass: "column" }, [
        _c(
          "span",
          { staticClass: "has-text-weight-bold has-margin-right-sm" },
          [_vm._v("Organization Type:")]
        ),
        _c("span", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.activeEmployer.organizationType.description)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column is-half" }, [
        _c(
          "span",
          { staticClass: "has-text-weight-bold has-margin-right-sm" },
          [_vm._v("City:")]
        ),
        _c("span", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.activeEmployer.city)),
        ]),
      ]),
      _c("div", { staticClass: "column" }, [
        _c(
          "span",
          { staticClass: "has-text-weight-bold has-margin-right-sm" },
          [_vm._v("State:")]
        ),
        _c("span", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.activeEmployer.state)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "columns" }, [
      _vm._m(0),
      _c("div", { staticClass: "column has-padding-left-none" }, [
        _c("span", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.activeEmployer.url)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column is-half" }, [
        _c(
          "span",
          { staticClass: "has-text-weight-bold has-margin-right-sm" },
          [_vm._v("Practice Types:")]
        ),
        _c(
          "span",
          { staticClass: "content" },
          _vm._l(_vm.activeEmployer.practiceTypes, function (practiceType) {
            return _c("b-tag", { key: practiceType.id }, [
              _vm._v(" " + _vm._s(practiceType.description) + " "),
            ])
          }),
          1
        ),
      ]),
      _c("div", { staticClass: "column is-half" }, [
        _c(
          "span",
          { staticClass: "has-text-weight-bold has-margin-right-sm" },
          [_vm._v("Subject Areas:")]
        ),
        _c(
          "span",
          { staticClass: "content" },
          _vm._l(_vm.activeEmployer.subjectAreas, function (subjectArea) {
            return _c("b-tag", { key: subjectArea.id }, [
              _vm._v(" " + _vm._s(subjectArea.description) + " "),
            ])
          }),
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "column is-narrow has-padding-right-none" },
      [
        _c(
          "span",
          { staticClass: "has-text-weight-bold has-margin-right-sm" },
          [_vm._v("Url:")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }