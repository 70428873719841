<template>
    <section>
        <b-field grouped>
            <b-field>
                <b-select placeholder="User Status" v-model="selectedStatus" @input="clearSelectedRow()">
                    <option v-for="(status, index) in userStatuses"
                        :value="status.value"
                        :key="index">
                        {{ status.display }}
                    </option>
                </b-select>
                <p class="control">
                    <Close v-if="selectedStatus != null" @close="clearFilter()" />
                </p>
            </b-field>
        </b-field>

        <b-table ref="list"
            detailed
            striped
            narrowed
            hoverable
            :detail-key="detailKey"
            :show-detail-icon="false"
            :data="filteredView"
            :loading="isLoading"
            @details-open="$emit('toggle-row')"
            @details-close="$emit('toggle-row')"
            @select="$emit('toggle-row')">
            <template v-slot:default="props">
                <slot v-bind:row="{ ...props.row }">
                    <b-table-column field="first_name" label="First Name">
                        {{ props.row.first_name }}
                    </b-table-column>

                    <b-table-column field="last_name" label="Last Name">
                        {{ props.row.last_name }}
                    </b-table-column>

                    <b-table-column field="email" label="Email">
                        {{ props.row.email }}
                    </b-table-column>
                </slot>

                <b-table-column field="status" label="Status" v-if="showStatusRow">
                    {{ props.row.status | ToDisplay(userStatuses) }}
                </b-table-column>

                <b-table-column :label="actionLabel" class="has-text-right">
                    <slot name="actions" v-bind:row="{ ...props.row }"></slot>
                </b-table-column>

                <b-table-column>
                    <div class="column has-text-right">
                        <a>
                            <b-icon :class="props.row.open ? 'is-expanded' : ''" icon="angle-right" @click.native.self.stop="toggleRow(props.row)"></b-icon>
                        </a>
                    </div>
                </b-table-column>
            </template>

            <template slot="detail" slot-scope="props">
                <slot name="detail" :row="{ ...props.row }">Default detail section</slot>
            </template>

            <template slot="empty">
                <slot name="empty">
                    <section class="section">
                        <p>No users.</p>
                    </section>
                </slot>
            </template>
        </b-table>
    </section>
</template>

<script>
    import Close from '@/components/actions/Close';

    export default {
        name: 'UserList',

        components: {
            Close
        },

        props: {
            value: Array,
            actionLabel: {
                type: String,
                default: ''
            },
            showStatusRow: {
                type: Boolean,
                default: true
            },
            defaultFilterValue: {
                type: String,
                default: null,
                validator: (val) => val == null || typeof val == 'string'
            },
            isLoading: {
                type: Boolean,
                default: false
            },
            detailKey: {
                type: String,
                default: 'id'
            }
        },

        data() {
            return {
                selectedRow: null,
                userStatuses: [
                    {
                        display: 'Approved',
                        value: 'approved'
                    },
                    {
                        display: 'Pending',
                        value: 'pending_review'
                    },
                    {
                        display: 'Rejected',
                        value: 'rejected'
                    }
                ],
                selectedStatus: this.defaultFilterValue
            };
        },

        computed: {
            filteredView() {
                if (this.selectedStatus != null && this.selectedStatus !== 'all') {
                    return this.value.filter(v => v.status === this.selectedStatus);
                } else {
                    return this.value;
                }
            }
        },

        methods: {
            toggleRow(row) {
                if (this.selectedRow != null) {
                    if (this.selectedRow[this.detailKey] === row[this.detailKey]) {
                        this.closeDetailsRow(this.selectedRow);
                        this.$emit('row-toggled', this.selectedRow);
                        this.selectedRow = null;
                        return;
                    }

                    this.clearSelectedRow();
                }

                this.selectedRow = row;
                this.openDetailsRow(row);
                this.$emit('row-toggled', row);
            },

            closeDetailsRow(row) {
                this.$refs.list.closeDetailRow(row);
            },

            openDetailsRow(row) {
                this.$refs.list.openDetailRow(row);
            },

            clearFilter() {
                this.selectedStatus = null;
                this.clearSelectedRow();
            },

            clearSelectedRow() {
                if (this.selectedRow != null) {
                    this.closeDetailsRow(this.selectedRow);
                    this.$emit('row-toggled', this.selectedRow);
                    this.selectedRow = null;
                }
            }
        },

        filters: {
            ToDisplay(value, statuses) {
                return statuses.find(s => s.value === value).display;
            }
        }
    };
</script>