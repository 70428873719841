var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.oidcIsAuthenticated
    ? _c(
        "b-navbar-item",
        { attrs: { tag: "div" } },
        [
          _c(
            "b-dropdown",
            {
              attrs: {
                hoverable: "",
                position: "is-bottom-left",
                "aria-role": "menu",
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "button is-primary",
                  attrs: { slot: "trigger", role: "button" },
                  slot: "trigger",
                },
                [
                  _c("b-icon", {
                    attrs: { icon: "user-circle", size: "is-medium" },
                  }),
                ],
                1
              ),
              _c(
                "b-dropdown-item",
                { attrs: { custom: "", "aria-role": "menuitem" } },
                [
                  _vm._v(" Logged as "),
                  _c("b", [_vm._v(_vm._s(_vm.oidcUser.given_name))]),
                ]
              ),
              _c("hr", { staticClass: "dropdown-divider" }),
              _c(
                "b-dropdown-item",
                {
                  attrs: {
                    value: "logout",
                    "aria-role": "menuitem",
                    "has-link": true,
                  },
                },
                [
                  _c("a", { attrs: { href: "#" }, on: { click: _vm.logout } }, [
                    _vm._v("Logout"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "a",
        { staticClass: "navbar-item", on: { click: _vm.authenticateOidc } },
        [_vm._v("Login")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }