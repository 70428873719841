<template>
    <b-loading :active.sync="isPageLoading"></b-loading>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'AuthCallback',

        mounted() {
            this.isLoading = true;
            this.oidcSignInCallback()
                .then(redirectPath => {
                    let isValidRedirectPath = (typeof redirectPath === 'string' || redirectPath instanceof String);

                    if (isValidRedirectPath && redirectPath !== '/') {
                        this.redirectUrl = redirectPath;
                    }

                    this.isLoading = false;
                    this.setUserHomepage().then(() => {
                        if (this.redirectUrl === null || this.redirectUrl === undefined) {
                            this.$router.push(this.userHomepage);
                        } else {
                            this.$router.push(this.redirectUrl);
                        }
                    });
                })
                .catch(() => {
                    this.$router.push({ name: 'oidcCallbackError' });
                });
        },

        data() {
            return {
                isLoading: false
            };
        },

        computed: {
            ...mapGetters(['userHomepage', 'isStoreLoading']),

            isPageLoading() {
                return this.isLoading || this.isStoreLoading;
            }
        },

        methods: {
            ...mapActions(['oidcSignInCallback', 'setUserHomepage'])
        }
    };
</script>