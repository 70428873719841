var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns" }, [
    _c(
      "div",
      { staticClass: "column is-one-fifth" },
      [
        _c("card", [
          _c("aside", { staticClass: "menu" }, [
            _c("p", { staticClass: "menu-label" }, [
              _vm._v(" Administration "),
            ]),
            _c(
              "ul",
              { staticClass: "menu-list" },
              _vm._l(_vm.tabRoutes, function (link, index) {
                return _c(
                  "li",
                  { key: index, staticClass: "is-active" },
                  [
                    _c("router-link", { attrs: { to: { name: link.name } } }, [
                      _vm._v(" " + _vm._s(link.displayLabel) + " "),
                    ]),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "column" },
      [
        _c(
          "card",
          { attrs: { title: _vm.activeTabHeaderLabel() } },
          [_c("router-view")],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }