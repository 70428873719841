var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "columns is-desktop is-multiline" },
      [_vm._t("firstRow")],
      2
    ),
    _c("div", { staticClass: "columns is-desktop is-multiline" }, [
      _vm._m(0),
      _c("div", { staticClass: "column has-text-left" }, [
        _c("span", { staticClass: "content" }, [
          _vm._v(
            " " +
              _vm._s(_vm.user.firstName) +
              ", " +
              _vm._s(_vm.user.lastName) +
              " "
          ),
        ]),
      ]),
      _vm._m(1),
      _c("div", { staticClass: "column has-text-left" }, [
        _c("span", { staticClass: "content" }, [
          _vm._v(" " + _vm._s(_vm.user.preferredName) + " "),
        ]),
      ]),
      _vm._m(2),
      _c("div", { staticClass: "column has-text-left" }, [
        _c("span", { staticClass: "content" }, [
          _vm._v(" " + _vm._s(_vm.user.phone) + " "),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "columns is-desktop is-multiline" }, [
      _vm._m(3),
      _c("div", { staticClass: "column has-text-left" }, [
        _c("span", { staticClass: "content" }, [
          _vm._v(" " + _vm._s(_vm.user.email) + " "),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "columns is-desktop is-multiline" },
      [_vm._t("lastRow")],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Name:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Preferred Name:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("label", { staticClass: "label" }, [_vm._v(" Phone: ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-narrow" }, [
      _c("label", { staticClass: "label" }, [_vm._v("Email:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }