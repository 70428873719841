import { troyesRole } from '@/helpers/staticValues';
import Standard from '@/views/shared/layouts/Standard';

// School Admin Components
import SchoolEmployerView from '@/views/school/SchoolEmployerView';
import SchoolSidebar from '@/views/school/Sidebar';
import SchoolStudentView from '@/views/school/SchoolStudentView';
import SchoolView from '@/views/school/Index';
import SchoolVolunteers from '@/views/school/SchoolVolunteers';

var children = [
    {
        path: '',
        name: 'school-home',
        meta: {
            showSidebar: true,
        },
        components: {
            default: SchoolView,
            sidebar: SchoolSidebar
        }
    },
    {
        path: 'employers',
        name: 'school-employers',
        meta: {
            showSidebar: true,
        },
        components: {
            default: SchoolEmployerView,
            sidebar: SchoolSidebar
        }
    },
    {
        path: 'students',
        name: 'school-students',
        meta: {
            showSidebar: true,
        },
        components: {
            default: SchoolStudentView,
            sidebar: SchoolSidebar
        }
    },
    {
        path: 'volunteers',
        name: 'school-volunteers',
        meta: {
            showSidebar: true,
        },
        components: {
            default: SchoolVolunteers,
            sidebar: SchoolSidebar
        }
    }
];

export default {
    path: '/school',
    component: Standard,
    meta: {
        isPublic: false,
        accessRole: troyesRole.SchoolAdmin
    },
    children
};
