<template>
    <FormControl
        :horizontal="horizontal"
        :controlLabel="controlLabel"
        :toolTip="toolTip"
        :centerText="centerText"
        :wrapHeader="wrapHeader"
        :leftColumnCss="leftColumnCss"
        :rightColumnCss="rightColumnCss">
        <b-input
            v-model="inputValue"
            @input="updateValue()"
            @blur="blur"
            :placeholder="placeholder"
            :disabled="readonly"
            v-bind="$attrs" />
    </FormControl>
</template>

<script>
    import AlignmentControlPropsMixin from '@/mixins/AlignmentControlPropsMixin';
    import FormControlPropsMixin from '@/mixins/FormControlPropsMixin';
    import FormInputMixin from '@/mixins/FormInputMixin';
    import FormControl from '@/components/formControls/base/FormControl';

    export default {
        name: 'InputControl',

        mixins: [ AlignmentControlPropsMixin, FormControlPropsMixin, FormInputMixin ],

        components: {
            FormControl
        },

        props: {
            placeholder: String,
            readonly: Boolean
        },

        computed: {
            customCss() {
                return this.horizontal ? 'no-wrap' : '';
            }
        },

        methods: {
            blur() {
                this.$emit('blur');
            }
        }
    };
</script>