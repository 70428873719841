var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field-validator",
    { attrs: { name: "Employer" } },
    [
      _c(
        "b-autocomplete",
        {
          attrs: {
            placeholder: "Employer Name",
            "open-on-focus": true,
            data: _vm.filteredDataArray,
            field: "name",
          },
          on: { select: _vm.selectEmployer },
          model: {
            value: _vm.selectedEmployerName,
            callback: function ($$v) {
              _vm.selectedEmployerName = $$v
            },
            expression: "selectedEmployerName",
          },
        },
        [_c("template", { slot: "empty" }, [_vm._v("No employers found")])],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }