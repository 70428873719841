var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-loading", {
    attrs: { active: _vm.isPageLoading },
    on: {
      "update:active": function ($event) {
        _vm.isPageLoading = $event
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }