var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("strong", [_vm._v(_vm._s(_vm.application.opportunityTitle))]),
    _c("br"),
    _vm._v(_vm._s(_vm.application.employerName) + " "),
    _c("br"),
    _vm._v("Ranking: " + _vm._s(_vm.application.ranking) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }