<template>
    <card title="Frequently Asked Questions">
        <section>
            <ul>
                <li><a href="#dates">When is the Public Interest Legal Career Fair?</a></li>
                <li><a href="#location">Where is the Fair?</a></li>
                <li><a href="#wifi">Is there WiFi available on site?</a></li>
                <li><a href="#application-review">When can I review applications and select students to interview?</a></li>
                <li><a href="#scheduling-process">How are students scheduled for interviews?</a></li>
                <li><a href="#interview-schedule">When will I find out my interview schedule?</a></li>
                <li><a href="#interview-cancel">What happens when a student cancels an interview?</a></li>
                <li><a href="#withdraw">How do I withdraw from the Fair?</a></li>
                <li><a href="#free-sign-up">What is Free Sign-Up?</a></li>
                <li><a href="#food">Is food provided?</a></li>
                <li><a href="#offers">Are there any policies I should be aware of when extending offers?</a></li>
            </ul>
        </section><br />
        <section>
            <h4 class="is-size-4" id="dates">When is the Public Interest Legal Career Fair?</h4>
            <p>
                The Fair will be held on {{ fairDate1 }} and {{ fairDate2 }}. Employer Check-In opens each day at 8:00 a.m. in Vanderbilt Hall.
                We recommend checking in as early as possible to avoid long wait times.  Interviews and Table Talk begin each day at 9:20 a.m. and end at 5:00 p.m.
            </p><br />

            <h4 class="is-size-4" id="location">Where is the Fair?</h4>
            <p>
                The Fair takes place at NYU School of Law. Employer Check-In takes place in Tishman Auditorium in Vanderbilt Hall, located at
                <a href="https://goo.gl/maps/s5CW6bXMwdUCdzGn7">40 Washington Square South</a>, between Macdougal and Sullivan Streets.
                All employer representatives must check in before proceeding to their interview and Table Talk locations.
            </p><br />
            <p>For detailed directions to the Law School, please <a href="http://www.law.nyu.edu/jdadmissions/visitingnyu/directionstocampus">click here</a>.</p><br />

            <h4 class="is-size-4" id="wifi">Is there WiFi available on site?</h4>
            <p>WiFi will be available to employers at the Fair. You will receive the WiFi password when you check in.</p><br />

            <h4 class="is-size-4" id="application-review">When can I review applications and select students to interview?</h4>
            <p>You may begin reviewing applications and selecting students to interview on {{ applicationReviewStart }}  You must complete all of your selections by {{ applicationReviewDeadline }}</p><br />

            <h4 class="is-size-4" id="scheduling-process">How are students scheduled for interviews?</h4>
            <p>
                After all employers submit their interview selections, the online system will run a scheduler that automatically fills students into interview slots based on the employers’ preferences and the students’ availability.
                Given the size and complexity of the program, it will not be possible to manually change interview times for any particular employer or student. In case of emergency, please contact students directly to make arrangements.
            </p><br />

            <h4 class="is-size-4" id="interview-schedule">When will I find out my interview schedule?</h4>
            <p>
                Initial interview schedules are expected to be published on {{ firstSchedulePublication }}.  Please be advised that this date is subject to change and is
                dependent upon all participating employers completing their interview selections by the deadline, {{ applicationReviewDeadline }}
                If you do not complete your selections by the deadline, you will delay both students and other employers from being able to access their interview schedules.
            </p><br />
            <p>
                Please note: Your interview schedule will not be final until 3:00 p.m. the day before your interviews
                (i.e., your schedule for Thursday will be final at 3:00 p.m. on Wednesday; your schedule for Friday will be final at 3:00 p.m. on Thursday).
                Your interview schedule will be accessible on your mobile device on the PILC Fair website, but we strongly recommending printing your schedule and bringing it with you to the Fair.
            </p><br />

            <h4 class="is-size-4" id="interview-cancel">What happens when a student cancels an interview?</h4>
            <p>
                When a student cancels a scheduled interview, that interview slot becomes open.
                Any open slots will be filled with your ranked applicants.
                If you selected to participate in Free Sign-Up, unranked applicants will also be included, but only after all ranked applicants have been given slots.
                (Rejected applicants will never be given open slots.)
            </p><br />
            <p>Cancellations after 3:00 p.m. on Wednesday are only permitted in case of emergency.  Students may cancel online but must email you directly as soon as possible to inform you of the situation.</p><br />

            <h4 class="is-size-4" id="withdraw">How do I withdraw from the Fair?</h4>
            <p>
                Withdrawing from the Fair is taken very seriously.  Students are counting on your participation, and you have taken a spot that could have been filled by another employer.
                Please only withdraw if absolutely necessary.  If you withdraw from the Fair after {{ employerRegistrationDeadline }}, you will be automatically waitlisted for next year’s Fair.
            </p><br />
            <p>To withdraw from the Fair, please email <a href="mailto:law.pilcfair@nyu.edu">law.pilcfair@nyu.edu</a> directly.</p><br />

            <h4 class="is-size-4" id="free-sign-up">What is Free Sign-Up?</h4>
            <p>
                If you agree to participate in Free Sign-Up, you will allow applicants that you do not rank when selecting candidates to interview,
                as well as students who apply after interview schedules are released, to be filled in for any open slots in your interview schedule.
                Open slots may occur if you do not have as many applicants as there are slots, if ranked applicants are not available due to scheduling conflicts,
                or if you do not rank enough applicants to fill your interview slots.
                Ranked applicants will always be given slots before unranked or new applicants.
                Applicants whom you have rejected will never be included in your interview schedule.
            </p><br />

            <h4 class="is-size-4" id="food">Is food provided?</h4>
            <p>
                Yes! Each day of the Fair, bagels and coffee will be provided at Employer Check-in, and you are invited to an Employer Luncheon from 12:30-1:45 p.m.
                in Rosenthal Pavilion, on the 10th Floor of the Kimmel Center.  Coffee and water will be available throughout the day.
            </p><br />

            <h4 class="is-size-4" id="offers">Are there any policies I should be aware of when extending offers to students?</h4>
            <p>Students are advised that they likely will hear from you within two weeks after the Fair, but you may extend offers to students at any time, including at the Fair.</p>
            <p>
                According to NALP’s Principles and Standards for Law Placement and Recruitment Activities, “offers to law student candidates should remain open for at least two weeks”
                and “candidates are expected to accept or release offers or request an extension by the applicable deadline.”
            </p><br />
            <p>
                For additional details, see <a href="https://www.nalp.org/fulltextofnalpprinciplesandstandards#Part_V._General_Standards">Part V: General Standards for the Timing of Offers and Decisions</a>.
                If you have questions, please contact <a href="mailto:law.pilcfair@nyu.edu">law.pilcfair@nyu.edu</a>.
            </p><br />
        </section>

    </card>
</template>

<script>
    export default {
        name: 'EmployerFaq',

        data() {
            return {
                // TODO: Fetch these dates, make sure they're actual dates. The formatting in these strings is how each of these should appear above.
                fairDate1: 'Thursday, February 6, 2020',
                fairDate2: 'Friday, February 7, 2020',
                applicationReviewStart: 'January 17, 2020, 9:00 a.m.',
                applicationReviewDeadline: 'January 29, 2020, 5:00 p.m.',
                firstSchedulePublication: 'January 31, 2020', 
                employerRegistrationDeadline: 'November 30, 2020, 5:00 p.m.'
            };
        }
    };
</script>

<style scoped>
    ul {
        list-style: disc;
        padding-left: 3em;
    }
</style>